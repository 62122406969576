import React from 'react';
import Edit_Automated_Quiz_Unit_Logic from './Edit_Automated_Quiz_Unit_Logic';

export default function Edit_Automated_Quiz_Unit({ isAboveLevel, isFinal }) {
	const {
		register,
		errors,
		isDirty,
		isValid,
		handleSubmit,
		create_unit_automated_quiz_form_error,
		create_unit_automated_quiz_form_progress,
		create_unit_automated_quiz_form_success,
		QuizId,
		onSubmit,
	} = Edit_Automated_Quiz_Unit_Logic({ isFinal, isAboveLevel });
	return (
		<>
			<div className='container Add_traditional_quiz card_quiz_create'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					تعديل إختبار
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation my-5'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='َQuizeName'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الإختبار
						</label>
						<input
							type='text'
							className='form-control'
							id='َQuizeName'
							{...register('QuizeName', { required: true })}
						/>
						{errors.QuizeName && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-4'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الدرجة
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control degreeinput'
							id='degree'
						/>
						{errors.degree && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='descriptionQuize'
							className='form-label fs-5 '
							style={{ color: '#044364 ' }}>
							الوصف
						</label>
						<input
							type='text'
							{...register('descriptionQuize', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.descriptionQuize && (
							<p className='text-danger'>Invalid input</p>
						)}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة
						</label>
						<input
							type='number'
							{...register('duration', { required: true })}
							className='form-control'
							id='duration'
						/>
						{errors.duration && <p className='text-danger'>Invalid input</p>}
					</div>

					{create_unit_automated_quiz_form_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{
									width: `${create_unit_automated_quiz_form_progress}%`,
								}}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>
								{create_unit_automated_quiz_form_progress}%{' '}
							</label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12'>
						<button
							disabled={!isDirty || !isValid}
							className='btn1'
							type='submit'
							style={{
								borderRadius: '10px ',
								minWidth: '150px ',
								padding: '10px 5px',
							}}>
							تعديل الاختبار
						</button>

						{create_unit_automated_quiz_form_error && (
							<label className='text-center w-100 text-danger'>
								{create_unit_automated_quiz_form_error?.response
									? create_unit_automated_quiz_form_error.response.data.message
									: create_unit_automated_quiz_form_error.message}
							</label>
						)}
					</div>
					{create_unit_automated_quiz_form_success && (
						<div class='alert alert-success text-center' role='alert'>
							تمت إضافة أسئلة الوحدة بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
