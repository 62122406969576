import React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetchAllUnitsFaqs from '../../../../../redux-toolkit/api/unit/getAllUnitFaqs';

export default function Show_Unit_Faqs_Logic() {
	const dispatch = useDispatch();

	const [page, setPage] = useState(1);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}

	const Authdata = useSelector((state) => state.SIGN.token);
	const { SubjectId, UnitId } = useParams();
	const [loading, setloading] = useState();
	const deleteFaq = (id) => {
		setloading(true);
		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_faqs/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};
	const { isLoading, isError, data, error, refetch } = useQuery(
		['sections', UnitId, 100, Authdata],
		fetchAllUnitsFaqs,
		{ enabled: true }
	);

	return {
		SubjectId,
		dispatch,
		data,
		isLoading,
		isError,
		error,
		refetch,
		loading,
		deleteFaq,
	};
}
