import React, { useEffect, useState, useRef } from 'react';
// import cantshowlesson from '../../assets/cant.jpg';

import LeaveComment from '../../components/LeaveComment';

import Slider from '../../components/slider';

import './index.css';

import Questino from '../../components/questino';
import Button from '../../components/button';

import Layout from '../Layout/Layout';
import { postCommentfun } from '../../redux-toolkit/reducers/PostCommentLesson/PostCommentLessonSlice';

import QuestionoModel from '../questionModel';

import LessonLogic from './lessonLogic';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import wrongAnswer from '../../assets/icons/wrongcheckbox.png';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { AudioPlayerControlSprite, Audio } from 'react-audio-player-pro';
import MainComment from '../../components/mainComment';
import save from '../../assets/icons/save.png';
import ReactPlayer from 'react-player';
import {
	getError,
	getSuccess,
} from '../../redux-toolkit/reducers/PostCommentLesson/PostCommentLessonSlice';
import { CaretRightOutlined } from '@ant-design/icons';
import { PauseOutlined } from '@ant-design/icons';
import { FastBackwardOutlined } from '@ant-design/icons';
import { FastForwardOutlined } from '@ant-design/icons';
import ToastError from '../../components/ToastSuccessError/ToastError';
import CountDown from '../../components/CountDoun';
import {
	getSuccess as getSuccessQ,
	getError as getErrorQ,
	clearMsg as clearMsgQ,
	setError,
	setSuccess,
} from '../../redux-toolkit/reducers/notification';
import MainHeader from '../../components/MainHeader/MainHeader';
import { useHistory } from 'react-router-dom';
import bg from '../../assets/lesson/bg.png';
import Modal from 'react-bootstrap/Modal';
import AccordionCustom from '../../components/Accordion';
import ButtonM from '../../components/ButtonM/ButtonM';
import SubTitle from '../../components/SubTitle/SubTitle';
import heart from '../../assets/icons/heart.svg';
function LessonScreen(props) {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	const {
		arr,
		finishExam,
		commentLesson,
		lessonDetails,
		showQuestionPopup,
		vidQueAnswered,
		showAnswers,
		currentTimeOfVideo,
		videoRef,
		lessonId,
		handleDownloadPdf,
		handleOpenPdf,
		handledownloadMp3,
		checkIfQuestionWasShoed,
		checkIfThereISAQuestionsInGivenTime,
		handleVideoPlay,
		setFinishExam,
		handleOpenMp3,
		// videoQuestions,
		setVidQueAnswered,
		hideQuestionOnTheScreen,
		questionToShow,
		setShowAnswers,
		loading,
		getNextLesson,
		loadingBtn,
		setloadingBtn,
		isVideoLoaded,
		setsuccmess,
		seterrmess,
		errmess,
		sucmess,
		errorNextAccess,
		UnitLessones,
		getUnitLessones,
		nextId
	} = LessonLogic();
	const token = localStorage.getItem('token');
	const lessonID = useParams();
	// https://java-course.online/api/get_all_automated_quiz_questions_with_options_by_lesson_id/1
	const [lessonAutomated, setLessonAutomated] = useState();
	const [studendAnswers, setStudentAnswers] = useState([]);
	const [startExam, setStartExam] = useState(false);
	const [lessonD, setLessonD] = useState();
	const navigate = useNavigate();
	const [loadingTimer, setloadingTimer] = useState(false);
	const successMsg = useSelector(getSuccess);
	const errorMsg = useSelector(getError);
	const successMsgQ = useSelector(getSuccessQ);
	const errorMsgQ = useSelector(getErrorQ);
	const [loadingExamm, setloadingExamm] = useState(true);
	const dispatch = useDispatch();
	const [result, setresult] = useState();
	const [imagesAtt, setimagesAtt] = useState(false);
	const [filesAtt, setfilesAtt] = useState(false);
	const [soundsAtt, setsoundsAtt] = useState(false);
	const [quizDetails, setquizDetails] = useState();
	const [errorMes, setErrorMes] = useState('');
	const [successMessage, setSucessMessage] = useState('');
	const [timer, settimer] = useState();
	const [LessonAutomatedWitAnswer, setLessonAutomatedWitAnswer] = useState([]);
	const [visibleTmer, setvisibleTmer] = useState(false);
	const [idNextLesson, setidNextLesson] = useState();
	const [Error, setError] = useState();
	const [finish, setfinish] = useState(true);
	const [showCorrecting, setShowCorrecting] = useState(false);
	const reactPlayerRef = useRef();
	const [totalDuration, setTotalDuration] = useState('00:00');
	const [currentVideoTime, setCurrentVideoTime] = useState('00:00');

	const [isVideoPlaying, setIsVideoPlaying] = useState(false);

	const [isItPlaying,SetIsItPlaying] = useState(false);

	const [seekValue, setseekValue] = useState(0);

	const video_Controler_Ref = useRef(null);
	const player = useRef();

	const [isFirstTimeRunTheVideo, setIsFirstTimeRungTheVideo] = useState(true);
	const [duration, setDuration] = useState('00:00');
	const [videoQulity, setVideoQuality] = useState('large');
	const [errorExamMessage, setErrorExamMessage] = useState(false);
	useEffect(() => {
		lessonDetails?.lesson_attachments?.map((attach, index) => {
			if (attach.lesson_attachment_type === '1') {
				setimagesAtt(true);
			}
			if (attach.lesson_attachment_type === '2') {
				setfilesAtt(true);
			}
			if (attach.lesson_attachment_type === '3') {
				setsoundsAtt(true);
			}
		});
	}, [lessonDetails?.lesson_attachments]);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = (e) => setShow(true);
	const [Saved, setSaved] = useState(true);

	const removeLesson = (id) => {
		// setloading(false);
		handleClose();

		const token = localStorage.getItem('token');
		console.log(id);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/remove_lesson_from_student_saved_lessons`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						lesson_id: id,
					},
				}
			)
			.then((res) => {
				// setloading(true);
				setSucessMessage('تم إالغاء الحفظ');
				setSaved(false);
			})
			.catch((err) => {
				// setloading(true);

				setErrorMes('لم يتم إالغاء الحفظ	');
				// alert('succes');
				// console.log(err);
			});
	};

	//************************************************************************************************** */
	//************************************************************************************************** */
	//************************************************************************************************** */
	const hanldeStudentAnswer = (questionID, optionID) => {
		const copyAnswers = [...studendAnswers];
		let isAnsweredBefore = false;

		for (let i = 0; i < copyAnswers.length; i++) {
			if (copyAnswers[i].aqq_id == questionID) {
				isAnsweredBefore = true;

				copyAnswers[i].option_id = optionID;
			}
		}

		if (!isAnsweredBefore) {
			copyAnswers.push({ aqq_id: questionID, option_id: optionID });
		}

		console.log('student answers ==> ', copyAnswers);
		setStudentAnswers(copyAnswers);
	};
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL}/get_all_automated_quiz_questions_with_options_by_lesson_id/${lessonID.id}`,
			{
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			}
		)
			.then((response) => response.json())
			.then((data) => {
				setLessonAutomated(data.data);
				console.log('lessondata', data);
			})
			.then((error) =>
				console.log('error inside automated quizez in lesson', error)
			);
	}, []);
	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_student_lesson_automated_quiz_questions_with_answers_by_lesson_id?lesson_id=${lessonID.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				setLessonAutomatedWitAnswer(data.data);
				setresult(data?.data?.result);
				console.log(
					'fffffffffffffffffffffffffffffffffffff',
					data?.data?.result
				);

				console.log('lessondata', data);
			})
			.catch((error) =>
				console.log('error inside automated quizez in lesson', error)
			);
	}, []);
	useEffect(() => {
		lessonDetails?.automated_quiz_id &&
			axios
				.get(
					`${process.env.REACT_APP_API_URL}/get_automated_quiz_by_id/${lessonDetails?.automated_quiz_id}`,
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				)
				.then((data) => {
					setquizDetails(data.data.data);

					console.log('fffffffffffffffffffffffffffffffffffff');

					console.log('quizdata', data.data);
				})
				.catch((error) =>
					console.log('error inside automated quizez in lesson', error)
				);
	}, [lessonDetails?.automated_quiz_id]);

	// useEffect(() => {
	// 	getUnitLessones(lessonDetails?.unit_id);
	// 	console.log('yeeeeeeeeeeeeeeeeeh', UnitLessones);
	// 	UnitLessones &&
	// 		UnitLessones?.map((lesson, index) => {
	// 			console.log('lesssssssssssssss ', lesson);
	// 			if (lesson?.lesson_id == lessonID.id) {
	// 				setidNextLesson(UnitLessones[index + 1].lesson_id);
	// 			}
	// 		});
	// }, [lessonDetails?.unit_id]);

	const openInNewTab = (url) => {
		window.open(url, '_blank');
	};
	const startQuiz = () => {
		if (lessonDetails?.has_lesson_gived_quiz_before) {
			setloadingTimer(true);
			//اذا لم يكن الطالب مقدم لهذا الامتحان من قبل ويمكنه حله سنقوم باستدعاء البدء بالاختبار وغير ذلك سنقوم باظهار رسالة له تخبره بالتفاصيل
			if (!lessonDetails?.can_solve_quiz) {
				setStartExam(true);
				finish == false && setfinish(true);
				studendAnswers.length > 0 && setStudentAnswers([]);
				// setStartExam(true) &&
				axios
					.post(
						`${process.env.REACT_APP_API_URL}/start_automated_quiz_by_automated_quiz_id`,
						{
							automated_quiz_id: lessonDetails?.automated_quiz_id,
						},
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					)
					.then((res) => {
						setloadingTimer(false);
						settimer(res?.data?.data?.remaining_time);

						console.log('end  Time', res?.data?.data);
					})
					.catch((err) => {
						setloadingTimer(false);
					});
			} else {
				// setsuccmess("عزيزي الطالب يجب أن تكون مشتركاً بالمادة و منهياً لجميع الدروس السابقة مع اختباراتها  للبدء بهذا الاختبار");
				// console.log('why i can not solve the exam 🤦‍♂️')
				setErrorExamMessage(true);
			}
		}
	};
	function getRandomNumberInRange(min, max) {
		return Math.random() * (max - min) + min;
	}
	const postAnswers = () => {
		setloadingExamm(false);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/post_the_answer_for_automated_quizzes_question`,
				{
					answers: studendAnswers,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				console.log('lesson automated quize', data);
				setresult(data?.data?.result);
				setSucessMessage('تم انجاز الاختبار');
				// dispatch(setSuccess(' تم انجاز الاختبار'));
				setFinishExam(true);
				setStartExam(false);
				localStorage.getItem('token') && window.location.reload();
				// getNextLesson();
			})
			.catch((error) => {
				error?.response?.data?.message ===
				'تمت الاجابة على هذا السؤال من قبل هذا الطالب من قبل'
					? setErrorMes('تمت الاجابة على هذا السؤال من قبل هذا الطالب من قبل')
					: // dispatch(
					  // 		setError('تمت الاجابة على هذا السؤال من قبل هذا الطالب من قبل')
					  //   )
					  setError(error.response.data.message);
				setfinish(false);
				// dispatch(setError(error?.response?.data?.message));
				setFinishExam(false);
				setStartExam(false);
				console.log('error inside lesson quize', error);
			});

		// setFinishExam(true);

		console.log('asnswersssss', studendAnswers);
	};
	useEffect(() => {
		if (timer) {
			setvisibleTmer(true);
		} else if (quizDetails?.quiz_remaining_time) {
			setvisibleTmer(true);
		} else {
			setvisibleTmer(false);
		}
	}, [timer, quizDetails?.quiz_remaining_time]);

	useEffect(() => {
		const intervalID = setInterval(() => {
			// duration in minutes and presentage seconds
			let duration = player?.current?.getInternalPlayer()?.getDuration();
			// console.log("duration is is is " , duration);

			// wait unitl the video player loaded and get the right values
			if (player?.current?.getInternalPlayer()?.getDuration()) {
				// const presentageSeconds = duration.toString()[2]+duration.toString()[3];
				// const seconds = presentageSeconds * 60;
				// const minutes = duration.toString()[0]+duration.toString()[1];

				// // console.log('minutes are are are ', minutes);
				// // console.log('seconds are are are ', seconds);

				// setTotalDuration(minutes + ":" + seconds);

				toHHMMSSTotalDuration(duration);
				// setseekValue(duration)

				clearInterval(intervalID);
			}
		}, 1000);
	}, []);

	const removeAdditionalYoutubeThings = () => {
		var iframe =
			document.getElementsByTagName('iframe')[0]?.contentWindow?.document;

		if (iframe) {
			iframe
				.getElementsByClassName('ytp-pause-overlay ytp-scroll-min')[0]
				.remove();
			iframe
				.getElementsByClassName('ytp-chrome-top ytp-show-cards-title')[0]
				.remove();
		}

		// console.log('iframe youtube is =>', iframe[0].contentWindow.document);
		// for title
		//ytp-chrome-top ytp-show-cards-title

		// for recommonded videos
		//ytp-pause-overlay ytp-scroll-min

		// var youtube = document.getElementById('widget2');
		// youtube = youtube.contentWindow;
		// console.log('youtube ', youtube)
		// youtube.oncontextmenu = function(e) {
		// e.preventDefault();
		// console.log("Blocked successfully ! ".repeat(50));
		// }
	};

	const handleUnstarted = (e) => {
		console.log('unstarted video', e);
	};

	const onChangeBitrate = (event) => {
		const internalPlayer = player.current?.getInternalPlayer('hls');
		if (internalPlayer) {
			// currentLevel expect to receive an index of the levels array
			internalPlayer.currentLevel = event.target.value;
		}
	};

	const hadlechangeURl = () => {
		console.log('url is => ', player?.current?.props);
	};

	var toHHMMSSCurrentTime = (secs) => {
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		let lastHours = hours > 9 ? hours : '0' + hours;
		let lastMinutes = minutes > 9 ? minutes : '0' + minutes;
		let lastSeconds = seconds > 9 ? seconds : '0' + seconds;

		if (hours == 0) {
			setCurrentVideoTime(lastMinutes + ':' + lastSeconds);
		} else {
			setCurrentVideoTime(lastHours + ':' + lastMinutes + ':' + lastSeconds);
		}
	};
	var toHHMMSSTotalDuration = (secs) => {
		var sec_num = parseInt(secs, 10);
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor(sec_num / 60) % 60;
		var seconds = sec_num % 60;

		let lastHours = hours > 9 ? hours : '0' + hours;
		let lastMinutes = minutes > 9 ? minutes : '0' + minutes;
		let lastSeconds = seconds > 9 ? seconds : '0' + seconds;

		if (hours == 0) {
			setTotalDuration(lastMinutes + ':' + lastSeconds);
		} else {
			setTotalDuration(lastHours + ':' + lastMinutes + ':' + lastSeconds);
		}
	};

	useEffect(() => {
		let intervalId = setInterval(() => {
			// duration in minutes and presentage seconds
			let duration = player?.current?.getInternalPlayer()?.getCurrentTime();
			// console.log("duration is is is " , duration);

			// wait unitl the video player loaded and get the right values
			if (player?.current?.getInternalPlayer()?.getCurrentTime()) {
				// let presentageSeconds = duration.toString()[2]+duration.toString()[3];

				// let seconds = presentageSeconds * 60;

				// let minutes = duration.toString()[0]+duration.toString()[1];

				// console.log('minutes are are are ', minutes);
				// console.log('seconds are are are ', seconds);
				// if(seconds.toString().length == 1) {
				//  seconds = '0' + seconds;
				// }
				// if(minutes.toString().length == 1){
				//  minutes = minutes + '0';
				// }

				// console.log('previous secons are ', currentVideoTime)
				// console.log('dur du dur',duration)
				// removeAdditionalYoutubeThings();
				toHHMMSSCurrentTime(duration);
				setDuration(player?.current?.getInternalPlayer()?.getDuration());
				if (isFirstTimeRunTheVideo) {
					setseekValue(duration);
					setIsFirstTimeRungTheVideo(false);
				}

				// console.log( 'current vidoe viiiiii ' , player?.current?.player?.isPlaying)

				// SetIsItPlaying(player?.current?.player?.isPlaying);
				// clearInterval(intervalId);
			}
		}, 1000);
	}, []);

	const handleToggleVideoPlayer = () => {
		// console.log('is playing', isVideoPlaying)
		if (isVideoPlaying) {
			player.current.getInternalPlayer().pauseVideo();
			// video_Controler_Ref.current.style.bottom = '0px !important';
		} else {
			player.current.getInternalPlayer().playVideo();

			// setTimeout(() => {

			//  video_Controler_Ref.current.style.bottom = '-100% !important';
			// }, 3000);
		}

		setIsVideoPlaying(prev => !prev);
	};
	const handleSeekForward = () => {
		if (!isVideoPlaying) {
			setIsVideoPlaying(true);
			player.current.getInternalPlayer().playVideo();
		}
		toHHMMSSCurrentTime(
			player.current.getInternalPlayer().getCurrentTime() + 10
		);
		player?.current
			?.getInternalPlayer()
			?.seekTo(player.current.getInternalPlayer().getCurrentTime() + 10);
	};

	const handleSeekBackward = () => {
		if (!isVideoPlaying) {
			setIsVideoPlaying(true);
			player.current.getInternalPlayer().playVideo();
		}
		toHHMMSSCurrentTime(
			player.current.getInternalPlayer().getCurrentTime() - 10
		);
		player?.current
			?.getInternalPlayer()
			?.seekTo(player.current.getInternalPlayer().getCurrentTime() - 10);
	};

	const handleOnSeekChange = (e) => {
		// console.log('seeking to', e.target.value);
		setseekValue(e.target.value);
		toHHMMSSCurrentTime(e.target.value);
		player?.current?.getInternalPlayer().seekTo(e.target.value);

		// if(!isVideoPlaying){
		//  setIsVideoPlaying(true)
		//  player.current.getInternalPlayer().playVideo()
		// }
	};

	// prevent context menu on this page
	useEffect(() => {
		document.onkeydown = keyboardDown;
		document.onkeyup = keyboardUp;
		document.oncontextmenu = function (e) {
			var evt = new Object({ keyCode: 93 });
			stopEvent(e);
			keyboardUp(evt);
		};
		function stopEvent(event) {
			if (event.preventDefault != undefined) event.preventDefault();
			if (event.stopPropagation != undefined) event.stopPropagation();
		}
		function keyboardDown(e) {
			if (e.keyCode == 123) {
				stopEvent(e);
			}
		}
		function keyboardUp(e) {
			if (e.keyCode == 123) {
				stopEvent(e);
			}
		}

		window.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});

		// setInterval(() => {
		//  // document.getElementsByTagName('iframe')[0].contentWindow.window.addEventListener('contextmenu', (e) => {e.preventDefault()})
		//  // console.log('docuemnt for first iframe', document.getElementsByTagName('iframe')[0].contentWindow.window.addEventListener('contextmenu', (e) => {e.preventDefault()}));
		// }, 1000);
	}, []);

	const handleMouseEnterVideo = () => {
		// console.log("mouse enter ",video_Controler_Ref?.current?.style.bottom )
		console.log(video_Controler_Ref.current.style.bottom)
		// video_Controler_Ref.current.style.bottom = '0 !important';
		video_Controler_Ref.current.classList.remove('hide_controller');
		video_Controler_Ref.current.classList.add('show_controller');
		// setTimeout(() => {
		//  video_Controler_Ref.current.style.bottom = '0 !important';
		// }, 3000);
	};

	const handleMouseLeaveVideo = () => {

		video_Controler_Ref.current.classList.remove('show_controller');
		video_Controler_Ref.current.classList.add('hide_controller');

		// video_Controler_Ref.current.style.bottom = '-20% !important';
	};

	const handleOnMouseClickVideo = (event) => {
		// console.log('clicking video ', video_Controler_Ref.current.style.bottom)

		event.stopPropagation();
		// console.log('click video controler bottom value ', video_Controler_Ref.current)
		if ((video_Controler_Ref.current.style.bottom = '0 !important')) {
			video_Controler_Ref.current.style.bottom = '-20% !important';
		} else {
			video_Controler_Ref.current.style.bottom = '0 !important';
		}

		if (isVideoPlaying) {
			player.current.getInternalPlayer().pauseVideo();
			video_Controler_Ref.current.style.bottom = '0px !important';

			setTimeout(() => {
				video_Controler_Ref.current.style.bottom = '-20% !important';
			}, []);
		} else {
			player.current.getInternalPlayer().playVideo();

			video_Controler_Ref.current.style.bottom = '0px !important';
		}

		setIsVideoPlaying(!isVideoPlaying);

		// setTimeout(() => {
		//  video_Controler_Ref.current.style.bottom = '0 !important';
		// }, 3000);
	};

	const handleOnTouchStart = () => {
		console.log('clicking video ', video_Controler_Ref.current.style.bottom);
		if ((video_Controler_Ref.current.style.bottom = '0 !important')) {
			video_Controler_Ref.current.style.bottom = '-20% !important';
		} else {
			video_Controler_Ref.current.style.bottom = '0 !important';
		}

		if (isVideoPlaying) {
			player.current.getInternalPlayer().pauseVideo();
			// video_Controler_Ref.current.style.bottom = '0px !important';
		} else {
			player.current.getInternalPlayer().playVideo();
		}

		setIsVideoPlaying(!isVideoPlaying);
	};

	const handleOnTouchCancel = () => {
		// video_Controler_Ref.current.style.bottom = '-100% !important';
	};

	const handleClickVideoController = (event) => {
		video_Controler_Ref.current.style.bottom = '0 !important';
		// setTimeout(() => {
		//  video_Controler_Ref.current.style.bottom = '-100 !important';
		// }, 3000);
		
		event.stopPropagation();
	};

	useEffect(() => {
		// console.log('video is not playeing now ? ' , isVideoPlaying);
		// video_Controler_Ref.current.style.bottom = '0 !important';
		// if(isVideoPlaying) {
		// }else {
		// }
	}, [isVideoPlaying]);

	const handleQualityChange = (e) => {
		console.log(e.target.value);
		player?.current?.getInternalPlayer()?.setPlaybackQuality(e.target.value);
		// player?.current?.getInternalPlayer()?.setOption()
		setVideoQuality(e.target.value);
	};

	function detectLanguage(text) {
		// split into words
		const langs = text
			.trim()
			.split(/\s+/)
			.map((word) => {
				return detect(word);
			});

		// pick the lang with the most occurances
		return (langs || []).reduce(
			(acc, el) => {
				acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1;
				acc.max = acc.max ? (acc.max < acc.k[el] ? el : acc.max) : el;
				return acc;
			},
			{ k: {} }
		).max;

		function detect(text) {
			const scores = {};

			const regexes = {
				en: /[\u0000-\u007F]/gi,
				zh: /[\u3000\u3400-\u4DBF\u4E00-\u9FFF]/gi,
				hi: /[\u0900-\u097F]/gi,
				ar: /[\u0621-\u064A\u0660-\u0669]/gi,
				bn: /[\u0995-\u09B9\u09CE\u09DC-\u09DF\u0985-\u0994\u09BE-\u09CC\u09D7\u09BC]/gi,
				he: /[\u0590-\u05FF]/gi,
			};

			for (const [lang, regex] of Object.entries(regexes)) {
				// detect occurances of lang in a word
				let matches = text.match(regex) || [];
				let score = matches.length / text.length;

				if (score) {
					// high percentage, return result
					if (score > 0.85) {
						return lang;
					}
					scores[lang] = score;
				}
			}

			// not detected
			if (Object.keys(scores).length == 0) return null;

			// pick lang with highest percentage
			return Object.keys(scores).reduce((a, b) =>
				scores[a] > scores[b] ? a : b
			);
		}
	}

	const values = [
		'I am a happy cow', // English
		'Je suis une vache heureuse', // French
		'Ich bin eine glückliche Kuh', // German
		'Soy una vaca feliz', // Spanish
		'Saya sapi yang bahagia', // Indonesian
		'我是一头快乐的牛', // Chinese Simplified
		'我是一頭快樂的牛', // Chinese Traditional
		'私は幸せな牛です', // Japanese
		'मैं एक खुश गाय हूँ', // Hindi
		'انا بقرة سعيدة', // Arabic
		'Я счастливая корова', // Russian
	];

	const data = {};

	values.forEach((value) => {
		data[value] = detectLanguage('I am a happy cow');
	});

	// ******************************************************************
	const [pagination, setpagination] = useState(3);
	const [prevPagination, setprevPagination] = useState(0);
	const next = () => {
		if (pagination < lessonDetails?.faqs?.length) {
			setpagination(pagination + 3);
			setprevPagination(prevPagination + 3);
		}
	};
	const prev = () => {
		if (prevPagination >= 5) {
			setpagination(pagination - 3);
			setprevPagination(prevPagination - 3);
		}
	};
	const [paginationComment, setpaginationComment] = useState(3);
	const [prevPaginationComment, setprevPaginationComment] = useState(0);

	const nextComment = () => {
		if (paginationComment < commentLesson?.data?.length) {
			setpaginationComment(paginationComment + 3);
			setprevPaginationComment(prevPaginationComment + 3);
		}
	};
	const prevComment = () => {
		if (prevPaginationComment >= 3) {
			setpaginationComment(paginationComment - 3);
			setprevPaginationComment(prevPaginationComment - 3);
		}
	};

	const handleNavigate = () => {
		navigate('/allComments', {
			state: { url: `${process.env.REACT_APP_API_URL}/get_all_comments_of_lesson_by_lesson_id/${lessonId?.id}` },
		});
	};
	const handleNavigateImage = (url) => {
		navigate('/image', {
			state: { url: url },
		});
	};

	// ******************************************************************

	 if ( (!lessonDetails?.access && lessonDetails?.isFree == 0) ) {
		// console.log('role is', localStorage.getItem('role'))
		// console.log('errrrr'.repeat(200));
		return (
			<Layout>
				{/* <MainHeader /> */}
				<div
					className='my-5'
					style={{
						display: 'grid',
						placeItems: 'center',
					}}>
					<i class="fa fa-lock h1 text-danger" style={{fontSize: '5rem'}} aria-hidden="true"></i>

					<div class="alert alert-danger h4 my-4 text-center shadow" role="alert">
						عذرا عزيزي الطالب لا يمكنك متابعة هذه الدرس كونك غير مشترك بهذا الكورس
					</div>
				</div>
			</Layout>
		);
	} else
		return (
			<Layout>
				{/* <MainHeader /> */}
				<div className='lessonScreen'>
					<div className='text-center'>
						{/* <h3 className='fw-bold  my-5 mx-auto '>
							{lessonDetails?.lesson_name && lessonDetails?.lesson_name}
						</h3> */}
						{lessonDetails?.lesson_name && (
							<SubTitle
								title={lessonDetails?.lesson_name && lessonDetails?.lesson_name}
							/>
						)}
						{lessonDetails?.is_saved && Saved && (
							<>
								<button
									className='border-0 bg-light'
									data-toggle='tooltip'
									data-placement='right'
									title='ألغاء حفظ الدرس'>
									<svg
										onClick={handleShow}
										style={{ color: 'red', cursor: 'pointer' }}
										src={heart}
										// xmlns='http://www.w3.org/2000/svg'
										width='34px'
										height='34px'
										fill='currentColor'
										className='bi bi-heart-fill mt-2 mx-2 '
										viewBox='0 0 16 16'>
										<path
											fill-rule='evenodd'
											d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z'
										/>
									</svg>
								</button>
								{/* <img src={save} onClick={handleShow} /> */}
								<Modal show={show} onHide={handleClose}>
									<Modal.Body>هل تريد إلغاء الحفظ ؟؟</Modal.Body>
									<Modal.Footer>
										<button
											// backgroundColor={'#5C636A'}
											style={{
												// backgroundColor: '#5C636A',
												border: 'none',
												borderRadius: '5px',
											}}
											onClick={handleClose}
											className=' fs-5 text-white mx-2 py-2 px-3 btn1'>
											الغاء
										</button>

										<button
											// backgroundColor={'#0B5ED7'}
											style={{
												// backgroundColor: '#0B5ED7',
												border: 'none',
												borderRadius: '5px',
											}}
											onClick={() => removeLesson(lessonId.id)}
											className='fs-5 text-white mx-2 py-2 px-3 btn2'>
											تاكيد
										</button>
									</Modal.Footer>
								</Modal>
							</>
						)}
					</div>

					<div className='whatUWlearn '>
						<div className='imageCon'>
							<div className='video-container col-md-12 p-0 mx-auto '>
								<div className='iframeContainer'>
									<div className='hideSharebtn'></div>
									<div className=' mt-4 mx-auto container rounded-x overflow-hidden  '>
										{/* <iframe
										src={lessonDetails?.lesson_first_video}
										title='YouTube video'
										allowFullScreen></iframe> */}

										{/* React player for youtube  */}

										<div
											className='wrapVideo position-relative'
											onMouseEnter={() => handleMouseEnterVideo()}
											onMouseLeave={() => handleMouseLeaveVideo()}
											onClick={(e) => handleOnMouseClickVideo(e)}
											//  onTouchStart={() => handleOnMouseClickVideo()}
											//  onTouchCancel={() => handleOnTouchCancel()}
										>
											<div
												className='player-wrapper ratio ratio-16x9 w-100'
												id='reactPlayer_container_youtube'>
												{isVideoLoaded ? (
													<ReactPlayer
														width={'100%'}
														height={'100%'}
														controls={false}
														ref={player}
														onStart={() => console.log('video starting')}
														onPlay={(e) => {
															console.log('ready'.repeat(50));
														}}
														onSeek={() => console.log('seek to to')}
														// playing
														url={
															lessonDetails?.lesson_first_video.split(
																'&list'
															)[0]
														}
														pip={'false'}
														config={{
															youtube: {
																playerVars: {
																	showinfo: 0,
																	modestbranding: 1,
																	fs: 0,
																	rel: '0', // related videos
																	modestbranding: 1,
																	enablejsapi: 1,
																	cc_load_policy: 1,
																	disablekb: 1,
																	vq: 'small',
																},

																embedOptions: {
																	events: (e) => e.preventDefault(),
																},

																onUnstarted: (e) => handleUnstarted(e),
															},
															file: {
																attributes: {
																	controlsList: 'nodownload',
																	onContextMenu: (e) => e.preventDefault(),
																	disablepictureinpicture: 'true',
																},
																tracks: [
																	{
																		kind: 'subtitles',
																		src: 'subs/subtitles.en.vtt',
																		srcLang: 'ar',
																		default: true,
																	},
																	{
																		kind: 'subtitles',
																		src: 'subs/subtitles.ja.vtt',
																		srcLang: 'ja',
																	},
																	{
																		kind: 'subtitles',
																		src: 'subs/subtitles.de.vtt',
																		srcLang: 'de',
																	},
																],
															},
														}}
													/>
												) : (
													<div className='my-5 text-center w-100'>
														<div class='spinner-border' role='status'>
															<span class='visually-hidden'>Loading...</span>
														</div>
													</div>
												)}
											</div>

											<div
												className='video_controler show_controller'
												ref={video_Controler_Ref}
												onClick={(event) => { handleClickVideoController(event)} }>
												<div className='controller_wrapper'>
													{/* <div className="pausePlay_container"> */}
													<div className='wrapper_firstControler'>
														<FastBackwardOutlined
															onClick={() => handleSeekBackward()}
															width={50}
															style={{ color: 'white', fontSize: '30px' }}
														/>
														{isVideoPlaying ? (
															<PauseOutlined
																width={50}
																style={{ color: 'white', fontSize: '30px' }}
																onClick={() => handleToggleVideoPlayer()}
															/>
														) : (
															<CaretRightOutlined
																width={50}
																style={{ color: 'white', fontSize: '30px' }}
																onClick={() => handleToggleVideoPlayer()}
															/>
														)}

														<FastForwardOutlined
															onClick={() => handleSeekForward()}
															width={50}
															style={{ color: 'white', fontSize: '30px' }}
														/>

														<div className='duration_container'>
															{totalDuration} / {currentVideoTime}
														</div>
													</div>

													{/* </div> */}

													<div className='rangebar_container'>
														{/* 
	<Range
        step={1}
        min={0}
        max={player?.current?.getInternalPlayer().getDuration()}
        values={seekValue}
        onChange={(values) => handleOnSeekChange(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#ccc'
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              backgroundColor: '#999'
            }}
          />
        )}
      /> */}

														<input
															type='range'
															dir='ltr'
															id='points'
															name='points'
															min='0'
															// defaultValue={seekValue}
															max={duration}
															value={seekValue}
															style={{
																width: '100%',
																height: '3px',
																outline: 'none',
															}}
															onChange={(e) => handleOnSeekChange(e)}
															onInput={(e) => handleOnSeekChange(e)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{showQuestionPopup && (
								<QuestionoModel
									question={questionToShow}
									show={showQuestionPopup}
									hideFunction={hideQuestionOnTheScreen}
									answeredQuestions={setVidQueAnswered}
								/>
							)}
						</div>

						<div className=' mb-5'>
							<SubTitle
								title={'سوف تتعلم في هذه الدرس'}
								className='text-center'
							/>
							<div className='mt-3   px-5  text-center'>
								<div className='my-5' style={{ listStyle: 'none' }}>
									{lessonDetails &&
										lessonDetails?.what_we_will_learn?.map((whl, index) => {
											return (
												<li className='fs-4 ' key={index}>
													{whl}
												</li>
											);
										})}
								</div>
							</div>

							<hr />
						</div>
						<div className='mb-5'>
							<SubTitle title={'معلومات عن الدرس'} />
							<div className='  text-center  '>
								<div className='   mt-5 '>
									<div className=' fs-4'>
										<span className='mx-2'> مدة الدرس :</span>
										<span className='mx-1'>
											<strong>
												{lessonDetails?.lesson_duration &&
													lessonDetails?.lesson_duration}
											</strong>
										</span>
									</div>
									<div className=' mt-2 fs-4'>
										<span className='mx-2 '> عدد النقاط :</span>
										<span className='mx-1'>
											<strong>
												{lessonDetails?.lesson_points &&
													lessonDetails?.lesson_points}
											</strong>
										</span>
									</div>

									{lessonDetails?.teacher_url && lessonDetails?.access && (
										<p
											className='text-center mx-auto  '
											style={{ borderRadius: '20px' }}>
												<a
													style={{ fontSize: '1.2rem' }}
													className=' '
													href={lessonDetails?.teacher_url}>
											<ButtonM
												text={'تواصل مع الأستاذ'}
												className='mx-auto my-4'>
												
											</ButtonM>
											</a>
										</p>
									)}
								</div>
							</div>
						</div>

						<hr />
						{/* 
						<div className='mb-5'>
							<SubTitle title={'متطلبات الدرس'} />
							<div className=' '></div>
							<div className='text-center'>
								<div className=' fs-4'>
									<div className='text-center '>
										<h4
											className='mx-auto fw-bold  my-5'
											style={{ color: '#044364' }}>
											{' '}
										</h4>
									</div>

									<ul style={{ listStyle: 'none', width: '100%' }}>
									
										<li className='my-3 '>
											{' '}
											توضح لك الدورة التدريبية بالضبط كيفية العمل باستخدام
											برنامج الفوتوشوب و صبح خبيرًا
										</li>
										<li className='my-3'>
											{' '}
											يتم توفير ملفات التمرين وأدلة الدراسة! تحميل الفيديوهات
											للسفر
										</li>
										<li className='my-3'>
											{' '}
											ما عليك سوى استخدام برنامج فوتوشوب أي اصدار
										</li>
										<li className='my-3'>
											{' '}
											ما عليك سوى استخدام برنامج فوتوشوب أي اصدار
										</li>
										<li className='my-3'>
											{' '}
											لتأكيد لا توجد خبرة مطلوبة. نبدأ بالأساسيات
										</li>
									</ul>
								</div>
							</div>
						</div>

						<hr /> */}
					</div>
					{/* {soundsAtt && filesAtt && imagesAtt && (
					<TitleSection title='مرفقات الدرس ' />
				)} */}
					{imagesAtt && (
						<div className='imageGallary'>
							<div className='slider'>
								{lessonDetails?.lesson_attachments?.length > 0 ? (
									<Slider gap={30}>
										{lessonDetails?.lesson_attachments?.map((attach, index) => {
											if (attach.lesson_attachment_type === '1') {
												return (
													// <Link
													// 	className='imgAttach'
													// 	to='/image'
													// 	state={{
													// 		url: `${process.env.REACT_APP_STORAGE_URL}/${attach.lesson_attachment_file}`,
													// 	}}
													// 	style={{ width: '31%' }}>
													<img
														onClick={() =>
															handleNavigateImage(
																`${process.env.REACT_APP_STORAGE_URL}/${attach.lesson_attachment_file}`
															)
														}
														width={'31%'}
														// height='500px'
														className=' '
														src={`${process.env.REACT_APP_STORAGE_URL}/${attach.lesson_attachment_file}`}
														alt='Second slide'
														style={{ cursor: 'pointer' }}
													/>
													// </Link>
												);
											}
										})}
									</Slider>
								) : (
									<span>لا يوجد مرفقات صور</span>
								)}
							</div>
						</div>
					)}
					{filesAtt && (
						<div className='paperAttach container px-5'>
							<div className='mt-3'>
								<h4 style={{ color: '#044364' }} className='fw-bold '>
									ملفات الدرس :
								</h4>
							</div>

							{lessonDetails?.lesson_attachments?.length > 0 ? (
								<>
									{lessonDetails?.lesson_attachments?.map((attach, index) => {
										if (attach.lesson_attachment_type === '2') {
											return (
												<div className='row item_file mt-5 ' key={index}>
													<div className='col-lg-10 col-md-9 col-sm-8 col-7 py-3 file_title'>
														{attach.lesson_attachment_title}
													</div>
													<div className='col-lg-2 col-md-3 col-sm-4 col-5 btn_download'>
														<a
															className='text-white'
															onClick={() =>
																openInNewTab(
																	`${process.env.REACT_APP_STORAGE_URL}${attach.lesson_attachment_file}`
																)
															}>
															PDF تحميل{' '}
														</a>
													</div>
												</div>
											);
										}
									})}
								</>
							) : (
								<span>لا يوجد مرفقات كتابية</span>
							)}
						</div>
					)}
					{soundsAtt && (
						<div className='paperAttach container px-5 my-5'>
							<div className=''>
								<h4 style={{ color: '#044364' }} className='fw-bold '>
									ملفات الصوت للدرس :
								</h4>
							</div>

							{lessonDetails?.lesson_attachments?.length > 0 ? (
								<>
									{lessonDetails?.lesson_attachments?.map((attach, index) => {
										if (attach.lesson_attachment_type === '3') {
											return (
												<div className='row item_file mt-5 '>
													<div className='col-lg-6 col-md-4 col-sm-12 col-12 py-3 file_title'>
														{attach.lesson_attachment_title}
													</div>
													<div className='col-lg-6 col-md-8 col-sm-12 col-12  '>
														<AudioPlayerControlSprite />
														<Audio
															// string - path to audio file, required
															src={`${process.env.REACT_APP_STORAGE_URL}${attach.lesson_attachment_file}`}
															// MediaMetadata - media meta data
															// https://developer.mozilla.org/en-US/docs/Web/API/MediaMetadata/MediaMetadata
															// optional
															mediaMetadata={{
																// required
																title: 'Pure Water',

																// optional
																artist: 'Meydän',

																// optional
																album: 'Interplanetary Forest',

																// optional
																artwork: [
																	// src, sizes and type is required
																	{
																		src: '../assets/facebook.png',
																		sizes: '64x64',
																		type: 'image/png',
																	},
																	{
																		src: '../assets/facebook.png',
																		sizes: '128x128',
																		type: 'image/png',
																	},
																],
															}}
															// string - wrapper's class name, optional, deafult: ''
															className='my-class-name'
															// callback function - called on did mount, optional, default: noop
															onDidMount={console.log('what the hack is this')}
															// string - name for download file, optional, deafult: <src>
															// downloadFileName={'../../assets2/clock_sound.mp3'}
															// downloadFileName
															// boolean - show repeat button, optional, deafult: false
															useRepeatButton={true}
														/>
													</div>
												</div>
											);
										}
									})}
								</>
							) : (
								<span>لا يوجد مرفقات صوتية</span>
							)}
						</div>
					)}
					<div className='container'>
						{lessonDetails?.has_lesson_gived_quiz_before ? (
							<div className=''>
								<h4 style={{ color: '#044364' }} className='fw-bold '>
									تحقق من معلوماتك
								</h4>
								<div></div>
								<p className='d-block my-3'>
									{' '}
									تحقق من معلوماتك عبر الإجابة عن الأسئلة التالية.
								</p>
							</div>
						) : null}
						{localStorage.getItem('token') ? (
							<>
								{localStorage.getItem('role') == 1 ? (
									<>
										{lessonDetails?.has_student_answered_on_lesson_quiz ? (
											<div className='lesson__questions'>
												{LessonAutomatedWitAnswer?.automated_quiz_text && (
													<div
														className='lesson_question_text'
														dangerouslySetInnerHTML={{
															__html:
																LessonAutomatedWitAnswer?.automated_quiz_text,
														}}></div>
												)}
												<ol type='1' className='questions__container'>
													{LessonAutomatedWitAnswer?.data?.map(
														(quistion, index) => (
															<li className={`question`}>
																<div className='question__container'>
																	<div className='topsection'>
																		<h4
																			dangerouslySetInnerHTML={{
																				__html: quistion.question,
																			}}></h4>
																	</div>

																	<div className='answers'>
																		{quistion?.the_options?.map(
																			(answer, id) => {
																				return (
																					<div
																						className={`answerItself ${
																							answer.is_true == '1'
																								? 'rightAnswer'
																								: null
																						} }`}>
																						<label
																							className='mt-2 fs-4'
																							dangerouslySetInnerHTML={{
																								__html: answer.answear,
																							}}></label>
																						<input
																							style={{
																								width: '18px',
																								height: '18px',
																								borderRadius: '30%',
																							}}
																							type='radio'
																							className={`${
																								answer.is_student_answer &&
																								answer.is_true == 0
																									? 'hidecheckbox'
																									: ''
																							} mx-2`}
																							checked={answer.is_true == '1'}
																							value={answer.text}
																							name={index}
																						/>
																						<div className='statusImage'>
																							{answer.is_student_answer &&
																							0 == answer.is_true ? (
																								<img src={wrongAnswer} alt='' />
																							) : null}
																						</div>
																					</div>
																				);
																			}
																		)}
																	</div>
																</div>
															</li>
														)
													)}
												</ol>
												<div className='text-center'>
													<Button
														text={`النتيجة ${
															result ? Math.round(result) + '/100   ' : '0'
														}`}
														className='examResultBtn mx-auto'
													/>
												</div>
											</div>
										) : (
											<>
												{(lessonDetails?.access || lessonDetails?.isFree) &&
													lessonDetails?.has_lesson_gived_quiz_before && (
														<>
															{/* <div className='examScreen__explanatino'>
															<h2>شرح عن الاختبار</h2>
															<ul>
																<li>
																	{quizDetails && quizDetails.description}
																</li>
															</ul>
														</div> */}
															{Error && (
																<div class='alert alert-dark' role='alert'>
																	{Error}
																</div>
															)}
															{!startExam && (
																<div className='w-100 text-center'>
																	<button
																		onClick={() => startQuiz()}
																		className='startExamBtn '>
																		{quizDetails?.quiz_remaining_time &&
																		quizDetails?.quiz_remaining_time
																			? '	الاستمرار بالاختبار'
																			: Error
																			? 'إعادة الإختبار'
																			: 'البدء بالاختبار'}
																	</button>
																</div>
															)}
															<div className='lesson__questions'>
																<ul
																	type='1'
																	className='questions__container'
																	style={{ listStyle: 'none' }}>
																	{loadingTimer ? (
																		<div class='spinner-border' role='status'>
																			<span class='sr-only'>Loading...</span>
																		</div>
																	) : (
																		<>
																			{visibleTmer && startExam && !Error && (
																				<CountDown
																					start={startExam}
																					endTime={
																						quizDetails &&
																						quizDetails?.quiz_remaining_time
																							? quizDetails.quiz_remaining_time
																							: timer
																					}
																				/>
																			)}
																		</>
																	)}
																	{quizDetails &&
																		!(
																			quizDetails?.quiz_remaining_time || timer
																		) &&
																		startExam &&
																		!loadingTimer && (
																			<div
																				class='alert alert-dark'
																				role='alert'>
																				انتهى الوقت يمكنك إرسال الإجابات لكن لن
																				تحصل على النقاط
																			</div>
																		)}

																	{startExam &&
																		lessonAutomated &&
																		quizDetails?.automated_quiz_text && (
																			<div
																				className='lesson_question_text'
																				dangerouslySetInnerHTML={{
																					__html:
																						quizDetails?.automated_quiz_text,
																				}}></div>
																		)}

																	{startExam &&
																		lessonAutomated &&
																		lessonAutomated.map((question, index) => {
																			return (
																				<>
																					{/* '2022-08-28T17:14:02.758265Z' */}

																					<Questino
																						showAnswersToUser={finishExam}
																						key={index}
																						hanldeStudentAnswer={
																							hanldeStudentAnswer
																						}
																						questionId={question.id}
																						question={question.question}
																						options={question.options}
																						isTrue={
																							index % 2 == 0 ? true : false
																						}
																						marks={question.point}
																						notes={question.question_note}
																					/>
																				</>
																			);
																		})}
																</ul>
															</div>
														</>
													)}
												{studendAnswers.length > 0 &&
													studendAnswers.length == lessonAutomated.length &&
													finish &&
													(!finishExam ? (
														<div className='btn_send_answer text-center mx-auto'>
															<Button
																text=' ارسل'
																className={`lessonsSecreen__FinishExamBtns mx-auto `}
																onclick={() => postAnswers()}
															/>
														</div>
													) : (
														<Button
															text={`${Math.round(result) + '/100'}النتيحة`}
															className='examResultBtn'
														/>
													))}
											</>
										)}
									</>
								) : (
									<>
										{/* <div className='examScreen__explanatino'>
											<h2>شرح عن الاختبار</h2>
											<ul>
												<li>{quizDetails && quizDetails.description}</li>
											</ul>
										</div> */}
										{lessonAutomated &&
											lessonAutomated.map((question, index) => {
												return (
													<>
														{/* '2022-08-28T17:14:02.758265Z' */}
														<div className='lesson__questions'>
															<ol type='1' className='questions__container'>
																<Questino
																	showAnswersToUser={finishExam}
																	key={index}
																	hanldeStudentAnswer={hanldeStudentAnswer}
																	questionId={question.id}
																	question={question.question}
																	options={question.options}
																	isTrue={index % 2 == 0 ? true : false}
																	marks={question.point}
																	notes={question.question_note}
																/>
															</ol>
														</div>
													</>
												);
											})}
									</>
								)}
							</>
						) : (
							<>
								{lessonDetails?.has_student_answered_on_lesson_quiz ? null : (
									<>
										{(lessonDetails?.access || lessonDetails?.isFree) &&
											lessonDetails?.has_lesson_gived_quiz_before && (
												<>
													{/* <div className='examScreen__explanatino'>
													<h2>شرح عن الاختبار</h2>
													<ul>
														<li>{quizDetails && quizDetails.description}</li>
													</ul>
												</div> */}
													{Error && (
														<div class='alert alert-dark' role='alert'>
															{Error}
														</div>
													)}

													<div className='lesson__questions'>
														<ol type='1' className='questions__container'>
															{lessonAutomated &&
																lessonAutomated.map((question, index) => {
																	return (
																		<>
																			<Questino
																				showAnswersToUser={finishExam}
																				key={index}
																				hanldeStudentAnswer={
																					hanldeStudentAnswer
																				}
																				questionId={question.id}
																				question={question.question}
																				options={question.options}
																				isTrue={index % 2 == 0 ? true : false}
																				marks={question.point}
																				notes={question.question_note}
																			/>
																		</>
																	);
																})}
														</ol>
													</div>
												</>
											)}

										{studendAnswers.length > 0 &&
											studendAnswers.length == lessonAutomated.length &&
											finish &&
											(!finishExam ? (
												<div className='btn_send_answer'>
													<Button
														text=' ارسل'
														className={`lessonsSecreen__FinishExamBtns `}
														onclick={() => postAnswers()}
													/>
												</div>
											) : (
												<Button
													text={`${Math.round(result) + '/100'}النتيحة`}
													className='examResultBtn'
												/>
											))}
									</>
								)}
							</>
						)}
					</div>

					{lessonDetails?.faqs?.length > 0 && (
						<div className='container '>
							<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
								أسئلة شائعة حول الدرس:
							</h4>
							<div className='container'>
								<AccordionCustom
									data={lessonDetails?.faqs}
									backgroundImage={bg}
								/>
							</div>
						</div>
					)}

					{/* {lessonDetails?.has_lesson_gived_quiz_before ? null : loadingBtn &&
				  lessonDetails.access ? (
					<>
						<TitleSection title='طلب انهاء الدرس ' />
						<Button
							text=' انهاء الدرس '
							className='examResultBtn'
							onclick={() => getNextLesson() && setloadingBtn(false)}
						/>
					</>
				) : (
					<div
						class='spinner-border'
						role='status'
						style={{ marginRight: '50%' }}>
						<span class='sr-only'>Loading...</span>
					</div>
				)} */}
					{/* 
				{lessonDetails?.teacher_url && (
					<ContactTeacher url={lessonDetails?.teacher_url} />
				)} */}
					{/* <TitleSection title='أبرز التعليقات' /> */}
					<ToastError
						setSuccessMessage={setSuccess}
						setErrorMessage={setErrorMes}
						ErrorMessage={errorMes}
						successMessage={successMessage}
					/>
					{/* {commentLesson?.data?.length > 0 ? (
					<Slider isComments page={'lesson'} id={lessonId.id}>
						{commentLesson?.data.map((item) => (
							<Comment comment={item} />
						))}
					</Slider>
				) : null} */}
					<div className='comments container'>
						{commentLesson && commentLesson?.data?.length > 0 ? (
							<>
								<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
									أبرز التعليقات
								</h4>
								{commentLesson?.data?.map((el, index) => {
									if (
										index < paginationComment &&
										prevPaginationComment <= index
									)
										return (
											<MainComment
												name={el?.student_name}
												date={el?.date_time}
												text={el?.comment}
												img={`${process.env.REACT_APP_STORAGE_URL}/${el?.student_image}`}
											/>
										);
								})}
								<ButtonM
									text='عرض المزيد'
									onClick={handleNavigate}
									className='mx-auto'
								/>
							</>
						) : (
							<></>
						)}
						{commentLesson?.data?.length <= 3 ? (
							<> </>
						) : (
							commentLesson?.data?.length > 0 && (
								<div className='row justify-content-center'>
									<Button
										text={<span className='text-danger'>السابق</span>}
										className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
										onclick={prevComment}
										backgroundColor='white'
									/>
									<Button
										text={<span className='text-danger'>التالي</span>}
										className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
										onclick={nextComment}
										backgroundColor='white'
									/>
								</div>
							)
						)}
					</div>
					<div className='text-center'>

						{
							lessonDetails?.next_lesson_id && lessonDetails?.can_access_next_lesson && 
						<a
							style={{ fontSize: '1.2rem' }}
							className='buttonM buttonM-2 mx-auto'
							href={`/lesson/${lessonDetails?.next_lesson_id}`}>
							الدرس التالي
						</a>

						}

					</div>
					{localStorage.getItem('role') == 1 && (
						<LeaveComment
							postDataComment={postCommentfun}
							lesson_id={lessonId.id}
							loading={loading}
						/>
					)}
				</div>
			</Layout>
		);
}

export default LessonScreen;
