import React, { useState } from 'react';
import ShowLessonAnswearLogic from './ShowLessonAnswerLogic';
import rightAnswer from './../../../../assets/icons/rightcheckbox.png';
import wrongAnswer from './../../../../assets/icons/wrongcheckbox.png';
import Error from '../../../alerts/Error/Error';
import Layout from '../../../../pages/Layout/Layout';
import LayoutSideStudent from '../../../../pages/LayoutSide/LayoutSideStudent';
import Button from '../../../button';
import MainHeader from '../../../MainHeader/MainHeader';
import { useLocation } from 'react-router-dom';
import clock from '../../../../assets/icons/clock.png';
import point from '../../../../assets/icons/point.png';
export default function ShowLessonAnswear() {
	const { IsLoading, IsError, data } = ShowLessonAnswearLogic();
	const { state } = useLocation();

	// console.log('state is  ', state.quizDetailes);
	const quiz = state && state?.quizDetailes;
	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={'الاختبار'}>
				{quiz && (
					<>
						<div className='row justify-content-center text-center my-5'>
							<h1 className='fw-bold mb-4'>
								<span style={{ color: '#044364' }}>
									{quiz?.quiz_name && quiz?.quiz_name}
								</span>
							</h1>
							<div className='row justify-content-center'>
								<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
									<img src={clock} alt='book' width={'30px'}></img>

									<span className='mx-2 fs-3'>
										{' '}
										مدة الاختبار :
										{'د' + quiz?.quiz_duration && quiz?.quiz_duration > 60
											? Math.floor(quiz?.quiz_duration / 60)
											: quiz?.quiz_duration}
										دقيقة
									</span>
								</div>
								<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
									<img
										className='mx-2'
										src={point}
										alt='point'
										width={'30px'}></img>

									<span className='mx-2 fs-3'>
										عدد النقاط: {quiz?.quiz_points} نقطة{' '}
									</span>
								</div>
							</div>

							{/* <img
							src={
								unitDetails &&
								`${process.env.REACT_APP_STORAGE_URL}${
									unitDetails && unitDetails.unit_cover
								}`
							}
							alt='cover'
						/> */}
						</div>{' '}
					</>
				)}
				<div className='examScreen__explanatino container'>
					{/* <h2>شرح عن الاختبار</h2> */}

					<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
						<li>{quiz?.description}</li>
					</ul>
				</div>
				<div>
					<div className='lesson__questions container'>
						<ol type='1' className='questions__container'>
							{IsLoading && (
								<div class='d-flex justify-content-center'>
									<div class='spinner-grow text-dark' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							)}
							{data && data?.automated_quiz_text && (
								<div
									className='lesson_question_text'
									dangerouslySetInnerHTML={{
										__html: data?.automated_quiz_text,
									}}></div>
							)}
							{data?.data?.map((quistion, index) => (
								<li className={`question`}>
									<div className='question__container'>
										<div className='topsection'>
											<h6
												dangerouslySetInnerHTML={{
													__html: quistion.question,
												}}></h6>
										</div>

										<div className='answers'>
											{quistion?.the_options?.map((answer, id) => {
												return (
													<div
														className={`answerItself ${
															answer.is_true == 1 ? 'rightAnswer' : null
														} }`}>
														<label
															className='mx-2  '
															dangerouslySetInnerHTML={{
																__html: answer.answear,
															}}></label>
														<input
															type='radio'
															className={` mt-1 ${
																answer.is_student_answer && answer.is_true == 0
																	? 'hidecheckbox'
																	: ''
															}`}
															checked={answer.is_true == 1}
															value={answer.text}
															name={index}
															style={{ width: '20px ', height: '20px' }}
														/>
														<div className='statusImage'>
															{answer.is_student_answer &&
															0 == answer.is_true ? (
																<img src={wrongAnswer} alt='' />
															) : null}
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</li>
							))}
							{data && (
								<div className=' text-center'>
									<Button
										text={`${
											data?.result === '-1' ? '0' : data?.result + '/100'
										}النتيحة  `}
										className='examResultBtn'
									/>
								</div>
							)}

							{IsError && (
								<Error
									errorText={
										IsError?.response?.data?.message
											? IsError?.response?.data?.message
											: IsError.message
									}
								/>
							)}
						</ol>
					</div>
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}
