import React, { useEffect, useState } from 'react';
import './LibrarySite.css';

import SectionCardList from './SecionCardList';
import axios from 'axios';
import SubTitle from '../SubTitle/SubTitle';

export default function LibrarySite({ dataLibrary }) {
	//************************************************************************* */
	const [Sections, setSections] = useState([]);
	//************************************************************************* */
	const getAllLibrarySection = async () => {
		const res = await axios.get(
			`${process.env.REACT_APP_API_URL}/get_website_library_sections?limit=19`,
			{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}}
		);
		setSections(res.data.data.data);
	};
	//************************************************************************ */
	useEffect(() => {
		getAllLibrarySection();
	}, []);

	//************************************************************************ */
	return (
		<>
			<div
				className='container-fluid Library_Site_Head p-2 '
				// style={{
				//   marginBottom: "100px",
				//   backgroundImage: `url(${background_winter_courses})`,
				//   backgroundRepeat: "no-repeat",
				//   backgroundSize: "cover",
				//   backgroundPosition: "center",
				// }}
			>
				<div className='text-center '>
					<SubTitle title={'مكتبة الموقع'} />
				</div>
			</div>
			<div className='container-fluid'>
				<div className='row g-2 justify-content-center '>
					<div className='col-10  col-md-10 col-lg-10 align-items-center justify-content-center '>
						<p
							className='mx-auto mt-3 text-center '
							style={{
								fontSize: ' 1.2rem',
							}}
							dangerouslySetInnerHTML={{
								__html: dataLibrary?.website_library_description,
							}}></p>
					</div>
				</div>
			</div>

			<div className='pb-3 mt-5'>
				{/* <p className='text-center mx-4  p-2 main_title-winter_courses fw-bolder fs-4 '>
					اقسام مكتبة الموقع
				</p> */}

				<SubTitle title={'أقسام مكتبة الموقع'} />

				{/* {localStorage.getItem('is_active') == 0 ||
				!localStorage.getItem('token') ? (
					<div className='w-100 text-center my-5'>
						<div
							class='alert alert-success mx-auto shadow'
							style={{ maxWidth: '400px' }}
							role='alert'>
							عذرا عزيزي الطالب ... يجب أن يكون لديك حساب مفعل لتتمكن من
							الإستفادة من خدمات مكتبة منصة الشرق الأوسط
						</div>
					</div>
				) : null} */}

				<SectionCardList sections={Sections} desc={dataLibrary?.website_library_description}/>
			</div>
		</>
	);
}
