import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
import LayoutSideStudent from '../../pages/LayoutSide/LayoutSideStudent';
import LayoutSideTeacher from '../../pages/LayoutSide/LayoutSideTeacher';
import './Professors.css';
import ProfessorsLogic from './ProfessorsLogic';
export default function Professors() {
	const { Professors } = ProfessorsLogic();

	console.log('professons', Professors);
	const id = 1;
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<LayoutSideStudent desc='الاساتذه'>
				<div className=' Professors container'>
					<div className='row justify-content-center   '>
						{Professors && Professors.length > 0 ? (
							Professors.map((item) => {
								return (
									<div className='col-md-3 col-lg-2  mx-2 mx-lg-4 rounded-x shadow col-sm-4 col-8 text-center border my-4 p-4'>
										<img
											alt='profileimg'
											src={`${
												process.env.REACT_APP_STORAGE_URL + '/' + item.image
											}`}
											className=' p-4  '
											style={{
												width: '80%',
												height: 'auto',
												borderRadius: '50%',
											}}
										/>
										<h3> {item.teacher_name}</h3>
										<h5>اسم الماده</h5>
										<Link
											to={`/teacher/${item.teacher_id}`}
											state={{ id: item.teacher_id }}>
											<button className='contact rounded-x'> تواصل</button>
										</Link>
									</div>
								);
							})
						) : (
							<div className='w-100 text-center'>
								<h3 className='mt-5'> لا يوجد لديك مدرسين</h3>
							</div>
						)}
					</div>
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}
