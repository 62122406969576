import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GetData } from "../../customhook/getDatawithAuth";
import { get_all_students_that_have_quizzes_by_teacher } from "../../redux-toolkit/reducers/Unit_test/UnitSlice";

export default function StudentsLogic({api,Title}){



    const {getDatafrom,IsLoading,IsError,data:students}  = GetData(`${api}`)



 const dispatch = useDispatch();
//  const students = useSelector(state=>state.unitTest.get_all_students_that_have_quizzes_by_teacher)



 
  




useEffect(()=>{
    document.title = Title 
    getDatafrom();
},[])







    let info=[{
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },
    {
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },
    
    {
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    }]
    let info1=[{
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },
    {
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },
    
    {
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },{
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    },{
        src:'./images/Ellipse 1706.png',
        names:'الاسم'
    }]
    return{
info,info1,students,IsLoading,IsError
    }
}