import React from 'react'
import { useParams } from 'react-router-dom';

export default function Quiz_To_Logic() {
  const {SubjectId} = useParams();
return{
  SubjectId
}
  
}
