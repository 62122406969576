import React from 'react';
import './index.css';
import circle from '../../assets/icons/circle.svg';

function MainComment({ img, name, date, text, notification, className }) {
	console.log('imageeeeeeeeeee', img);
	if (notification === 'yes') {
		img = process.env.REACT_APP_STORAGE_URL + img;
	}
	return (
		<div className={`mainComment ${className}`}>
			<div className='commentCard'>
				<div className='comment_top_cardcontent'>
					<div className='name_image_container'>
						<div className=''>
							{notification === 'yes' ? null : (
								<img
									className=''
									width={'40px'}
									height={'40px'}
									src={img}
									alt='user profile image'
								/>
							)}
						</div>
						<div className=''>
							<h4 className='fw-bold mt-2 fs-5'>
								<span style={{ color: '#044364', marginRight: '10px' }}>
									{name}
								</span>
							</h4>
						</div>
					</div>

					<div
						className=' fw-bold px-3 '
						style={{ textAlign: 'left', color: '#044364' }}>
						{date}
					</div>
				</div>

				<div className='col-12 col-lg-11 mx-lg-5    comment_content_itself'>
					{text}
				</div>
			</div>
		</div>
	);
}

export default MainComment;
