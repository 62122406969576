import React from 'react';
import { Link } from 'react-router-dom';
import { Bounce } from 'react-reveal';
function SubjectCards(props) {
	const { subject } = props;
	const subject_id = subject.subject_id;

	console.log(subject);
	return (
		<div className=' my-3 col-md-5 col-lg-3 col-sm-6  col-xs-8 col-10 text-center'>
			<Bounce>
				<Link
					to={`/studentUnits?id=${subject_id}`}
					state={{ id: subject_id, name: subject.subject_name }}
					className='card section_Card    '>
					<div
						className=' mx-auto'
						style={{ width: '100%', borderRadius: '25px', padding: '0px' }}>
						<div className='' style={{ padding: '0px', borderRadius: '25px' }}>
							<img
								// src={
								// 	process.env.REACT_APP_STORAGE_URL +
								// 	section.section_image
								// }
								src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
								width='100%'
								height='200px '
								className=''
							/>
							<h4 class=' mt-4  rounded-x  text-black section_desc_itself '>
								{subject.subject_name}
							</h4>
							<p
								className='section_desc_itself'
								style={{
									color: 'black',
									fontSize: '0.9rem',
									maxWidth: '90%',
									margin: 'auto',
								}}>
								يحتوي هذا القسم على العديد من الكورسات المهمة
							</p>
						</div>
					</div>
				</Link>
			</Bounce>
		</div>
	);
}

export default SubjectCards;
