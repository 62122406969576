import React, { useEffect } from 'react';
import Add_Unit_Faqs_Logic from './Add_Unit_Faqs_Logic';

import { clearFaqq2 } from '../../../../../redux-toolkit/reducers/Units/UnitSlice';
import { useDispatch } from 'react-redux';
export default function Add_Unit_Faqs() {
	const { data, register, onSubmit, errors, isDirty, isValid, handleSubmit } =
		Add_Unit_Faqs_Logic();
	const disabled = useDispatch();
	const [success, setSuccsess] = React.useState(data.data);
	useEffect(() => {
		return () => {
			disabled(clearFaqq2());
		};
	}, []);
	useEffect(() => setSuccsess(data.data), [data?.data]);
	return (
		<>
			{success && (
				<div class='alert alert-success text-center' role='alert'>
					تهانينا تم إضافة السؤال بنجاح
				</div>
			)}
			{data.isError && (
				<div className='alert alert-danger' role='alert'>
					{data.errorMessage}
				</div>
			)}
			<div className='  py-5 '>
				<div className='d-flex justify-content-center'>
					<div className='card shadow  text-center py-4  login-card'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className='mb-3 '>
								<div className='mb-3 '>
									<label className='fw-bold fs-4' style={{ color: '#044364' }}>
										السؤال
									</label>
								</div>
								<input
									{...register('question', { required: true })}
									type='text'
									className='shadow w-75 main-input p-2 fs-5 mx-auto'
									style={{ borderRadius: '10px ', border: '1px solid #044364' }}
								/>
								{errors.question && (
									<label className='text-center w-100 text-danger'>
										Invalid input
									</label>
								)}
							</div>

							<div className='mb-3'>
								<div className='mb-3'>
									<label className='fw-bold fs-4' style={{ color: '#044364' }}>
										الجواب
									</label>
								</div>
								<input
									{...register('answer', { required: true })}
									type='text'
									style={{ borderRadius: '10px ', border: '1px solid #044364' }}
									className='shadow w-75 main-input  p-2 fs-5'
								/>
								{errors.answer && (
									<label className='text-center w-100 text-danger'>
										Invalid input
									</label>
								)}
							</div>
							<div className='mb-3'></div>

							{data.isFetching && (
								<div className='d-flex mb-3'>
									<div class='spinner-border m-auto' role='status'>
										<span class='sr-only'>Loading...</span>
									</div>
								</div>
							)}
							<div className='d-flex'>
								<button
									disabled={!isDirty || !isValid}
									type='submit'
									className='btn1 m-auto w-50 rounded text-white fw-bold py-2'>
									إضافة السؤال
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
