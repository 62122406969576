import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Professorsabbreviation.css';
import ProfessorsabbreviationLogic from './ProfessorsabbreviationLogic';

import Layout from '../../pages/Layout/Layout';

export default function Professorsabbreviation() {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	const { Professor } = ProfessorsabbreviationLogic();
	console.log('profffesssong ', Professor);

	return (
		<Layout>
			<div className='container Professorsabbreviation overflow-hidden'>
				{Professor && (
					<div>
						<div className='row my-4 justify-content-center justify-content-md-around '>
							<div className='col-10 col-md-5 align-items-center justify-content-center  my-1  p-2'>
								<p>
									{/* <img alt='Professorsabbreviationicon' width="50px" height="50px" className='Professorsabbreviationicon' src={`${process.env.REACT_APP_STORAGE_URL + "/"+ Professor?.image   }`}/>   */}
									<span style={{ fontWeight: '700' }}>
										{Professor?.teacher_name}
									</span>
									<span className='fw-bold'> - </span>
									{/* <span style={{fontWeight:'700'}}> 
             اسم المادة
                </span> */}
								</p>
								<p className='d-flex align-items-center justify-content-center my-5'>
									{Professor?.description}
								</p>
							</div>
							<div className='col-10 col-md-5 p-4  my-1'>
								<img
									alt=''
									style={{
										width: '75%',
										borderRadius: '25px',
										height: '300px',
									}}
									className='mx-auto d-block'
									src={
										process.env.REACT_APP_STORAGE_URL + '/' + Professor?.image
									}
								/>
							</div>
						</div>

						{Professor?.subjects &&
							Professor?.subjects.length > 0 &&
							Professor?.subjects.map((item) => {
								return (
									<div className='row my-4 justify-content-center justify-content-md-around '>
										<div className='col-10  border shadow my-1 rounded-x p-1'>
											<p className='fs-3 fw-bold'>
												{/* <img alt='Professorsabbreviationicon' className='Professorsabbreviationicon' src='media/Professorsabbreviation/Professorsabbreviationicon.png'/>   */}
												فيديو تعريفي عن مادة : {item?.subject_name}
											</p>
											<div>
												<div className='ratio ratio-21x9 my-2 mx-auto rounded-x overflow-hidden  '>
													<iframe
														src={
															process.env.REACT_APP_STORAGE_URL +
															item?.introductory_video
														}
														title='YouTube video'
														allowfullscreen></iframe>
												</div>
											</div>
										</div>

										<div className='w-100'></div>
										<div className='row justify-content-center my-4'>
											<div className='col-5 '>
												<button className='contactwithprofessor rounded-x text-center'>
													التواصل مع الأستاذ
												</button>
											</div>
											<div className='col-5 text-center'>
												<button className='gotomaterial rounded-x'>
													الذهاب إلى المادة
												</button>
											</div>
										</div>
									</div>
								);
							})}
					</div>
				)}
			</div>
		</Layout>
	);
}
