import React , {useState} from 'react'
import { useQuery } from 'react-query';
import fetchOffers from '../../redux-toolkit/api/fetchOffersApi';

const OffersLogic = () => {

    const [page,setPage] =useState(1);
    function handlePageClick (data)
    {
      console.log(data)
     setPage(data.selected+1);
    }
  
    const {isLoading, isError, data, error} = useQuery(['comments',page,`${process.env.REACT_APP_API_URL}/get_last_offers?limit=5&local=ar`], fetchOffers);
    console.log("offerssssss Page" , data?.data?.data);
    const offersData = data?.data?.data
  return {
     offersData,
     data,
     page,
     handlePageClick,
     isError,
     error,
     isLoading
  }
}

export default OffersLogic