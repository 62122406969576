import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetchAllLessonFaqs from '../../../../../redux-toolkit/api/Lesson/fetchAllLessonFaqs';
import axios from 'axios';
export default function Show_All_Lesson_Faqs_Logic() {
	const [loading, setloading] = useState();
	const dispatch = useDispatch();

	const [page, setPage] = useState(1);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}

	const Authdata = useSelector((state) => state.SIGN.token);
	const { SubjectId, UnitId, LessonId } = useParams();

	const { isLoading, isError, data, error, refetch } = useQuery(
		['Lesson', LessonId, 100, Authdata],
		fetchAllLessonFaqs,
		{ enabled: true }
	);
	const deleteFaq = (id) => {
		setloading(true);
		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_faqs/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};

	return {
		SubjectId,
		dispatch,
		data,
		isLoading,
		isError,
		error,
		refetch,
		loading,
		deleteFaq,
	};
}
