import React from 'react';
import Layout from '../../pages/Layout/Layout';
import AccordionCustom from '../Accordion';
import MainHeader from '../MainHeader/MainHeader';
import bg from '../../assets/lesson/bg.png';
const CommonQuestion = () => {
	return (
		<Layout>
			{/* <MainHeader /> */}

			<div className='container mt-5 '>
				<div className='text-center'>
					<h4 style={{ color: '#044364' }} className='fw-bold my-5 '>
						الاسئلة الشائعة{' '}
					</h4>
				</div>
				<AccordionCustom data={''} backgroundImage={bg} />
				<div className='text-center my-5'>
					<p>
						يسعدنا الرد على استفساراتكم و مقترحاتكم في اي وقت من خلال الدعم
						الفني للطلاب على الواتساب{' '}
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default CommonQuestion;
