import React, { memo } from 'react';
import ReactQuill from 'react-quill';
import { useLocation } from 'react-router-dom';
import uuid from 'react-uuid';
import { Editor } from '@tinymce/tinymce-react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import Add_automated_quiz_quistions_Logic from './Add_automated_quiz_quistions_Logic';
window.katex = katex;

function Add_automated_quiz_quistions() {
	const {
		register,
		errors,
		isDirty,
		isValid,
		onSubmit,
		fields,
		handleSubmit,
		isSuccess,
		IsLoading,
		watch,
		IsError,
		question,
		firstOption,
		secondOption,
		thirdOption,
		fourthOption,
	} = Add_automated_quiz_quistions_Logic();

	return (
		<>
			<div className='container Add_traditional_quiz'>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation'
					noValidate>
					<div className=''>
						<label
							htmlFor='question'
							className='form-label fs-4'
							style={{ color: '#044364' }}>
							السؤال
						</label>

						<ReactQuill
							style={{ height: '300px', marginBottom: '120px' }}
							theme={'snow'}
							onChange={(e) => (question.current = e)}
							value={question.current}
							modules={Editor.modules}
							formats={Editor.formats}
							bounds={'.app'}
							placeholder={'أدخل السؤال ...'}
						/>
						{/* <input
              type="text"
              className="form-control"
              id="question"
              {...register("question", { required: true })}
            /> */}
						{/* {errors.question && <p className="text-danger">Invalid input</p>} */}
					</div>

					{/* <div className="col-md-6">
            <label htmlFor="point" className="form-label">
              الدرجة
            </label>
            <input
              type="number"
              {...register("point", { required: true })}
              className="form-control degreeinput"
              id="degree"
            />
            {errors.point && <p className="text-danger">Invalid input</p>}
          </div> */}

					<div className='col-12'>
						<label
							htmlFor='point'
							className='form-label fs-4'
							style={{ color: '#044364' }}>
							الإجابات
						</label>
						{fields.map((item, index) => {
							return (
								<div className='row mb-g' key={item.id}>
									<div className='col-md-12 col-12 d-flex align-items-center justify-content-center gap-2'>
										{/* <input
                      type="text"
                      {...register(`options.${index}.answear`, {
                        required: false,
                      })}
                      className="form-control degreeinput"
                      id="degree"
                    />{" "}  */}
										<input
											defaultChecked={index === 0}
											type='radio'
											{...register('correct', { required: true })}
											value={index}
											style={{
												width: '25px',
												height: '25px',
											}}
										/>{' '}
										<ReactQuill
											style={{ height: '300px', marginBottom: '120px' }}
											theme={'snow'}
											onChange={(e) => {
												if (index == 3) {
													fourthOption.current = e;
												} else if (index == 0) {
													firstOption.current = e;
												} else if (index == 1) {
													secondOption.current = e;
												} else if ((index = 2)) {
													thirdOption.current = e;
												}
											}}
											// value={fourthOption.current}
											modules={Editor.modules}
											formats={Editor.formats}
											bounds={'.app'}
											placeholder={'أدخل الجواب ...'}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div className='col-md-12 text-center'>
						<label
							htmlFor='question_note'
							className='form-label fs-4'
							style={{ color: '#ef0000' }}>
							ملاحظة السؤال
						</label>
						<input
							type='text'
							className='form-control w-50 mx-auto'
							id='question_note'
							{...register('question_note', { required: true })}
						/>
						{errors.question_note && (
							<p className='text-danger'>ملاحظة السؤال مطلوب</p>
						)}
					</div>
					<div className='col-12 text-center'>
						<button
							disabled={false}
							className=' btn1  my-2'
							style={{
								minWidth: '150px',
								borderRadius: '10px',
								padding: '10px 5px',
							}}
							type='submit'>
							رفع الاختبار
						</button>

						{IsError && (
							<label className='text-center w-100 text-danger'>
								{IsError.errorMessage}
							</label>
						)}
					</div>
					{isSuccess && (
						<div class='alert alert-success text-center' role='alert'>
							تمت إضافة السؤال بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}

Editor.modules = {
	/*toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ],*/
	toolbar: [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block'],

		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ header: [1, 2, 3, false] }],

		['link', 'image', 'formula'],

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],

		['clean'], // remove formatting button
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};

export default Add_automated_quiz_quistions;
