import {  Navigate, Outlet } from "react-router-dom";
import RequireAuthLogic from "./RequireAuthLogic";



const RequireAuth = ({ allowedRoles }) => {

   
    const {location ,user} = RequireAuthLogic();


    return (

        allowedRoles.includes(user)    
             ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    
                );
}

export default RequireAuth;