import React,{useEffect,useState} from 'react'

const GuardianStudentTeacherLogic = () => {

    const [gurdianStudent,setGurdianStudent] = useState()
    useEffect(() => {
        async function getData() {
          const id=1;
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_guardian_by_student/${id}`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":"Bearer 2|vbqCqitJbBoF43KXGQ4emaMPazD1xVqLcp3W2LGs"
            }
          }
          ).then(response => response.json());
          setGurdianStudent(actualData);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa TeacherStudnetsGradianStudent",actualData) 
        }
        getData()
      }, [])

  return {
    gurdianStudent,
  }
}

export default GuardianStudentTeacherLogic