import React, { useEffect } from 'react';
import OurTable from '../../components/table';

import './index.css';

import Layout from '../Layout/Layout';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';

const ExamsTableScreen = (props) => {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<LayoutSideStudent desc='البرنامج'>
				<div className='examtablescreen'>
					{/* <LessonTitle /> */}
					<OurTable />
				</div>
			</LayoutSideStudent>
		</Layout>
	);
};

export default ExamsTableScreen;
