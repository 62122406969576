import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Attachment from './Attatchment';
import Slider from '../slider';
import pdfImg from '../../assets/icons/pdf.png';
import mp3FileImg from '../../assets/icons/mp3file.png';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getLessonAttachmentAPI } from './LessonAttachmentAPI';
import TitleSection from '../../components/titleSection';
import DeleteModal from './DeleteModal';
import EditModal from './EditModal';
import AddTaskModal from '../table/AddTaskModal';
import AddAttatcmentModal from './AddModal';
import Layout from '../../pages/Layout/Layout';
import MainHeader from '../MainHeader/MainHeader';

function TeacherAttatchment() {
	//********************************************************************* */
	const [lessonDetails, setlessonDetails] = useState([]);
	const [Loading, setLoading] = useState(false);
	const { id } = useParams();
	const [Refresh, setRefresh] = useState(false);
	const [deleteItemId, setdeleteItemId] = useState(0);
	const [editFileId, seteditFileId] = useState(0);
	//********************************************************************* */
	useEffect(() => {
		getLessonAttachmentAPI(id, setlessonDetails, setLoading);
	}, []);
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	//********************************************************************* */

	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='container my-5'>
				{Loading && lessonDetails.length === 0 && (
					<div className='h-100 text-center' role='status'>
						<span className='spinner-grow align-middle'></span>
					</div>
				)}
				{/* <TitleSection title='مرفقات الدرس' /> */}
				<div className=' text-center'>
					<h3 style={{ color: '#044364' }} className='fw-bold mt-5 mx-auto'>
						مرفقات الدرس
					</h3>
				</div>
				<h4 style={{ color: '#044364' }} className='fw-bold my-5 mx-auto'>
					معرض الصور
				</h4>
				<div className='imageGallary'>
					<div className='slider'>
						{lessonDetails?.length > 0 ? (
							<Carousel>
								{lessonDetails?.map((attach, index) => {
									if (attach.type === '1') {
										return (
											<Carousel.Item>
												<img
													loading='lazy'
													className='d-block w-100'
													src={`${process.env.REACT_APP_STORAGE_URL}/${attach.file}`}
													alt='Second slide'
												/>
												<Carousel.Caption className='descImage'>
													<h5 className=''>{attach.title}</h5>
													<div className=''>
														<button
															className='btn1 border-0 px-2 py-1'
															data-bs-toggle='modal'
															data-bs-target='#EditAttatchmentModal'
															style={{ borderRadius: '5px' }}
															onClick={() => seteditFileId(attach.id)}>
															تعديل{' '}
														</button>
														<button
															className='me-3 btn2 border-0 px-2 py-1'
															data-bs-toggle='modal'
															data-bs-target='#deleteAttatchmentModal'
															style={{ borderRadius: '5px' }}
															onClick={() => setdeleteItemId(attach.id)}>
															حذف{' '}
														</button>
													</div>
												</Carousel.Caption>
											</Carousel.Item>
										);
									}
								})}
							</Carousel>
						) : (
							<span>لا يوجد مرفقات صور</span>
						)}
					</div>
				</div>

				<h4 style={{ color: '#044364' }} className='fw-bold my-5 mx-auto'>
					المرفقات الكتابية
				</h4>
				<div className='paperAttach'>
					{lessonDetails?.length > 0 ? (
						<Slider gap={30}>
							{lessonDetails?.map((attach, index) => {
								if (attach.type === '2') {
									return (
										<Attachment
											key={index}
											img={pdfImg}
											title={attach.title}
											btn1Text='تعديل'
											btn2Text='حذف'
											ladder_file={`${process.env.REACT_APP_STORAGE_URL}${attach.file}`}
											btn1function={() => {}}
											btn2function={(e) => {}}
											fileId={attach.id}
											setDeleteFileId={setdeleteItemId}
											setEditFileId={seteditFileId}
										/>
									);
								}
							})}
						</Slider>
					) : (
						<span>لا يوجد مرفقات كتابية</span>
					)}
				</div>

				<h4 style={{ color: '#044364' }} className='fw-bold my-5 mx-auto'>
					المرفقات الصوتية
				</h4>
				<div className='paperAttach'>
					{lessonDetails?.length > 0 ? (
						<Slider gap={30}>
							{lessonDetails?.map((attach, index) => {
								if (attach.type === '3') {
									return (
										<Attachment
											key={index}
											img={mp3FileImg}
											title={attach.title}
											link={attach.file}
											btn1Text='تعديل'
											btn2Text='حذف'
											btn1function={() => {}}
											btn2function={() => {}}
											fileId={attach.id}
											setDeleteFileId={setdeleteItemId}
											setEditFileId={seteditFileId}
										/>
									);
								}
							})}
						</Slider>
					) : (
						<span>لا يوجد مرفقات صوتية</span>
					)}
				</div>
				<div className='text-center pb-4'>
					<button
						className='btn btn1 w-50 my-5'
						data-bs-toggle='modal'
						data-bs-target='#AddAttatchmentModal'>
						{' '}
						اضف ملف جديد
					</button>
				</div>
				<DeleteModal
					fileId={deleteItemId}
					setRefresh={setRefresh}
					refresh={Refresh}
				/>
				<EditModal
					element={lessonDetails.find((e) => e.id === editFileId)}
					lessonId={id}
					setRefresh={setRefresh}
					refresh={Refresh}
				/>
				{/* <AddTaskModal /> */}
				<AddAttatcmentModal lessonId={id} />
			</div>
		</Layout>
	);
}

export default TeacherAttatchment;
