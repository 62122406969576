import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Lesson from '../lesson/index';
import './index.css';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import {
	clearMsg,
	getError,
	getSuccess,
} from '../../redux-toolkit/reducers/notification';

function UnitLessons({ children, lessons }) {
	// const [lessons,setLessons] = useState(lessons);

	// useEffect(() => {
	//     const lessonsClone = lessons;
	//     for(let i = 0; i < 4; i++){
	//         lessonsClone.push(i+1);
	//     }
	//     setLessons(current => [...lessonsClone])
	// }, []);

	console.log(lessons);
	const successMsg = useSelector(getSuccess);
	const errorMsg = useSelector(getError);

	return (
		<div className='row'>
			<div className='unintLessons__content'>
				{lessons.map((lesson, id) => {
					return (
						<Lesson
							isSaved={lesson.is_saved}
							access={lesson.access}
							btnType={'save'}
							id={lesson.lesson_id}
							lessonImage={lesson.lesson_image}
							key={id}
							btnText='البدء بالدرس'
							lesson_points={lesson.lesson_points}
							lessonName={lesson.lesson_name}
							lessonNum={lesson.lesson_order}
							lessonTime={lesson.lesson_duration}
							isLock={!lesson.access}
							isFree={lesson.isFree}
						/>
					);

					//   <Lesson id={lesson.id} key={id} isLock btnText="البدء بالدرس" starsNum={45} lessonName="الهندسة" lessonNum="الدرس الاول" lessonTime="دقيقة 60"/>}
				})}
				<ToastSuccessError
					successMessage={successMsg}
					ErrorMessage={errorMsg}
					clearMsg={clearMsg}
				/>
			</div>
		</div>
	);
}

export default UnitLessons;
