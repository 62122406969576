import React from 'react';
import { Link } from 'react-router-dom';
import { delete_automated_quiz_by_automated_quiz } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Button from 'react-bootstrap/Button';
import Select_Unit_Logic from './Select_Unit_Logic';
import PopUp from '../popup/popup';
export default function Select_Unit() {
	const { data } = Select_Unit_Logic();
	const [pop, setPop] = React.useState(false);
	const [popData, setPopData] = React.useState(null);
	return (
		<div className='m-5'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{!data.isErro && data?.data && (
				<table
					className='table-bordered w-100  mt-3 table_style  text-center table-striped'
					// style={{ minHeight: "15vh" }}
				>
					<thead>
						<tr>
							<th className='first_child'> #</th>
							<th> اسم الوحدة </th>
							<th> رابط الفيديو </th>
							<th> الترتيب </th>
							<th> وصف الوحدة </th>
							<th> غلاف الوحدة </th>
							<th> عدد الدروس </th>
							<th> متطلبات الوحدة</th>
							<th> مدة الدروس </th>
							<th> </th>
							<th className='last_child'> </th>
						</tr>
					</thead>
					{data?.data && (
						<tbody>
							{data?.data?.map((Unit, index) => (
								<tr key={Unit.unit_id}>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										{index + 1}
									</td>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										{' '}
										{Unit.unit_name}
									</td>
									<td style={{ overflow: 'auto' }}>
										<iframe
											src={`${process.env.REACT_APP_STORAGE_URL}${Unit.unit_video}`}
											width='200px'
											height='200px'
											allowfullscreen='allowfullscreen'></iframe>
									</td>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										{Unit.order}
									</td>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										<label style={{ maxWidth: '250px', overflowY: 'auto' }}>
											{Unit?.unit_description}
										</label>
									</td>
									<td
										style={{
											overflow: 'auto',
											maxWidth: '10vw',
											padding: '0',
										}}>
										<div
											style={{
												width: '100%',
												height: '100%',
											}}>
											<img
												loading='lazy'
												className='img-fluid'
												src={`${process.env.REACT_APP_STORAGE_URL}/${Unit.unit_cover}`}
												alt='Girl in a jacket'
												style={{
													objectFit: 'cover',
													width: '200px',
													height: '150px',
												}}
											/>
										</div>
									</td>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										{Unit.lessons_count}
									</td>
									<td style={{ overflow: 'auto' }}>
										<label style={{ maxWidth: '200px', maxHeight: '150px' }}>
											{Unit?.requirements?.map((requirment) => (
												<label>{requirment}</label>
											))}
										</label>
									</td>
									<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
										{Unit.lessons_sum_duration}
									</td>
									<td>
										<Button
											style={{ border: 'none' }}
											className='btn2'
											onClick={() => {
												setPop(true);
												setPopData(Unit);
											}}>
											التفاصيل
										</Button>
									</td>
									{/* <td> <Link to={`edit/${Quiz.id}`}  className='btn btn-dark'> تعديل </Link> </td> */}
									{/* <td> <button onClick={()=>  dispatch(delete_automated_quiz_by_automated_quiz({ QuizId:Quiz.id,UnitId:1,Refetch:refetch }))  } className='btn btn-danger'> حذف </button> </td> */}
									<td>
										<Link to={`type_quiz/${Unit.unit_id}`} className='btn btn1'>
											أضف اختبار
										</Link>
									</td>
									{/* <td> <Link to={`show_automated_quiz_questions_options/${Quiz.id}`}  className='btn btn-success' >  عرض الأسئلة  </Link>  </td> */}
								</tr>
							))}
						</tbody>
					)}
				</table>
			)}
			{data.isError && (
				<div class='d-flex justify-content-center'>
					<label className='text-danger'>{data.errorMessage}</label>
				</div>
			)}
			<PopUp show={pop} onHide={() => setPop(false)} data={popData} />
			{/* {data?.data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }   */}
		</div>
	);
}
