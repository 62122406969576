import React from 'react';
import Edit_traditional_quiz_Logic from './Edit_traditional_quiz_Logic';

export default function Edit_traditional_quiz({ isFinal, isAboveLevel }) {
	const {
		handlecorrection_Ladder_file,
		register,
		errors,
		isDirty,
		isValid,
		correction_Ladder_file_Ref,
		correction_Ladder_file_error,
		edit_traditional_quiz_questions_progress,
		edit_traditional_quiz_questions_error,
		edit_traditional_quiz_questions_success,
		onSubmit,
		QuizId,
		handleSubmit,
		watch
	} = Edit_traditional_quiz_Logic({ isFinal, isAboveLevel });

	return (
		<>
			<div className='container Add_traditional_quiz card_quiz_create'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					تعديل إختبار
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation my-5'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='َQuizeName'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الإختبار
						</label>
						<input
							type='text'
							className='form-control'
							id='َQuizeName'
							{...register('QuizeName', { required: true })}
						/>
						{errors.QuizeName && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-4'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الدرجة
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control degreeinput'
							id='degree'
						/>
						{errors.degree && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='descriptionQuize'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الوصف
						</label>
						<input
							type='text'
							{...register('descriptionQuize', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.descriptionQuize && (
							<p className='text-danger'>Invalid input</p>
						)}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة
						</label>
						<input
							type='number'
							{...register('duration', { required: true })}
							className='form-control'
							id='duration'
						/>
						{errors.duration && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='mb-3'>
						<label
							htmlFor='correction_Ladder_file'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							سلم التصحيح
						</label>
						<input
							ref={correction_Ladder_file_Ref}
							title='يرجى اختيار ملف من نوع pdf'
							onChange={handlecorrection_Ladder_file}
							accept='application/pdf'
							type='file'
							class='form-control w-50'
							id='correction_Ladder_file'
							aria-label='file example'
						/>
						{correction_Ladder_file_error ? (
							<div className='text-danger'>{correction_Ladder_file_error}</div>
						) : (
							<></>
						)}
					</div>

					{
							typeof watch('correction_ladder_file') == 'string' &&  watch('correction_ladder_file') != ''? 
							<iframe 
							src={ process.env.REACT_APP_STORAGE_URL + watch('correction_ladder_file')} 
							width='100%' 
							height={'300px'} 
							/>
							: 
							null

						
						}

					<div className='mb-3'>

						<label
							htmlFor='questions_file'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							 أسئلة الاختبار
						</label>

						<input
							// ref={correction_Ladder_file_Ref}
							title='يرجى اختيار ملف من نوع pdf'
							// onChange={handlecorrection_Ladder_file}
							accept='application/pdf'
							type='file'
							class='form-control w-50'
							id='questions_file'
							{...register('questions_file')}
							aria-label='file example'
						/>

						{
							typeof watch('questions_file') == 'string' ? 
							<iframe 
							src={ process.env.REACT_APP_STORAGE_URL + watch('questions_file')} 
							width='100%' 
							height={'300px'} 
							/>

							: 
							null

						
						}
			
					</div>

					{edit_traditional_quiz_questions_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{
									width: `${edit_traditional_quiz_questions_progress}%`,
								}}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>
								{edit_traditional_quiz_questions_progress}%{' '}
							</label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12'>
						<button
							className=' btn1'
							style={{
								borderRadius: '10px ',
								minWidth: '150px ',
								padding: '10px 5px',
							}}
							type='submit'>
							تعديل الاختبار
						</button>

						{edit_traditional_quiz_questions_error && (
							<label className='text-center w-100 text-danger'>
								{edit_traditional_quiz_questions_error?.response
									? edit_traditional_quiz_questions_error.response.data.message
									: edit_traditional_quiz_questions_error.message}
							</label>
						)}
					</div>
					{edit_traditional_quiz_questions_success && (
						<div class='alert alert-success text-center' role='alert'>
							تم تعديل الأسئلة بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
