

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetData } from '../../customhook/getDatawithAuth';

export default function ProfileLogic() {

    const { StudentId } = useParams();

    const [editPassword, setEditPassword] = useState(false);


    // const { getDatafrom: getdatastudent, IsLoading: studentDetailsIsLoading, IsError: studentDetailsError, data:studentDetails } = GetData(`get_student_by_guardian`);


    // useEffect(() => {
    //     getdatastudent();
    // }, [])

    const [studentDetails,setstudentDetails] = useState()

  useEffect(() => {
    let token = localStorage.getItem('token');
    if(localStorage.getItem('token2')){
      token = localStorage.getItem('token2');
    }
    async function getData() {
      const actualData = await fetch(
      `${process.env.REACT_APP_API_URL}/get_student_by_guardian`,{
      headers:{
         "Authorization":`Bearer ${token}`
      }
      }
      ).then(response => response.json());
      setstudentDetails(actualData);
  
      console.log("actual Dataaaaaaaaaaaaaaaaaaaa",actualData) 
    }
    getData()
  }, [])

    return {
        // editPassword,
        // setEditPassword ,
        // studentDetailsIsLoading,
        // studentDetailsError,
        // studentDetails
        studentDetails
    }
}