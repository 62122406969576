import React, { useState } from 'react';
import Path from '../../assets/icons/Path 398.png';
import './index.css';

function AccordionCustom(props) {
	const [visibile, setvisibile] = useState(false);
	const [indexItem, setindexItem] = useState(-1);
	const { data, backgroundImage, backgrounColor, color } = props;
	const openQuestion = (id) => {
		setindexItem(id);

		console.log('cliked');
		setvisibile(true);
		// setheight('220px');
	};
	const closeQuestion = (id) => {
		setindexItem(id);
		console.log('cliked close');
		setvisibile(false);
		// setheight('220px');
	};
	return (
		<div>
			{data &&
				data.map((item, index) => (
					<div
						className='faqs_card row align-items-center'
						style={{
							backgroundImage: `url(${backgroundImage})`,
							backgroundColor: backgrounColor,
							color: color,
						}}>
						<ul className='col-10'>
							<li className='question_text'>{item.question}</li>
						</ul>
						<div
							className='col-2'
							style={{
								textAlign: 'left',
							}}>
							{visibile && indexItem == index ? (
								<button
									style={{ border: 'none' }}
									onClick={() => closeQuestion(index)}>
									<img
										style={{
											width: '20px',
											height: '20px',
											rotate: '270deg',
										}}
										src={Path}></img>
								</button>
							) : (
								<button
									style={{ border: 'none' }}
									onClick={() => openQuestion(index)}>
									<img
									className='customCarousl_image_toggler'
										style={{
											width: '20px',
											height: '20px',
											rotate: '90deg',
										}}
										src={Path}></img>
								</button>
							)}
						</div>
						{visibile && indexItem == index && (
							<p className=' col-12'>{item.answer}</p>
						)}
					</div>
				))}
		</div>
	);
}

export default AccordionCustom;
