import React,{useState,useEffect} from 'react'

const StudentForTeacherLogic = () => {
    const [TeachersStudent,setTeacherStudent] = useState()
    useEffect(() => {
        async function getData() {
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_all_students_by_teacher`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":"Bearer 1|DvlUlZJSqhze9xrYe7FSv5RIxujriK3hj62z5tua"
            }
          }
          ).then(response => response.json());
          setTeacherStudent(actualData);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa TeacherStudnetss",actualData) 
        }
        getData()
      }, [])
  return {
    TeachersStudent,
  }
}

export default StudentForTeacherLogic