import React from 'react'
import Edit_Comment_Logic from './Edit_Comment_Logic';

export default function Edit_Comment() {
    const {updatecomment,data,register,onSubmit,errors,isDirty,isValid,handleSubmit}= Edit_Comment_Logic();
    return (
      <>
   {updatecomment.isSuccess&&<div class="alert alert-success text-center" role="alert">
 تهانينا تم تعديل التعليق بنجاح
</div>} 
     {
        updatecomment.isError&& <div className='alert alert-danger' role='alert'>{updatecomment.errorMessage}</div>
     }
      <div className='  py-5 '>

      
       <div className='d-flex justify-content-center'>
          <div className='card shadow  py-4  login-card'>
            <form onSubmit={handleSubmit(onSubmit)}>
    <div className='mb-3'>
              <div className='mb-3'>
                  <label className='fw-bold'>السؤال</label>
              </div>
             <input   {...register("comment",{ required: true })}  type='text' className='shadow w-100 main-input border-0' />
             {errors.comment&&<label className='text-center w-100 text-danger'>Invalid input</label>}
  </div>
  

  <div className='mb-3'>
            
      
  </div>
  
  
  
          {
        updatecomment.isFetching && <div className='d-flex mb-3'><div class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div></div>
      }
          <div className='d-flex'>
          <button disabled={!isDirty || !isValid} type='submit' className='login-button m-auto w-50 rounded text-white fw-bold py-2' >تعديل التعليق</button>
          </div>
          </form>
          </div>
          </div>
  
      </div>
      
      
      </>
    )
}
