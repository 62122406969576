import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

function EditModal({ element, setRefresh, refresh }) {
  //********************************************************* */
  let formData = new FormData();
  const [Task, setTask] = useState(element.task);
  const [Start_time, setStart_time] = useState(element.start_time);
  const [End_time, setEnd_time] = useState(element.end_time);
  const [Loading, setLoading] = useState(false);
  //********************************************************* *
  const editTask = async (taskId, formData) => {
    setLoading(true);
    await axios.post(
      `${process.env.REACT_APP_API_URL}/edit_student_day_task_by_student_day_task_id/${taskId}`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    setLoading(false);
    setRefresh(!refresh);
  };
  //********************************************************* */
  useEffect(() => {
    setTask(element.task);
    setStart_time(element.start_time);
    setEnd_time(element.end_time);
  }, [element]);
  //********************************************************* */
  return (
    <div
      className="modal fade"
      id="EditModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  bg-white">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              تعديل
            </h5>
          </div>
          <div className="modal-body">
            <form
              onSubmit={(event) => {
                event.preventDefault();

                formData.append("task", Task);
                formData.append("start_time", Start_time);
                formData.append("end_time", End_time);
                // console.log("start_time--->", Start_time);
                // console.log("end_time--->", End_time);
                editTask(element.id, formData);
              }}
            >
              <div>
                <label htmlFor="" className="">
                  اسم المهمة
                </label>
                <input
                  type="text"
                  className="form-control mt-2"
                  value={Task}
                  name="task"
                  onChange={(event) => setTask(event.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="">
                  وقت البدء
                </label>
                <input
                  type="time"
                  className="form-control mt-2"
                  value={Start_time}
                  name="start_time"
                  onChange={(event) => setStart_time(event.target.value)}
                />
              </div>
              <div>
                <label htmlFor="" className="">
                  وقت الانتهاء
                </label>
                <input
                  type="time"
                  name="end_time"
                  value={End_time}
                  className="form-control mt-2"
                  onChange={(event) => setEnd_time(event.target.value)}
                />
              </div>
              <div className="text-center mt-3">
                {Loading ? (
                  <div class="text-center" role="status">
                    <span class="spinner-grow text-primary"></span>
                  </div>
                ) : (
                  <button type="submit" className="btn btn-primary w-75">
                    حفظ
                  </button>
                )}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              الغاء
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
