import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetData } from '../../customhook/getDatawithAuth';

export default function ProfileLogic() {
	const [editPassword, setEditPassword] = useState(false);

	const {
		getDatafrom: getdatastudent,
		IsLoading: studentDetailsIsLoading,
		IsError: studentDetailsError,
		data: studentDetails,
	} = GetData(`/get_student_profile`);

	useEffect(() => {
		getdatastudent();
	}, []);

	return {
		editPassword,
		setEditPassword,
		studentDetailsIsLoading,
		studentDetailsError,
		studentDetails,
	};
}
