import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';

const LoginAsTeacher = () => {
    const {teacherToken} = useParams();
    const navigate = useNavigate();
    
    useEffect(() => {
        console.log('token is' , teacherToken);

        localStorage.setItem('token', teacherToken);
        localStorage.setItem('role',2);
        localStorage.setItem('admin_as_teacher', 1);
        
        setTimeout(() => {
            navigate('/options');
            window.location.reload();
        }, 1500);

    } , [])

    return <Layout>
        <div style={{display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
        {/* <h1> Login as teacher </h1>
        <h1> token: {teacherToken} </h1> */}

    <h1 className='w-100 text-center my-5'>يتم الآن جلب معلومات الاستاذ ... الرجاء الانتظار</h1>
    <div class="spinner-grow" role="status">
    <span class="visually-hidden">Loading...</span>
    </div>
        </div>
    </Layout>
}

export default LoginAsTeacher;