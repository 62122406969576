import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const getPosts = createAsyncThunk(
	'aboutUs/getAllPosts',
	async (_, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_about_us_site_info`
			);
			const data = await res.json();
			if (res.ok) {
				console.log('YES');
				console.log('data', data);
				return data;
			} else {
				console.log('NO', data);
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			console.log('error', error);
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
};

export const AboutUsSlice = createSlice({
	name: 'aboutUs',
	initialState,
	reducers: {},
	extraReducers: {
		[getPosts.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[getPosts.fulfilled]: (state, action) => {
			state.loading = false;
			console.log('fulfilled', action.payload);
			state.post = action.payload;
		},
		[getPosts.rejected]: (state, action) => {
			console.log('rejected_aboutUs', action);
			state.loading = false;
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default AboutUsSlice.reducer;
