import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from '../../components/button/index';
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import Questino from '../../components/questino';

import './automatedUnit.css';
import Layout from '../Layout/Layout';

import CountDown from '../../components/CountDoun';
import MainHeader from '../../components/MainHeader/MainHeader';
import clock from '../../assets/icons/clock.png';
import point from '../../assets/icons/point.png';
import timerimg from '../../assets/icons/egg-timer.png';
const AutomatedUnit = (props) => {
	const { id } = useParams();
	const [questions, setQuestions] = useState();
	const [studendAnswers, setStudentAnswers] = useState([]);
	const [finishExam, setFinishExam] = useState(false);
	const [isAnswering, setIsAnswering] = useState(false);
	const [isAnsweredBefore, setIsAnsweredBefore] = useState(false);
	const [correctingData, setCorrectingData] = useState();
	const location = useLocation();
	const [quizDetails, setquizDetails] = useState();
	const [startExam, setStartExam] = useState(false);
	const [timer, settimer] = useState();
	const [visibleTmer, setvisibleTmer] = useState(false);
	const [Error, setError] = useState();
	const [finish, setfinish] = useState(true);
	const [loading, setloading] = useState(true);

	const navigate = useNavigate();
	useEffect(() => {
		console.log('quiz id', id);

		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_all_automated_quiz_questions_with_options_by_automated_quiz/${id}`,
				{},
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				setQuestions(data.data.data);
				console.log('questions for automated quiz', data.data.data);
			})
			.catch((error) => {
				console.log('error inside quiz questions', error);
			});
	}, []);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_automated_quiz_by_id/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			})
			.then((data) => {
				setquizDetails(data.data.data);
				setloading(false);

				console.log('fffffffffffffffffffffffffffffffffffff');

				console.log('quizdata', data.data);
			})
			.catch((error) => {
				setloading(false);
				console.log('error inside automated quizez in lesson', error);
			});
	}, []);

	const hanldeStudentAnswer = (questionID, optionID) => {
		const copyAnswers = [...studendAnswers];
		let isAnsweredBefore = false;

		for (let i = 0; i < copyAnswers.length; i++) {
			if (copyAnswers[i].question_id == questionID) {
				isAnsweredBefore = true;

				copyAnswers[i].answer_id = optionID;
			}
		}

		if (!isAnsweredBefore) {
			copyAnswers.push({ question_id: questionID, answer_id: optionID });
		}

		console.log('student answers ==> ', copyAnswers);
		setStudentAnswers(copyAnswers);
	};
	const startQuiz = () => {
		setStartExam(true);
		finish == false && setfinish(true);
		!quizDetails.has_started_quiz_before &&
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/start_automated_quiz_by_automated_quiz_id`,
					{
						automated_quiz_id: id,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
				)
				.then((res) => {
					settimer(res?.data?.data?.remaining_time);

					console.log('end  Time', res?.data?.data);
				})
				.catch((err) => {});
	};
	const postAnswers = () => {
		setIsAnswering(true);

		let answersArrayToPost = [];
		for (let i = 0; i < studendAnswers.length; i++) {
			answersArrayToPost.push({
				aqq_id: studendAnswers[i].question_id,
				option_id: studendAnswers[i].answer_id,
			});
		}
		const Data = {
			answers: answersArrayToPost,
		};
		console.log('array of answers', answersArrayToPost);
		axios.defaults.headers.post['Content-Type'] =
			'application/x-www-form-urlencoded';

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/post_the_answer_for_automated_quizzes_question`,
				Data,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,

						Accept: 'application/json',
					},
				}
			)
			.then((data) => {
				console.log('lesson automated quize', data);

				setCorrectingData(data.data);

				setFinishExam(true);
				setStartExam(false);
				setTimeout(
					() =>
						window.location.replace(
							`/myquizes/units/automatedquiz/automatedquiz/${id}`,
							{ state: { quizDetailes: quizDetails } }
						),
					//  navigate(`/myquizes/units/automatedquiz/automatedquiz/${id}`),
					1000
				);
			})
			.catch((error) => {
				console.log('error inside lesson quize', error);
				setError(error.response.data.message);
				setIsAnsweredBefore(true);
				setFinishExam(false);
				setStartExam(false);
				setfinish(false);
			})
			.finally(() => {
				setIsAnswering(false);
				setStartExam(false);
			});

		console.log('asnswersssss', studendAnswers);
	};
	useEffect(() => {
		if (timer) {
			setvisibleTmer(true);
		} else if (quizDetails?.quiz_remaining_time) {
			setvisibleTmer(true);
		} else {
			setvisibleTmer(false);
		}
	}, [timer, quizDetails?.quiz_remaining_time]);

	return (
		<Layout>
			{/* <MainHeader /> */}
			{/* <ScreenTitle
				title={quizDetails?.subject_name && quizDetails?.subject_name}
				secondTitle='مدة الإنجاز'
				secondTitleNum={
					'د' + quizDetails?.duration && quizDetails?.duration > 60
						? Math.floor(quizDetails?.duration / 60)
						: quizDetails?.duration
				}
				thirdTitle='عدد النقاط'
				thirdTitleNum={quizDetails?.points && quizDetails?.points}
				completTitle={quizDetails?.nameOfQuiz && quizDetails?.nameOfQuiz}
				lessonNum={quizDetails?.lesson_name && quizDetails?.lesson_name}
			/> */}

			<div className='row justify-content-center text-center my-5'>
				<h1 className='fw-bold mb-4'>
					{loading ? (
						<div class='spinner-border' role='status'>
							<span class='sr-only'>Loading...</span>
						</div>
					) : (
						<span style={{ color: '#044364' }}>
							{quizDetails?.nameOfQuiz && quizDetails?.nameOfQuiz}
						</span>
					)}
				</h1>
				<div className='row justify-content-center'>
					<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
						<img src={clock} alt='book' width={'30px'}></img>
						{loading ? (
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						) : (
							<span className='mx-2 fs-3'>
								{' '}
								مدة الاختبار :
								{'د' + quizDetails?.duration && quizDetails?.duration > 60
									? Math.floor(quizDetails?.duration / 60)
									: quizDetails?.duration}
								دقيقة
							</span>
						)}
					</div>
					<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
						<img className='mx-2' src={point} alt='point' width={'30px'}></img>
						{loading ? (
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						) : (
							<span className='mx-2 fs-3'>
								عدد النقاط: {quizDetails?.points && quizDetails?.points} نقطة{' '}
							</span>
						)}
					</div>
				</div>

				{/* <img
							src={
								unitDetails &&
								`${process.env.REACT_APP_STORAGE_URL}${
									unitDetails && unitDetails.unit_cover
								}`
							}
							alt='cover'
						/> */}
			</div>
			<div className='examScreen__explanatino'>
				{/* <h2>شرح عن الاختبار</h2> */}
				{loading ? (
					<div class='spinner-border' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				) : (
					<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
						<li>{quizDetails && quizDetails.description}</li>
					</ul>
				)}
			</div>
			{localStorage.getItem('role') == 1 ? (
				<>
					{!visibleTmer && quizDetails?.has_started_quiz_before && (
						<div class='alert alert-dark container' role='alert'>
							يمكنك إرسال الإجابات لكن لن تحصل على النقاط
						</div>
					)}
					{!startExam && (
						<div className='w-100 text-center mt-5'>
							<button onClick={startQuiz} className='startExamBtn '>
								{quizDetails?.quiz_remaining_time &&
								quizDetails.quiz_remaining_time
									? '	الاستمرار بالاختبار'
									: '	البدء بالاختبار'}
							</button>
						</div>
					)}
					<div className='container'>
						{visibleTmer && startExam && !Error && (
							<div className='row w-50'>
								<div className='col-2 text-center'>
									{' '}
									<img src={timerimg} alt='timer' height={'70px'}></img>
								</div>
								<div className='col-3 py-2'>
									<CountDown
										start={startExam}
										endTime={
											quizDetails && quizDetails?.quiz_remaining_time
												? quizDetails.quiz_remaining_time
												: timer
										}
									/>
								</div>
							</div>
						)}
						{Error && (
							<div class='alert alert-dark' role='alert'>
								{Error}
							</div>
						)}

						{startExam &&
							questions &&
							questions?.map((question, index) => {
								return (
									<Questino
										showAnswersToUser={false}
										key={index}
										hanldeStudentAnswer={hanldeStudentAnswer}
										questionId={question.id}
										question={question.question}
										options={question.options}
										marks={question.point}
										notes={question.question_note}
									/>
								);
							})}

						{!isAnswering ? (
							studendAnswers?.length == questions?.length && (
								<div className='finishExamBtn'>
									{!finishExam ? (
										finish && (
											<div className='w-25 mx-auto'>
												<Button
													text={'إنهاء الاختبار'}
													className={`lessonsSecreen__FinishExamBtns`}
													onclick={postAnswers}
													// disabled={isAnsweredBefore}
												/>
											</div>
										)
									) : (
										<div className=' mx-auto'>
											<Button
												text={' النتيجة ' + correctingData?.result}
												className='examResultBtn'
											/>
										</div>
									)}
								</div>
							)
						) : (
							<div
								style={{
									margin: '20px auto !important',
									textAlign: 'center',
									paddingTop: '50px',
									width: '100%',
								}}>
								<div class='spinner-border' role='status'>
									<span class='visually-hidden'>Loading...</span>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<>
					<div className='container'>
						{questions &&
							questions?.map((question, index) => {
								return (
									<Questino
										showAnswersToUser={false}
										key={index}
										hanldeStudentAnswer={hanldeStudentAnswer}
										questionId={question.id}
										question={question.question}
										options={question.options}
										marks={question.point}
										notes={question.question_note}
									/>
								);
							})}
					</div>
				</>
			)}
		</Layout>
	);
};

export default AutomatedUnit;
