import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useUploadForm } from "../../customhook/Uploadfiles";
import {
  setError,
  setSuccess,
} from "../../redux-toolkit/reducers/notification";
let allowedExtension = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];
export default function Add_Unit_Logic({ setLoading }) {
  let navigate = useNavigate();
  const { SubjectId } = useParams();

  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      points: "",
      video: "",
      start_date: "",
      end_date: "",
      requirements: "",
      transable: "0",
      unit_order: '',
      auto_ordering: 1
    },
  });

  useEffect(() => {
    setValue("subject_id", SubjectId);
  }, []);

  const {
    isSuccess: add_unit_success,
    uploadForm: add_unit,
    progress: add_unit_progress,
    IsError: add_unit_error,
    data: add_unit_data,
    IsLoading: add_unit_loading,
  } = useUploadForm("/add_unit", {
    setLoading,
    setSuccess,
    setError,
    SuccessMsg: "تم إضافة الوحدة",
    redirectURL: "..",
  });

  useEffect(() => {
    if (add_unit_success) {
      navigate(`/options/mysubjects/showallunit/${SubjectId}`);
    }
  }, [add_unit_success]);

  const onSubmit = async (data) => {
    const add_unit_form = new FormData();
    add_unit_form.append("name", data.name);
    add_unit_form.append("description", data.description);
    add_unit_form.append("points", data.points);
    if(data.video[0]){
      add_unit_form.append("video", data.video[0]);
    }else {

    }

    add_unit_form.append('auto_ordering' , data.auto_ordering ? 1 : 0);
    
    if(!data.auto_ordering){
      add_unit_form.append('unit_order' , data.unit_order );
    }

    add_unit_form.append("isFree", data.isFree);
    add_unit_form.append("start_date", data.start_date);
    add_unit_form.append("end_date", data.end_date);
    add_unit_form.append("requirements", data.requirements);
    add_unit_form.append("subject_id", SubjectId);
    add_unit_form.append("transable", data.transable);
    add_unit_form.append("cover", cover);
    console.log("cover ", cover);

    for (var pair of add_unit_form.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    await add_unit(add_unit_form);
  };

  const [cover_error , setcover_error] = useState("") ;
  const [cover , setcover] = useState(null) ;
  const cover_Ref = useRef() ;

  const handlecover = (e) => {
    if (
      e.target.files[0].size / (1024 * 1024) < 8 &&
      allowedExtension.indexOf(e.target.files[0].type) > -1
    ) {
      setcover_error("");
      setcover(e.target.files[0]);
    } else {
      setcover(null);
      setcover_error("يرجى اختيار صورة بلاحقة jpg,png ");
      cover_Ref.current.value = "";
    }
  };
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    handlecover,
    cover,
    cover_Ref,
    cover_error,
    add_unit_error,
    add_unit_progress,
    add_unit_success,
    add_unit_loading,
    onSubmit,
    watch
  };
}
