import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	setError,
	setSuccess,
} from '../../../../redux-toolkit/reducers/notification';
import Error from '../../../alerts/Error/Error';
import DeleteModal from '../../../DeleteModal';
import Show_All_Lesson_For_Quiz_Logic from './Show_All_Lesson_For_Quiz_Logic';

export default function Show_All_Lesson_For_Quiz() {
	const { data, UnitId, isLoading, dispatch, error } =
		Show_All_Lesson_For_Quiz_Logic();
	console.log('dataaaa', data);
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDeleteAutomatedQuizeId = () => {
		//delete_automated_quiz/13
		setIsDeleting(true);

		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_automated_quiz/${selectedAutomatedQuizeId}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((error) => {
				setError('حدث خطأ ما يرجى المحاولة لاحقا');
				setIsDeleting(false);
			})
			.then(() => {
				setIsDeleting(false);
			});
	};

	return (
		<div className='container my-5'>
			{isLoading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{error && (
				<Error
					errorText={
						error?.response?.data?.message
							? error?.response?.data?.message
							: error.message
					}
				/>
			)}

			{!error && !isLoading && (
				<table className=' table-bordered  mt-3 table_style  text-center table-striped '>
					<thead>
						<tr>
							<th className='first_child'>#</th>
							<th> اسم الدرس </th>
							<th> ترتيب الدرس </th>
							<th> وصف الدرس </th>
							<th> غلاف الدرس </th>
							<td>مدة الدرس</td>
							<th> ماذا سوف نتعلم</th>
							<th>الأسئلة الشائعة</th>
							<th className='last_child text-center' colSpan={3}>
								{' '}
								الاختبارات
							</th>
							{/* <th className='last_child'></th> */}
						</tr>
					</thead>
					{data?.data && (
						<tbody>
							{data?.data.map((lesson, index) => (
								<tr key={lesson.lesson_id}>
									{console.log(index, 'lesson', lesson)}
									<td>{index}</td>
									<td> {lesson.lesson_name} </td>
									<td> {lesson.lesson_order} </td>
									<td>
										{' '}
										{lesson.lesson_description?.length > 30
											? lesson.lesson_description.slice(0, 30) + '...'
											: lesson.lesson_description}{' '}
									</td>
									<td>
										{' '}
										<div
											style={{
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												height: '100%',
											}}>
											<img
												loading='lazy'
												className='img-fluid'
												src={`${process.env.REACT_APP_STORAGE_URL}/${lesson.lesson_image}`}
												alt='Girl in a jacket'
												style={{
													objectFit: 'cover',
													width: '300px',
													height: '150px',
												}}
											/>
										</div>{' '}
									</td>
									<td> {lesson.lesson_duration} </td>
									<td>
										{' '}
										{lesson?.what_we_will_learn?.map((willlearn) => (
											<label>
												{willlearn.length > 25
													? willlearn.slice(0, 25) + '...'
													: willlearn}
											</label>
										))}
									</td>
									{/* <td> {lesson.lessons_sum_duration} </td> */}
									<td>
										{' '}
										{lesson?.faqs?.map((faq) => (
											<label>{faq.question}</label>
										))}
									</td>

									{/* <td> <Link to={`edit/${Quiz.id}`}  className='btn btn-dark'> تعديل </Link> </td> */}
									{/* {  (data?.data?.data?.length-1===index)&&<td> <button onClick={()=>  dispatch(delete_last_lesson({ LessonId:lesson.lesson_id,UnitId }))  } className='btn btn-danger'>  حذف الدرس </button> </td>} */}

									{lesson.has_lesson_gived_quiz_before && (
										<td>
											{' '}
											<Link
												to={`add_questions/${lesson.automated_quiz_id}`}
												className='btn3 btn  '>
												{' '}
												أضف أسئلة{' '}
											</Link>{' '}
										</td>
									)}
									{lesson.has_lesson_gived_quiz_before && (
										<td>
											{' '}
											<Link
												to={`show_questions/${lesson.automated_quiz_id}`}
												className='btn btn1'>
												{' '}
												عرض الأسئلة{' '}
											</Link>{' '}
										</td>
									)}

									{lesson.has_lesson_gived_quiz_before && (
										<td>
											<button
												type='button'
												class='btn btn2'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal'
												onClick={() =>
													setSelectedAutomatedQuizIe(lesson.automated_quiz_id)
												}>
												حذف الاختبار
											</button>
										</td>
									)}

					{
                    lesson.has_lesson_gived_quiz_before &&
                    <td>
                    {" "}
                    <Link
                      to={`edit/${lesson.automated_quiz_id}`}
                      className="btn btn-success"
                    >
                      {" "}
                       تعديل الاختبار{" "}
                    </Link>{" "}
                  </td>
                  }

									{!lesson.has_lesson_gived_quiz_before && (
										<td colSpan={3}>
											{' '}
											<Link
												to={`create_quiz/${lesson.lesson_id}`}
												className='btn btn1'>
												{' '}
												إنشاء اختبار{' '}
											</Link>{' '}
										</td>
									)}
								</tr>
							))}
						</tbody>
					)}
					{/* {data.isError&&<div class="d-flex justify-content-center"><label className='text-danger'>{data.errorMessage}</label></div>} */}
				</table>
			)}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا الاختبار ؟'}
				handleDelete={() =>
					handleDeleteAutomatedQuizeId(selectedAutomatedQuizeId)
				}
				isDeleting={isDeleting}
			/>
		</div>
	);
}
