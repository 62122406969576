import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getOpinion } from '../../redux-toolkit/reducers/Opinion/OpinionSlice';

export default function OpinionTarekeStudentsLogic() {
	const dispatch = useDispatch();
	const opinion = useSelector((state) => state.opinion);
	const dataOpinion = opinion?.post?.data?.data;

	const navigate = useNavigate();

	const handleNavigate = () => {
		navigate('/allComments', {
			state: { url: `${process.env.REACT_APP_API_URL}/get_users_opinions` },
		});
	};

	useEffect(() => {
		dispatch(getOpinion());
	}, []);

	return {
		handleNavigate,
		dataOpinion,
	};
}
