import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';

const LoginAsStudent = () => {
	const { studentToken } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		console.log('token is', studentToken);
		// console.log('is_active is', isactive);

		localStorage.setItem('token', studentToken);
		localStorage.setItem('role', 1);
		// localStorage.setItem('admin_as_teacher', 1);

		setTimeout(() => {
			navigate('/studentprofile');
			window.location.reload();
		}, 1500);
	}, []);

	return (
		<Layout>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					width: '100%',
					height: '100%',
				}}>
				{/* <h1> Login as teacher </h1>
        <h1> token: {teacherToken} </h1> */}

				<h1 className='w-100 text-center my-5'>
					يتم الآن جلب معلومات الطالب ... الرجاء الانتظار
				</h1>
				<div class='spinner-grow' role='status'>
					<span class='visually-hidden'>Loading...</span>
				</div>
			</div>
		</Layout>
	);
};

export default LoginAsStudent;
