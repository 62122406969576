import React, { useEffect } from 'react';
import uuid from 'react-uuid';
import './Show_Asnwear_Unit_Tranditiosnal.css';
import ShowAsnwearUnitTranditiosnalLogic from './ShowAnswerUnitTraditionalLogic';
import Layout from '../../../../pages/Layout/Layout';
import LayoutSideStudent from '../../../../pages/LayoutSide/LayoutSideStudent';
import Button from '../../../button';
import MainHeader from '../../../MainHeader/MainHeader';
import { useLocation } from 'react-router-dom';
import clock from '../../../../assets/icons/clock.png';
import point from '../../../../assets/icons/point.png';

export default function ShowAsnwearUnitTranditiosnal({ corrected }) {
	const {
		data,
		isSuccess,
		progress,
		IsLoading,
		IsError,
		qustions_Quiz_success,
		qustions_Quiz_IsLoading,
		qustions_Quiz_IsError,
		qustions_Quiz_data,
	} = ShowAsnwearUnitTranditiosnalLogic();
	console.log(qustions_Quiz_data);

	useEffect(() => {
		setInterval(() => {
			console.log('yes '.repeat(100), qustions_Quiz_data);
		}, 2000);
	}, []);
	const { state } = useLocation();
	const quiz = state.quizDetailes;

	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={'الاختبار'}>
				<div className='container Show_Asnwear_Unit_Tranditiosnal'>
					<div className='Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center'>
						<h1 className='fw-bold mb-4'>
							<span style={{ color: '#044364' }}>{quiz?.quiz_name}</span>
						</h1>
						<div className='row justify-content-center'>
							<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
								<img src={clock} alt='book' width={'30px'}></img>

								<span className='mx-2 fs-3'>
									{' '}
									مدة الاختبار :
									{'د' + quiz?.quiz_duration && quiz?.quiz_duration > 60
										? Math.floor(quiz?.quiz_duration / 60)
										: quiz?.quiz_duration}
									دقيقة
								</span>
							</div>
							<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
								<img
									className='mx-2'
									src={point}
									alt='point'
									width={'30px'}></img>

								<span className='mx-2 fs-3'>
									عدد النقاط: {quiz?.quiz_points && quiz?.quiz_points} نقطة{' '}
								</span>
							</div>
						</div>

						<div className='examScreen__explanatino'>
							<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
								<li>{quiz && quiz.quiz_description}</li>
							</ul>
						</div>
						<h3 className=' mx-auto' style={{ color: '#044364' }}>
							أسئلة الاختبار
						</h3>

						<iframe 
						src={process.env.REACT_APP_STORAGE_URL + qustions_Quiz_data?.data?.questions_file  }
						width='100%'
						height="500px"
						/>
						
						{/* 
						<div className='container p-2 my-2'>
							<div
								id='carouselExampleIndicators'
								className=' Show_Asnwear_Unit_Tranditiosnal_carousel carousel  slide w-75 mx-auto my-4  position-relative    '
								data-bs-ride='carousel'>
								<div className='carousel-indicators'>
									{qustions_Quiz_data?.data?.map((source, index) => {
										return (
											<button
												key={uuid()}
												type='button'
												data-bs-target='#carouselExampleIndicators'
												data-bs-slide-to={index}
												className={` ${index === 0 ? 'active' : ''} `}
												aria-current='true'
												aria-label={`Slide ${index + 1}`}></button>
										);
									})}
								</div>

								{!IsLoading ? (
									<div className='carousel-inner '>
										{qustions_Quiz_data?.data?.map((source, index) => {
											return (
												<div
													className={`carousel-item  ${
														index === 0 ? 'active' : ''
													} `}>
													<a
														rel='noreferrer'
														target='_blank'
														href={`${process.env.REACT_APP_STORAGE_URL}${source.image_link}`}>
														<img
															src={` ${process.env.REACT_APP_STORAGE_URL}${source.image_link}`}
															className='d-block w-100'
															alt='...'
														/>
													</a>
												</div>
											);
										})}
									</div>
								) : (
									<div class='spinner-border' role='status'>
										<span class='sr-only'>Loading...</span>
									</div>
								)}
								<button
									className='carousel-control-prev'
									type='button'
									data-bs-target='#carouselExampleIndicators'
									data-bs-slide='prev'>
									<span
										className='carousel-control-prev-icon'
										aria-hidden='true'></span>
									<span className='visually-hidden'>Previous</span>
								</button>
								<button
									className='carousel-control-next'
									type='button'
									data-bs-target='#carouselExampleIndicators'
									data-bs-slide='next'>
									<span
										className='carousel-control-next-icon'
										aria-hidden='true'></span>
									<span className='visually-hidden'>Next</span>
								</button>
							</div>
						</div> */}
					</div>
					<div className='Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center'>
						<h3 className=' mx-auto' style={{ color: '#044364' }}>
							أجوبة الطالب
						</h3>

						<div className='container p-2 my-2'>
							<div
								id='carouselExampleIndicators'
								className=' Show_Asnwear_Unit_Tranditiosnal_carousel carousel  slide w-75 mx-auto my-4  position-relative    '
								data-bs-ride='carousel'>
								<div className='carousel-indicators'>
									{data?.data?.map((source, index) => {
										return (
											<button
												key={uuid()}
												type='button'
												data-bs-target='#carouselExampleIndicators'
												data-bs-slide-to={index}
												className={` ${index === 0 ? 'active' : ''} `}
												aria-current='true'
												aria-label={`Slide ${index + 1}`}></button>
										);
									})}
								</div>
								{!IsLoading ? (
									<div className='carousel-inner '>
										{data?.data?.map((source, index) => {
											return (
												<div
													className={`carousel-item  ${
														index === 0 ? 'active' : ''
													} `}>
													<a
														rel='noreferrer'
														target='_blank'
														href={`${process.env.REACT_APP_STORAGE_URL}${source}`}>
														<img
															src={` ${process.env.REACT_APP_STORAGE_URL}${source}`}
															className='d-block w-100'
															alt='...'
														/>
													</a>
												</div>
											);
										})}
									</div>
								) : (
									<div class='spinner-border' role='status'>
										<span class='sr-only'>Loading...</span>
									</div>
								)}
								<button
									className='carousel-control-prev'
									type='button'
									data-bs-target='#carouselExampleIndicators'
									data-bs-slide='prev'>
									<span
										className='carousel-control-prev-icon'
										aria-hidden='true'></span>
									<span className='visually-hidden'>Previous</span>
								</button>
								<button
									className='carousel-control-next'
									type='button'
									data-bs-target='#carouselExampleIndicators'
									data-bs-slide='next'>
									<span
										className='carousel-control-next-icon'
										aria-hidden='true'></span>
									<span className='visually-hidden'>Next</span>
								</button>
							</div>
						</div>
					</div>
					{/* {corrected && console.log('y'.repeat(1000))}
					{corrected && (
						<Button
							text={` النتيحة ${
								data?.result === '-1' ? '0' : data?.result + '/100'
							}  `}
							className='examResultBtn'
						/>
					)} */}

					<>
						{/* {!IsLoading && (
							<div className='text-center'>
								<Button
									text={` النتيحة ${
										data?.result === '-1'
											? '0'
											: data?.result?.deserved_mark + '/100'
									}  `}
									className='examResultBtn'
								/>

								{data?.result?.teacher_notes && (
									<>
										<hr />
										<p className='fs-2'>
											ملاحظات الاستاذ: + {data?.result?.teacher_notes}
										</p>
									</>
								)}
							</div>
						)} */}
					</>
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}
