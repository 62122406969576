import React from 'react';
// import SideBarStudent from '../SideBar/SideBarStudent';

const LayoutSideStudent = (props) => {
	if (localStorage.getItem('role') == '1') {
		return (
			<>
				{/* <div className='sideBar__relative'>
					<SideBarStudent />
				</div> */}
				<div className='d-flex Profile'>
					<div className='d-flex side__left__content'>
						{/* <div className='d-flex side__left text-center'>
							<h4>{props.desc}</h4>
							{props.date && <h5>{props.date}</h5>}
						</div> */}
						{props.children}
					</div>
				</div>
			</>
		);
	} else {
		return props.children;
	}
};

export default LayoutSideStudent;
