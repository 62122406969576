import React, { useEffect, useState } from 'react';
import './LibrarySiteMin.css';
import libraryImage from '../../assets/library-site/bg.png';

import { Link } from 'react-router-dom';
import SubTitle from '../SubTitle/SubTitle';
import ButtonM from '../ButtonM/ButtonM';
export default function LibrarySiteMin() {
	const [data, setData] = useState([]);
	const [laoding, setlaoding] = useState(true);
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL}/get_website_library_photo_and_description`
		)
			.then((response) => response.json())
			.then((actualData) => {
				console.log('library sec', actualData);
				setlaoding(false);
				setData(actualData);
			})
			.then((error) => {
				setlaoding(false);
				console.log('error library', error);
			});
		// ).then(res=>res.json()).then(res=>setData(res));

		// console.log("library site info",response)
	}, []);
	const dataLibrary = data.data;
	return (
		<>
			{/* start   site newspaper  */}
			<div className='text-center mt-5 mb-3'>
				{/* <h2 className='fw-bold my-4 mt-5'>
					<span style={{ color: '#044364' }}>مكتبة الموقع </span>
				</h2> */}
				<SubTitle title={'مكتبة الموقع'} />
				<p
					className='mx-auto w-75 mt-3 py-4 text-center '
					style={{
						fontSize: ' 1.5rem',
					}}
					dangerouslySetInnerHTML={{
						__html: dataLibrary?.website_library_description,
					}}></p>
			</div>

			<div class=' LibrarySiteMin '>
				<img src={libraryImage} loading='lazy' height={'300'} />
				{/* <h3 className='text-white'>ما الذي توفره مكتبة الموقع</h3> */}
				<Link
					to='/LibrarySite'
					className='  '
					// style={{
					// 	marginTop: `38% !important`,
					// 	marginBottom: `31% !important`
					// }}
				>
					{' '}
					{/* تصفح الآن{' '} */}
					{/* <ButtonM text={'تصفح الآن'} className="linkLib bg-white" /> */}
					<button className='linkLib btn btn-white bg-white text-black rounded' >تصفح الآن</button>
				</Link>

				{/* <div class='col-7 col-sm-8 col-md-2 bg-white mx-auto m-2 p-2 rounded-x'>
							<img
								src={documentlogo}
								className='libraryminsite mx-auto d-block p-2'
								alt='...'
							/>

							<h5 className='card-title p-2'>
								كتب المنهج الدراسي للتحميل بصيغة pdf{' '}
							</h5>
						</div>
						<div class='col-7 col-sm-8 col-md-2 bg-white mx-auto m-2 p-2 rounded-x'>
							<img
								src={edit_document}
								className='mx-auto d-block p-2'
								alt='...'
							/>
							<h5 className='card-title p-2'>نوط الأساتذة مع الملاحظات</h5>
						</div>
						<div class='col-7 col-sm-8 col-md-2 mx-auto bg-white m-2 p-2 rounded-x'>
							<img
								src={books_stack_of_three}
								className='mx-auto d-block p-2'
								alt='...'
							/>
							<h5 className='card-title p-2'>دورات امتحانية مع حلولها</h5>
						</div> */}
			</div>

			{/* end   site newspaper  */}
		</>
	);
}
