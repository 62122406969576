import { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setError, setSuccess } from "../redux-toolkit/reducers/notification";

export const useUploadForm = (url, options) => {
  const token = useSelector((state) => state.SIGN.token);
  // console.log(${process.env.REACT_APP_API_SITE}${url})
  const [data, setData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsError, setIsError] = useState("");
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();

  const uploadForm = async (formData) => {
    // setIsLoading(true);

    console.log('url for signup is =>', url)

    for (var pair of formData.entries()) {
      console.log("formData", pair[0] + ", " + pair[1]);
    }

    // axios.withCredentials = true;

    // setIsLoading(true);
    if (options?.setLoading) options?.setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}` + url, formData, {
        headers: { 
          // "Cookie":"XSRF-TOKEN=eyJpdiI6IkRsb005ZHlieHQzV3U3UEJtdE5PK2c9PSIsInZhbHVlIjoiQVdVelorTUJ1MVVlQmNrRlVwR0c0bk5NR3FUT1pKY3p4QkFaWGdhazFJa2h5UlNsTm8yTXpKTWlvbVBEbVJFQ2J6YTByajlZV3ZFZmIvNnVCSFpoQ1JRUnJNMmMya2s3MllLVkFpVDVlL1JMNFdXUWNmK3B0cW1jR1FPMjBFS2kiLCJtYWMiOiI3NzM5YzVlMjIxZmRjZTNhMTg2YThiMTIxNjY2NTM2ZWQyNzEzNDc0NTY0OTk5NWFmM2VmNTU0OThjZWJiNzhkIiwidGFnIjoiIn0%3D; laravel_session=eyJpdiI6IjA4ODRCbi80dUdMZDczTGxNY2Y2VXc9PSIsInZhbHVlIjoiSnl4NU4yL2Jsc3R3YVBQUkM0SjlUMzBFSHI2MlNPd0lHSlprWnM0ckh1TUszbUV6TDN1YUt2NHVEMUpaM202Z2VzQnhTNGNiMUZXSERDcVp0dWhFOE1MRHdKUnB4SmpDSlZoUkkyaTNNeFIvRXovWTc2d1VsckV3cDdraEFudHYiLCJtYWMiOiJmN2M0ODk4NDJhYWU4ZDBiOTgyYmQ4NzI2ODYyMzUyYzdiZmVjN2RkMjE3OGQzY2M1NGU2MjA3ZWQwN2EzYjVjIiwidGFnIjoiIn0%3D"

          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
        },

        onUploadProgress: (progressEvent) => {
          const progress = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log("progress ", progress);

          setProgress(progress);
        },
        // onDownloadProgress: (progressEvent) => {
        //   const progress = 50 + (progressEvent.loaded / progressEvent.total) * 100;
        //   // console.log(progress);
        //   setProgress(progress);
        // },
      })
      .then((res) => {
        setIsSuccess(true);
        console.log('signup response is =>',res);
        if (res.status == 200) {
          const { data } = res;
          console.log("yesssss",data);
          console.log("URL",url);
          if(url=='/third_page_check_up_for_signup'){
            console.log("yes it is enterrr")
            setIsSuccess(true);
          }
          if(url.includes('edit_automated_quiz') ) {
            alert('تم تعديل معلومات الاختبار بنجاح')
          }
          if(data?.data?.token){
            console.log("dataaa",data?.data?.token);
            localStorage.setItem('token' , data?.data?.token)
            localStorage.setItem('role',1)
          }
          // setIsError("");
          // console.log("the register is success");
          setIsSuccess(true);
          console.log('validate 3 is true right now')
          // setProgress(0);
          // setTimeout(() => setIsSuccess(false), 4000);
          // alert('تمت العملية بنجاح')
          if (options?.setSuccess)
          if(url != '/third_page_check_up_for_signup'){
            dispatch(options?.setSuccess(options?.SuccessMsg));
          }
           
          if (options?.redirectURL) navigator(options?.redirectURL);
          // return setData(data);
        }
      })
      .catch((error) => {
        // setProgress(0);
        if(error.response.data.message && location.pathname != 'signup'){
          alert(error.response.data.message);
        }else {
          alert('حدث خطأ ما يرجى المحاولة لاحقا')
        }
      
        console.log("Error Message-->", error);
        if (options?.setError)
          dispatch(options?.setError(error.response.data.message));
        // return setIsError(error);
      })
      .finally(() => {
        if (options?.setLoading) options?.setLoading(false);
        setProgress(0);
      });
  };

  return { uploadForm, isSuccess, progress, IsLoading, IsError, data };
};