import React, { useEffect, useState } from 'react';
import './MyPoints.css';
import Layout from '../Layout/Layout';
import SideBar from '../SideBar/SideBar';
import Star from '../../assets/mypoints/winner.png';
import SideBarStudent from '../SideBar/SideBarStudent';
import MyPointsLogic from './MyPointsLogic';
import MainHeader from '../../components/MainHeader/MainHeader';

const MyPoints = () => {
	const { points, loading } = MyPointsLogic();
	console.log('loading', loading);
	console.log('ggggg');
	console.log(points?.points?.data[0]);


	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='sideBar__relative'>{/* <SideBarStudent /> */}</div>

			<div className='d-flex Profile'>
				<div className='d-flex side__left__content'>
					<div className='container points'>
						<h3 style={{ color: '#044364' }} className='fw-bold my-3'>
							النقاط
						</h3>
						<div className='row MyPonits__content justify-content-center'>
							<div className=' col-10 col-sm-10 col-md-8  my-3 text-center'>
								{loading ? (
									<div className='text-center'>
										<div class='spinner-border mx-auto' role='status'>
											<span class='sr-only'>Loading...</span>
										</div>
									</div>
								) : (
									<div
										className=' mx-auto point_card  p-4  justify-content-center '
										style={{ color: '#044364' }}>
										<img
											src={Star}
											style={{
												width: '30%',
												height: '30%',
												margin: '0px auto',
											}}
											className='card-img-top'
											alt='...'
										/>
										<h5 className='mt-2'>عدد النقاط الكلي</h5>
										<h5 className='card-title   mt-2 '>
											{points?.points?.data[0]}
											{' نقطة'}
										</h5>
									</div>
								)}
							</div>
							<div className='row justify-content-center my-5 '>
								<div className='col-sm-12 col-md-6 col-lg-6 mx-auto points__details point_card p-5'>
									<button className='btn btn-primary points__detail__button'>
										{' '}
										الية تحصيل النقط{' '}
									</button>

									<p>
										يتم تحصيل النقاط عن طريق إنجاز الدروس والإختبارات والتحديات
										داخل المنصة
									</p>
								</div>
							</div>
							{/* <div className='row justify-content-center mt-5'>
								<div className='col-lg-3 col-md-4 col-sm-6 col-10  '>
									<div className='mx-3 px-1 py-3 point_card  text-center my-3'>
										<h5>الوحدة الاولى</h5>
										<p>الدرس الاول</p>
										<h5>5 نقاط</h5>
									</div>
								</div>
								<div className='col-lg-3 col-md-4 col-sm-6 col-10  '>
									<div className='mx-3 px-1 py-3 point_card  text-center my-3'>
										<h5>الوحدة الاولى</h5>
										<p>الدرس الاول</p>
										<h5>5 نقاط</h5>
									</div>
								</div>
								<div className='col-lg-3 col-md-4 col-sm-6 col-10  '>
									<div className='mx-3 px-1 py-3 point_card  text-center my-3'>
										<h5>الوحدة الاولى</h5>
										<p>الدرس الاول</p>
										<h5>5 نقاط</h5>
									</div>
								</div>
								<div className='col-lg-3 col-md-4 col-sm-6 col-10  '>
									<div className='mx-3 px-1 py-3 point_card  text-center my-3'>
										<h5>الوحدة الاولى</h5>
										<p>الدرس الاول</p>
										<h5>5 نقاط</h5>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};
export default MyPoints;
