import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { get_lesson_comment_by_id, Reset_update_lesson_comment_by_id, update_lesson_comment_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
export default function Edit_Comment_Logic() {
  const lessonComment = useSelector(state => state.lesson.get_lesson_comment_by_id);
  const updatecomment = useSelector(state => state.lesson.update_lesson_comment_by_id)

  const { CommentId } = useParams();
  const dispatch = useDispatch();


  const { register, setValue, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
    mode: "onChange", defaultValues: {
      comment: '',
        
    }
  });

  useEffect(() => {
    dispatch(get_lesson_comment_by_id({ CommentId }));
  }, [])



 
  useEffect(() => {
    if (lessonComment.data) {
      setValue('comment', lessonComment.data.comment);
    }
    console.log(lessonComment);
  }, [lessonComment.data])

  const onSubmit = data => {
    const formdata = new FormData();
    formdata.append('comment', data.comment);

    dispatch(update_lesson_comment_by_id({ formdata, CommentId }))
  }

  const data = useSelector((state) => state.units.add_unit_faqs)

  return { data, register, errors, isDirty, isValid, handleSubmit, onSubmit, updatecomment }
}
