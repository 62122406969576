import React from 'react'
import { useRef } from 'react';
import {useForm} from 'react-hook-form'
import { useParams } from 'react-router-dom';
import { useUploadForm } from '../../../../customhook/Uploadfiles';
export default function Create_Automated_Quiz_Lesson_Logic() {
  const { getValues ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
    QuizeName:'',
    points:0,
    descriptionQuize:'',
    duration:'', 
  }});

  
  const questionText = useRef(null);

  const {LessonId} = useParams();





  const { isSuccess:create_unit_automated_quiz_form_success, uploadForm:create_unit_automated_quiz_func, progress:create_unit_automated_quiz_form_progress ,IsError:create_unit_automated_quiz_form_error,data:create_unit_automated_quiz_form_data } = useUploadForm('/create_automated_quiz');

 
  const onSubmit = async (data) => {
    console.log(typeof(data.points))
    const create_unit_automated_quiz_form = new FormData(); 
    create_unit_automated_quiz_form.append('lesson_id',LessonId);
    create_unit_automated_quiz_form.append('isFinal',0);
    create_unit_automated_quiz_form.append('duration',data.duration);
    create_unit_automated_quiz_form.append('isAboveLevel',1);
    create_unit_automated_quiz_form.append('nameOfQuiz',data.QuizeName);
    create_unit_automated_quiz_form.append('points', data.points);
    create_unit_automated_quiz_form.append('description', data.descriptionQuize)
    create_unit_automated_quiz_form.append('transable', 0)
    if(questionText.current != ''){
      questionText.current && create_unit_automated_quiz_form.append('automated_quiz_text',questionText.current)
    }
     await   create_unit_automated_quiz_func(create_unit_automated_quiz_form);
   };
 







  
return {
register,
errors,
isDirty,
isValid,
handleSubmit,
create_unit_automated_quiz_form_error,
create_unit_automated_quiz_form_progress,
create_unit_automated_quiz_form_success,
questionText,
onSubmit
}
}
