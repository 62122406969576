import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GetData } from '../../customhook/getDatawithAuth';

export default function ProfileLogic() {
	// const { StudentId } = useParams();

	//  get Student Id  from url
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	const { StudentId } = useParams();
	console.log('StudentId');
	console.log(StudentId);
	console.log('params', useParams());
	const [editPassword, setEditPassword] = useState(false);

	const {
		getDatafrom: getdatastudent,
		IsLoading: studentDetailsIsLoading,
		IsError: studentDetailsError,
		data: studentDetails,
	} = GetData(`/get_student_profile_by_id/${StudentId}`);

	useEffect(() => {
		getdatastudent();
	}, []);

	return {
		editPassword,
		setEditPassword,
		studentDetailsIsLoading,
		studentDetailsError,
		studentDetails,
	};
}
