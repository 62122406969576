import React, { useRef, useState, useEffect } from 'react';

import './index.css';
import Layout from '../Layout/Layout';
import ProfileLogic from './profileLogic';

import axios from 'axios';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';
import MainHeader from '../../components/MainHeader/MainHeader';
import profile from '../../assets/signinPage/profile.png';
import email from '../../assets/signinPage/email.png';
import phone from '../../assets/signinPage/phone-outgoing.png';
import lock from '../../assets/signinPage/Layer 2.png';
import CountrySelect from 'react-bootstrap-country-select';
import { useForm } from 'react-hook-form';
import ToastError from '../../components/ToastSuccessError/ToastError';
const ProfileScreenByToken = () => {
	const role = localStorage.getItem('role');
	const token = localStorage.getItem('token');
	const [countries, setcountries] = useState([]);
	const {
		editPassword,
		setEditPassword,
		studentDetailsIsLoading,
		// studentDetailsError,
		studentDetails,
	} = ProfileLogic();
	console.log('studentDetailsss', studentDetails);
	const [data, setdata] = useState({});
	const [loading, setloading] = useState(false);
	const [isVisible, setVisible] = useState(true);
	const oldPassword = useRef();
	const newPassword = useRef();
	const confirmPassword = useRef();
	const [disibled, setdisibled] = useState(true);
	const [value, setValue] = useState(null);
	const editProfile = () => {
		setloading(true);
	};

	const [errormessage,setErrorMessage] = useState('');
	const [successMessage,setSuccessMessage] = useState('');

	const [isNotEqual,setIsNotEqual] = useState(false);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
		watch
	  } = useForm({
		mode: "onChange",
		defaultValues: {
			password: '' ,
			confirmpassword: ''
	  }});
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_countries`)
			.then((res) => {
				console.log('countries is ', res.data.data);
				setcountries(res.data.data);
			});
	}, []);
	const onChange = () => {
		setdata({
			old_password: oldPassword.current.value,
			new_password: newPassword.current.value,
		});
	};
	const onSubmit = (data) => {
	// 	newPassword.current.value &&
	// 	newPassword.current.value === confirmPassword.current.value
	// 		? setVisible(false)
	// 		: setVisible(true);
	// };

	// const changePassword = () => {
	// 	data &&
	// 		axios
	// 			.post(
	// 				`${process.env.REACT_APP_API_URL}/update_student_password`,
	// 				data ,
	// 				{
	// 					headers: {
	// 						Authorization: `Bearer ${token}`,
	// 					},
	// 				}
	// 			)
	// 			.then((res) => {
	// 				console.log(res);
	// 				alert('تم تغيير كلمة المرور');
	// 				setEditPassword(false);
	// 			});
		const student_form_data = new FormData();

	if(data.password != data.confirmpassword){

		setIsNotEqual(true);

	}else {
		setloading(true);
		setIsNotEqual(false);

		student_form_data.append('password', data.password);
		// student_form_data.append('new_password',data.new_password);

		console.log('sddsdfsdsfsddsf sd fsd ',data);

			axios
			.post(
			`${process.env.REACT_APP_API_URL}/update_student_password`,
			student_form_data ,
			{
			headers: {
				Authorization: `Bearer ${token}`,
			},
			}
			)
			.then((res) => {
			// console.log(res);
			// alert('تم تغيير كلمة المرور');
			setloading(false);
			setSuccessMessage('تم تعديل كلمة السر بنجاح')
			// setEditPassword(false);
			}).catch(error => {	
				setErrorMessage('حدثت مشكلة ما يرجى المحاولة مرة أخرى')
			})
			.finally(() => {
				setloading(false);
				setdisibled(true);
				reset("")
			})
	}

	};

	useEffect(() => {
		if(isNotEqual) {
			setIsNotEqual(false);
		}
	},[watch('password'), watch('confirmpassword')])

	// console.log(data);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<ToastError 
			ErrorMessage={errormessage}
			successMessage={successMessage}
			setSuccessMessage={setSuccessMessage}
			setErrorMessage={setErrorMessage}
			/>
			<LayoutSideStudent desc={'البروفايل'}>
				<div className='profileScreen'>
					<div className='profileScreen__content '>
						<h3 style={{ color: '#044364' }} className='fw-bold my-3 container'>
							البروفايل
						</h3>
						<div className='d-flex justify-content-center'>
							{studentDetailsIsLoading ? (
								<div className='text-center mt-5'>
									<div class='spinner-border mx-auto' role='status'>
										<span class='sr-only'>Loading...</span>
									</div>
								</div>
							) : (
								<div className='   py-4  mt-2 mb-5 login-card'>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div className='mb-3  mt-5'>
											<div className='mb-4'>
												<label className='fw-bold fs-5'>اسم المستخدم</label>
											</div>
											<div className='parent mb-5 '>
												<input
													disabled={true}
													placeholder='ادخل اسم المستخدم'
													autoComplete
													// {...register('username', { required: true })}
													type='text'
													name='username'
													className=' form-control    login_input'
													value={studentDetails?.data?.name}
												/>
												<img src={profile} className='sun' />
											</div>
										</div>
										<div className='mb-3  mt-5'>
											<div className='mb-4'>
												<label className='fw-bold fs-5'>
													البريد الإلكتروني{' '}
												</label>
											</div>
											<div className='parent mb-5 '>
												<input
													placeholder=' ادخل البريد الالكتروني  '
													autoComplete
													disabled={true}
													// {...register('username', { required: true })}
													type='email'
													name='email'
													className=' form-control    login_input'
													value={studentDetails?.data?.email}
												/>
												<img src={email} className='sun' />
											</div>
										</div>
										<div className='mb-3  mt-5'>
											<div className='mb-4'>
												<label className='fw-bold fs-5'>رقم الموبايل </label>
											</div>
											<div className='parent mb-5 '>
												<input
													placeholder='ادخل رقم الموبايل '
													autoComplete
													// {...register('username', { required: true })}
													type='text'
													name='phone'
													disabled={true}
													className=' form-control    login_input'
													value={studentDetails?.data?.phone}
												/>
												<img src={phone} className='sun' />
											</div>
										</div>

										<div className='mb-3  mt-5'>
											<div className='mb-4'>
												<label className='fw-bold fs-5'>البلد </label>
											</div>
											<div className='parent mb-5 '>
												{/* <CountrySelect
													value={studentDetails?.data?.city}
													onChange={setValue}
													matchNameFromStart={false}
													matchAbbreviations
													placeholder={'اختر البلد'}
													name='country'
												/> */}
												<select
													disabled={true}
													class='form-select'
													aria-label='Default select example'>
													<option selected>Open this select menu</option>
													{countries.map((country) => (
														<option value={country.id}>{country.name}</option>
													))}
												</select>
											</div>
										</div>

										<div className='mb-3 mt-5 '>
											<div className='mb-3 mt-2 '>
												<label className='fw-bold fs-5'>كلمة السر</label>
											</div>
											<div className='parent mt-4 mb-5'>
												<input
													disabled={disibled}
													placeholder='ادخل كلمة المرور'
													autoComplete
													{...register('password')}
													type='password'
													name='password'
													className=' form-control login_input'
												/>

												<img src={lock} className=' sun' />
											</div>
										</div>
										<div className='mb-3 mt-5 '>
											<div className='mb-3 mt-2 '>
												<label className='fw-bold fs-5'> تأكيد كلمة السر</label>
											</div>
											<div className='parent mt-4 mb-5'>
												<input
													disabled={disibled}
													placeholder='تأكيد كلمة السر  '
													autoComplete
													{...register('confirmpassword')}
													type='password'
													name='confirmpassword'
													className=' form-control login_input'
												/>

												<img src={lock} className=' sun' />
											</div>
										</div>

										
							{
								isNotEqual && 
								<div className='my-3 alert text-danger text-center'> تأكيد كلمة السر غير مطابق لكلمة السر المدخلة </div>
							}

										{loading ? (
											<div className='text-center'>
												<div className='text-center mt-5 mx-auto'>
													<div class='spinner-border mx-auto' role='status'>
														<span class='sr-only'>Loading...</span>
													</div>
												</div>
											</div>
										) : (
											!disibled && (
												<div className='d-flex'>
													<button
														onClick={() => setdisibled(false)}
														type='submit'
														className='login-button m-auto  rounded text-white fw-bold py-2'>
														تأكيد
													</button>

													<button
														onClick={() => setdisibled(true)}
														type='submit'
														className='login-button m-auto  rounded text-white fw-bold py-2'>
														إلغاء
													</button>
												</div>
											)
										)}

									</form>
									{disibled && (
										<div className='d-flex'>
											<button
												onClick={() => setdisibled(false)}
												className='login-button m-auto  rounded text-white fw-bold py-2'>
												تعديل
											</button>
										</div>
									)}
								</div>
							)}
					
						</div>
					</div>
				</div>
			</LayoutSideStudent>
		</Layout>
	);
};

export default ProfileScreenByToken;
