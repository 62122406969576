import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';

export default function Options() {
	return (
		<>
			{/* <MainHeader /> */}

			{/* <LayoutSideTeacher> */}
			<Outlet />
			{/* </LayoutSideTeacher> */}
		</>
	);
}
