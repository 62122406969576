import React from "react";

function SeeMoreModal({ records }) {
  //   console.log(records);
  //***************************************** */
  const renderTasks = records.tasks.map((task) => {
    return (
      <>
        <div>
          <span className="lead">اسم المهمة: </span>
          <span className="text-muted">{task.task}</span>
        </div>
        <div>
          <span className="lead">وقت البدء: </span>
          <span className="text-muted">{task.start_time}</span>
        </div>
        <div>
          <span className="lead">وقت الانتهاء: </span>
          <span className="text-muted">{task.end_time}</span>
        </div>
        <hr />
      </>
    );
  });
  //***************************************** */
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable bg-white">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {records.day_name}
            </h5>
          </div>
          <div className="modal-body">{renderTasks}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeeMoreModal;
