import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect , useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import SubStudents2 from '../../components/Students/SubStudents2';
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';
import fetchStudentsQuizes from '../../redux-toolkit/api/fetchStudentsQuizes';
import { useQuery } from 'react-query';

const StudentsVisas = ({api,to}) => {

    const [isloading,setIsLoading] = useState(true);
    const [students,setStudents] = useState([]);
    const [erro,setError] = useState(null);
    const [subjectId,setSubjectId] = useState();
    const [page,setPage] =useState(1);
    const [query,setQuery] = useState('');

    const unOrderStudentsList = useRef();
    const [stuentId , setStduentId] = useState();
    const inputSearchRef = useRef();
    const [searchResult,setSearchResult] = useState('');

    const params = useParams();


    function handlePageClick (data)
    {
      setPage(data.selected+1);
    }

    const handleUnorderStudentsList = (searchQuery) => {
      if(searchQuery == '') {
          unOrderStudentsList.current.style.height = '0px';
      }else {
          unOrderStudentsList.current.style.height = 'auto';
      }
    }

    const handleSearchStudent = (e) => {
        
      unOrderStudentsList.current.style.height = 'auto';
      setQuery(e.target.value);
  
      handleUnorderStudentsList(e.target.value);
  
      // setValue('student_id',e.target.value);
  }

    // const {isLoading, isError, data, error} = useQuery(['students',page, params.subjectId , query], fetchStudentsQuizes);

    useEffect(() => {
        // console.log('params is',params)
        // setSubjectId(params.subjectId)
        setIsLoading(true);

        axios.get(`${process.env.REACT_APP_API_URL}${api}`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('data', data.data.data);
            setStudents(data.data.data);
            // setIsLoading(false);
        })
        .catch(error => {
            // console.log('error')
        })
        .finally(() => {
            setIsLoading(false);
        })
        
    },[])

    return (

        <div className='container'>

          <div className="w-100 text-center">

        {/* <input 
        type='text' 
        ref={inputSearchRef} 
        placeholder=' اكتب هنا للبحث عن طالب عن طريق الاسم' 
        onChange={(e) => handleSearchStudent(e)} 
        className='shadow w-100 main-input rounded mt-3 p-2'
        style={{height: '60px', maxWidth: '500px', border: '2px solid #222 !important', fontSize: '1.2rem'}}
        /> */}

        </div>
           
           <div className='students_select mb-2 rounded'>   
               {/* <ul className='unorder_students_list' ref={unOrderStudentsList} >
                       {
                           searchResult && searchResult?.map((student,index) => {
                               return   <li 
                                      //  onClick={() => handleSelectStudentFromDrobDownList(student)} 
                                       value={student.student_id} 
                                       key={index}>
                                           {student.name}
                                       </li>
                           })
                       }
                   </ul> */}

                    
       {/* <div className='text-center my-5'>
            <button className='btn btn-primary' onClick={handleAddStudent}>اضف طالب</button>
       </div> */}


           </div>

    <div className='pho rounded shadow border d-flex align-items-center justify-content-center gap-3 flex-wrap'>
    {
            isloading ? 
            <div className='w-100 text-center mb-5'>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        : 
        
        students?.length > 0 ? students.map((student) => {
              return (
                <Link to={`${to}/${student.student_id}`}>
                  <SubStudents2
                  key={student.student_id}
                    src={
                      student.user_image
                        ? student.user_image
                        : student.student_image
                    }
                    name={student?.name ? student.name : student.student_name}
                    studentId={student.student_id}
                    subjectId={params.subjectId}
                    isVisas
                  />
                  </Link>
                
              );
            }) 
            : 
            <div class="alert alert-danger mx-auto shadow" role="alert">
            لم يقم أية طالب بعد بتقديم بطلب تأشيرات خروج بعد في هذه المادة
          </div>
        }

</div>

{/* { (data?.total < 1  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />)  }   */}
        </div>


    );
}

export default StudentsVisas;