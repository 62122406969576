import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import fetchcommentsbyunitid from '../../../../../redux-toolkit/api/unit/fetchcommentsbyunitid';

export default function Show_All_Comments_Logic() {
	const dispatch = useDispatch();
	const [loading, setloading] = useState();
	const [page, setPage] = useState(1);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}
	const deleteComment = (id) => {
		setloading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_comment_for_unit/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};
	const Authdata = useSelector((state) => state.SIGN.token);
	const { UnitId } = useParams();

	const { isLoading, isError, data, error, refetch } = useQuery(
		['commentsunit', UnitId, 10, page, Authdata],
		fetchcommentsbyunitid,
		{ enabled: true }
	);

	return {
		dispatch,
		data,
		isLoading,
		isError,
		error,
		refetch,
		handlePageClick,
		loading,
		deleteComment,
	};
}
