import React from 'react';
import Add_Traditiona_Quiz_Quistions_Files_Logic from './Add_Traditiona_Quiz_Quistions_Files_Logic';
import './Add_Traditiona_Quiz_Quistions_Files.css';
export default function Add_Traditiona_Quiz_Quistions_Files() {
	const {
		Quistions,
		handleQuistions,
		Add_Traditiona_Quiz_Quistions_Files_success,
		progress_Add_Traditiona_Quiz_Quistions_Files,
		IsError_Add_Traditiona_Quiz_Quistions_Files,
		Add_Traditiona_Quiz_Quistions_Files_loading,
		Add_Traditiona_Quiz_Quistions_Files_Event,
	} = Add_Traditiona_Quiz_Quistions_Files_Logic();

	return (
		<div className='container'>
			<div className=' col-sm-12 my-3'>
				<div className='mb-3'>
					<i
						className='fa fa-book'
						style={{ marginLeft: '1rem', fontSize: '1.5rem' }}
					/>
					<label className='fw-bold fs-4' style={{ color: '#044364 ' }}>
						{' '}
						قم بإضافة صور الأسئلة
					</label>
				</div>
				<div className='bg-white shadow fine mb-3 d-flex flex-wrap  justify-content-center row'>
					{Quistions?.map((certificate) => (
						<div className='col-3' key={certificate.name}>
							{' '}
							<img
								alt='certafecates'
								src={URL.createObjectURL(certificate)}
								className='imagesquistions'
							/>
						</div>
					))}
					{Quistions?.length >= 8 ? (
						<> </>
					) : (
						<label className='col-12 text-center' htmlFor='certificate'>
							<i className='fa fa-plus'></i>
							<div>
								<input
									multiple
									accept='image/png, image/jpeg'
									id='certificate'
									type='file'
									onChange={(e) => {
										handleQuistions(e);
									}}
									style={{ display: 'none' }}
									className='hideinput'
								/>
							</div>
						</label>
					)}
				</div>

				{Add_Traditiona_Quiz_Quistions_Files_loading &&
					progress_Add_Traditiona_Quiz_Quistions_Files && (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{
									width: `${progress_Add_Traditiona_Quiz_Quistions_Files}%`,
								}}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>
								{progress_Add_Traditiona_Quiz_Quistions_Files}{' '}
							</label>
						</div>
					)}

				{!Add_Traditiona_Quiz_Quistions_Files_success &&
				!Add_Traditiona_Quiz_Quistions_Files_loading ? (
					<div className='d-flex justify-content-center'>
						<button
							disabled={!Quistions.length}
							onClick={Add_Traditiona_Quiz_Quistions_Files_Event}
							className='btn1 m-auto w-25 rounded text-white fw-bold py-2 my-3'
							type='submit'>
							رفع الأسئلة
						</button>
					</div>
				) : (
					<></>
				)}
				{Add_Traditiona_Quiz_Quistions_Files_success && (
					<div class='alert alert-success text-center' role='alert'>
						تهانينا تم إاضافة الأسئلة بنجاح
					</div>
				)}
				{IsError_Add_Traditiona_Quiz_Quistions_Files && (
					<label className='text-center w-100 text-danger'>
						{
							IsError_Add_Traditiona_Quiz_Quistions_Files.response?.data
								?.message
						}
					</label>
				)}
			</div>
		</div>
	);
}
