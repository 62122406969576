import axios from "axios";
import React, { useState } from "react";

function DeleteModal({ taskId, refresh, setRefresh }) {
  //**************************************************** */
  const deleteTask = async (taskId) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/delete_student_day_task_by_student_day_task_id/${taskId}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    setRefresh(!refresh);
  };
  //**************************************************** */
  return (
    <div
      className="modal fade h-75"
      id="deleteModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog  bg-white">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              تأكيد الحذف
            </h5>
          </div>
          <div className="modal-body">
            <p className="h4">هل تريد بالتأكيد حذف هذه المهمة؟</p>
          </div>
          <div className="modal-footer d-flex justify-content-start">
            <button
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
              onClick={deleteTask.bind(null, taskId)}
            >
              موافق
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
