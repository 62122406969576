import React from 'react';
import { useLocation } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
import Layout from '../Layout/Layout';
import img from '../../assets/AboutUs/Mask Group 2.png';

function ImageOpen() {
	const location = useLocation();
	const url = location?.state?.url;

	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className=' text-center'>
				<img src={url ? url : img} />
			</div>
		</Layout>
	);
}

export default ImageOpen;
