import React, { useState } from 'react';

import './ProfileDetailsTeacher.css';

import ProfileLogic from './ProfileDetailsTeacherLogic';

import { useSelector } from 'react-redux';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import { clearMsg } from '../../redux-toolkit/reducers/notification';
import profile from '../../assets/signinPage/profile.png';
import email from '../../assets/signinPage/email.png';
import phone from '../../assets/signinPage/phone-outgoing.png';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import ToastError from '../../components/ToastSuccessError/ToastError';

const ProfileDetailsTeacher = ({ Title }) => {
	const [loading, setLoading] = React.useState(false);
	const successMessage = useSelector((state) => state.notification.isSuccess);
	const errorMessage = useSelector((state) => state.notification.isError);
	const [isLoadingEditPassword,setIsLoadingEditPassword] = useState(false);
	const [successmessage,setSuccessMessage] = useState('');
	const [errormessage,setErrorMessage] = useState('');

	    const { register, handleSubmit , reset , formState: { errors } } = useForm({
      defaultValues: {
        old_password: '',
        new_password: '',

      },mode:'onChange'
    });

	const addStudentRecord = (data) => {
      
		setIsLoadingEditPassword(true);
  
		console.log('data gggg' , data)
		const formCallsRecordData = new FormData();
  
		formCallsRecordData.append('old_password', data.old_password);
		formCallsRecordData.append('new_password', data.new_password);
  
  
		axios.post(`${process.env.REACT_APP_API_URL}/update_teacher_password`, formCallsRecordData , 
		{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
		.then((data) => {
  
		  setSuccessMessage('تم تعديل كلمة السر بنجاح  ');
		//   refetch();
		  reset();
		})
		.catch(error => {
			if(error.response.data.message) {
				setErrorMessage(error.response.data.message)
				
			}else {
			  setErrorMessage('حدث خطأ ما يرجى المحاولة مرة أخرى')

		}
	})
		.finally(() => {
			setIsLoadingEditPassword(false);
  
		//   addRecordModalRef.current.style.display = 'none';
		//   addRecordModalColseBtn.current.click();
		})
  
	  }
	const {
		editPassword,
		setEditPassword,
		teacherDetailsIsLoading,
		teacherDetailsError,
		teacherDetails,
		setOldPassword,
		oldpassword,
		newpassword,
		setNewPassword,
		UpdatePassword,
		updatepasswordIsError,
	} = ProfileLogic({ Title, setLoading });
	console.log(teacherDetails);

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={errorMessage}
				clearMsg={clearMsg}
				/>

			<ToastError 
			ErrorMessage={errormessage}
			successMessage={successmessage}
			setErrorMessage={setErrorMessage}
			setSuccessMessage={setSuccessMessage}
			/>
			<div className='profileScreen'>
				<div className='profileScreen__content '>
					<h3 style={{ color: '#044364' }} className='fw-bold my-3 container'>
						البروفايل
					</h3>
					<div className='d-flex justify-content-center'>
						{teacherDetailsIsLoading ? (
							<div className='text-center mt-5'>
								<div class='spinner-border mx-auto' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<div className='   py-4  mt-2 mb-5 login-card'>
								<div className='userImage text-center'>
									<img
										loading='lazy'
										src={`${process.env.REACT_APP_STORAGE_URL}${teacherDetails?.data?.teacher_image}`}
										style={{
											width: '150px',
											height: '150px',
											borderRadius: '50%',
										}}
									/>
								</div>
									<div className='mb-3  mt-1'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>الاسم </label>
										</div>
										<div className='parent mb-5 '>
											<input
												// placeholder='الاسم  '
												autoComplete
												// {...register('name', { required: true })}
												type='text'
												name='name'
												className=' form-control    login_input'
												value={teacherDetails?.data?.teacher_username}
											/>
											<img src={profile} className='sun' />
										</div>
									</div>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>اسم المستخدم</label>
										</div>
										<div className='parent mb-5 '>
											<input
												// placeholder='ادخل اسم المستخدم'
												autoComplete
												// {...register('username', { required: true })}
												type='text'
												name='username'
												className=' form-control    login_input'
												value={teacherDetails?.data?.teacher_username}
											/>
											<img src={profile} className='sun' />
										</div>
									</div>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>البريد الإلكتروني </label>
										</div>
										<div className='parent mb-5 '>
											<input
												// placeholder=' ادخل البريد الالكتروني  '
												autoComplete
												// {...register('username', { required: true })}
												type='email'
												name='email'
												className=' form-control    login_input'
												value={teacherDetails?.data?.teacher_email}
											/>
											<img src={email} className='sun' />
										</div>
									</div>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>رقم الموبايل </label>
										</div>
										<div className='parent mb-5 '>
											<input
												// placeholder='ادخل رقم الموبايل '
												autoComplete
												// {...register('username', { required: true })}
												type='text'
												name='phone'
												className=' form-control    login_input'
												value={teacherDetails?.data?.phone_number}
											/>
											<img src={phone} className='sun' />
										</div>
									</div>

										<hr/>
										<h3 className="text-center ">تعديل كلمة السر</h3>

										<form >

									<div className='mb-3 mt-3' >
									<div className='mb-3 mt-2' >
										<label className='fw-bold fs-5' > كلمة السر القديمة </label>
									</div>
									<div className='parent mt-4 mb-5'>
										<input
											placeholder='ادخل كلمة السر القديمة  '
											autoComplete
											type='password'
											name='password'
											className=' form-control login_input'
											{...register("old_password")} 
										/>

										{/* <img src={lock} className=' sun' /> */}
									</div>
								</div>
								<div className='mb-3 mt-5 '>
									<div className='mb-3 mt-2 '>
										<label className='fw-bold fs-5'> تأكيد كلمة السر الجديدة</label>
									</div>
									<div className='parent mt-4 mb-5'>
										<input
											placeholder='  ادخل كلمة السر الجديدة '
											autoComplete
											type='password'
											name='confirmpassword'
											className=' form-control login_input'
											{...register("new_password")} 
										/>

										{/* <img src={lock} className=' sun' /> */}
									</div>
								</div>

									{loading ? (
									<div className='text-center'>
										<div className='text-center mt-5 mx-auto'>
											<div class='spinner-border mx-auto' role='status'>
												<span class='sr-only'>Loading...</span>
											</div>
										</div>
									</div>
								) : (
									<div className='d-flex'>
										<button
											onClick={handleSubmit(addStudentRecord)}
											className='login-button m-auto  rounded text-white fw-bold py-2'>
											تعديل
										</button>
									</div>
								)}
								</form>
							</div>
						)}
					</div>
				</div>
			</div>
			{/* <div className='profileScreen'>
				<div className='profileScreen__content'>
					<div className='topcontent'>
						<div className='userImage'>
							<img
								loading='lazy'
								src={`${process.env.REACT_APP_STORAGE_URL}${teacherDetails?.data?.teacher_image}`}
								style={{
									width: '100%',
									height: '100%',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>
							{teacherDetails?.data?.teacher_username}
						</h3>
					</div>

					{!editPassword ? (
						<div className={`bottomContent`}>
							<div className='leftContent'>
								<ul>
									<li>
										<div>الاسم</div>
										<div>{teacherDetails?.data?.teacher_username}</div>
									</li>
									<li>
										<div> اسم المستخدم</div>
										<div>{teacherDetails?.data?.teacher_username}</div>
									</li>
									<li>
										<div> الايميل</div>
										<div>{teacherDetails?.data?.teacher_email}</div>
									</li>
									<li>
										<div> رقم الهاتف</div>
										<div>{teacherDetails?.data?.phone_number}</div>
									</li>
								</ul>
								<div className='title'>المعلومات الشخصية</div>
							</div>
						</div>
					) : (
						<form onSubmit={UpdatePassword}>
							<div className={`editpassword`}>
								<div className='enterOld'>
									<div className='text'>
										{' '}
										<img loading='lazy' src={chalks} alt='' />
										أدخل كلمة السر القديمة
									</div>
									<input
										autoComplete
										value={oldpassword}
										onChange={(e) => {
											setOldPassword(e.target.value);
										}}
										type='password'
									/>
								</div>
								<div className='enterNew'>
									<div className='text'>
										<img loading='lazy' src={chalks} alt='' /> كلمة السر الجديدة{' '}
									</div>
									<input
										autoComplete
										value={newpassword}
										onChange={(e) => {
											setNewPassword(e.target.value);
										}}
										type='password'
									/>
								</div>
								<div className='btnsContainer'>
								
								
									<button className='saveBtn btn btn-success' type='submit'>
										حفظ التعديلات
									</button>
									<button
										className='closeBtn btn btn-danger '
										onClick={() => setEditPassword(false)}>
										الغاء{' '}
									</button>
								</div>
							</div>
						</form>
					)}

				
				</div>
				{[teacherDetailsError, updatepasswordIsError].map(
					(oneError) =>
						oneError && (
							<Error
								errorText={
									oneError?.response?.data?.message
										? oneError?.response?.data?.message
										: oneError.message
								}
							/>
						)
				)}
			</div> */}
		</>
	);
};

export default ProfileDetailsTeacher;
