import React,{useEffect,useState} from 'react'

const StudentTraditionalAnsTeacherLogic = () => {
    const [traditionalQuizAns,setTraditionalQuizAns] = useState()
    const id=6;
    useEffect(() => {
        async function getData() {
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_student_traditional_quiz_answer_files_by_id/${id}`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":"Bearer 2|vbqCqitJbBoF43KXGQ4emaMPazD1xVqLcp3W2LGs"
            }
          }
          ).then(response => response.json());
            setTraditionalQuizAns(actualData);
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa TeacherStudnetssprofile",actualData) 
        }
        getData()
      }, [])
  return {
    traditionalQuizAns
  }
}

export default StudentTraditionalAnsTeacherLogic