import React, { useEffect, useRef, useState } from 'react'
import {  useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from "react-router-dom";
import { useUploadForm } from "../../customhook/Uploadfiles";
import { get_traditional_quiz_by_traditional_quiz_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
let allowedExtension = ['application/pdf'];
export default function Edit_traditional_quiz_Logic({isFinal,isAboveLevel}) {
    const {QuizId,UnitId} = useParams();
   
   const data = useSelector(state=>state.unitTest.get_traditional_quiz_by_traditional_quiz_id);
  
   const [correction_Ladder_file_error,setcorrection_Ladder_file_error] = useState('');
   const [correction_Ladder_file,setcorrection_Ladder_file]=useState(null)
   const correction_Ladder_file_Ref = useRef();
    const dispatch = useDispatch();
    const { getValues,setValue ,register, watch,handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        QuizeName:'',
        points:0,
        descriptionQuize:'',
        duration:'', 
        questions_file: '',
        correction_ladder_file: ''
      }});

   
     


      useEffect(()=>{
       dispatch(get_traditional_quiz_by_traditional_quiz_id(QuizId))
      },[])

      useEffect(()=>{
        if(data.data)
        {
        
        setValue('QuizeName',data.data.quiz_name);
        setValue('points',data.data.quiz_points);
        setValue('descriptionQuize',data.data.quiz_description);
        setValue('duration',data.data.quiz_duration);
        setValue('questions_file',data.data.questions_file);
        setValue('correction_ladder_file',data.data.correction_ladder_file);
        // setcorrection_Ladder_file(data.data)
        }  
    },[data])



      const { isSuccess:edit_traditional_quiz_questions_success, uploadForm:edit_traditional_quiz_questions, progress:edit_traditional_quiz_questions_progress ,IsError:edit_traditional_quiz_questions_error,data:edit_traditional_quiz_questions_data } = useUploadForm(`/edit_unit_traditional_quiz/${QuizId}`);
      

      const onSubmit = async (data) => {
        
        console.log('leader leader leader leader', correction_Ladder_file)

        const edit_traditional_quiz_questions_form = new FormData(); 
        edit_traditional_quiz_questions_form.append('unit_id',UnitId);
        edit_traditional_quiz_questions_form.append('isFinal',isFinal);
        edit_traditional_quiz_questions_form.append('duration',data.duration);
        edit_traditional_quiz_questions_form.append('isAboveLevel',isAboveLevel);
        edit_traditional_quiz_questions_form.append('nameOfQuiz',data.QuizeName);
        edit_traditional_quiz_questions_form.append('points', data.points);

        if(correction_Ladder_file){

          edit_traditional_quiz_questions_form.append('correction_Ladder_file',correction_Ladder_file );
        }

        edit_traditional_quiz_questions_form.append('description', data.descriptionQuize)
        edit_traditional_quiz_questions_form.append('transable', 0);

        if(typeof edit_traditional_quiz_questions_form.questions_file == 'string' || typeof edit_traditional_quiz_questions_form.questions_file == 'undefined'){

        }else {
          edit_traditional_quiz_questions_form.append('questions_file', data.questions_file[0])
        }

           return await edit_traditional_quiz_questions(edit_traditional_quiz_questions_form);
       };
     






      const handlecorrection_Ladder_file= (e)=>{
    
      if( e.target.files[0].size/(1024*1024)<8 && allowedExtension.indexOf(e.target.files[0].type)>-1)
      {
        setcorrection_Ladder_file_error('')
        setcorrection_Ladder_file( e.target.files[0])
        setValue('correction_ladder_file', '');

    }
    else
    {
        setcorrection_Ladder_file(null);
        setcorrection_Ladder_file_error('يرجى اختيار ملف بلاحقة pdf ');
        correction_Ladder_file_Ref.current.value='';
    }

    }
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    handlecorrection_Ladder_file,
    correction_Ladder_file,
    correction_Ladder_file_Ref,
    correction_Ladder_file_error,
    edit_traditional_quiz_questions_error,
    edit_traditional_quiz_questions_progress,
    edit_traditional_quiz_questions_success,
    QuizId,
    onSubmit,
    watch
    }
}
