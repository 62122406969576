import React from 'react';
import SubTitle from '../SubTitle/SubTitle';
import './WhoAreWe.css';
export default function WhoAreWe({ data }) {
	return (
		<>
			{/* start who we are  */}

			<div className='who_are_we container text-center p-4'>
				{/* <div className="row"> */}
				<div className='d-flex justify-content-center align-items-center flex-column'>
					{/* <h2 className='fw-bold mb-4'>
						<span style={{ color: '#044364' }} className="fs-2">من نحن !؟</span>
					</h2> */}
					<SubTitle title='من نحن !؟' />
					<p
						className='who_are_we_content fs-4 mt-5'
						dangerouslySetInnerHTML={{
							__html: data?.aboutUs,
						}}>
						{/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum ex
						asperiores, vitae quaerat, optio saepe explicabo ullam beatae
						ratione, quos porro molestias obcaecati id eligendi hic aut tempore
						cupiditate iure! Lorem ipsum dolor sit amet consectetur, adipisicing
						elit. Aliquid ipsum voluptatum dignissimos quibusdam tempore
						voluptatibus maxime temporibus, necessitatibus quae reprehenderit
						odit id dolor minima nesciunt molestiae illo velit ullam ex! */}
					</p>
				</div>
				{/* <div className="col-md-6" >
            <img src="media/who-are-we/who-are-we.png" width="100%" height="auto" />
        </div> */}
				{/* </div>    */}
			</div>

			{/* end who we are  */}
		</>
	);
}
