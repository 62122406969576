import React from 'react';
import { Link } from 'react-router-dom';
import Error from '../alerts/Error/Error';
import Show_All_Material_For_Quiz_Logic from './Show_All_Material_For_Quiz_Logic';

export default function Show_All_Material_For_Quiz({ Title }) {
	const { data } = Show_All_Material_For_Quiz_Logic({ Title });

	return (
		<div className='mx-2 my-5'>
			<h3 className='fw-bold mb-4 text-center mt-5'>
				<span style={{ color: '#044364' }}>إنشاء اختبار </span>
			</h3>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{data.isError && <Error errorText={data.errorMessage} />}
			{data.data && (
				<table
					className=' table-bordered  mt-3 table_style  text-center table-striped'
					// style={{ minHeight: "400px" }}
				>
					<thead>
						<tr>
							<th className='first_child'> اسم المادة </th>
							<th> اسم القسم </th>
							<th> صورة المادة </th>
							<th>عدد الوحدات في المادة</th>
							<th>عدد الدروس</th>
							<th>الوصف</th>
							<th>النقاط</th>
							<th>السعر</th>
							<th>عدد الوحدات</th>
							<th className='last_child'> </th>
						</tr>
					</thead>
					<tbody>
						{data.data?.map((subject) => (
							<tr key={subject.subject_id}>
								<td> {subject.subject_name} </td>
								<td> {subject.section_name} </td>
								<td>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
										}}>
										<img
											loading='lazy'
											className='img-fluid'
											src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
											alt='Girl in a jacket'
											style={{
												objectFit: 'cover',
												width: '100%',
												height: '12vh',
											}}
										/>
									</div>
								</td>
								<td> {subject.number_of_units_in_the_subject} </td>
								<td> {subject.number_of_lessons} </td>
								<td>
									{' '}
									{subject.subject_description.length > 55
										? subject.subject_description.slice(0, 55) + '...'
										: subject.subject_description}{' '}
								</td>
								<td> {subject.points} </td>
								<td> {subject.price} </td>
								<td> {subject.number_of_units_in_the_subject} </td>
								<td>
									{' '}
									<Link
										to={`${subject.subject_id}`}
										state={{ sub_id: subject.subject_id }}
										className='btn btn1 m-1'>
										{' '}
										اختيار المادة{' '}
									</Link>{' '}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
