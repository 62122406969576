import React from 'react'
import { useParams } from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux'
import { useForm } from 'react-hook-form';
import { add_lesson_faqs } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
export default function Add_Lesson_Faqs_Logic() {
  
    const {LessonId} = useParams();
    const dispatch=useDispatch();
    const { register , handleSubmit,reset, formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        question:'',
      answer:'',
      transable:0
    }});
    const onSubmit = data => {
       const formdata = new FormData();
       formdata.append('question',data.question);
       formdata.append('answer',data.answer);
       formdata.append('transable',data.transable);
       formdata.append('lesson_id',LessonId);
      dispatch(add_lesson_faqs({formdata,reset}))
    }
  
    const data=useSelector((state)=>state.lesson.add_lesson_faqs)
    
  return {data,register,errors,isDirty,isValid,handleSubmit,onSubmit}
}
