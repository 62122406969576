
import React,{useEffect,useState} from 'react'


const OneStudentForTeacherLogic = ({id}) => {
    console.log(id);
    const [profileStudent,setProfileStudent] = useState()
    useEffect(() => {
        async function getData() {
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_student_profile_by_id/${id}`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":"Bearer 2|vbqCqitJbBoF43KXGQ4emaMPazD1xVqLcp3W2LGs"
            }
          }
          ).then(response => response.json());
          setProfileStudent(actualData);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa TeacherStudnetssprofile",actualData) 
        }
        getData()
      }, [])
  return {
    profileStudent,
  }
}

export default OneStudentForTeacherLogic