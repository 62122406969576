import React, { useEffect } from 'react'
import { useDispatch , useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom'
import { GetData } from '../../../../customhook/getDatawithAuth';


export default function Lesson_Quizes_Logic({api}) {


    const {StudentId} = useParams();
    const {state} = useLocation();

  console.log('state is i si i', state)
   
    const {getDatafrom, isSuccess, progress , IsLoading,  IsError , data }  = GetData(`${api}student_id=${StudentId}&subject_id=${state?.sub_id}`)
   


    useEffect(()=>{
      getDatafrom();
      console.log('state here is ', state);
    },[])
    return {
      isSuccess, progress,IsLoading,IsError,data
  }
}
