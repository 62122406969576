import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_last_lesson } from '../../../../redux-toolkit/reducers/Lesson/LessonSlice';
import Show_All_Lesson_Logic from './Show_All_Lesson_Logic';
import { v4 as uuid } from 'uuid';
import Error from '../../../alerts/Error/Error';
import PopUp2 from '../../../popup/popup2';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import ToastError from '../../../ToastSuccessError/ToastError';
import ReactPlayer from 'react-player';
export default function Show_All_Lesson() {
	const { data, UnitId, dispatch, loading, handleDeleteLesson, error , refetch} =
		Show_All_Lesson_Logic();
	const [pop, setPop] = React.useState(false);
	const [popData, setPopData] = React.useState(null);
	console.log('data of show all lesson', data);
	const [lessonsWithVideoId,setLessonsWithVideoId] = useState([]);

	const [errorMessage,setErrorMessage] = useState('');
	const [successMessage,setSuccessMessage] = useState('');

	// const [setIsChangingDirection] = useState()

	const toggleVideoDirection = (lesson) => {

		const dataToSend = {
			is_vertical: lesson.is_vertical == 1 ? 0 : 1 
		}

		axios.post(`${process.env.REACT_APP_API_URL}/change_lesson_mode_by_lesson_id/${lesson.lesson_id}`,
		dataToSend , {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
		.then(data => {
			setSuccessMessage(`تم تعديل حالة فيديو الدرس  ليكون ${lesson.is_vertical == 0 ? 'عموديا' : "أفقيا"}`)
		})
		.catch(error => {

		})
		.finally(() => {
			refetch();
		})
	}

	useEffect(() => {
		if(data) {
			const lessonsCopy = data?.data ;
			const newLessons = [];

			lessonsCopy.map((lesson, index) => {

				// const videoIdArray = lesson.lesson_first_video.split('watch?v=');
				// const videoId = videoIdArray[1].split('&index');

				const videoIdItSelf = lesson.lesson_first_video.split('&list')[0]


				newLessons.push({...lesson , lesson_first_video: videoIdItSelf})
			})
			
			setLessonsWithVideoId([...newLessons]);
			console.log('new lessons' , newLessons)
		}
	},[data])

	return (
		<div className='container my-5'>
			<ToastError 
			ErrorMessage={errorMessage}
			successMessage={successMessage}
			setErrorMessage={setErrorMessage}
			setSuccessMessage={setSuccessMessage}
			/>
			{error && (
				<Error
					errorText={
						error?.response?.data?.message
							? error?.response?.data?.message
							: error.message
					}
				/>
			)}
			{loading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{
				<div>
					{!error && (
						<table className='table-bordered  w-100  mt-3 table_style  text-center table-striped'>
							<thead>
								<tr>
									<th className='first_child'>#</th>
									<th> اسم الدرس </th>
									<th> ترتيب الدرس </th>
									<th> وصف الدرس </th>
									<th> فيديو الدرس </th>
									<td>مدة الدرس</td>
									<th> ماذا سوف نتعلم</th>
									<th className='last_child'> </th>
								</tr>
							</thead>
							{data?.data && (
								<tbody>
									{lessonsWithVideoId?.map((lesson, index) => (
										<tr key={lesson.lesson_id}>
											<td>{index}</td>
											<td> {lesson.lesson_name} </td>
											<td> {lesson.lesson_order} </td>
											<td style={{ wordBreak: 'break-all' }}>
												<label
													style={{
														maxWidth: '250px',
														maxHeight: '200px',
														overflowY: 'auto',
													}}>
													{lesson.lesson_description}
												</label>
											</td>
											<td
												style={{
													overflow: 'auto',
												}}>
												<div
													style={{
														width: '100%',
														height: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}>
													{/* <img
														loading='lazy'
														className='img-fluid'
														src={`${process.env.REACT_APP_STORAGE_URL}/${lesson.lesson_image}`}
														alt='Girl in a jacket'
														style={{
															objectFit: 'cover',
															width: '150px',
															height: '150px ',
														}}
													/> */}
													{/* <div style={{width: '200px'}}> */}
													<ReactPlayer 
													width={'200px'}
													height={'300px'}
													url={  lesson.lesson_first_video}
													key={lesson.lesson_id}
													/>

													

{/* 
													<iframe width="200" height="315"
														src={ 'https://www.youtube.com/watch?v=' + lesson.lesson_first_video[0] + "&index" + lesson.lesson_first_video[1]}>
													</iframe> */}

													{/* </div> */}
													{/* <iframe src={`${lesson.lesson_/first_video}`} width='200' height='200'/> */}
												</div>
											</td>
											<td> {lesson.lesson_duration} </td>
											<td>
												{lesson?.what_we_will_learn?.map((willlearn) => (
													<label
														style={{
															maxWidth: '250px',
															maxHeight: '200px',
															overflowY: 'auto',
														}}>
														{willlearn}
													</label>
												))}
											</td>
											{/* <td> {lesson.lessons_sum_duration} </td> */}
											<td
												style={{
													display: 'flex',
													flexDirection: 'column',
													gap: '15px',
												}}>
												<Button
													className='btn3 m-1 border-0'
													onClick={() => {
														setPop(true);
														setPopData(lesson);
													}}>
													التفاصيل
												</Button>
												<Link
													to={`comments/${lesson.lesson_id}`}
													className='btn btn1 m-1'>
													{' '}
													التعليقات{' '}
												</Link>{' '}
												<Link
													to={`edit_lesson/${lesson.lesson_id}`}
													className='btn btn2 m-1'
													UnitId>
													{' '}
													تعديل الدرس{' '}
												</Link>{' '}
												<Link
													to={`${lesson.lesson_id}/Lesson_faqs`}
													className='btn btn3 m-1'>
													{' '}
													الأسئلة الشائعة{' '}
												</Link>{' '}
												<Link
													to={`/lessonAttatchment/${lesson.lesson_id}`}
													className='btn btn1 m-1'>
													المرفقات
												</Link>

												<button className='btn btn-dark' onClick={(e) =>  toggleVideoDirection(lesson)}> 
													تحويل إلى {lesson?.is_vertical == 0 ? 'عمودي' : "أفقي"}
												</button>

												{data?.data?.length - 1 == index &&
													localStorage.getItem('admin_as_teacher') == 1 && (
														<button
															onClick={() =>
																dispatch(
																	handleDeleteLesson({
																		lessonid: lesson.lesson_id,
																	})
																)
															}
															className='btn btn-danger'>
															حذف الدرس
														</button>
													)}
																			
											</td>
										</tr>
									))}
								</tbody>
							)}
							{/* {data.isError&&<div class="d-flex justify-content-center"><label className='text-danger'>{data.errorMessage}</label></div>} */}
						</table>
					)}
					<Link className='btn btn1 my-3 w-25' to={`add_lesson`}>
						أضف درس جديد
					</Link>
				</div>
			}
			<PopUp2 show={pop} onHide={() => setPop(false)} data={popData} />

			<div style={{ margin: '30px auto' }} />
		</div>
	);
}
