import React from 'react';

import SubTitle from '../SubTitle/SubTitle';
import './VideoHowToUseSite.css';
import Zoom from 'react-reveal';
export default function VideoHowToUseSite({ data }) {
	return (
		<>
			{/* start  video how to use site  */}

			<div className='video-how-to-use-site '>
				<div className='text-center my-5'>
					{/* <h2 className='fw-bold mb-4'>
						<span style={{ color: '#044364' }}> كيفية استخدام الموقع</span>
					</h2> */}
					<SubTitle title={'كيفية استخدام الموقع'} className="linear_background" />
				</div>
				<div className='  '>
					<Zoom>
						<div
							className=' my-4 container  main_video_section '
							style={{ padding: '0px' }}>
							{/* <iframe src="//www.youtube.com/embed/YE7VzlLtp-4" title="YouTube video" allowFullScreen></iframe> */}
							<iframe
								className='vidio_ifram'
								src={process.env.REACT_APP_STORAGE_URL + data?.explainer_video}
								title='YouTube video'
								allowFullScreen
								style={{ margin: '0px' }}></iframe>
						</div>
					</Zoom>
				</div>
			</div>

			{/* end  video how to use site  */}
		</>
	);
}
