import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';
import OneStudentForTeacherLogic from './OneStudentForTeacherLogic'


const OneStudentForTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
    const params= useParams()
    const id=1;
    const {
        profileStudent,
      }=OneStudentForTeacherLogic(id);
  return (
    <Layout>
    <LayoutSideTeacher desc={"الطالب"}>
    
     {profileStudent && <h1>{profileStudent.message}</h1>}
    </LayoutSideTeacher>
</Layout>
  )
}

export default OneStudentForTeacher