import { useState } from "react";
import { useQuery } from "react-query";
import fetchSubjectsBySubSetionId from '../../redux-toolkit/api/fetchSubjectsBySubSetionId';
export default function WinterCoursesLogic(subSectionId) {
    const [page,setPage] =useState(1);
    console.log('sub section id from winter courses logic' , subSectionId)
    const {isLoading, isError, data, error} = useQuery(['sections',{page,subSectionId}], fetchSubjectsBySubSetionId);

  
    console.log("data bla bla",data)
   function handlePageClick (data)
   {
    setPage(data.selected+1);
   
   }
    return {
        page,handlePageClick,isLoading, isError, data, error
  }
}
