import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_automated_quiz_by_automated_quiz } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Error from '../alerts/Error/Error';
import DeleteModal from '../DeleteModal';

import Pagination from '../Pagination/Pagination';
import ShowallautomatedfinalQuizLogic from './ShowallautomatedfinalQuizLogic';

export default function ShowallautomatedfinalQuiz() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();

	const {
		DeleteQuiz,
		loadin,
		IsError,
		dispatch,
		final_automated_quiz_by_unit_id,
		IsLoading,
		getDatafrom,
	} = ShowallautomatedfinalQuizLogic();
	console.log(final_automated_quiz_by_unit_id);
	return (
		<div className='container my-5'>
			{IsLoading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{IsError && (
				<Error
					errorText={
						IsError?.response?.data?.message
							? IsError?.response?.data?.message
							: IsError.message
					}
				/>
			)}

			{
				<div
					class='table-responsive mt-5'
					style={{ margin: '20px auto !important' }}>
					<table className=' w-100 text-center   table_style table-bordered table-striped'>
						<thead>
							<tr>
								<th className='first_child'> اسم الاختبار </th>
								<th> مدة الاختبار </th>
								<th> نقاط الاختبار </th>
								<th> </th>
								<th> </th>
								<th> </th>
								<th className='last_child'> </th>
							</tr>
						</thead>
						{final_automated_quiz_by_unit_id?.data && (
							<tbody style={{ lineHeight: '60px' }}>
								{final_automated_quiz_by_unit_id?.data?.map((Quiz) => (
									<tr key={Quiz.id}>
										<td> {Quiz.quiz_name} </td>
										<td> {Quiz.quiz_duration} </td>
										<td> {Quiz.quiz_points} </td>
										<td>
											{' '}
											<Link to={`edit/${Quiz.id}`} className='btn btn1'>
												{' '}
												تعديل{' '}
											</Link>{' '}
										</td>
										<td>
											<button
												type='button'
												class='btn btn2'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal'
												onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}>
												حذف
											</button>
										</td>
										<td>
											{' '}
											<Link
												to={`Add_automated_quiz_questions/${Quiz.id}`}
												className='btn btn3'>
												{' '}
												أضف أسئلة{' '}
											</Link>{' '}
										</td>
										<td>
											{' '}
											<Link
												to={`show_automated_quiz_questions_options/${Quiz.id}`}
												className='btn btn1'>
												{' '}
												عرض الأسئلة{' '}
											</Link>{' '}
										</td>
									</tr>
								))}
							</tbody>
						)}
					</table>
					<Link className='btn btn1 mt-5' to='create_unit_automated_quiz'>
						أضف اختبار جديد
					</Link>
					<DeleteModal
						messagConfirm={'	هل تريد حذف هذا الاختبار ؟'}
						handleDelete={() => DeleteQuiz(selectedAutomatedQuizeId)}
						isDeleting={loadin}
					/>
					<div style={{ margin: '30px auto' }} />
					{/* {data?.data?.total < 10  ? <> </> : <Pagination  data={data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>
			}
		</div>
	);
}
