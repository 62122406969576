import { useDispatch, useSelector } from 'react-redux';
import { get_all_above_level_automated_quiz_by_unit_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
export default function ShowallautomateduplevelQuizLogic() {
	const dispatch = useDispatch();
	const { UnitId } = useParams();
	const [loading, setloading] = useState();

	const data = useSelector(
		(state) => state.unitTest.get_all_above_level_automated_quiz_by_unit_id
	);
	const deleteQuiz = (id) => {
		setloading(true);
		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_automated_quiz/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};
	useEffect(() => {
		dispatch(get_all_above_level_automated_quiz_by_unit_id({ UnitId }));
	}, []);

	return { dispatch, data, UnitId, deleteQuiz, loading };
}
