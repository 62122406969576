import axios from "axios";

  export default async function  fetchSubSections ({queryKey})
 {
   
  // console.log("query key",queryKey)
//    console.log('subsectionId', queryKey[1].subSectionId.id);
        // console.log(queryKey[1].page);
    const url = `${process.env.REACT_APP_API_URL}/get_subjects_by_sub_section?sub_section_id=${queryKey[1].subSectionId.id}`;
    console.log('fetch subject',url);
   //  console.log('query page key', queryKey[1].page);
   var params = new URLSearchParams();
        params.append("limit", 5);
        params.append("local", 'ar');
   const {data} = await axios.get(url,{params: params})    

   return  data;
 } 
