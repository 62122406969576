import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import axios from 'axios';
import {
	setError,
	setSuccess,
} from '../../redux-toolkit/reducers/notification';
import { useDeferredValue } from 'react';
import { useDispatch } from 'react-redux';

export default function ExamLogic() {
	const [fileTypes, setFileTypes] = useState(['JPG', 'PNG', 'GIF']);
	const [examTime, setExamTime] = useState();
	const [startExam, setStartExam] = useState(false);
	const [examIsFinished, setExamIsFinished] = useState(false);
	const [file, setFile] = useState(null);
	const [allFiles, setAllFiles] = useState([]);
	const [QuizQuestions, setQuizQuestions] = useState([]);
	const [QuizDetails, setQuizDetails] = useState({});
	const quiz_id = useParams();
	const [ImagesData, setImagesData] = useState([]);
	const [AnswersFile, setAnswersFile] = useState();
	const [loading, setloading] = useState(true);
	const [loadingPostAnswer, setloadingPostAnswer] = useState(false);
	const [loadingDetailes, setloadingDetailes] = useState(true);
	const token = localStorage.getItem('token');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleChange = (file) => {
		setFile(file);
		setImagesData([...ImagesData, file]);
		console.log('fileeeeee', file);
	};

	// useEffect(() => {
	// 	let interval;
	// 	if (startExam == true) {
	// 		interval = setInterval(() => {
	// 			if (examTime != 0) {
	// 				setExamTime((currentValue) => currentValue - 1);
	// 			} else {
	// 				clearInterval(interval);
	// 				setExamIsFinished(true);
	// 			}
	// 		}, 1000);
	// 	}

	// 	if (examIsFinished) {
	// 		clearInterval(interval);
	// 	}
	// }, [startExam, examIsFinished]);

	useEffect(() => {
		const sliderImages = [...allFiles];
		console.log('allFiles', sliderImages);
		let url;
		if (file) {
			url = window.URL.createObjectURL(file);
		}
		sliderImages.push(url);

		setAllFiles(sliderImages);
	}, [file]);

	// const formatSecondsToMinutes = (seconds) => {
	// 	if (seconds > 0) {
	// 		const CompletMinutes = Math.floor(seconds / 60);
	// 		const restSeconds = seconds - 60 * CompletMinutes;
	// 		if (examIsFinished) {
	// 			return <span>00:00</span>;
	// 		} else {
	// 			return `${
	// 				CompletMinutes < 10 ? '0' + CompletMinutes : CompletMinutes
	// 			}:${restSeconds < 10 ? '0' + restSeconds : restSeconds}`;
	// 		}
	// 	} else {
	// 		setStartExam(false);
	// 	}
	// };

	const getAllQuizQuestions = async () => {
		await axios

			.get(
				`${process.env.REACT_APP_API_URL}/get_traditional_quiz_questions_files_by_id/${quiz_id.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				setQuizQuestions(data.data.data);
				console.log(data.data.data);
			})
			.catch((error) => console.log('error unit', error));
	};
	const getAllQuizDetails = async () => {
		await axios

			.get(
				`${process.env.REACT_APP_API_URL}/get_traditional_quiz_by_quiz_id/${quiz_id.id}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				setQuizDetails(data.data.data);
				setExamTime(data?.data?.data?.quiz_duration);
				console.log(data.data.data);
				setloadingDetailes(false);
			})
			.catch((error) => {
				setloadingDetailes(false);
				console.log('error unit', error);
			});
	};
	const SavedAnswersFiles = async () => {
		setloadingPostAnswer(true);

		let formData = new FormData();
		formData.append('traditional_quiz_id', quiz_id.id);
		for (let i = 0; i < ImagesData.length; i++) {
			formData.append(`image_answers[${i}]`, ImagesData[i]);
		}
		// for (var pair of formData.entries()) {
		// 	console.log('formData', pair[0] + ', ' + pair[1]);
		// }

		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/post_student_traditional_quiz_answers`,
				formData,
				{
					headers: {
						Accept: 'aplication/json',
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((data) => {
				dispatch(setSuccess('تم انجاز الاختبار'));
				// setQuizDetails(data.data.data);
				setAnswersFile(data.data.data.correction_Ladder_file);

				setExamIsFinished(true);
				setloadingPostAnswer(false);
				// setTimeout(
				// 	() =>
				// 		navigate(
				// 			`/myquizes/units/traditional_test_unit/uncorrected/${quiz_id.id}`
				// 		),
				// 	1000
				// );

				console.log(data.data.data);
			})
			.catch((error) => {
				error?.response?.data?.message === 'validation.required'
					? dispatch(setError(' يجب ادخال الاجوبة'))
					: error?.response?.data?.message ===
					  'the student already have answers in this quiz'
					? dispatch(setError('تم انجاز الاختبار سابقا'))
					: dispatch(setError(error?.response?.data?.message));
				setloadingPostAnswer(false);

				console.log('error unit', error?.response?.data?.message);
				setloadingPostAnswer(false);
				// router.push(`/unitt/${23}`);
			});
	};

	useEffect(() => {
		getAllQuizQuestions();
		getAllQuizDetails();
	}, []);

	allFiles && console.log(allFiles);

	return {
		fileTypes,
		examTime,
		startExam,
		examIsFinished,
		file,
		allFiles,
		handleChange,
		AnswersFile,
		setStartExam,
		setExamIsFinished,
		QuizQuestions,
		QuizDetails,
		SavedAnswersFiles,
		setloading,
		loading,
		loadingDetailes,
		loadingPostAnswer,
	};
}
