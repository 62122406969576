import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { dayFilter, monthFilter, weekFilter } from './getTime';
export default function StudentArchiveLogic(
  setTotal,
  setUrl,
  setSubject,
  setFilter,
  setError
) {
	const [errMassege, seterrMassege] = useState('');
	const [loading, setloading] = useState(true);
	const [states, setStates] = useState([
		{
			value: '1',
			text: 'يوم',
			checked: false,
			url: `${process.env.REACT_APP_API_URL}/get_total_study_hours_by_start_time_and_end_time`,
			filter: dayFilter(),
		},
		{
			value: '2',
			text: 'اسبوع',
			checked: false,
			url:
				process.env.REACT_APP_API_URL + '/get_total_study_hours_by_last_week',
			filter: weekFilter(),
		},
		{
			value: '3',
			text: 'شهر',
			checked: false,
			url:
				process.env.REACT_APP_API_URL + '/get_total_study_hours_by_last_month',
			filter: monthFilter(),
		},
		{
			value: '4',
			text: 'مدة زمنية محددة',
			checked: false,
			url:
				process.env.REACT_APP_API_URL +
				'/get_total_study_hours_by_start_time_and_end_time',
			filter: {},
		},
	]);
	const [renderInfos, setRenderInfos] = useState(false);


  const handleChange = (e) => {
    let clone = states;

		if (e.target.value == '4') {
			setRenderInfos(true);
		} else {
			setRenderInfos(false);
		}

		for (let i = 0; i < states.length; i++) {
			if (clone[i].value === e.target.value) {
				setUrl(clone[i].url);
				setFilter(clone[i].filter);
				clone[i].checked = true;
			} else {
				clone[i].checked = false;
			}
		}

    setStates([...clone]);
  };
  const achivmentTotlaAPI = (url) => async (params) => {
    console.log("p--->", params);
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { ...params },
      });
      console.log("total--->", res.data);
      setTotal(res.data.data);
    } catch (e) {
      setError(e.response.data.message)
      // alert(e.response.data.message);
    }
  };
  const achivmentSubjectAPI = (url) => async (params) => {
    console.log("subP--->", params);
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: { ...params },
      });
      console.log("subject--->", res.data);
      setSubject(res.data.data);
    } catch (e) {
      setError(e.response.data.message)
      // alert(e.response.data.message);
    }
  };
  return {
    states,
    renderInfos,
    handleChange,
    achivmentTotlaAPI,
    achivmentSubjectAPI,
  };
}
