import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
import fetchsections from '../../redux-toolkit/api/fetchsectionapi';
import Pagination from '../Pagination/Pagination';
import './Sections.css';
import SectionsLogic from './SectionsLogic';
import img from '../../assets/AboutUs/Mask Group 2.png';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import MainHeader from '../MainHeader/MainHeader';
import Slider from '../slider';
import SliderOffers from '../SliderOffers/SliderOffers';
import SubTitle from '../SubTitle/SubTitle';
import { Rotate, Roll, LightSpeed, Slide, Zoom, Fade } from 'react-reveal';

export default function Sections() {
	const location = useLocation();
	console.log('location', location);

	const { listRef, isLoading, isError, data, error, handlePageClick, page } =
		SectionsLogic();

	if (location?.pathname == '/sections') {
		return (
			<Layout>
				{/* <MainHeader /> */}
				{isLoading && (
					<div className='d-flex justify-content-center mt-5'>
						<div className='spinner-border' role='status'>
							<span className='visually-hidden'>Loading...</span>
						</div>
					</div>
				)}
				{data?.data.length > 0 && (
					<div
						className='sections container  text-center mb-5 mt-5 '
						ref={listRef}>
						<div className='text-center mt-5'>
							{/* <span style={{ color: '#044364' }}>الأقسام </span> */}
							<SubTitle title={'الأقسام'} />
						</div>

						{/* is loading  */}

						{/* is error */}
						{isError && <span>Error: {error.message}</span>}

						{/* data */}

						<div
							className='row  position-relative'
							style={{ justifyContent: 'space-around' }}>
							{data?.data?.map((section) => {
								return (
									<Fade right>
										<div className='  col-md-4 col-lg-3 col-sm-6  col-xs-10 col-10 '>
											<Link
												state={{ item: section }}
												key={section.section_id}
												to={`/ScientificBaccalaureate/${section.section_id}`}
												className='card section_Card mx-auto linear_background text-white  my-3 '>
												<img
													alt={section.name}
													src={
														process.env.REACT_APP_STORAGE_URL +
														section.section_image
													}
													// src={img}
													width='100%'
													height='200px'
													className=''
												/>

												<div className='section_desc'>
													<h4 class=' section_title_itself  w-100 rounded-x '>
														{section.section_name}
													</h4>
													<p
														style={{  fontSize: '1rem' }}
														className='section_desc_itself'>
														يحتوي هذا القسم على العديد من الكورسات المهمة
													</p>
												</div>
											</Link>
										</div>
									</Fade>
								);
							})}
						</div>
					</div>
				)}

				{!isLoading && (
					<div className='my-5'>
						<Pagination
							data={data}
							handlePageClick={handlePageClick}
							page={page}
						/>
					</div>
				)}

				<div className='my-5' />
			</Layout>
		);
	} else {
		return (
			<>
				{isLoading && (
					<div class='d-flex justify-content-center'>
						<div class='spinner-border' role='status'>
							<span class='visually-hidden'>Loading...</span>
						</div>
					</div>
				)}
				{data?.data.length > 0 && (
					<div class='sections container mt-4 text-center ' ref={listRef}>
						<div className='text-center my-5'>
							{/* <h2 className='fw-bold my-5'> */}
							{/* <span style={{ color: '#044364' }}>الأقسام </span> */}
							<SubTitle title={'الأقسام'} />
							{/* </h2> */}
						</div>

						{/* is loading  */}

						{/* is error */}
						{isError && <span>Error: {error.message}</span>}

						{/* data */}

						<div
							className='row  position-relative'
							style={{ justifyContent: 'space-around' }}>
							{data?.data?.map((section) => {
								return (
									<Zoom right>
										<div className='  col-md-6 col-lg-3 col-sm-6  col-xs-10 col-10 '>
											<Link
												state={{ item: section }}
												key={section.section_id}
												to={`/ScientificBaccalaureate/${section.section_id}`}
												className='card section_Card mx-2 linear_background text-white  my-2'>
												<img
													alt={section.name}
													src={
														process.env.REACT_APP_STORAGE_URL +
														section.section_image
													}
													// src={img}
													width='100%'
													height='200px'
													className=''
												/>

												<div className='section_desc'>
													<h4 class=' section_title_itself  rounded-x '>
														{section.section_name}
													</h4>
													<p
														style={{ fontSize: '1rem' }}
														className='section_desc_itself'>
														يحتوي هذا القسم على العديد من الكورسات المهمة
													</p>
												</div>
											</Link>
										</div>
									</Zoom>
								);
							})}
						</div>

						{data?.total < 7 ? (
							<> </>
						) : (
							<div className='my-4'>
								<Pagination
									data={data}
									handlePageClick={handlePageClick}
									page={page}
								/>
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}
