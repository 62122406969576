import axios from "axios";

  export default async function  fetchAllLessonByUnitIdWithAuth ({queryKey})
 {
     
console.log(queryKey);

 
 const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id?limit=${queryKey[1]}&unit_id=${queryKey[2]}` ,
 {
  headers: {
    'Authorization': `Bearer ${queryKey[3]}` 
  } 
});




   return  data;
 } 