import React from 'react';
import Edit_Lesson_Faqs_Logic from './Edit_Lesson_Faqs_Logic';

export default function Edit_Lesson_Faqs() {
	const {
		updatefaqs,
		data,
		register,
		onSubmit,
		errors,
		isDirty,
		isValid,
		handleSubmit,
	} = Edit_Lesson_Faqs_Logic();
	return (
		<>
			{updatefaqs.isSuccess && (
				<div class='alert alert-success text-center' role='alert'>
					تهانينا تم تعديل السؤال بنجاح
				</div>
			)}
			{updatefaqs.isError && (
				<div className='alert alert-danger' role='alert'>
					{updatefaqs.errorMessage}
				</div>
			)}
			<div className='  py-5 '>
				<div className='d-flex justify-content-center'>
					<div className='card shadow text-center  py-4  login-card'>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className='mb-3'>
								<div className='mb-3'>
									<label className='fw-bold fs-4' style={{ color: '#044364' }}>
										السؤال
									</label>
								</div>
								<input
									{...register('question', { required: true })}
									type='text'
									style={{ borderRadius: '10px ', border: '1px solid #044364' }}
									className='shadow w-75 main-input  p-2 fs-5'
								/>
								{errors.question && (
									<label className='text-center w-100 text-danger'>
										Invalid input
									</label>
								)}
							</div>

							<div className='mb-3'>
								<div className='mb-3'>
									<label className='fw-bold fs-4' style={{ color: '#044364' }}>
										الجواب
									</label>
								</div>
								<input
									{...register('answer', { required: true })}
									type='text'
									style={{ borderRadius: '10px ', border: '1px solid #044364' }}
									className='shadow w-75 main-input  p-2 fs-5'
								/>
								{errors.answer && (
									<label className='text-center w-100 text-danger'>
										Invalid input
									</label>
								)}
							</div>
							<div className='mb-3'></div>

							{updatefaqs.isFetching && (
								<div className='d-flex mb-3'>
									<div class='spinner-border m-auto' role='status'>
										<span class='sr-only'>Loading...</span>
									</div>
								</div>
							)}
							<div className='d-flex'>
								<button
									disabled={!isDirty || !isValid}
									type='submit'
									className='btn1 m-auto w-50 rounded text-white fw-bold py-2'>
									تعديل السؤال
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
