import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from "@reduxjs/toolkit";

export const postContactUs = createAsyncThunk(
  "ContactUs/postContactUs",
  async ({ name, email, message }, thunkAPI) => {
    console.log("data", message);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/add_conact_us_information`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            message,
          }),
        }
      );
      let data = await response.json();
      console.log("dataContactUsSlice", data);
      if (response.ok) {
        console.log("response from postContactUsSlice", response);
        //   localStorage.setItem("token", data.token)
        return { ...data, name: name, email: email, message: message };
      } else {
        console.log("errorrrrrrrrrr ContactUs", data);
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Errorrrrrrrrrrrrr", e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const initialState = {
  post: [],
  loading: false,
  isSuccess: false,
  error: null,
  successMsg: "",
  errorMsg: "",
  loading: false,
};

export const PostContactUsSlice = createSlice({
  name: "ContactUs",
  initialState,
  reducers: {
    clearMsg(state, action) {
      state.errorMsg = "";
      state.successMsg = "";
    },
  },
  extraReducers: {
    [postContactUs.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.loading = true;
    },
    [postContactUs.fulfilled]: (state, action) => {
      state.loading = false;
      console.log("fulfilled_ContactUs", action.payload);
      state.post = action.payload;
      state.isSuccess = true;
      state.successMsg = "تم ارسال الطلب بنجاح";
      state.loading = false;
    },
    [postContactUs.rejected]: (state, action) => {
      console.log("rejected_ContactUs", action);
      state.loading = false;
      state.error = action.payload;
      state.loading = false;
      console.log("rej---->", action.payload);
      state.errorMsg = action.payload?.message || "حدث خطأ بالشبكة";
    },
  },
});
export const getSuccess = createDraftSafeSelector(
  (state) => state.postContact,
  (postContact) => postContact.successMsg
);
export const getError = createDraftSafeSelector(
  (state) => state.postContact,
  (postContact) => postContact.errorMsg
);
export const getLoading = createDraftSafeSelector(
  (state) => state.postContact,
  (postContact) => postContact.loading
);
export const { clearMsg } = PostContactUsSlice.actions;
export default PostContactUsSlice.reducer;
