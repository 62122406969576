import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useAxios from 'axios';
import { useSelector } from 'react-redux';
import { GetData } from '../../../../customhook/getDatawithAuth';
export default function Unit_Quiz_Coreected_Logic({ api }) {
	const { StudentId, UnitId } = useParams();
	const { state } = useLocation();

	console.log('uncorrectred subject id ', state?.sub_id);

	const { getDatafrom, isSuccess, progress, IsLoading, IsError, data } =
		GetData(`${api}student_id=${StudentId}&subject_id=${state?.sub_id}`);

	useEffect(() => {
		getDatafrom();
	}, []);

	return {
		isSuccess,
		progress,
		IsLoading,
		IsError,
		data,
	};
}
