import React from 'react';
import './rules_and_low.css';

import RulesLogic from './RulesLogic';
import MainHeader from '../MainHeader/MainHeader';

export default function Rules() {
	const { rules } = RulesLogic();
	console.log('rules', rules);
	return (
		<>
			{/* <div className='rules_and_low  '>
				<div className='container'>
					<img
						className='rounded-x'
						alt='img_rules_and_law'
						src={img_rules_and_law}
						width='100%'
						height='auto'
					/>
				</div>
			</div> */}
			{/* <MainHeader /> */}
			<div className='container text-right text-bold mt-5'>
				<h4>الأحكام والقوانين</h4>
				<p
					className='p-2'
					dangerouslySetInnerHTML={{
						__html: rules?.post?.data?.terms_and_laws,
					}}></p>
			</div>
		</>
	);
}
