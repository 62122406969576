import React,{useState,useEffect} from 'react';
import Questino from '../../components/questino/index';
import './index.css';

const QuestionoModel = ({question,show = false , hideFunction , answeredQuestions}) => {

    const [internalShow,setInternalShow] = useState(show);
    // const [answeredQuestion,setAnsweredQuestion] = useState({});

    // const [q,setq] = useState({
    //     "id": 121,
    //     "question": "Aut vero voluptates voluptas nihil provident cumque distinctio harum. Cumque est ducimus explicabo quas facere. Sequi ea laudantium facere occaecati facere recusandae.",
    //     "question_time": 12,
    //     "point": "1",
    //     "options": [
    //         {
    //             "id": 1,
    //             "answear": "Eveniet doloribus sint consequatur quaerat. Numquam quis qui rerum modi porro fugit. Ut pariatur neque et amet quis ullam.",
    //             "is_true": true
    //         },
    //         {
    //             "id": 2,
    //             "answear": "Aliquam dolorem sequi aut non. Voluptas suscipit sed laboriosam ut rerum sint sequi. At vitae error rerum neque tenetur cupiditate et.",
    //             "is_true": false
    //         },
    //         {
    //             "id": 3,
    //             "answear": "Eveniet officiis velit magnam aut qui impedit. Est deleniti libero qui qui ut. Ut consectetur dolorum odio. Quia quo nesciunt voluptatem cumque voluptatem aut quia.",
    //             "is_true": false
    //         },
    //         {
    //             "id": 4,
    //             "answear": "Placeat distinctio doloribus itaque sequi. Natus labore id magnam excepturi.",
    //             "is_true": false
    //         }
    //     ]
    // })
    const [q,setq] = useState({...question});

    const [selectedOptionId,setSelectedOptionId] = useState(null);

    const handleOnChangeOption = (option) => {
        setSelectedOptionId(option.id);
        // const copiedQuestion = {...q};
        // for(let i = 0 ; i < copiedQuestion.options.length ; i++){
        //     if(copiedQuestion.options[i].id == option.id) {
        //         copiedQuestion.options[i].selected = true;
        //     } 
        // }
        
        
    }

    const addSelectedAnswerAfterConfirming = () => {
        answeredQuestions( prev => [...prev,{questino_id: question.id,option_id: selectedOptionId}]);
    }

    useEffect(() => {
        console.log("tooot");
        console.log("question",question);
        console.log("q",q);
        
    },[])
    
    return <div className={`question__popup ${!show?'hidepopup':'showPopUp'} `}>
        <div className='popup__content'>
                <div className='content__header'>
                    <h5 className='question'><span>السؤال: </span>{question && question.question && question.question}</h5>     
                    <h6 className='marks'>({question && question.point && question.point})نقطة</h6>
                </div>
                

                <div className='content__body'>
                    <ul>
                        {
                           question && question.options&&question.options.map((option,index) => {
                                return selectedOptionId == option.id ? <li key={index} >
                                            <label onClick={(e) => handleOnChangeOption(option)}>
                                            <input className="checkbox" checked type={'checkbox'}/>
                                                {option.answear}    
                                            </label>
                                        </li> :
                                        <li key={index} >
                                            <label onClick={(e) => handleOnChangeOption(option)}>
                                            <input className="checkbox" type={'checkbox'}/>
                                                {option.answear}    
                                            </label>
                                        </li> 
                            })
                        }
                    </ul>
                </div>

                <div className='content__footer'>
                    <button onClick={() => {hideFunction(); addSelectedAnswerAfterConfirming()}}>تأكيد</button>
                </div>
        </div>
    </div>
}

export default QuestionoModel;