import React, { useEffect, useState } from 'react';
import './OpinionTarekeStudents.css';

import OpinionTarekeStudentsLogic from './OpinionTarekeStudentsLogic';
import Button from '../button';
import MainComment from '../mainComment';
import SubTitle from '../SubTitle/SubTitle';
import ButtonM from '../ButtonM/ButtonM';
export default function OpinionTarekeStudents() {
	const { dataOpinion, handleNavigate } = OpinionTarekeStudentsLogic();
	const [pagination, setpagination] = useState(3);
	const [prevPagination, setprevPagination] = useState(0);

	const next = () => {
		if (pagination < dataOpinion?.length) {
			setpagination(pagination + 3);
			setprevPagination(prevPagination + 3);
		}
	};
	const prev = () => {
		if (prevPagination >= 3) {
			setpagination(pagination - 3);
			setprevPagination(prevPagination - 3);
		}
	};
	return (
		<>
			{/* start opinion tareke students   */}

			{/* {dataOpinion?.length > 0 && (
				<div className='opinion-tareke-students text-center'>
					<h3> آراء وتجارب طلاب موقع طريقي</h3>
					<div className='container p-1 '>
						<div className='row justify-content-around  '>
							{dataOpinion &&
								dataOpinion.length > 0 &&
								dataOpinion.map((item, index) => {
									if (index < 4) {
										return (
											<div
												className='col-md-5 col-xs-10 bg-white m-4 rounded-x card_opinion'
												key={item?.id}>
												<div className='d-flex flex-row '>
													<img
														alt='opinion tareke students'
														width='40px'
														height='40px'
														className='rounded-pill'
														src={`${
															process.env.REACT_APP_STORAGE_URL +
															item.user_image
														}`}
													/>
													<p>{item?.opinion}</p>
												</div>
												<div className='d-flex flex-row justify-content-start'>
													<img
														alt='opinion tareke students'
														width='20px'
														height='20px'
														className='rounded-pill'
														src={chalks}
														//   src={opinion?.user_image}
													/>
													<p>{item?.user_name}</p>
												</div>
											</div>
										);
									}
								})}

							<p
								onClick={handleNavigate}
								className='btn btn-outline-info CursorLinkopinion w-100 '>
								عرض المزيد
							</p>
						</div>
					</div>
				</div>
			)} */}
			<div className='comments container'>
				{dataOpinion && dataOpinion.length > 0 ? (
					<>
						{/* <h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							أبرز التعليقات
						</h4> */}
						<SubTitle title={'أبرز التعليقات'} className='mb-4' />
						{dataOpinion?.map((el, index) => {
							if (index < pagination && prevPagination <= index)
								return (
									<MainComment
										// img={el?.student_image}
										img={process.env.REACT_APP_STORAGE_URL + el?.user_image}
										// name={"اسم الطالب"}
										name={el?.student_name ? el?.student_name : el?.user_name}
										// name={el.student_image}
										date={el?.date_time}
										// date={"12/5/2022"}
										// text="lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus "
										text={el.comment ? el?.comment : el?.opinion}
									/>
								);
						})}
						{/* <p
							onClick={handleNavigate}
							className='btn btn-outline-info CursorLinkopinion w-100 '>
							عرض المزيد
						</p> */}
						<ButtonM
							text='عرض المزيد'
							onClick={handleNavigate}
							className='mx-auto'
						/>
					</>
				) : (
					<p
						style={{ borderRadius: '10px' }}
						className='p-3 text-center border w-50 w-sm-75 m-auto my-5'>
						لا يوجد تعليقات الى الأن
					</p>
				)}
				{dataOpinion && dataOpinion?.length < 3 ? (
					<> </>
				) : (
					<div className='row justify-content-center'>
						<Button
							text={<span className='text-danger'>السابق</span>}
							className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
							onclick={prev}
							backgroundColor='white'
						/>
						<Button
							text={<span className='text-danger'>التالي</span>}
							className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
							onclick={next}
							backgroundColor='white'
						/>
					</div>
				)}
			</div>
		</>
	);
}
