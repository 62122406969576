import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function PopUp(props) {
	console.log(props.data);
	const { data } = props;
	return (
		<Modal
			animation
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title
					className='text1'
					id='contained-modal-title-vcenter'
					style={{ width: '100%' }}>
					تفاصيل الوحدة
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container row'>
					<p className='col-2 mb-3 text1'>اسم الوحدة: </p>
					<p className='col-4 mb-3 text2'> {data?.unit_name}</p>
					<p className='col-2 mb-3 text1'>ترتيب الوحدة: </p>
					<p className='col-4 mb-3 text2'> {data?.order}</p>
					<p className='col-2 mb-3 text1'>وصف الوحدة: </p>
					<p className='col-10 mb-3 text2'> {data?.unit_description}</p>
					<p className='col-2 mb-3 text1'>عدد الدروس : </p>
					<p className='col-4 mb-3 text2'>{data?.lessons_count}</p>
					<p className='col-2 mb-3 text1'>مدة الدروس: </p>
					<p className='col-4 mb-3 text2'> {data?.lessons_sum_duration}</p>
					<p className='col-2 mb-3 text1'>المتطلبات: </p>
					<p className='col-10 mb-3 text2'> {data?.requirements}</p>
					<p className='col-6 mb-3 text1'> فيديو الوحدة: </p>
					<p className='col-6 mb-3 text1'> صورة الوحدة</p>
					<p className='col-6 mb-3 '>
						{
							<iframe
								style={{ width: '100% ', height: '200px' }}
								src={`${process.env.REACT_APP_STORAGE_URL}${data?.unit_video}`}
								allowfullscreen='allowfullscreen'></iframe>
						}
					</p>

					<div className='col-6'>
						<img
							className='img-fluid'
							src={`${process.env.REACT_APP_STORAGE_URL}/${data?.unit_cover}`}
							alt='Girl in a jacket'
							style={{ width: '100% ', height: '200px' }}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className='text-end w-100'>
					<Button className='btn1' onClick={props.onHide}>
						خروج
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
