import React, { useEffect } from 'react';
import AboutUs from '../../components/Aboutus/AboutUs';
import MainContent from '../../components/MainContent/MainContent';
import MainHeader from '../../components/MainHeader/MainHeader';
import Layout from '../Layout/Layout';

export default function AboutUsPage() {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<MainContent />
			<AboutUs />
		</Layout>
	);
}
