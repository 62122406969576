import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {get_teacher_subjects} from './../../redux-toolkit/reducers/Subjects/SubjectsSlice'

export default function Show_All_Material_For_Quiz_Logic({Title}) {
    const dispatch = useDispatch();

    useEffect(()=>{
            document.title   = Title+ " |  إنشاء الاختبارات";    
        dispatch(get_teacher_subjects())
    },[])

  
    const data = useSelector((state)=>state.subjects.get_teacher_subjects)
  
    return {
        data 
    }
}
