import React from 'react'
import Layout from '../Layout/Layout';
import './Student.css';
import {TiDelete} from 'react-icons/ti'
import {BiTimeFive} from 'react-icons/bi'
const Student = () => {
  return (
    <Layout>
    <div>
<div>
      <div className='student_name_date mt-3'>
      <div>
           <div className='Student__name'>
               <TiDelete className='delete'/>
               <div className='Student__details'>
               <p>lorem ipsum</p>
               <p>Lorem ipsum dolor sit amet.</p>
               <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam mollitia alias molestias maiores possimus iure velit doloribus sit laudantium pariatur.</p>
               <p>اضغط هنا</p>
               </div>
           </div>
     </div>
           <div className='date'>
                <h5 className='date__time'> <BiTimeFive />   Nov  24 2020  at 9:30 </h5>
           </div>
      </div>
      <hr />
</div>


<div>
      <div className='student_name_date mt-3'>
      <div>
           <div className='Student__name'>
               <TiDelete className='delete'/>
               <div className='Student__details'>
               <p>lorem ipsum</p>
               <p>Lorem ipsum dolor sit amet.</p>
               <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam mollitia alias molestias maiores possimus iure velit doloribus sit laudantium pariatur.</p>
               <p>اضغط هنا</p>
               </div>
           </div>
     </div>
           <div className='date'>
                <h5 className='date__time'> <BiTimeFive className='date__now' />   Nov  24 2020  at 9:30 </h5>
           </div>
      </div>
      <hr />
</div>


<div>
      <div className='student_name_date mt-3'>
      <div>
           <div className='Student__name'>
               <TiDelete className='delete'/>
               <div className='Student__details'>
               <p>lorem ipsum</p>
               <p>Lorem ipsum dolor sit amet.</p>
               <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam mollitia alias molestias maiores possimus iure velit doloribus sit laudantium pariatur.</p>
               <p>اضغط هنا</p>
               </div>
           </div>
     </div>
           <div className='date'>
                <h5 className='date__time'> <BiTimeFive />   Nov  24 2020  at 9:30 </h5>
           </div>
      </div>
      <hr />
</div>

<div>
      <div className='student_name_date mt-3'>
      <div>
           <div className='Student__name'>
               <TiDelete className='delete'/>
               <div className='Student__details'>
               <p>lorem ipsum</p>
               <p>Lorem ipsum dolor sit amet.</p>
               <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsam mollitia alias molestias maiores possimus iure velit doloribus sit laudantium pariatur.</p>
               <p>اضغط هنا</p>
               </div>
           </div>
     </div>
           <div className='date'>
                <h5 className='date__time'> <BiTimeFive />   Nov  24 2020  at 9:30 </h5>
           </div>
      </div>
      <hr />
</div>

</div>

    </Layout>
  )
}

export default Student