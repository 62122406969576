import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import SideBar from '../SideBar/SideBar';

import { Link } from 'react-router-dom';
import axios from 'axios';

const SonsExams = () => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [guardianStudents, setGuardianStudents] = useState([]);
	const [isLoading, setIsloading] = useState(true);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	useEffect(() => {
		async function getData() {
			axios
				.get(`${process.env.REACT_APP_API_URL}/get_student_by_guardian`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				})
				.then((data) => {
					setGuardianStudents(data.data);
				})

				.catch((error) => {
					console.log('reports data', error);
				})
				.finally(() => {
					setIsloading(false);
				});

			//   console.log("actual Dataaaaaaaaaaaaaaaaaaaa",actualData)
		}
		getData();
	}, []);

	return (
		<Layout>
			<div className='sideBar__relative'>
				<SideBar />
			</div>

			<h1 className='w-100 text-center my-4'>إختبارات أبنائي</h1>
			<div className='profileScreen mb-5'>
				<div className='reports_container'>
					{/* <div className='topcontent'>
						<div className='userImage'>
							<img loading="lazy" 
								src={`${process.env.REACT_APP_STORAGE_URL}${studentDetails?.data[0]?.student_image}`}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>اسم المستخدم</h3>
					</div> */}

					{/* {studentDetailsError && <Error errorText={studentDetailsError} />} */}

					{isLoading && (
						<div className='w-100 text-center my-5'>
							<div class='spinner-border' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					{guardianStudents?.data &&
						guardianStudents?.data &&
						guardianStudents?.data?.map((item) => {
							return (
								<>
									<div
										className='card shadow'
										style={{ width: '16rem', height: '20rem' }}>
										<img
											loading='lazy'
											src={`${process.env.REACT_APP_STORAGE_URL}${item?.student_image}`}
											className='card-img-top'
											alt='...'
											style={{ height: '150px' }}
										/>
										<div className='card-body d-flex flex-column justify-content-between'>
											<h3 className='card-title text-center w-100'>
												{item?.student_name}
											</h3>
											{/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
											<Link
												to={`/studentsubjects/${item.student_id}`}
												class='btn btn-success w-100'>
												عرض المزيد
											</Link>
										</div>
									</div>
								</>
							);
						})}

					{/* { role == 1 && (
						<Button
							text={'تعديل كلمة السر'}
							className='editPasswordBtn'
							onclick={() => setEditPassword(!editPassword)}
						/>
					)} */}

					{guardianStudents && guardianStudents?.data?.length == 0 && (
						<h1 className='text-center w-100 my-5'>
							ليس لديك أية أبناء , تواصل مع الادارة لاضافة أبناء لحسابك
						</h1>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default SonsExams;
