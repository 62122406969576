import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {useForm} from 'react-hook-form'
import { get_faqs_by_id, update_lesson_faqs_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
export default function Edit_Lesson_Faqs_Logic() {
    const faqbyid= useSelector(state=>state.lesson.get_faqs_by_id);
    const updatefaqs = useSelector(state=>state.lesson.update_lesson_faqs_by_id) 

    const {faqId,LessonId} = useParams();
    const dispatch=useDispatch();
  

    console.log(faqId);
    const { register , setValue ,handleSubmit, formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        question:'',
      answer:'',
      transable:0
    }});

    useEffect(()=>{
    dispatch(get_faqs_by_id({faqId}));
    },[])

    useEffect(()=>{
  if(faqbyid.data)
  {
   
    setValue('question',faqbyid.data.question );
    setValue('answer',faqbyid.data.answer );
  }
    },[faqbyid.data])

    const onSubmit = data => {
       const formdata = new FormData();
       formdata.append('question',data.question);
       formdata.append('answer',data.answer);
       formdata.append('transable',data.transable);
       formdata.append('lesson_id',LessonId);

      dispatch(update_lesson_faqs_by_id({formdata,faqId}))
    }
  
    const data=useSelector((state)=>state.units.add_unit_faqs)
    
  return {data,register,errors,isDirty,isValid,handleSubmit,onSubmit,updatefaqs}
}
