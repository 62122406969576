import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_lesson_comment_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
import Show_All_Comments_Logic from './Show_All_Comments_Logic';
import Pagination from '../../../../Pagination/Pagination';
import { delete_comment_for_unit } from '../../../../../redux-toolkit/reducers/Units/UnitSlice';
import DeleteModal from '../../../../DeleteModal';
export default function Show_All_Comments() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const {
		dispatch,
		data,
		SubjectId,
		isLoading,
		isError,
		error,
		refetch,
		handlePageClick,
		page,
		loading,
		deleteComment,
	} = Show_All_Comments_Logic();

	console.log(data);
	return (
		<div className='container my-5'>
			{isLoading ? (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			) : (
				<>
					<table className='w-100  table_style table-bordered text-center  table-striped  mb-4'>
						<thead>
							<tr>
								<th className='first_child'>#</th>
								<th> التعليق </th>
								<th> اسم الطالب </th>
								<th> التوقيت</th>
								<th className='last_child'></th>
							</tr>
						</thead>
						{data?.data?.data?.data && (
							<tbody>
								{data?.data?.data?.data?.map((comment, index) => (
									<tr key={comment.id}>
										<td>{index}</td>
										<td> {comment.comment}</td>
										<td> {comment.student_name} </td>
										<td> {comment.date_time} </td>
										<td>
											<button
												type='button'
												class='btn btn2'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal'
												onClick={() => setSelectedAutomatedQuizIe(comment.id)}>
												حذف
											</button>
										</td>
										{/* <td><Link to={`edit_comment/${comment.id}`}   className='btn btn-success' >  تعديل التعليق </Link></td> */}
										{/* {(index===(data?.data?.length-1))&&<td> <button className='btn btn-danger' onClick={()=>{dispatch(delete_unit_by_id({UnitId:Unit.unit_id,SubjectId}))}}> حذف الوحدة  </button> </td> }   */}
									</tr>
								))}
							</tbody>
						)}

						{isError && (
							<div class='d-flex justify-content-center'>
								<label className='text-danger'>
									{error?.response?.data?.message
										? error?.response?.data?.message
										: `  هناك مشكلة ما رمز الخطأ: ${error.response.status}`}
								</label>
							</div>
						)}
					</table>
					{data?.data?.data?.total >= 10 ? (
						<Pagination
							data={data?.data?.data}
							handlePageClick={handlePageClick}
						/>
					) : (
						<></>
					)}
				</>
			)}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا التعليق ؟'}
				handleDelete={() => deleteComment(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
