import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { get_unit_details_by_unit_id } from "../../../../redux-toolkit/reducers/Units/UnitSlice";
import { useUploadForm } from "../../../../customhook/Uploadfiles";
import {
  setError,
  setSuccess,
} from "../../../../redux-toolkit/reducers/notification";
let allowedExtension = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];
export default function Edit_Unit_Logic({ setLoading }) {
  const { UnitId, SubjectId } = useParams();

  const data = useSelector((state) => state.units.get_unit_details_by_unit_id);

  const [cover_error, setcover_error] = useState("");
  const [cover, setcover] = useState(null);
  const cover_Ref = useRef();
  const dispatch = useDispatch();

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      points: "",
      video: "",
      isFree: 1,
      start_date: "",
      end_date: "",
      requirements: "",
      transable: "0",
      unit_order: ''
    },
  });

  useEffect(() => {
    dispatch(get_unit_details_by_unit_id({ UnitId }));
  }, []);

  useEffect(() => {
    if (data.data) {
      setValue("name", data.data.unit_name);
      setValue("description", data.data.unit_description);

      setValue("video", data.data.unit_video);
      setValue("start_date", data.data.start_date);
      setValue("end_date", data.data.end_date);
      setValue("requirements", data.data.requirements);
      setValue("points", data.data.points);
      setValue('unit_order', data.data.unit_order)
    }
  }, [data]);

  const {
    isSuccess: edit_unit_success,
    uploadForm: edit_unit,
    progress: edit_unit_progress,
    IsError: edit_unit_error,
    data: edit_unit_data,
    IsLoading: edit_unit_loading,
  } = useUploadForm(`/update_unit/${UnitId}`, {
    setLoading,
    setSuccess,
    setError,
    SuccessMsg: "تم تعديل الوحدة",
    redirectURL: "..",
  });

  const onSubmit = async (data) => {
    const edit_unit_form = new FormData();
    edit_unit_form.append("name", data.name);
    edit_unit_form.append("description", data.description);
    edit_unit_form.append("points", data.points);
    if(typeof data.video[0] != 'string'){

      edit_unit_form.append("video", data.video[0]);
    }

    if(data.unit_order != ''){
      edit_unit_form.append("unit_order", data.unit_order);
    }

    edit_unit_form.append("isFree", data.isFree);
    edit_unit_form.append("start_date", data.start_date);
    edit_unit_form.append("end_date", data.end_date);
    edit_unit_form.append("requirements", data.requirements);
    edit_unit_form.append("subject_id", SubjectId);
    edit_unit_form.append("transable", data.transable);

    if(cover){
      edit_unit_form.append("cover", cover);
    }
    
    await edit_unit(edit_unit_form);
  };

  const handlecover = (e) => {
    if (
      e.target.files[0].size / (1024 * 1024) < 8 &&
      allowedExtension.indexOf(e.target.files[0].type) > -1
    ) {
      setcover_error("");
      setcover(e.target.files[0]);
    } else {
      setcover(null);
      setcover_error("يرجى اختيار صورة بلاحقة jpg,png ");
      cover_Ref.current.value = "";
    }
  };
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    edit_unit_error,
    edit_unit_progress,
    edit_unit_success,
    edit_unit_loading,
    onSubmit,
    handlecover,
    cover,
    cover_Ref,
    cover_error,
    onSubmit,
  };
}
