import React from "react";

function task({ element, setEditModalElement, setDeleteTaskId }) {
  return (
    <td id={element.id}>
      <div className="content">
        <div className="">
          <span className="text-primary fs-5">المادة:</span>
          <span className="text-muted me-2">{element.task}</span>
        </div>
        <div>
          <span className=" text-primary fs-5">وقت البدء:</span>
          <span className="text-muted me-2">{element.start_time}</span>
        </div>
        <div>
          <span className=" text-primary fs-5">وقت الانتهاء:</span>
          <span className="text-muted me-2">{element.end_time}</span>
        </div>
        <span
          className="text-dark h6 mt-2"
          data-bs-toggle="modal"
          data-bs-target="#EditModal"
          style={{ cursor: "pointer" }}
          onClick={setEditModalElement.bind(null, element)}
        >
          تعديل
        </span>
        <span
          className="text-dark me-5 h6"
          data-bs-toggle="modal"
          data-bs-target="#deleteModal"
          style={{ cursor: "pointer" }}
          onClick={setDeleteTaskId.bind(null, element.id)}
        >
          حذف
        </span>
      </div>
    </td>
  );
}

export default task;
