import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './login.css';
import {
	clearMsg,
	fetchSignIn,
	getError,
	getSuccess,
} from '../../redux-toolkit/reducers/Auth/Auth';
import { useForm } from 'react-hook-form';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/logo.png';
import Layout from '../../pages/Layout/Layout';
import MainHeader from '../MainHeader/MainHeader';
import profile from '../../assets/signinPage/profile.png';
import lock from '../../assets/signinPage/Layer 2.png';
import ButtonM from '../ButtonM/ButtonM';
import SubTitle from '../SubTitle/SubTitle';
export default function Login({ text, role }) {
	const dispatch = useDispatch();
	const successMsg = useSelector(getSuccess);
	const errorMsg = useSelector(getError);
	const data = useSelector((state) => state.SIGN.signin);
	const token = useSelector((state) => state.SIGN.token);
	const navigator = useNavigate();
	// console.log(successMsg);
	// console.log(errorMsg);
	//*************************************************************************************************************************** */
	useEffect(() => {
		// console.log("token----->", token);
		if (token) navigator('/');
	}, token);
	//*************************************************************************************************************************** */
	//*************************************************************************************************************************** */
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			username: '' ,
			password: '' ,
			role_id: role ,
		},
	});
	const onSubmit = (data) => {
		dispatch(
			fetchSignIn({
				username: data.username,
				password: data.password,
				role_id: Number(role),
			})
		);
	};

	return (
		<Layout>
			<ToastSuccessError
				successMessage={successMsg}
				ErrorMessage={errorMsg}
				clearMsg={clearMsg}
			/>

			{/* <MainHeader /> */}
			<div className='w-100 text-center mt-5 '>
				{/* <h5 className='mb-5  mx-auto py-1  title_login'>
					تسجيل الدخول ك{text}
				</h5> */}
				<SubTitle  title={`تسجيل الدخول ك${text}`} />
			</div>
			<div className='d-flex justify-content-center '>
				<div className='   py-4  mt-2 mb-5 login-card shadow'>
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* <div className='mb-3'> */}
						{/* <div className='mb-3'>
								<i
									className='fa fa-lock'
									style={{ marginLeft: '1rem', fontSize: '1.5rem' }}
								/>
								<label className='fw-bold'>تسجل الدخول كـ</label>
							</div> */}
						{/* <select
								{...register('role_id')}
								className='form-select w-50 mx-auto text-center'>
								<option value='1'>طالب</option>
								<option value='2'> أستاذ </option>
								<option value='3'> أب </option>
							</select>
						</div> */}
						<div className='mb-3  mt-5'>
							<div className='mb-4'>
								<label className='fw-bold fs-5'>اسم المستخدم</label>
							</div>
							<div className='parent mb-5 '>
								<input
									placeholder='ادخل اسم المستخدم'
									autoComplete
									{...register('username', { required: true })}
									type='text'
									className=' form-control    login_input'
								/>
								{errors.username && (
									<p className='mt-2 w-100 text-danger'>
										يجب إدخال اسم المستخدم
									</p>
								)}
								<img
									src={profile}
									className='sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{/* {errors.name && (
								<label className='text-center w-100  text-danger'>
									اسم المستخدم مطلوب
								</label>
							)} */}
						</div>

						<div className='mb-3 mt-5 '>
							<div className='mb-3 mt-2 '>
								<label className='fw-bold fs-5'>كلمة السر</label>
							</div>
							<div className='parent mt-4 '>
								<input
									placeholder='ادخل كلمة المرور'
									autoComplete
									{...register('password', { required: true })}
									type='password'
									className=' form-control login_input'
								/>

								<img
									src={lock}
									className=' sun'
									// style={{ marginLeft: '1rem', fontSize: '2rem' }}
								/>
							</div>
							{errors.password && (
								<label className='mt-2 w-100 text-danger'>
									كلمة السر مطلوبة
								</label>
							)}
						</div>

						{data.isFetching ? (
							<div className='d-flex mb-3'>
								<div class='spinner-border m-auto' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							</div>
						) : (
							<div className='d-flex'>
								<button
									disabled={!isDirty || !isValid}
									type='submit'
									className='btn btn-success m-auto  rounded text-white fw-bold py-2'>
									الدخول
								</button>

								
							</div>
						)}
					</form>
					<p className='text-center mx-auto  w-100 px-3 my-5'>
						ان لم يكن لديك حساب فى الاكاديمية أنشىء{' '}
						<a href='/signup'> حساب جديد</a> الان
					</p>
				</div>
			</div>
		</Layout>
	);
}
