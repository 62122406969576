import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Lesson_Quizes_Logic from './Lesson_Quizes_Logic';

export default function Lesson_Quizes({ api }) {
	const { isSuccess, progress, IsLoading, IsError, data } = Lesson_Quizes_Logic(
		{ api }
	);
	const state = useLocation();

	console.log('state state', state);
	return (
		<div className='container  my-5'>
			{
				<div className=''>
					<h3 className='fw-bold mb-4 text-center my-3'>
						<span style={{ color: '#044364' }}>الإختبارات </span>
					</h3>
					{IsLoading && (
						<div class='d-flex justify-content-center'>
							<div class='spinner-grow text-dark' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}
					<table
						className=' table-bordered table   table-striped  table_style'
						style={{ marginTop: '40px ' }}>
						<thead>
							<tr>
								<th className='first_child'>#</th>
								<th> الاسم </th>
								<th> الوصف</th>
								<th> المدة</th>
								<th>النقاط</th>
								<th className='last_child'></th>
							</tr>
						</thead>
						{data?.data && (
							<tbody>
								{data?.data?.map((quiz, index) => (
									<tr key={quiz.id}>
										<td>{index}</td>
										<td> {quiz.quiz_name} </td>
										<td> {quiz.description} </td>
										<td> {quiz.quiz_duration} </td>
										<td> {quiz.quiz_points} </td>

										<td>
											<Link
												to={`automatedquiz/${quiz.id}`}
												className='btn btn1'
												state={{ quizDetailes: quiz }}>
												{' '}
												عرض الاختبار
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						)}
						{IsError && (
							<div class='d-flex justify-content-center'>
								<label className='text-danger'>
									{IsError?.response?.data?.message
										? IsError?.response?.data?.message
										: IsError.message}
								</label>
							</div>
						)}
					</table>
				</div>
			}
		</div>
	);
}
