import React, { useState, useEffect } from 'react';
import './index.css';

import lock from '../../assets/icons/lock.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import heart from '../../assets/icons/heart.svg';
import heartfill from '../../assets/icons/heart-fill.svg';
import {
	setError,
	setSuccess,
} from '../../redux-toolkit/reducers/notification';
import { useDispatch, useSelector } from 'react-redux';

import ToastSuccessError from '../ToastSuccessError/ToastSucessError';

function Lesson({
	btnType,
	btnText,
	isLock,
	starsNum,
	lessonName,
	lessonNum,
	lessonTime,
	lesson_points,
	date,
	id,
	isFree,
	lessonImage,
	access,
	isSaved,
	lesson_description,
}) {
	const [loading, setloading] = useState(true);
	const [show, setShow] = useState(false);
	const dispatch = useDispatch();
	const handleClose = () => setShow(false);
	const handleShow = (e) => setShow(true);

	const [Saved, setSaved] = useState(isSaved);
	const [Savedpage, setSavedpage] = useState(false);
	// *******************************************************************
	useEffect(() => {
		if (
			window.location.pathname == '/subjectss'
			// ||
			// window.location.pathname == '/lessones'
		) {
			setSaved(true);
			setSavedpage(true);
			console.log('its ok ', window.location.pathname);
		}
	}, []);
	const removeLesson = (id) => {
		setloading(false);
		handleClose();

		const token = localStorage.getItem('token');
		console.log(id);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/remove_lesson_from_student_saved_lessons`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						lesson_id: id,
					},
				}
			)
			.then((res) => {
				setloading(true);
				dispatch(setSuccess('تم إلغاء الحفظ'));
				setSaved(!Saved);
			})
			.catch((err) => {
				setloading(true);

				dispatch(setError('لم يتم إلغاء الحفظ	'));
				// alert('succes');
				// console.log(err);
			});
	};

	// ***************************************************************************

	const saveLesson = (id) => {
		const token = localStorage.getItem('token');
		setloading(false);

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/add_lesson_to_student_saved_lessons?lesson_id=${id}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)
			.then((res) => {
				dispatch(setSuccess('تم الحفظ'));
				setloading(true);
				setSaved(!Saved);
			})
			.catch((err) => {
				console.log(err.response.data.message);

				setloading(true);

				console.log(err);
				if (
					err.response.data.message ===
					'the lesson is already exists in this student saved lesson list'
				)
					dispatch(setError('تم الحفظ سابقا'));
				setloading(true);
			});
	};

	// ************************************************************

	return (
		<div className='lessonCard mx-auto text-center'>
			{/* {starsNum && (
				<div className={`stars ${starsNum}`}>
				<img src={star} alt='' />
				<div>{starsNum}</div>
				</div>
			)} */}
			{isLock && <img src={lock} className='locking' alt='' />}
			{isLock ? <div className='locked' /> : null}
			{/* {access && isFree == 0 ? null : <div className='locked' />} */}
			{/* {!access && isFree == 0 ? null : <div className="locked" /> } */}
			{/* {!access && isFree != 0 ? <div className="locked" /> : null } */}
			{/* { ? null : <div className="locked"/> } */}
			<div className={` row`}>
				{/* {btnType === 'delete' ? ( */}

				<div className='col-12 image_section'>
					<Link to={`/lesson/${id}`}>
						<img
							width={'100%'}
							height='100%'
							src={`${process.env.REACT_APP_STORAGE_URL}/${lessonImage}`}
							className='centerimg'
							alt=''
						/>
					</Link>
				</div>

				<div className='col-12 detailes_section py-2'>
					<h5 style={{ textAlign: 'center', width: '100%' }}>
						<span>{lessonName}</span>
					</h5>
					<h6>
						مدة الانجاز : <span className=''></span>
						{lessonTime}
					</h6>
					{lesson_points && (
						<span>
							عدد النقاط : <span className=''></span>
							{lesson_points} نقطة
						</span>
					)}

					<span className='mx-3'>
						{(Savedpage ? true : true) && Saved ? (
							<>
								<svg
									onClick={handleShow}
									style={{ color: 'red' }}
									src={heart}
									// xmlns='http://www.w3.org/2000/svg'
									width='24px'
									height='24px'
									fill='currentColor'
									className='bi bi-heart-fill mt-2 mx-2'
									viewBox='0 0 16 16'>
									<path
										fill-rule='evenodd'
										d='M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z'
									/>
								</svg>

								<Modal show={show} onHide={handleClose}>
									<Modal.Body>هل تريد إلغاء الحفظ ؟؟</Modal.Body>
									<Modal.Footer>
										<Button className='btn1' onClick={handleClose}>
											الغاء
										</Button>
										<Button className='btn2' onClick={() => removeLesson(id)}>
											تاكيد
										</Button>
									</Modal.Footer>
								</Modal>
							</>
						) : (Savedpage ? true : true) && !Saved ? (
							<span>
								<svg
									onClick={(e) => saveLesson(id, e)}
									style={{ color: 'red' }}
									src={heartfill}
									width='24px'
									height='24px'
									fill='currentColor'
									className='bi bi-heart mt-2 mx-2'
									viewBox='0 0 16 16'>
									<path d='m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z' />
								</svg>
								{/* <span>حفظ الدرس</span> */}
							</span>
						) : null}
					</span>
				</div>
				<div className='col-12 start_section py-3 fs-5'>
					<Link to={`/lesson/${id}`} style={{ color: 'white' }}>
						{btnText}
					</Link>
				</div>

				{/* {date && <p className='date'>{date}</p>} */}
			</div>
		</div>
	);
}

export default Lesson;
