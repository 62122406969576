import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './index.css';
// import img from '../../assets/icons/pdf.png';

function Attachment({
	title,
	btn1Text,
	btn2Text,
	btn1function,
	btn2function,
	link,
	ladder_file,
	img,
}) {
	const openInNewTab = (url) => {
		window.open(url, '_blank');
	};
	// const navigate = useNavigate();

	return (
		<div className='attachment'>
			<img src={img} alt='' />
			<div className='content'>
				<div className='title'>{title}</div>
				<div className='buttonsContainer'>
					<button className='openBtn' onClick={btn2function}>
						{btn2Text == 'تشغيل' ? (
							<Link
								to={'/audioplayer'}
								onClick={() => localStorage.setItem('audio', link)}>
								{btn2Text}
							</Link>
						) : (
							<a onClick={() => openInNewTab(ladder_file)}>{btn2Text}</a>
						)}
					</button>
					<button className='downloadBtn' onClick={btn1function}>
						<a href={ladder_file} download='pdf'>
							{btn1Text}
						</a>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Attachment;
