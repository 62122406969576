import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteModal from '../../components/DeleteModal';
import {
	delete_traditional_quiz_by_traditional_quiz_id,
	get_all_final_traditional_quizzes_by_unit_id,
} from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Show_all_traditional_test_Logic from './Show_all_traditional_test_Logic';

export default function Show_all_traditional_test() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const { data, dispatch, UnitId, loading, deleteQuiz } =
		Show_all_traditional_test_Logic();

	return (
		<div className='container my-5'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{data.isError && (
				<label className='text-danger text-center w-100'>
					{data.errorMessage}
				</label>
			)}
			{data.data && (
				<div class=' my-5'>
					<div />
					<table className=' w-100 text-center  table_style table-bordered table-striped'>
						<thead>
							<tr>
								<th className='first_child'> اسم الاختبار </th>
								<th> مدة الاختبار </th>
								<th> نقاط الاختبار </th>
								<th></th>
								{/* <th></th>
								<th></th> */}
								<th className='last_child'></th>
							</tr>
						</thead>
						<tbody style={{ lineHeight: '60px' }}>
							{data.data?.map((Quiz) => (
								<tr key={Quiz.quiz_id}>
									<td> {Quiz.quiz_name} </td>
									<td> {Quiz.quiz_duration} </td>
									<td> {Quiz.quiz_points} </td>
									<td>
										{' '}
										<Link to={`edit/${Quiz.quiz_id}`} className='btn btn1'>
											{' '}
											تعديل{' '}
										</Link>{' '}
									</td>
									<td>
										<button
											type='button'
											class='btn btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={() => setSelectedAutomatedQuizIe(Quiz.quiz_id)}>
											حذف
										</button>
									</td>
									{/* <td>
										{' '}
										<Link
											to={`add_traditional_quiz_questions_files/${Quiz.quiz_id}`}
											className='btn btn3'>
											{' '}
											أضف أسئلة{' '}
										</Link>{' '}
									</td> */}
									{/* <td>
										{' '}
										<Link
											to={`show_traditional_quiz_questions_files/${Quiz.quiz_id}`}
											className='btn btn1'>
											{' '}
											عرض الأسئلة{' '}
										</Link>{' '}
									</td> */}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			<Link to={`add_quiz`} className='btn btn1 mx-auto'>
				{' '}
				إنشاء اختبار جديد{' '}
			</Link>
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا الاختبار ؟'}
				handleDelete={() => deleteQuiz(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>

			<div style={{ margin: '30px auto' }} />
		</div>
	);
}
