import React, { useEffect } from 'react'
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';
import TraditionalQuizTeacherLogic from './TraditionalQuizTeacherLogic'

const TraditionalQuizTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
    const {traditionalQuiz} = TraditionalQuizTeacherLogic();
  return (
    <Layout>
    <LayoutSideTeacher desc={"الطالب"}>
    
     {traditionalQuiz && <h1>{traditionalQuiz.message}</h1>}
    </LayoutSideTeacher>
</Layout>
  )
}

export default TraditionalQuizTeacher