import React, { useEffect, useState } from 'react';
import './index.css';
import { Zoom } from 'react-reveal';
import Layout from '../Layout/Layout';

import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import Lesson from '../../components/lesson';
import MainHeader from '../../components/MainHeader/MainHeader';

// import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';

const LessonesPage = (props) => {
	// const { units } = SubjectsLogic();

	//  get subject id from url
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	const unitId = urlParams.get('id');

	const [lessones, setLessones] = useState();
	const [units, setUnits] = useState([]);
	const [lessones_finishes, setlessones_finishes] = useState([]);
	const [lessnGreen, setLessonGreen] = useState(0);
	const [loading, setloading] = useState(true);

	//************************************************ */
	const { state } = useLocation();
	const subjectId = state?.id;
	console.log('subjectcttt Id ', subjectId);

	const token = localStorage.getItem('token');

	const getLessones = async () => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					unit_id: unitId,
					limit: 20,
				},
			})
			.then((response) => {
				console.log(response.data.data);
				setLessones(response.data.data);
				setloading(false);
			})
			.catch((err) => {
				console.log(err);
				setloading(false);
			});
	};

	useEffect(() => {
		getLessones();
	}, []);
	//************************************************ */

	// units
	const getUnits = async () => {
		console.log('unitsss');
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_count_of_units_with_finished_count_by_subject_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						subject_id: subjectId,
					},
				}
			)
			.then((res) => {
				// setUnits(res.data)
				console.log('unitss', res.data.data);
				setUnits(res.data.data);

				console.log(units);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getUnits();
	}, []);
	//************************************************ */

	const [unit_id, setunit_id] = useState(1);

	const getFinishLessones = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_count_of_lessons_with_finished_count_by_unit_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						unit_id: unit_id,
					},
				}
			)
			.then((res) => {
				// setUnits(res.data)
				console.log('lesson finishes', res.data.data);
				setLessonGreen(res.data.data.student_lesson_count);
				let temp = [];
				for (let i = 0; i < res.data.data.total_number_of_lessons; i++)
					temp.push(1);
				setlessones_finishes(temp);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		getFinishLessones();
	}, [unit_id]);
	//******************** lesson finished******/

	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='subjectsScreen container text-center my-5'>
				<h3 style={{ color: '#044364' }} className='fw-bold my-3'>
					{state.name}
				</h3>
				{/* <LessonTitle /> */}
				{/* <Spacer  /> */}

				{/* <h3 className='Subjects__Title'>عدد الدروس المنجزة</h3> */}

				{loading ? (
					<div className='text-center mt-5'>
						<div class='spinner-border mx-auto' role='status'>
							<span class='sr-only'>Loading...</span>
						</div>
					</div>
				) : (
					<div className='row my-5' style={{ justifyContent: 'space-around' }}>
						{lessones
							? lessones.map((lesson) => (
									<div className='col-lg-3 mx-2 my-3 col-md-4 col-sm-6 col-12 text-center'>
										<Zoom>
											<Lesson
												isSaved={lesson.is_saved}
												id={lesson.lesson_id}
												key={lesson.lesson_id}
												btnText='الذهاب الى الدرس'
												lessonName={lesson.lesson_name}
												lessonTime={lesson.lesson_duration}
												lessonImage={lesson.lesson_image}
											/>
										</Zoom>
									</div>
							  ))
							: 'لا يوجد دروس '}
					</div>
				)}

				{/* <h3 className='Subjects__Title'>عدد الوحدات</h3>
				<div className='archivedUnitsContainer'>
					{units?.total_units && units?.total_units?.length > 0
						? units.total_units?.map((unit, index) => {
								return (
									<div
										onClick={() => {
											setunit_id(unit);
											setlessones_finishes([]);
										}}>
										<ArchivedUnit
											number={index + 1}
											isArchived={index < units?.student_unit_count}
											key={index}
										/>
										
									</div>
								);
						  })
						: 'انت غير مشترك في هذه الوحدة'}
				</div>
		
				<h3 className='Subjects__Title'>عدد الدروس</h3>
				<div className='archivedUnitsContainer'>
					{lessones_finishes && lessones_finishes.length > 0
						? lessones_finishes.map((unit, index) => {
								return (
									<ArchivedUnit
										isArchived={lessnGreen > index}
										number={index + 1}
										key={index}
									/>
								);
						  })
						: 'انت غير مشترك في هذه الدروس'}
				</div>*/}
			</div>
		</Layout>
	);
};

export default LessonesPage;
