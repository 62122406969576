import React, { useState, useEffect } from 'react';

import './index.css';
import Layout from '../Layout/Layout';
import ProfileLogic from './profileLogic';
import axios from 'axios';
import MainHeader from '../../components/MainHeader/MainHeader';
import profile from '../../assets/signinPage/profile.png';
import email from '../../assets/signinPage/email.png';
import phone from '../../assets/signinPage/phone-outgoing.png';
import lock from '../../assets/signinPage/Layer 2.png';
import CountrySelect from 'react-bootstrap-country-select';
const ProfileScreenById = ({ role }) => {
	const [value, setValue] = useState(null);
	const [countries, setcountries] = useState([]);
	const {
		// editPassword,
		// setEditPassword,
		studentDetailsIsLoading,
		// studentDetailsError,
		studentDetails,
	} = ProfileLogic();
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_countries`)
			.then((res) => {
				console.log('countries is ', res.data.data);
				setcountries(res.data.data);
			});
	}, []);
	console.log(studentDetails);
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='profileScreen'>
				<div className='profileScreen__content'>
					<h3 style={{ color: '#044364' }} className='fw-bold my-3 container'>
						البروفايل
					</h3>
					{studentDetailsIsLoading ? (
						<div className=' text-center'>
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						</div>
					) : (
						<div className='d-flex justify-content-center'>
							<div className='   py-4  mt-2 mb-5 login-card'>
								<div className='text-center'>
									<div className='userImage'>
										<img
											src={`${process.env.REACT_APP_STORAGE_URL}${studentDetails?.data?.user_image}`}
											style={{
												width: '150px',
												height: '150px',
												objectFit: 'cover',
												borderRadius: '50%',
											}}
										/>
									</div>
								</div>
								<form>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>اسم المستخدم</label>
										</div>
										<div className='parent mb-5 '>
											<input
												placeholder='ادخل اسم المستخدم'
												autoComplete
												// {...register('username', { required: true })}
												type='text'
												name='username'
												className=' form-control    login_input'
												value={studentDetails?.data?.name}
											/>
											<img src={profile} className='sun' />
										</div>
									</div>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>البريد الإلكتروني </label>
										</div>
										<div className='parent mb-5 '>
											<input
												placeholder=' ادخل البريد الالكتروني  '
												autoComplete
												// {...register('username', { required: true })}
												type='email'
												name='email'
												className=' form-control    login_input'
												value={studentDetails?.data?.email}
											/>
											<img src={email} className='sun' />
										</div>
									</div>
									<div className='mb-3  mt-5'>
										<div className='mb-4'>
											<label className='fw-bold fs-5'>رقم الموبايل </label>
										</div>
										<div className='parent mb-5 '>
											<input
												placeholder='ادخل رقم الموبايل '
												autoComplete
												// {...register('username', { required: true })}
												type='text'
												name='phone'
												className=' form-control    login_input'
												value={studentDetails?.data?.phone}
											/>
											<img src={phone} className='sun' />
										</div>
									</div>

									{localStorage.getItem('role') == 1 && (
										<>
											{' '}
											<div className='mb-3  mt-5'>
												<div className='mb-4'>
													<label className='fw-bold fs-5'>البلد </label>
												</div>
												<div className='parent mb-5 '>
													<select
														class='form-select'
														aria-label='Default select example'>
														<option selected>Open this select menu</option>
														{countries.map((country) => (
															<option value={country.id}>{country.name}</option>
														))}
													</select>
												</div>
											</div>
											<div className='mb-3 mt-5 '>
												<div className='mb-3 mt-2 '>
													<label className='fw-bold fs-5'>كلمة السر</label>
												</div>
												<div className='parent mt-4 mb-5'>
													<input
														placeholder='ادخل كلمة المرور'
														autoComplete
														// {...register('password', { required: true })}
														type='password'
														name='password'
														className=' form-control login_input'
													/>

													<img src={lock} className=' sun' />
												</div>
											</div>
											<div className='mb-3 mt-5 '>
												<div className='mb-3 mt-2 '>
													<label className='fw-bold fs-5'>
														{' '}
														تأكيد كلمة السر
													</label>
												</div>
												<div className='parent mt-4 mb-5'>
													<input
														placeholder='تأكيد كلمة السر  '
														autoComplete
														// {...register('password', { required: true })}
														type='password'
														name='confirmpassword'
														className=' form-control login_input'
													/>

													<img src={lock} className=' sun' />
												</div>
											</div>
										</>
									)}
									{localStorage.getItem('role') == 1 && (
										<div className='d-flex'>
											<button
												// disabled={!isDirty || !isValid}
												type='submit'
												className='login-button m-auto  rounded text-white fw-bold py-2'>
												تعديل
											</button>
										</div>
									)}
								</form>
							</div>
						</div>
					)}
					{/* <div className='topcontent'>
						<div className='userImage'>
							<img
								src={`${process.env.REACT_APP_STORAGE_URL}${studentDetails?.data?.user_image}`}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>اسم المستخدم</h3>
					</div> */}

					{/* {studentDetailsError && <Error errorText={studentDetailsError} />} */}

					{/* {
						// !editPassword
						true ? (
							<div className={`bottomContent`}>
								<div className='leftContent'>
									<ul>
										<li>
											<div>الاسم</div>
											<div>{studentDetails?.data?.name}</div>
											<p>{console.log('studdddent', studentDetails)}</p>
										</li>
										<li>
											<div>اسم الاب</div>
											<div>{studentDetails?.data?.father_name}</div>
										</li>
										<li>
											<div>اسم الام</div>
											<div>{studentDetails?.data?.mother_name}</div>
										</li>
										<li>
											<div>الجنس</div>
											<div>{studentDetails?.data?.user_gender}</div>
										</li>
										<li>
											<div>تاريخ الميلاد</div>
											<div>{studentDetails?.data?.birthday}</div>
										</li>
										<li>
											<div>العنوان</div>
											<div>{studentDetails?.data?.address}</div>
										</li>
										<li>
											<div>المحافظة</div>
											<div>{studentDetails?.data?.city}</div>
										</li>
									</ul>
									<div className='title'>المعلومات الشخصية</div>
								</div>

								<div className='rightContent'>
									<ul>
										<li>
											<div>الصف</div>
											<div>{studentDetails?.data?.class}</div>
										</li>
										<li>
											<div>المدرسة</div>
											<div>{studentDetails?.data?.school}</div>
										</li>
										<li>
											<div>رقم الهاتف</div>
											<div>{studentDetails?.data?.phone}</div>
										</li>
										<li>
											<div>الايميل</div>
											<div>{studentDetails?.data?.email}</div>
										</li>
									</ul>
									<div className='title'>معلومات الحساب</div>
								</div>
							</div>
						) : (
							<div className={`editpassword`}>
								<div className='enterOld'>
									<div className='text'>
										{' '}
										<img src={chalks} alt='' />
										أدخل كلمة السر القديمة
									</div>
									<input type='password' />
								</div>
								<div className='enterNew'>
									<div className='text'>
										<img src={chalks} alt='' /> كلمة السر الجديدة{' '}
									</div>
									<input type='password' />
								</div>
								<div className='confirmNew'>
									<div className='text'>
										<img src={chalks} alt='' /> تأكيد كلمة السر{' '}
									</div>
									<input type='password' />
								</div>

								<div className='btnsContainer'>
									<Button
										className='saveBtn'
										text='حفظ التعديلات'
										// onclick={() => setEditPassword(false)}
									/>
									<Button
										className='closeBtn'
										text='الغاء'
										// onclick={() => setEditPassword(false)}
									/>
								</div>
							</div>
						)
					} */}

					{/* {
						// editPassword
						true && role == 1 && (
							<Button
								text={'تعديل كلمة السر'}
								className='editPasswordBtn'
								// onclick={() => setEditPassword(!editPassword)}
							/>
						)
					} */}
				</div>
			</div>
		</Layout>
	);
};

export default ProfileScreenById;
