import React, { useEffect, useState } from 'react';
import Show_Lesson_Answear_Logic from './Show_Lesson_Answear_Logic';
import rightAnswer from './../../../../assets/icons/rightcheckbox.png';
import wrongAnswer from './../../../../assets/icons/wrongcheckbox.png';
import Error from '../../../alerts/Error/Error';
import axios from 'axios';
import ToastError from '../../../ToastSuccessError/ToastError';
import { useLocation } from 'react-router-dom';
import clock from '../../../../assets/icons/clock.png';
import point from '../../../../assets/icons/point.png';
import Button from '../../../button';

export default function Show_Lesson_Answear() {
	const { IsLoading, IsError, data, QuizId, StudentId } =
		Show_Lesson_Answear_Logic();
	const [teacherNotes, setTeacherNotes] = useState(
		data?.teacher_notes == null ? '' : data?.teacher_notes
	);
	const [isPostingNotes, setIsPostingNotes] = useState(false);
	const [successMessage, setSeuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const { state } = useLocation();
	const quizDetailes = state.quizDetailes;
	console.log('quiz details is ', quizDetailes);
	const handleTeacherNotic = (e) => {
		setTeacherNotes(e.target.value);
	};

	useEffect(() => {
		setTeacherNotes(data?.teacher_notes);
	}, [data]);

	// post_automated_quiz_notes_by_automated_quiz_and_student_id

	const postTeacherNotes = () => {
		// send data if and only if teacher notes not empty
		if (teacherNotes != '') {
			setIsPostingNotes(true);

			const dataToSend = {
				student_id: StudentId,
				autometed_quiz_id: QuizId,
				teacher_notes: teacherNotes,
			};

			axios
				.post(
					`${process.env.REACT_APP_API_URL}/post_automated_quiz_notes_by_automated_quiz_and_student_id`,
					dataToSend,
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
				)
				.then((data) => {
					console.log('data from sending teacher notes is ', data);
					setSeuccessMessage('تم ارسال ملاحظاتك بنجاح');
				})
				.catch((error) => {
					console.log('error from sending teacher notes', error);
					setErrorMessage('حدثت مشكلة ما يرجى المحاولة مرة اخرى');
				})
				.finally(() => {
					setIsPostingNotes(false);
				});
		}
	};

	return (
		<div>
			<ToastError
				ErrorMessage={errorMessage}
				successMessage={successMessage}
				setSuccessMessage={setSeuccessMessage}
				setErrorMessage={setErrorMessage}
			/>
			<div className='row justify-content-center text-center my-5'>
				<h1 className='fw-bold mb-4'>
					<span style={{ color: '#044364' }}>{quizDetailes?.quiz_name}</span>
				</h1>
				<div className='row justify-content-center'>
					<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
						<img src={clock} alt='book' width={'30px'}></img>

						<span className='mx-2 fs-3'>
							{' '}
							مدة الاختبار :
							{'د' + quizDetailes?.quiz_duration &&
							quizDetailes?.quiz_duration > 60
								? Math.floor(quizDetailes?.quiz_duration / 60)
								: quizDetailes?.quiz_duration}
							دقيقة
						</span>
					</div>
					<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
						<img className='mx-2' src={point} alt='point' width={'30px'}></img>

						<span className='mx-2 fs-3'>
							عدد النقاط:{' '}
							{quizDetailes?.quiz_points && quizDetailes?.quiz_points} نقطة{' '}
						</span>
					</div>
				</div>

				{/* <img
							src={
								unitDetails &&
								`${process.env.REACT_APP_STORAGE_URL}${
									unitDetails && unitDetails.unit_cover
								}`
							}
							alt='cover'
						/> */}
			</div>
			<div className='examScreen__explanatino'>
				{/* <h2>شرح عن الاختبار</h2> */}
				<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
					<li>{quizDetailes && quizDetailes.quiz_description}</li>
					<li>
						تحتوي معظم الدورات التدريبية على تقييمات تفاعلية تختبر معلوماتك ،
						مثل التقييم أدناه. يمكن أن يكونوا جزءًا من تسلسل تعليمي أو حتى
						اختبار ، لكننا سننتقل إلى ذلك بعد ذلك.
					</li>
					<li>
						تتضمن بعض المشكلات زر إظهار الإجابة الذي قد يتم عرضه قبل أو بعد
						محاولتك الإجابة على مشكلة. يمكنك أيضًا إعادة تعيين المشكلة إذا كنت
						ترغب في البدء من جديد.
					</li>
					<li>
						يمكنك إرسال الإجابات عدة مرات حسب حاجتك. ، حدد إجابتك ثم انقر أو
						انقر فوق إرسال لمعرفة ما إذا كانت إجابتك صحيحة.
					</li>
				</ul>
			</div>

			<div className='lesson__questions container'>
				<ol type='1' className='questions__container'>
					{IsLoading && (
						<div class='d-flex justify-content-center'>
							<div class='spinner-grow text-dark' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					{data?.data?.map((quistion, index) => (
						<li className={`question`}>
							<div className='question__container'>
								<div className='topsection'>
									<h6
										className='fs-4'
										dangerouslySetInnerHTML={{
											__html: quistion.question,
										}}></h6>
									<span className='fs-6'>Point {quistion.point} </span>
								</div>

								<div className='answers gap-3'>
									{quistion?.the_options?.map((answer, id) => {
										return (
											<div
												className={`answerItself   ${
													answer.is_true == '1' ? 'rightAnswer' : null
												} }`}>
												<input
													style={{ width: '20px', height: '20px' }}
													type='radio'
													className={` m-1   ${
														answer.is_student_answer && answer.is_true == 0
															? 'hidecheckbox'
															: ''
													}`}
													checked={answer.is_true == '1'}
													value={answer.text}
													name={`${index} `}
												/>
												<label
													className='mx-1 fs-5'
													dangerouslySetInnerHTML={{
														__html: answer.answear,
													}}></label>
												<div className='statusImage'>
													{answer.is_student_answer && 0 == answer.is_true ? (
														<img loading='lazy' src={wrongAnswer} alt='' />
													) : null}
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</li>
					))}

					{IsError && (
						<Error
							errorText={
								IsError?.response?.data?.message
									? IsError?.response?.data?.message
									: IsError.message
							}
						/>
					)}
				</ol>
				<hr />

				<div className='w-100 text-center'>
					{data && (
						<Button
							text={` النتيحة ${
								data?.result === '-1' ? '0' : data?.result + '/100'
							}  `}
							className='examResultBtn'
						/>
					)}

					<h2 className='w-100 my-3 '>ملاحظاتك على الامتحان المؤتمت</h2>

					<div className='my-1' />

					<textarea
						type='text'
						className='form-control mx-auto'
						style={{ maxWidth: '800px', margin: '10px auto !important' }}
						onChange={(e) => handleTeacherNotic(e)}
						placeholder={'يمكنك كتابة ملاحظاتك هنا وارسالها للطالب'}
						value={teacherNotes}
						// defaultValue={data?.teacher_notes == null ? '' : data?.teacher_notes}
					/>

					{teacherNotes != '' &&
						(isPostingNotes ? (
							<div className='w-100 my-2'>
								<div class='spinner-border' role='status'>
									<span class='visually-hidden'>Loading...</span>
								</div>
							</div>
						) : (
							<button
								className='btn  mt-3 btn1'
								disabled={teacherNotes == '' ? true : false}
								onClick={() => postTeacherNotes()}>
								{data?.teacher_notes ? 'تعديل ملحوظاتك' : 'إرسال الملاحظات'}
							</button>
						))}
				</div>
			</div>
		</div>
	);
}
