import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function RequireAuthLogic() {
    // const user1 = useSelector(state=>Number(state.SIGN.role_id))
    const user = localStorage.getItem('role')
    console.log("user",user);
    const location = useLocation();
    return {
        location ,user
  }
}
