import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
export const getCategories = createAsyncThunk(
    'categories/getAllCategories', 
    async(_,thunkApi)=>{
    try{
         const res = await fetch (`${process.env.REACT_APP_API_URL}/categories`);
         const data = await res.json();
         if(res.ok){
             console.log("YES");
             console.log("data",data);
             return data;
         }
         else {
             console.log("NO",data);
             return thunkApi.rejectWithValue(data);
         }
    }
    catch(error){
        console.log("error",error);
        return thunkApi.rejectWithValue(error.message)
    }
})



const initialState={
    post:[],
    loading:false,
    error:null,
}

export const getCategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers:{
    [getCategories.pending]: (state,action)=>{
        state.loading =true;
        state.error=null;
    },
    [getCategories.fulfilled]: (state,action)=>{
        state.loading = false;
        console.log("fulfilled",action.payload);
        state.post = action.payload;
    },
    [getCategories.rejected]:  (state,action)=>{
        console.log("rejected_categories",action);
        state.loading=false;
        state.error=action.payload;
    },
  }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default getCategoriesSlice.reducer