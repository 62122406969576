import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { GetData } from '../../../../customhook/getDatawithAuth'
import { PostData } from '../../../../customhook/postJsonData';

export default function Student_Details_Logic() {

    let navigate = useNavigate();


    const { StudentId} = useParams();
  
    const {getDatafrom:  Get_student_exit   ,IsLoading:loading_student_exit,IsError:IsError_student_exit,data:data_student_exit     }    =      GetData(`/get_student_exit_visas_by_student_id?student_id=${StudentId}`);
   
    const {getDatafrom: Get_student_profile ,IsLoading:loading_student_profile,IsError:IsError_student_profile,data:data_student_profile     }  =    GetData(`/get_student_profile_by_id/${StudentId}`);

    const { PostData:postvisa, isSuccess:postvisasuccess,IsLoading:postvisaloading,IsError:postvisaError } = PostData(`/give_student_an_exit_visa_from_a_unit_by_student_id_and_subject_id`);

    console.log(postvisasuccess);



useEffect(()=>{
    Get_student_exit();
    Get_student_profile();
},[])




const  handelpostvisa = (formdata)=>{

postvisa({formdata})
}

useEffect(()=>{
if(postvisasuccess)
{
  navigate('/options/visas')
}
},[postvisasuccess]);

   return {
    loading_student_exit,
    IsError_student_exit,
    data_student_exit,
    loading_student_profile,
    IsError_student_profile,
    data_student_profile ,
    handelpostvisa,
    postvisaloading,
    postvisaError
};
}
