import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { GetData } from '../../customhook/getDatawithAuth';
import Layout from '../Layout/Layout';
import './index.css';

const TeacherBalance = () => {
    const [data,setData]  = useState(null);
    // const { data , IsLoading } =  GetData('/get_teacher_balance');

    React.useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get_teacher_balance`,{headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('teacher balance' , data.data);
            setData(data.data.data);
        })
    },[]);

    return (
        <div class="containerBalance">
  {/* <div class="drop" style={{color: "#ff0f5b"}}>
    <div class="content">
      <h2>01</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, vel.</p>
      <a href="#">Read More</a>
    </div>
  </div> */}
  {/* <div class="drop" style={{color: '#be01fe'}}>
    <div class="content">
      <h2>02</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, vel.</p>
      <a href="#">Read More</a>
    </div>
  </div> */}
  <div class="drop" style={{color: "#01b4ff"}}>
    <div class="content">
      <p>الرصيد الكلي</p>
      <h2>{data?.balance}</h2>
      {/* <a href="#">Read More</a> */}
    </div>
  </div>
</div>
    );
};

export default TeacherBalance;