import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useUploadForm } from '../../customhook/Uploadfiles';
let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png','image/bmp'];
export default function Add_Traditiona_Quiz_Quistions_Files_Logic() {
  let navigate = useNavigate();

   const [Quistions,setquistions] = useState([])
  const {QuizId}= useParams();

   const { isSuccess:Add_Traditiona_Quiz_Quistions_Files_success,
    IsLoading :Add_Traditiona_Quiz_Quistions_Files_loading,
     uploadForm:upload_Add_Traditiona_Quiz_Quistions_Files,
      progress:progress_Add_Traditiona_Quiz_Quistions_Files,
     IsError:IsError_Add_Traditiona_Quiz_Quistions_Files ,
     data:data_Add_Traditiona_Quiz_Quistions_Files_ } = useUploadForm('/add_traditional_quiz_questions_files');

     const Add_Traditiona_Quiz_Quistions_Files_Event = async () => {
      const Add_Traditiona_Quiz_Quistions_Files_Form = new FormData();
  
      Add_Traditiona_Quiz_Quistions_Files_Form.append('traditional_quiz_id',QuizId);

      Quistions.forEach((element,i) => {
        Add_Traditiona_Quiz_Quistions_Files_Form.append(`questions_files[${i}]`,element);
      });
       await   upload_Add_Traditiona_Quiz_Quistions_Files(Add_Traditiona_Quiz_Quistions_Files_Form)
     };



     useEffect(()=>{
 if (Add_Traditiona_Quiz_Quistions_Files_success)
 {
   setTimeout(() => {
    navigate(-1);
   }, 4000);  
 }
     },[Add_Traditiona_Quiz_Quistions_Files_success])



    const handleQuistions= (e)=>{
        let newQuistions = []
      for (var i = 0; i <= e.target.files.length - 1; i++) {
       
      if( e.target.files.item(i).size/(1024*1024)<4 && ((Quistions.length+newQuistions.length) < 8 ) && allowedExtension.indexOf(e.target.files.item(i).type)>-1)
      newQuistions.push(e.target.files.item(i));
      }    
      setquistions(prev=>[...prev,...newQuistions]);
      }

  return {
    Quistions,
    handleQuistions,
    Add_Traditiona_Quiz_Quistions_Files_success,
upload_Add_Traditiona_Quiz_Quistions_Files,
progress_Add_Traditiona_Quiz_Quistions_Files,
IsError_Add_Traditiona_Quiz_Quistions_Files,
Add_Traditiona_Quiz_Quistions_Files_Event,
Add_Traditiona_Quiz_Quistions_Files_loading
  }
}
