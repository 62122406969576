
import React , {useEffect , useState} from 'react'
import { useLocation } from 'react-router-dom'

const ProfessorsabbreviationLogic = () => {
  const {state} = useLocation()
  console.log("location",state)
  const id = state?.id
    const [Professor,setProfessor] = useState()
    useEffect(() => {
        async function getData() {
          
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_teacher_info_by_id_with_his_subject?teacher_id=${id}`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":`Bearer ${localStorage.getItem('token')}`
            }
          }
          ).then(response => response.json());
          setProfessor(actualData?.data);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa professorsssLogic",actualData?.data) 
        }
        getData()
      }, [])
  return {
    Professor
  }
}

export default ProfessorsabbreviationLogic