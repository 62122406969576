import { FieldTimeOutlined } from '@ant-design/icons';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import MarkAsReadBtn from '../MarkAsReadBtn/MarkAsReadBtn';
import './Notification.css';

const Notification = ({
	linkto,
	message,
	isRead,
	notificatoin_id,
	image,
	id,
	timestamp,
	refetch,
}) => {
	const handleSetNotificationAsReaded = (notificationId) => {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/mark_a_notification_as_readed/${notificationId}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((data) => console.log(data))
			.catch((error) => console.log(error));
	};

	return (
		<Link
			to={linkto}
			className='notification_itself notify'
			style={{
				background: isRead == 1 ? 'rgba(255, 255, 255, 0.863)' : '#235C7033',
			}}
			onClick={() => handleSetNotificationAsReaded(notificatoin_id)}>
			<div className='image_markasreas_container'>
				<img
					src={process.env.REACT_APP_STORAGE_URL + image}
					className='notification_image'
				/>
				{/* <MarkAsReadBtn
					isRead={isRead}
					notificatoin_id={notificatoin_id}
					className='mt-0'
					refetch={refetch}
				/> */}
			</div>
			{/* {message} */}
			<div className='notify_content min-width-350'>
				<p className='text-end mb-1'> {message} </p>
				<div className='notify_time'>
					<FieldTimeOutlined />
					{timestamp}
				</div>
			</div>
		</Link>
	);
};

export default Notification;
