import React from 'react'
import SideBarTeacher from '../SideBar/SideBarTeacher'

const LayoutSideTeacher = (props) => {
  return (
    <>
         <div className='sideBar__relative'>
            <SideBarTeacher />
        </div>
        <div className='d-flex Profile'>
          <div className='d-flex side__left__content'>
            {/* <div className='d-flex side__left text-center'>
                    <h4>{props.desc}</h4>
                    {props.date && <h5>{props.date}</h5>}       
            </div> */}
                {props.children}
            </div>
        </div>
    </>
  )
}

export default LayoutSideTeacher