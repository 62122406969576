import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../attachment/index.css';
// import img from '../../assets/icons/pdf.png';

function Attachment({
	title,
	btn1Text,
	btn2Text,
	btn1function,
	btn2function,
	link,
	ladder_file,
	fileId,
	setDeleteFileId,
	setEditFileId,
	img,
}) {
	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};
	// const navigate = useNavigate();

	return (
		<div className='attachment'>
			<img className='my-2' loading='lazy' src={img} alt='' />
			<div className='content'>
				<div className='fs-4 fw-bold my-1'>{title}</div>
				<div className='buttonsContainer'>
					<button
						className='downloadBtn btn1 mx-2'
						data-bs-toggle='modal'
						data-bs-target='#EditAttatchmentModal'
						onClick={() => setEditFileId(fileId)}>
						{btn1Text}
					</button>
					<button
						className='openBtn btn2'
						data-bs-toggle='modal'
						data-bs-target='#deleteAttatchmentModal'
						onClick={() => setDeleteFileId(fileId)}>
						{btn2Text}
						{/* {btn2Text == "حذف" ? (
              <Link to="/audioplayer">{btn2Text}</Link>
            ) : (
              <a onClick={() => openInNewTab(ladder_file)}>{btn2Text}</a>
            )} */}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Attachment;
