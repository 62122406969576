import axios from "axios";

export default async function fetchSubSections({ queryKey }) {
   console.log('subsectionId', queryKey[1].SectionId.id);
  //  console.log('query page key', queryKey[1].page);
  console.log("data inside fetch", queryKey[1]);
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/get_sub_sections_by_section_id/${queryKey[1].SectionId.id}`,
    {
      params: { limit: 10, local: "ar" },
    }
  );

  return data;
}
