import React, { useState } from 'react';
import Button from '../../components/button';

import './ProfileStudentGuardian.css';
import Layout from '../Layout/Layout';
import ProfileStudentGuardianLogic from './ProfileStudentGuardianLogic';
import Error from '../../components/alerts/Error/Error';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProfileStudentGuardian = () => {
	const role = localStorage.getItem('role');
	const role2 = localStorage.getItem('role2');
	console.log('role', role);
	const [editPassword, setEditPassword] = useState(false);
	const {
		// editPassword,
		// setEditPassword,
		// studentDetailsIsLoading,
		// studentDetailsError,
		studentDetails,
	} = ProfileStudentGuardianLogic();
	console.log('studentDetails', studentDetails);

	const [oldPassword, setOldPassword] = useState();
	const [newPassword, setNewPassword] = useState();
	const [confirmNewPassword, setConfirmNewPassword] = useState();

	// console.log("studentDetails" , studentDetails?.data[0].name);

	const handleSavePassord = () => {
		let formData = new FormData();
		formData.append('old_password', oldPassword);
		formData.append('new_password', newPassword);

		const res = axios
			.post(
				`${process.env.REACT_APP_API_URL}/update_guardian_password`,
				formData,
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					setEditPassword(false);
					alert('تم تغيير كلمة السر');
					return res;
				} else {
					console.log(res);
					alert('يوجد خطا في النت');
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				alert(error.response.data.message);
				console.log(error.response);
			});
	};

	const navigate = useNavigate();
	const hanldeRegister = (id) => {
		const data = {
			id: id,
		};

		const res = axios
			.post(`${process.env.REACT_APP_API_URL}/login_with_student_id`, data, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;

					console.log('dataTokennnn', data);
					setEditPassword(false);
					alert(' تم تسجيل الدخول كطالب ');
					localStorage.setItem('token2', localStorage.getItem('token'));
					localStorage.setItem('role2', localStorage.getItem('role'));
					localStorage.setItem('token', data?.data?.token);
					localStorage.setItem('role', 1);
					navigate('/profileStudent');
					return res;
				} else {
					console.log(res);
					alert('يوجد خطا في النت');
					throw new Error('there is an error');
				}
			})
			.catch((error) => {
				alert(error.response.data.message);
				console.log(error.response);
			});
	};

	return (
		<Layout>
			<div className='profileScreen'>
				<div className='profileScreen__content'>
					<div className='topcontent'>
						<div className='userImage'>
							<img
								src={`${process.env.REACT_APP_STORAGE_URL}${studentDetails?.data[0]?.student_image}`}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>اسم المستخدم</h3>
					</div>

					{/* {studentDetailsError && <Error errorText={studentDetailsError} />} */}

					{studentDetails?.data &&
						studentDetails?.data &&
						studentDetails?.data?.map((item) => {
							return (
								<div className={`bottomContent`}>
									<div className='leftContent'>
										<ul>
											<li>
												<div>الاسم</div>
												<div>{item?.student_name}</div>
											</li>
											<li>
												<div>اسم الاب</div>
												<div>{item?.student_father_name}</div>
											</li>
											<li>
												<div>اسم الام</div>
												<div>{item?.student_mother_name}</div>
											</li>

											<li>
												<div>العنوان</div>
												<div>{item?.student_address}</div>
											</li>
											<li>
												<div>المحافظة</div>
												<div>{item?.student_city}</div>
											</li>
										</ul>
										<div className='title'>المعلومات الشخصية</div>
									</div>

									<div className='rightContent '>
										<ul>
											<li>
												<div>الايميل</div>
												<div>{item?.student_email}</div>
											</li>
										</ul>
										<div className='title'>معلومات الحساب</div>
									</div>

									{(role == 3 || role2 == 3) && (
										<div>
											<Button
												text={'تسجيل الدخول كطالب'}
												className='editPasswordBtn'
												onclick={() => hanldeRegister(item?.student_id)}
											/>
										</div>
									)}
								</div>
							);
						})}

					{/* { role == 1 && (
						<Button
							text={'تعديل كلمة السر'}
							className='editPasswordBtn'
							onclick={() => setEditPassword(!editPassword)}
						/>
					)} */}
				</div>
			</div>
		</Layout>
	);
};

export default ProfileStudentGuardian;
