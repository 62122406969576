import moment from "moment";
import monment from "moment";

const filter = (time_start, time_end) => () => ({ time_start, time_end });

export const dayFilter = filter(
  monment().subtract(1, "day").format("YYYY-MM-DD"),
  monment().format("YYYY-MM-DD")
);

export const monthFilter = filter(
  monment().subtract(1, "month").format("YYYY-MM-DD"),
  monment().format("YYYY-MM-DD")
);

export const weekFilter = filter(
  monment().subtract(7, "days").format("YYYY-MM-DD"),
  monment().format("YYYY-MM-DD")
);

export const yearFilter = filter(
  monment().subtract(1, "year").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD")
);
