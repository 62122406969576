import React from 'react';
import InstagramImage from '../../assets/profileStudent/save (1).png';
import Layout from '../Layout/Layout';
// import SideBar from '../SideBar/SideBar';
import Brain from '../../assets/profileStudent/Group 344.png';
import Elearning from '../../assets/profileStudent/Group 346.png';

import Token from '../../assets/profileStudent/Group 345.png';

import User from '../../assets/profileStudent/Group 347.png';

import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';
import './profileStudent.css';
import { Link } from 'react-router-dom';
import Zoom from 'react-reveal/Zoom';
import MainHeader from '../../components/MainHeader/MainHeader';
import { useEffect } from 'react';

const ProfileStudent = () => {
	console.log('profile student');

	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={''}>
				<div className='container profile_student'>
					<div className='row justify-content-center'>
						<div className='col-lg-4 col-md-4 col-sm-8 col-xs-8 col-9'>
							<Zoom top>
								<div className='  section_card shadow  studentProfile_card my-5 '>
									<div className='  row     justify-content-center '>
										<Link
											to='/studentprofile'
											className='text-center '
											style={{ marginTop: '70px' }}>
											<img
												src={User}
												style={{
													width: '50%',
													height: 'auto',
													margin: ' auto',
												}}
												className='card-img-top'
												alt='...'
											/>

											<h5
												style={{ color: '#EF0000' }}
												className='card-title  fw-bold mt-5'>
												البروفايل
											</h5>
										</Link>
									</div>
								</div>
							</Zoom>
							<Zoom bottom>
								<div className=' section_card shadow studentProfile_card my-5 '>
									<div className='  row     justify-content-center '>
										<Link
											to='/points'
											className='text-center '
											style={{ marginTop: '70px' }}>
											<img
												src={Token}
												style={{
													width: '50%',
													height: 'auto',
													margin: '0px auto',
												}}
												className='card-img-top'
												alt='...'
											/>{' '}
											<h5
												style={{ color: '#EF0000' }}
												className='card-title  fw-bold mt-5'>
												النقاط
											</h5>
										</Link>
									</div>
								</div>
							</Zoom>
						</div>

						<div
							className='col-lg-4 col-md-4 col-sm-8 col-xs-8 col-9'
							style={{
								alignContent: 'center',
								alignItems: 'center',
								marginTop: '20%',
							}}>
							<Zoom>
								<div className=' section_card shadow studentProfile_card my-5 '>
									<div className='  row     justify-content-center'>
										<Link
											to='/studentSubjects'
											className='text-center '
											style={{ marginTop: '70px' }}>
											<img
												src={InstagramImage}
												style={{
													width: '50%',
													height: 'auto',
													margin: '0px auto',
												}}
												className='card-img-top'
												alt='...'
											/>
											<h5
												style={{ color: '#EF0000' }}
												className='card-title  fw-bold mt-5'>
												المحفوظات
											</h5>{' '}
										</Link>
									</div>
								</div>
							</Zoom>
						</div>
						<div className='col-lg-4 col-md-4 col-sm-8 col-xs-10 col-9'>
							<Zoom top>
								<div className=' section_card shadow studentProfile_card my-5 '>
									<div className='  row     justify-content-center '>
										<Link
											to='/studentsubject'
											className='text-center '
											style={{ marginTop: '70px' }}>
											<img
												src={Elearning}
												style={{
													width: '50%',
													height: 'auto',
													margin: '0px auto',
												}}
												className='card-img-top'
												alt='...'
											/>

											<h5
												style={{ color: '#EF0000' }}
												className='card-title  fw-bold mt-5'>
												الكورسات
											</h5>
										</Link>
									</div>
								</div>
							</Zoom>
							<Zoom bottom>
								<div className=' section_card shadow studentProfile_card my-5'>
									<div className='  row     justify-content-center '>
										<Link
											to='/myquizes'
											className='text-center '
											style={{ marginTop: '70px' }}>
											<img
												src={Brain}
												style={{
													width: '50%',
													height: 'auto',
													margin: '0px auto',
												}}
												className='card-img-top'
												alt='...'
											/>
											<h5
												style={{ color: '#EF0000' }}
												className='card-title  fw-bold mt-5'>
												الاختبارات
											</h5>
										</Link>
									</div>
								</div>
							</Zoom>
						</div>
					</div>
				</div>
			</LayoutSideStudent>
		</Layout>
	);
};

export default ProfileStudent;
