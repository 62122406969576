import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// create_unit_traditional_quiz

export const create_unit_traditional_quiz = createAsyncThunk(
	'UnitTestSlice/create_unit_traditional_quiz',
	async ({ QuizId, UnitId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz/${QuizId}`,
				{
					method: 'DELETE',
					headers: { Authorization: `Bearer ${SIGN.token}` },
				}
			);
			const data = await res.json();
			if (res.ok) {
				thunkApi.dispatch(
					get_all_final_traditional_quizzes_by_unit_id({ UnitId })
				);
				return data;
			} else {
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const delete_traditional_quiz_by_traditional_quiz_id = createAsyncThunk(
	'UnitTestSlice/delete_traditional_quiz_by_traditional_quiz_id',
	async ({ QuizId, UnitId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz/${QuizId}`,
				{
					method: 'DELETE',
					headers: { Authorization: `Bearer ${SIGN.token}` },
				}
			);
			const data = await res.json();
			if (res.ok) {
				alert('تمت علمية الحذف بنجاح');
				thunkApi.dispatch(
					get_all_final_traditional_quizzes_by_unit_id({ UnitId })
				);
				return data;
			} else {
				alert('حدث خطأ ما .. يرجى المحاولة مرة اخرى');
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			alert('حدث خطأ ما .. يرجى المحاولة مرة اخرى');
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const get_all_above_level_traditional_quizzes_by_unit_id =
	createAsyncThunk(
		'UnitTestSlice/get_all_above_level_traditional_quizzes_by_unit_id',
		async ({ UnitId }, thunkApi) => {
			const { SIGN } = thunkApi.getState();
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/get_all_above_level_traditional_quizzes_by_unit_id?unit_id=${UnitId}`,
					{ headers: { Authorization: `Bearer ${SIGN.token}` } }
				);
				const isJson = response.headers
					.get('content-type')
					?.includes('application/json');
				const data = isJson ? await response.json() : null;
				if (response.status === 200) {
					return data;
				} else if (response.status === 204) {
					return thunkApi.rejectWithValue('لا يوجد اختبارات');
				} else {
					return thunkApi.rejectWithValue(data.message);
				}
			} catch (error) {
				return thunkApi.rejectWithValue(error.message);
			}
		}
	);

export const get_all_final_traditional_quizzes_by_unit_id = createAsyncThunk(
	'UnitTestSlice/get_all_final_traditional_quizzes_by_unit_id',
	async ({ UnitId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_all_final_traditional_quizzes_by_unit_id?unit_id=${UnitId}`,
				{ headers: { Authorization: `Bearer ${SIGN.token}` } }
			);

			const data = await res.json();
			if (res.ok) {
				return data;
			} else {
				return thunkApi.rejectWithValue(data.message);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(
				error.message === 'Unexpected end of JSON input'
					? 'لا يوجد أسئلة لعرضها'
					: error.message
			);
		}
	}
);

export const get_traditional_quiz_by_traditional_quiz_id = createAsyncThunk(
	'UnitTestSlice/get_traditional_quiz_by_traditional_quiz_id',
	async (Quizid, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_traditional_quiz_by_quiz_id/${Quizid}`,
				{ headers: { Authorization: `Bearer ${SIGN.token}` } }
			);
			const data = await res.json();

			if (res.ok) {
				return data;
			} else {
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const get_traditional_quiz_questions_files_by_id = createAsyncThunk(
	'UnitTestSlice/get_traditional_quiz_questions_files_by_id',
	async ({ QuizId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_traditional_quiz_questions_files_by_id/${QuizId}`,
				{ headers: { Authorization: `Bearer ${SIGN.token}` } }
			);
			const isJson = res.headers
				.get('content-type')
				?.includes('application/json');
			const data = isJson ? await res.json() : null;
			if (res.ok) {
				return data.data;
			} else {
				return thunkApi.rejectWithValue(
					data?.message ? data.message : ` رقم الخطأ ${res.status} `
				);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const delete_traditional_quiz_question_file_by_id = createAsyncThunk(
	'UnitTestSlice/delete_traditional_quiz_question_file_by_id',
	async ({ QuizId, file_id }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz_question_file_by_id/${file_id}`,
				{
					method: 'DELETE',
					headers: { Authorization: `Bearer ${SIGN.token}` },
				}
			);
			const data = await res.json();
			if (res.ok) {
				alert('تمت عملية الحذف بنجاح');
				thunkApi.dispatch(
					get_traditional_quiz_questions_files_by_id({ QuizId })
				);
				return data;
			} else {
				alert('عذرا حدث خطأ ما .. يرجى المحاولة مرة اخرى');
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

//automated unit quiz

export const delete_automated_quiz_by_automated_quiz = createAsyncThunk(
	'UnitTestSlice/delete_automated_quiz_by_automated_quiz',
	async (
		{
			QuizId,
			// , refetch
		},
		thunkApi
	) => {
		console.log(QuizId);
		const { SIGN } = thunkApi.getState();

		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/delete_automated_quiz/${QuizId}`,
				{
					method: 'DELETE',
					headers: { Authorization: `Bearer ${SIGN.token}` },
				}
			);
			const data = await res.json();
			if (res.ok) {
				// refetch();
				console.log(data);
				alert('تمت عملية الحذف بنجاح');
				return data;
			} else {
				alert('حدث خطأ ما يرجى المحاولة لاحقا');
				console.log(data);
				return thunkApi.rejectWithValue(data.message);
			}
		} catch (error) {
			alert('حدث خطأ ما يرجى المحاولة لاحقا');
			console.log(error);

			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const get_all_above_level_automated_quiz_by_unit_id = createAsyncThunk(
	'UnitTestSlice/get_all_above_level_automated_quiz_by_unit_id',
	async ({ UnitId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_all_above_level_automated_quiz_by_unit_id?unit_id=${UnitId}`,
				{ headers: { Authorization: `Bearer ${SIGN.token}` } }
			);

			const isJson = res.headers
				.get('content-type')
				?.includes('application/json');
			const data = isJson ? await res.json() : null;
			if (res.ok) {
				return data;
			} else {
				return thunkApi.rejectWithValue(
					data?.message ? data.message : ` رقم الخطأ ${res.status} `
				);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const get_all_automated_quiz_questions_with_options_by_automated_quiz =
	createAsyncThunk(
		'UnitTestSlice/get_all_automated_quiz_questions_with_options_by_automated_quiz',
		async ({ QuizId }, thunkApi) => {
			const { SIGN } = thunkApi.getState();
			try {
				const res = await fetch(
					`${process.env.REACT_APP_API_URL}/get_all_automated_quiz_questions_with_options_by_automated_quiz/${QuizId}`,
					{ headers: { Authorization: `Bearer ${SIGN.token}` } }
				);

				const isJson = res.headers
					.get('content-type')
					?.includes('application/json');
				const data = isJson ? await res.json() : null;
				if (res.status === 200) {
					return data;
				} else if (res.status === 204) {
					return thunkApi.rejectWithValue('لا يوجد أسئلة لعرضها');
				} else {
					return thunkApi.rejectWithValue(data.message);
				}
			} catch (error) {
				return thunkApi.rejectWithValue(error.message);
			}
		}
	);

export const add_automated_quiz_question_with_its_options = createAsyncThunk(
	'UnitTestSlice/add_automated_quiz_question_with_its_options',
	async ({ formdata, reset }, thunkApi) => {
		console.log(formdata);
		const { SIGN } = thunkApi.getState();
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/add_automated_quiz_question_with_its_options`,
				{
					method: 'POST',
					headers: {
						Authorization: `Bearer ${SIGN.token}`,
						'Content-type': 'application/json; charset=UTF-8',
						Accept: 'application/json',
					},
					body: JSON.stringify({ ...formdata }),
				}
			);
			const isJson = res.headers
				.get('content-type')
				?.includes('application/json');
			const data = isJson ? await res.json() : null;
			if (res.ok) {
				reset();

				alert('تمت علمية اضافة السؤال بنجاح');

				setTimeout(() => {
					thunkApi.dispatch(
						Reset_add_automated_quiz_question_with_its_options()
					);
				}, 4000);
				return data;
			} else {
				return thunkApi.rejectWithValue(
					data?.message ? data.message : ` رقم الخطأ ${res.status} `
				);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

export const delete_automated_quiz_question_with_its_options_by_automated_quiz_question =
	createAsyncThunk(
		'UnitTestSlice/add_automated_quiz_question_with_its_options',
		async ({ subQuizId }, thunkApi) => {
			const { SIGN } = thunkApi.getState();
			try {
				const res = await fetch(
					`${process.env.REACT_APP_API_URL}/delete_automated_quiz_question_with_its_options_by_id/${subQuizId}`,
					{
						method: 'Delete',
						headers: {
							Authorization: `Bearer ${SIGN.token}`,
							'Content-type': 'application/json; charset=UTF-8',
						},
					}
				);

				const isJson = res.headers
					.get('content-type')
					?.includes('application/json');
				const data = isJson ? await res.json() : null;
				console.log(res);
				console.log(data);
				if (res.ok) {
					// console.log(res);
					// console.log(data);

					alert('تمت عملية حذف السؤال بنجاح');

					setTimeout(() => {
						thunkApi.dispatch(
							Reset_add_automated_quiz_question_with_its_options()
						);
					}, 2000);
					return data;
				} else {
					console.log(res);
					console.log(data);
					alert('حدث خطأ ما .. الرجاء المحاولة لاحقا');
					return thunkApi.rejectWithValue(
						data?.message ? data.message : ` رقم الخطأ ${res.status} `
					);
				}
			} catch (error) {
				console.log(error);
				alert('حدث خطأ ما .. الرجاء المحاولة لاحقا');
				return thunkApi.rejectWithValue(error.message);
			}
		}
	);

export const edit_automated_quiz_for_unit = createAsyncThunk(
	'UnitTestSlice/edit_automated_quiz_for_unit',
	async ({ formdata, UnitId }, thunkApi) => {
		const { SIGN } = thunkApi.getState();

		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/edit_automated_quiz/${UnitId}`,
				{
					method: 'POST',
					headers: {
						'Content-type': 'application/json; charset=UTF-8',
						Authorization: `Bearer ${SIGN.token}`,
					},
					body: JSON.stringify({ ...formdata }),
				}
			);
			const data = await res.json();
			if (res.ok) {
				setTimeout(() => {
					thunkApi.dispatch(
						Reset_add_automated_quiz_question_with_its_options()
					);
				}, 2000);
				return data;
			} else {
				console.log(data);
				return thunkApi.rejectWithValue(data.message);
			}
		} catch (error) {
			console.log(error.message);

			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	delete_traditional_quiz_by_traditional_quiz_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	get_traditional_quiz_by_traditional_quiz_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	get_traditional_quiz_questions_files_by_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	delete_traditional_quiz_question_file_by_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	get_all_final_traditional_quizzes_by_unit_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	get_all_above_level_traditional_quizzes_by_unit_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},

	//automated Quiz

	get_all_above_level_automated_quiz_by_unit_id: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	delete_automated_quiz_by_automated_quiz: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	add_automated_quiz_question_with_its_options: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	get_all_automated_quiz_questions_with_options_by_automated_quiz: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},

	edit_automated_quiz_for_unit: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
	delete_automated_quiz_question_with_its_options_by_automated_quiz_question: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		data: null,
	},
};

export const UnitTestSlice = createSlice({
	name: 'UnitTestSlice',
	initialState,
	reducers: {
		Reset_add_automated_quiz_question_with_its_options: (state) => {
			state.add_automated_quiz_question_with_its_options.isFetching = false;
			state.add_automated_quiz_question_with_its_options.isSuccess = false;
			state.add_automated_quiz_question_with_its_options.isError = false;
			state.add_automated_quiz_question_with_its_options.errorMessage = null;
			state.add_automated_quiz_question_with_its_options.data = null;
		},
	},
	extraReducers: {
		[delete_traditional_quiz_by_traditional_quiz_id.pending]: (
			state,
			action
		) => {
			state.delete_traditional_quiz_by_traditional_quiz_id.isFetching = true;
			state.delete_traditional_quiz_by_traditional_quiz_id.isError = false;
			state.delete_traditional_quiz_by_traditional_quiz_id.errorMessage = null;
		},
		[delete_traditional_quiz_by_traditional_quiz_id.fulfilled]: (
			state,
			action
		) => {
			state.delete_traditional_quiz_by_traditional_quiz_id.isFetching = false;

			state.delete_traditional_quiz_by_traditional_quiz_id.data =
				action.payload;
		},
		[delete_traditional_quiz_by_traditional_quiz_id.rejected]: (
			state,
			action
		) => {
			state.delete_traditional_quiz_by_traditional_quiz_id.isFetching = false;
			state.delete_traditional_quiz_by_traditional_quiz_id.isError = true;
			state.delete_traditional_quiz_by_traditional_quiz_id.errorMessage =
				action.payload;
		},

		[get_traditional_quiz_by_traditional_quiz_id.pending]: (state, action) => {
			state.get_traditional_quiz_by_traditional_quiz_id.isFetching = true;
			state.get_traditional_quiz_by_traditional_quiz_id.isError = false;
			state.get_traditional_quiz_by_traditional_quiz_id.errorMessage = null;
		},
		[get_traditional_quiz_by_traditional_quiz_id.fulfilled]: (
			state,
			action
		) => {
			state.get_traditional_quiz_by_traditional_quiz_id.isFetching = false;
			state.get_traditional_quiz_by_traditional_quiz_id.data =
				action.payload.data;
			state.get_traditional_quiz_by_traditional_quiz_id.isSuccess = true;
		},
		[get_traditional_quiz_by_traditional_quiz_id.rejected]: (state, action) => {
			state.get_traditional_quiz_by_traditional_quiz_id.isFetching = false;
			state.get_traditional_quiz_by_traditional_quiz_id.isError = true;
			state.get_traditional_quiz_by_traditional_quiz_id.errorMessage =
				action.payload;
		},

		[get_traditional_quiz_questions_files_by_id.pending]: (state, action) => {
			state.get_traditional_quiz_questions_files_by_id.isFetching = true;
			state.get_traditional_quiz_questions_files_by_id.isError = false;
			state.get_traditional_quiz_questions_files_by_id.errorMessage = null;
		},
		[get_traditional_quiz_questions_files_by_id.fulfilled]: (state, action) => {
			state.get_traditional_quiz_questions_files_by_id.isFetching = false;
			state.get_traditional_quiz_questions_files_by_id.data = action.payload;
			state.get_traditional_quiz_questions_files_by_id.isSuccess = true;
		},
		[get_traditional_quiz_questions_files_by_id.rejected]: (state, action) => {
			state.get_traditional_quiz_questions_files_by_id.isFetching = false;
			state.get_traditional_quiz_questions_files_by_id.isError = true;
			state.get_traditional_quiz_questions_files_by_id.errorMessage =
				action.payload;
		},

		[delete_traditional_quiz_question_file_by_id.pending]: (state, action) => {
			state.delete_traditional_quiz_question_file_by_id.isFetching = true;
			state.delete_traditional_quiz_question_file_by_id.isError = false;
			state.delete_traditional_quiz_question_file_by_id.errorMessage = null;
		},
		[delete_traditional_quiz_question_file_by_id.fulfilled]: (
			state,
			action
		) => {
			state.delete_traditional_quiz_question_file_by_id.isFetching = false;
			state.delete_traditional_quiz_question_file_by_id.data =
				action.payload.data;
			state.delete_traditional_quiz_question_file_by_id.isSuccess = true;
		},
		[delete_traditional_quiz_question_file_by_id.rejected]: (state, action) => {
			state.delete_traditional_quiz_question_file_by_id.isFetching = false;
			state.delete_traditional_quiz_question_file_by_id.isError = true;
			state.delete_traditional_quiz_question_file_by_id.errorMessage =
				action.payload;
		},

		[get_all_final_traditional_quizzes_by_unit_id.pending]: (state, action) => {
			state.get_all_final_traditional_quizzes_by_unit_id.isFetching = true;
			state.get_all_final_traditional_quizzes_by_unit_id.isError = false;
			state.get_all_final_traditional_quizzes_by_unit_id.errorMessage = null;
		},
		[get_all_final_traditional_quizzes_by_unit_id.fulfilled]: (
			state,
			action
		) => {
			state.get_all_final_traditional_quizzes_by_unit_id.isFetching = false;
			state.get_all_final_traditional_quizzes_by_unit_id.data =
				action.payload.data;
			state.get_all_final_traditional_quizzes_by_unit_id.isSuccess = true;
		},
		[get_all_final_traditional_quizzes_by_unit_id.rejected]: (
			state,
			action
		) => {
			state.get_all_final_traditional_quizzes_by_unit_id.isFetching = false;
			state.get_all_final_traditional_quizzes_by_unit_id.isError = true;
			state.get_all_final_traditional_quizzes_by_unit_id.errorMessage =
				action.payload;
		},

		[get_all_above_level_traditional_quizzes_by_unit_id.pending]: (
			state,
			action
		) => {
			state.get_all_above_level_traditional_quizzes_by_unit_id.isFetching = true;
			state.get_all_above_level_traditional_quizzes_by_unit_id.isError = false;
			state.get_all_above_level_traditional_quizzes_by_unit_id.errorMessage =
				null;
		},
		[get_all_above_level_traditional_quizzes_by_unit_id.fulfilled]: (
			state,
			action
		) => {
			state.get_all_above_level_traditional_quizzes_by_unit_id.isFetching = false;
			state.get_all_above_level_traditional_quizzes_by_unit_id.data =
				action.payload.data;
			state.get_all_above_level_traditional_quizzes_by_unit_id.isSuccess = true;
		},
		[get_all_above_level_traditional_quizzes_by_unit_id.rejected]: (
			state,
			action
		) => {
			state.get_all_above_level_traditional_quizzes_by_unit_id.isFetching = false;
			state.get_all_above_level_traditional_quizzes_by_unit_id.isError = true;
			state.get_all_above_level_traditional_quizzes_by_unit_id.errorMessage =
				action.payload;
			state.get_all_above_level_traditional_quizzes_by_unit_id.data = null;
		},

		//automated Quiz

		[get_all_above_level_automated_quiz_by_unit_id.pending]: (
			state,
			action
		) => {
			state.get_all_above_level_automated_quiz_by_unit_id.isFetching = true;
			state.get_all_above_level_automated_quiz_by_unit_id.isError = false;
			state.get_all_above_level_automated_quiz_by_unit_id.errorMessage = null;
		},
		[get_all_above_level_automated_quiz_by_unit_id.fulfilled]: (
			state,
			action
		) => {
			state.get_all_above_level_automated_quiz_by_unit_id.isFetching = false;
			state.get_all_above_level_automated_quiz_by_unit_id.data =
				action.payload.data;
			state.get_all_above_level_automated_quiz_by_unit_id.isSuccess = true;
		},
		[get_all_above_level_automated_quiz_by_unit_id.rejected]: (
			state,
			action
		) => {
			state.get_all_above_level_automated_quiz_by_unit_id.isFetching = false;
			state.get_all_above_level_automated_quiz_by_unit_id.isError = true;
			state.get_all_above_level_automated_quiz_by_unit_id.errorMessage =
				action.payload;
		},

		[add_automated_quiz_question_with_its_options.pending]: (state, action) => {
			state.add_automated_quiz_question_with_its_options.isFetching = true;
			state.add_automated_quiz_question_with_its_options.isError = false;
			state.add_automated_quiz_question_with_its_options.errorMessage = null;
		},
		[add_automated_quiz_question_with_its_options.fulfilled]: (
			state,
			action
		) => {
			state.add_automated_quiz_question_with_its_options.isFetching = false;
			state.add_automated_quiz_question_with_its_options.data =
				action.payload.data;
			state.add_automated_quiz_question_with_its_options.isSuccess = true;
		},
		[add_automated_quiz_question_with_its_options.rejected]: (
			state,
			action
		) => {
			state.add_automated_quiz_question_with_its_options.isFetching = false;
			state.add_automated_quiz_question_with_its_options.isError = true;
			state.add_automated_quiz_question_with_its_options.errorMessage =
				action.payload;
		},

		[get_all_automated_quiz_questions_with_options_by_automated_quiz.pending]: (
			state,
			action
		) => {
			state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isFetching = true;
			state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isError = false;
			state.get_all_automated_quiz_questions_with_options_by_automated_quiz.errorMessage =
				null;
		},
		[get_all_automated_quiz_questions_with_options_by_automated_quiz.fulfilled]:
			(state, action) => {
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isFetching = false;
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.data =
					action.payload.data;
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isSuccess = true;
			},
		[get_all_automated_quiz_questions_with_options_by_automated_quiz.rejected]:
			(state, action) => {
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isFetching = false;
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.isError = true;
				state.get_all_automated_quiz_questions_with_options_by_automated_quiz.errorMessage =
					action.payload;
			},

		[edit_automated_quiz_for_unit.pending]: (state, action) => {
			state.edit_automated_quiz_for_unit.isFetching = true;
			state.edit_automated_quiz_for_unit.isError = false;
			state.edit_automated_quiz_for_unit.errorMessage = null;
		},
		[edit_automated_quiz_for_unit.fulfilled]: (state, action) => {
			state.edit_automated_quiz_for_unit.isFetching = false;
			state.edit_automated_quiz_for_unit.data = action.payload.data;
			state.edit_automated_quiz_for_unit.isSuccess = true;
		},
		[edit_automated_quiz_for_unit.rejected]: (state, action) => {
			state.edit_automated_quiz_for_unit.isFetching = false;
			state.edit_automated_quiz_for_unit.isError = true;
			state.edit_automated_quiz_for_unit.errorMessage = action.payload;
		},

		[delete_automated_quiz_question_with_its_options_by_automated_quiz_question.pending]:
			(state, action) => {
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isFetching = true;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isError = false;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.errorMessage =
					null;
			},
		[delete_automated_quiz_question_with_its_options_by_automated_quiz_question.fulfilled]:
			(state, action) => {
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isFetching = false;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.data =
					action.payload.data;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isSuccess = true;
			},
		[delete_automated_quiz_question_with_its_options_by_automated_quiz_question.rejected]:
			(state, action) => {
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isFetching = false;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.isError = true;
				state.delete_automated_quiz_question_with_its_options_by_automated_quiz_question.errorMessage =
					action.payload;
			},
	},
});

export const { Reset_add_automated_quiz_question_with_its_options } =
	UnitTestSlice.actions;
export default UnitTestSlice.reducer;
