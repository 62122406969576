import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetData } from '../../../../customhook/getDatawithAuth';

export default function ShowLessonAnswearLogic() {
	const { QuizId } = useParams();

	// const data = useSelector(state => state.lessonTest.get_automated_quiz_questions_by_automated_quiz_and_student_id);

	const { getDatafrom, progress, IsLoading, IsError, data } = GetData(
		`/get_student_automated_quiz_questions_with_answers_by_automated_quiz_id?autometed_quiz_id=${QuizId}`
	);

	useEffect(() => {
		getDatafrom();
	}, []);

	return {
		IsLoading,
		IsError,
		data,
	};
}
