import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UnitCard from '../../components/UnitCard';
import Layout from '../Layout/Layout';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';
import { useLocation, useParams, useRoutes } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
function UnitsPage() {
	// *****************************************************
	const [units, setunits] = useState([]);
	const token = localStorage.getItem('token');
	const [loading, setloading] = useState(true);
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	const subject_id = urlParams.get('id');
	const { state } = useLocation();
	console.log('dddddddddddddddddd');
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_units_by_subject_id`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					subject_id: subject_id,
					limit: 10,
				},
			})
			.then((response) => {
				setloading(false);
				console.log(response.data.data);
				setunits(response.data.data);
			})
			.catch((error) => {
				setloading(false);
				console.log(error);
			});
	}, [token]);

	// *****************************************************

	return (
		<Layout>
			{/* <MainHeader /> */}
			{/* <LayoutSideStudent desc={'  الوحدات'}> */}
			<div className='container text-center my-5'>
				<h3 style={{ color: '#044364' }} className='fw-bold my-4'>
					{state.name}
				</h3>

				{loading ? (
					<div class='spinner-border mt-5' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				) : (
					<div
						className='row my-5 sections '
						style={{ justifyContent: 'center' }}>
						{units &&
							units.map((unit) => (
								<UnitCard unit={unit} subject_id={subject_id} />
							))}
					</div>
				)}
			</div>
			{/* </LayoutSideStudent> */}
		</Layout>
	);
}

export default UnitsPage;
