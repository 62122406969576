import React, { useEffect } from 'react';
import './index.css';

import Layout from '../Layout/Layout';
import 'react-audio-player-pro/dist/style.css';
import { AudioPlayerControlSprite, Audio } from 'react-audio-player-pro';
import { useParams } from 'react-router-dom';

const AudioPlayerScreen = (props) => {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	const file = localStorage.getItem('audio');
	console.log('mp3 => ', file);
	return (
		<Layout>
			<div className='audioplayerScreen'>
				<AudioPlayerControlSprite />
				<Audio
					// string - path to audio file, required
					src={`${process.env.REACT_APP_STORAGE_URL}${file}`}
					// MediaMetadata - media meta data
					// https://developer.mozilla.org/en-US/docs/Web/API/MediaMetadata/MediaMetadata
					// optional
					mediaMetadata={{
						// required
						title: 'Pure Water',

						// optional
						artist: 'Meydän',

						// optional
						album: 'Interplanetary Forest',

						// optional
						artwork: [
							// src, sizes and type is required
							{
								src: '../assets/facebook.png',
								sizes: '64x64',
								type: 'image/png',
							},
							{
								src: '../assets/facebook.png',
								sizes: '128x128',
								type: 'image/png',
							},
						],
					}}
					// string - wrapper's class name, optional, deafult: ''
					className='my-class-name'
					// callback function - called on did mount, optional, default: noop
					onDidMount={console.log('what the hack is this')}
					// string - name for download file, optional, deafult: <src>
					// downloadFileName={'../../assets2/clock_sound.mp3'}
					// downloadFileName
					// boolean - show repeat button, optional, deafult: false
					useRepeatButton={true}
				/>
			</div>
		</Layout>
	);
};

export default AudioPlayerScreen;
