import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import FooterLogic from './FooterLogic';

import facebook from '../../assets/footer/facebook.png';
import youtube from '../../assets/footer/youtube.png';
import whatsapp from '../../assets/footer/whatsapp.png';
import instagram from '../../assets/footer/instagram.png';
import logo from '../../assets/logo/logo.png';

import ICR from '../../assets/icr/icr.png';
import imag1 from '../../assets/footer/Group 33.png';
import imag2 from '../../assets/footer/freepik--Character--inject-3.png';
import imag3 from '../../assets/footer/freepik--speech-bubble--inject-3.png';

export default function Footer() {
	const getClickableLink = (link) => {
		return link.startsWith('http://') || link.startsWith('https://')
			? link
			: `http://${link}`;
	};
	const { data, socialInfo } = FooterLogic();

	console.log('data', data);

	const [numbers, setNumber] = useState();

	useEffect(() => {
		async function getData() {
			const actualData = await fetch(
				`${process.env.REACT_APP_API_URL}/get_home_site_info?local=ar`
			).then((response) => response.json());
			setNumber(actualData?.data);

			console.log('actual Dataaaaaaaaaaaaaaaaaaaa', actualData);
		}
		getData();
	}, []);

	return (
		<>
			{/* start footer */}
			<footer className='overflow-hidden footer-home'>
				<div className='row justify-content-around image_root'>
					<div className='col-12 text-center images_section '>
						{' '}
						<img className=' image1 ' src={imag1} alt='' />
						<img className=' image2 ' src={imag2} alt='' />
						<img className=' image3 ' src={imag3} alt='' />
					</div>
				</div>
				<div className='row justify-content-between'>
					<div className='col-lg-6  col-12   logo_sec text-center'>
						<img className='logo_bg' src={logo} alt='' />
					</div>
					<div className='col-lg-6  col-12'>
						<div className='row'>
							<div className='d-flex flex-column flex-wrap col-md-6  col-lg-6 col-12 p-0'>
								<ul
									className='row flex-row flex-wrap mt-4  links_footer_content '
									style={{ justifyContent: 'center' }}>
									<div
										className='col-lg-4 col-sm-3 col-5  p-0  '
										style={{ fontSize: '14px' }}>
										<li className='link_iteself_mr'>
											<Link
												to='/'
												className='d-flex align-items-center gap-1 footer_link_itself'>
												الرئيسية
											</Link>
										</li>

										<li className='link_iteself_mr'>
											<Link
												to='/LibrarySite'
												className='d-flex align-items-center gap-1 footer_link_itself'>
												المكتبة
											</Link>
										</li>
										<li className='link_iteself_mr'>
											<Link
												to='/AboutUs'
												className='d-flex align-items-center gap-1  footer_link_itself'
												// style={{ whiteSpace: 'nowrap', marginRight: '10px' }}
											>
												لمحة عنا
											</Link>
										</li>
									</div>
									<div
										className='col-lg-8   col-sm-4 col-5 '
										style={{ fontSize: '14px' }}>
										<li className='link_iteself_mr'>
											<Link
												to='/commonQuestion'
												className='footer_link_itself'
												style={{ whiteSpace: 'nowrap', marginRight: '12px' }}>
												الأسئلة الشائعة
											</Link>
										</li>
										<li className='link_iteself_mr'>
											<Link
												to='/TermsandConditions'
												className='d-flex align-items-center footer_link_itself'
												style={{ whiteSpace: 'nowrap', marginRight: '12px' }}>
												سياسة الخصوصية
											</Link>
										</li>
									</div>
								</ul>
							</div>
							<div className='footer-logo d-flex justify-content-center  col-md-6 col-sm-10 col-xs-12 col-12 col-lg-6 mt-4 footer_contactus_icons'>
								<div>
									{/* <img src={logo} className='Footer_Main_logo' alt='' /> */}
									<div className='d-flex flex-column  justify-content-center align-items-center'>
										<h4 className='text-white p-4 fs-5 '>تواصلوا معنا</h4>
										<div
											className=' d-flex footer_icons flex-row w-100  flex-wrap justify-content-center p-2 my-2'
											style={{ width: 'fit-content' }}>
											<a href={socialInfo?.post?.data?.facebook}>
												<img
													src={facebook}
													alt='facebook logo'
													className='p-1 contact_footer_image_itself'
													style={{ width: '100px', height: '100px' }}
												/>
											</a>
											<a href={socialInfo?.post?.data?.instgram}>
												<img
													src={instagram}
													alt='instagram logo'
													className='p-1 contact_footer_image_itself'
													style={{ width: '70%', height: '100%' }}
												/>
											</a>
											<a href={socialInfo?.post?.data?.whatsapp}>
												<img
													src={whatsapp}
													alt='whatsapp logo'
													className='p-1 contact_footer_image_itself'
													style={{ width: '70%', height: '100%' }}
												/>
											</a>
											<a href={socialInfo?.post?.data?.youtube}>
												<img
													src={youtube}
													alt='youtube logo'
													className='p-1 contact_footer_image_itself'
													style={{ width: '70%', height: '100%' }}
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-12 col-12'>
								<div className='row w-100'>
									<div className=' col-12'>
										<div className='icr mb-1 d-flex justify-content-center align-items-center '>
											<a
												className='d-flex justify-content-center align-items-center'
												href='https://www.facebook.com/ICRCompanyPro'>
												<p className='mt-1 text-center'>
													تم تصميم وتطوير المنصة بواسطة{' '}
												</p>
												<img
													src={ICR}
													className='icr_footer_image'
													with='80'
													height='80'
												/>
											</a>
										</div>
									</div>
									<div className='col-12'>
										<div className='d-flex justify-content-center align-items-center text-center mb-1'>
											<h5>
												<a
													href={getClickableLink(
														'ideacodereality.icr@gmail.com'
													)}
													classNme='text-center'>
													<p className='my-2'>للتواصل :</p>{' '}
													IdeaCodeReality.ICR@gmail.com
												</a>
											</h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>

			{/* end footer */}
		</>
	);
}
