import React, { useState } from 'react';

import { Link, NavLink } from 'react-router-dom';
import './Header.css';
import HeaderLogic from './HeaderLogic';

import logo from '../../assets/logo/logo.png';
import navigation from '../../assets/icons/images.png';
import userlogo from '../../assets/icons/user.png';
import axios from 'axios';
import Notify from '../Notify/Notify';
import { useEffect } from 'react';

export default function Header() {
	const { statusAuth } = HeaderLogic();
	const [notifications, setNotifications] = useState(null);
	const [ShowNotifications, setShowNotifications] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	function backendLoggingOut(token) {
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/logout`,
				{},
				{ headers: { Authorization: `Bearer ${token}` } }
			)
			.then((data) => {
				console.log('logout data', data);
			})
			.catch((error) => {
				console.log('logout error', error);
			})
			.finally();
	}
	// const [homeRoute,setHomeRoute] = useState(false);
	const [visible, setVisible] = useState(true);
	const handlelogout = (e) => {
		e.preventDefault(localStorage.getItem('token'));
		backendLoggingOut();
		window.location.replace('/');
		window.location.reload();
		localStorage.removeItem('token');
		localStorage.removeItem('role');
		localStorage.removeItem('admin_as_teacher');
		localStorage.removeItem('is_active');
		if (localStorage.getItem('token2')) {
			localStorage.setItem('token', localStorage.getItem('token2'));
			localStorage.setItem('role', localStorage.getItem('role2'));
			localStorage.removeItem('token2');
			localStorage.removeItem('role2');
		}
	};

	const toggleNotifications = () => {
		setShowNotifications((show) => !show);
	};

	useEffect(() => {
		window.addEventListener('scroll', (e) => {
			// console.log(e.target)
			setShowNotifications(false);
			// if(e.target.getAttribute('id') == 'notification_container') {
			// 	setShowNotifications(true);
			// }else {
			// 	setShowNotifications(false);

			// }
		});

		document.addEventListener('click', function handleClickOutsideBox(event) {
			const box = document.getElementById('notification_container');

			if (!box.contains(event.target)) {
				//   box.style.display = 'none';
				setShowNotifications(false);
			}
		});
	}, []);

	const getNotificationsData = () => {
		console.log('get data', notifications);

		axios
			.get(`${process.env.REACT_APP_API_URL}/get_user_notification?limit=8`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((data) => {
				console.log('data notifications ', data.data);
				setNotifications(data.data);
			})
			.catch((error) => {
				console.log('error inside notifications', error);
			});
	};

	useEffect(() => {
		// get notification data for the first time
		getNotificationsData();

		// if there is a user then get notifications for him
		if (localStorage.getItem('role')) {
			let intervalForNotifications;

			intervalForNotifications = setInterval(getNotificationsData, 15000);

			// clean up the interval to skip memory leakes
			return () => {
				clearInterval(intervalForNotifications);
			};
		}
	}, []);

	useEffect(() => {
		if (notifications?.data?.data.length > 0) {
			setIsLoading(false);
		}
		if (notifications?.data?.data.length == 0) {
			setIsLoading(true);
		}
	}, [notifications]);

	const notificationState = (notification) => {
		// in case of student
		if (localStorage.getItem('role') == 1) {
			switch (notification.state) {
				case 1:
					return `/myquizes/lessons/automatedquiz/${notification.needed_id}`;
					break;

				default:
					return '';
			}
		}

		// in case of teacher
		if (localStorage.getItem('role') == 2) {
			switch (notification.state) {
				case 1:
					return `/options/quizes/typequiz/${notification.student_id}/lessons/automatedquiz/${notification.needed_id}`;
					break;

				default:
					return '';
			}
		}
	};

	return (
		<>
			<div className=' p-0'>
				<nav className='navbar navbar-expand-lg navbar-light text-center  '>
					{/* main header */}

					{/* not home route and logged in as student */}

					<NavLink className='navbar-brand m-0   LOGO LOGO2 bg-transparent ' style={{backgorund: 'rgb(245, 245, 245) !important'}} to='/'>
						<img
							src={logo}
							alt=''
							style={{ width: '200px' }}
							className='d-inline-block   align-text-top '
						/>
					</NavLink>
					<button
						onClick={() => setVisible(!visible)}
						className='navbar-toggler Toggler_Icon'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#navbarNav'
						aria-controls='navbarNav'
						aria-expanded='false'
						aria-label='Toggle navigation'
						style={{ marginLeft: `10px !important` }}>
						<span className='navbar-toggler-icon '></span>
					</button>
					<div
						className='collapse navbar-collapse justify-content-center'
						id='navbarNav'>
						<ul
							className='navbar-nav w-100 justify-content-around  '
							style={{ alignItems: 'center' }}>
							<li className='nav-item margin_r'>
								<NavLink
									className='nav-link  fs-5  '
									aria-current='page'
									to='/'>
									الرئيسية
								</NavLink>
							</li>

							<li className='nav-item margin_r'>
								<NavLink className='nav-link fs-5' to='/sections'>
									الأقسام
								</NavLink>
							</li>
							<li className='nav-item margin_r'>
								<NavLink className='nav-link fs-5' to='/offers'>
									العروض
								</NavLink>
							</li>
							{/* </ul>
					</div> */}

							{/* <li className='LOGO1 margin_r '>
								<NavLink className='navbar-brand m-0  LOGO LOGO1' to='/'>
									<img
										src={logo}
										alt=''
										style={{ width: '200px', height: '100px' }}
										className='d-inline-block   align-text-top'
									/>
								</NavLink>
							</li> */}

							{/* <div
						className='collapse navbar-collapse justify-content-center  left_section_width'
						id='navbarNav'>
						<ul className='navbar-nav w-100 justify-content-around  '> */}
							<li className='nav-item'>
								<NavLink className='nav-link fs-5' to='/LibrarySite'>
									مكتبة الموقع
								</NavLink>
							</li>

							<li className='nav-item'>
								<NavLink className='nav-link fs-5' to='/AboutUs'>
									لمحة عنا
								</NavLink>
							</li>

							{!localStorage.getItem('token') && (
								<div
									style={{ width: 'fit-content' }}
									className='d-flex flex-lg-row signparent  flex-column justify-content-center align-items-center  justify-content-lg-start '>
									<li className='nav-item mx-2'>
										<Link className='nav-link sign    ' to='/signup'>
											إنشاء حساب
										</Link>
									</li>
									<li className='nav-item mx-2 '>
										<Link className='nav-link      sign_in ' to='/signin'>
											دخول
										</Link>
									</li>
								</div>
							)}
						</ul>
					</div>

					{localStorage.getItem('token') && (
						<>
							{visible && (
								<div className='icons_nav  d-flex  flex-wrap align-items-center justify-content-center justify-content-lg-start'>
									<div
										// to='/notification'
										className='d-block link-dark text-decoration-none notifications_icon'>
										<div
											className='notification mx-4'
											id={'notification_container'}
											onClick={() => toggleNotifications()}>
											{notifications &&
												notifications?.result != '0' &&
												notifications?.result != null && (
													<div
														className='padge_notifications shadow bg-danger'
														style={{
															display:
																notifications?.result == '0' ? 'none' : 'flex',
														}}>
														<>{notifications?.result}</>
													</div>
												)}

											<img
												loading='lazy'
												src={navigation}
												alt='mdo'
												width='32'
												height='auto'
												className='rounded-circle'
											/>

											<div
												id={'notification_container'}
												// style={{display: 'block'  }}

												className={`notifications_container shadow rounded ${
													ShowNotifications
														? 'showNotifications'
														: 'hideNotifications'
												}`}
												// className={`notifications_container shadow rounded'}`}
											>
												<div className='p-2'>
													<h4 className='text-end'>الإشعارات</h4>
												</div>

												{isLoading && notifications?.data?.data?.length != 0 && (
													<div className='text-center my-3 w-100'>
														<div class='spinner-border' role='status'>
															<span class='visually-hidden'>Loading...</span>
														</div>
													</div>
												)}

												{notifications?.data?.data?.length == 0 && (
													<p className='text-center my-3'>
														ليس لديك إشعارات بعد
													</p>
												)}

												{notifications &&
													notifications?.data?.data
														?.slice(0, 5)
														.map((notification, index) => {
															return (
																<Notify
																	text={notification.message}
																	image={
																		process.env.REACT_APP_STORAGE_URL +
																		notification.sender_image
																	}
																	status={notification.state}
																	isRead={notification.has_seen}
																	linkto={notificationState(notification)}
																	timeStamp={notification.created_at}
																	notificatoin_id={notification.id}
																	refetch={getNotificationsData}
																/>
															);
														})}

												{
													<Link
														to='/notification'
														className='w-100 btn btn showAllNotification_btn w-100 rounded-0'>
														عرض الكل
													</Link>
													// :
													// <div className="w-100 btn btn showAllNotification_btn w-100 rounded-0">عرض الكل</div>
												}
											</div>
										</div>
									</div>

									<div className='dropdown  user mx-4 '>
										<Link
											to='/'
											className='d-block link-dark text-decoration-none dropdown-toggle'
											id='user'
											data-bs-toggle='dropdown'
											aria-expanded='false'>
											<img
												src={userlogo}
												alt='mdo'
												width='42'
												height='auto'
												className='rounded-circle'
											/>
										</Link>
										<ul
											className='dropdown-menu text-small  drop_profile'
											aria-labelledby='user'>
											{/* {localStorage.getItem('role')==2 && <li ><NavLink className="dropdown-item " to="/options"></NavLink></li>}techerProfile */}
											{localStorage.getItem('role') == 2 && (
												<li>
													<NavLink className='dropdown-item ' to='/options'>
														البروفايل الخاص بي
													</NavLink>
												</li>
											)}
											{localStorage.getItem('role') == 2 && (
												<li>
													<NavLink
														className='dropdown-item'
														to='/techerProfile'>
														لوحة التحكم
													</NavLink>
												</li>
											)}

											{localStorage.getItem('role') == 1 && (
												<li>
													<NavLink
														className='dropdown-item '
														to='/studentprofile'>
														البروفايل
													</NavLink>
												</li>
											)}
											{localStorage.getItem('role') == 1 && (
												<li>
													<NavLink
														className='dropdown-item'
														to='/profileStudent'>
														لوحة التحكم
													</NavLink>
												</li>
											)}

											{localStorage.getItem('role') == 3 && (
												<li>
													<NavLink className='dropdown-item ' to='/profile'>
														لوحة التحكم الخاصه بي
													</NavLink>
												</li>
											)}
											{localStorage.getItem('role') == 3 && (
												<li>
													<NavLink
														className='dropdown-item '
														to='/profileStudentGuardian'>
														البروفايل الخاص بإبني
													</NavLink>
												</li>
											)}
											{/* {localStorage.getItem('role')==} */}
											{/* <li ><NavLink className="dropdown-item" to="/offers">العروض</NavLink></li> */}
											<li>
												<button
													onClick={handlelogout}
													className='dropdown-item'>
													تسجيل الخروج
												</button>
											</li>
										</ul>
									</div>
								</div>
							)}
						</>
					)}
				</nav>

				{/* end nav bar  */}
			</div>
		</>
	);
}
