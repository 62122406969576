import axios from 'axios';
import React, { useState } from 'react';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import ToastError from '../../components/ToastSuccessError/ToastError';
export default function ModalSubsecrip(props) {
	// props.isForLibarary ;
	const { btn, price, name } = props;
	const [img, setimg] = useState('');
	const [imageToSend,setImageToSend] = useState('');
	const {id} =  useParams();
	const closeBtnRef = useRef();
	const modalRef = useRef();
	const [next, setnext] = useState(false);

	const [errorMessage,setErrorMessage] = useState();
	const [successMessage,setSuccessMessage] = useState();

	const handleSelectImage = (e) => {
		console.log('event is  ', e.currentTarget.files[0]);
		setimg(URL.createObjectURL(e.currentTarget.files[0]));
		setImageToSend(e.currentTarget.files[0]);
	};

	const handleConfirm = () => {

		setnext(true);

		const subscribeForm = new FormData();

		subscribeForm.append('balance',price);

		if(props.isForLibarary){
			// subscribeForm.append('subject_id',null);
			subscribeForm.append('library_section_id',props.libraryId);
		}else {
			subscribeForm.append('subject_id',id);
			// subscribeForm.append('library_section_id',null);

		}
		subscribeForm.append('payment_image',imageToSend);

		if(props.isForLibarary){
			subscribeForm.append('type','LIBRARY');
		}else {
			subscribeForm.append('type','COURSES');
		}

		axios.post(`${process.env.REACT_APP_API_URL}/post_payment`, 
		subscribeForm , 
		{
			headers: {
				"Authorization": `Bearer ${localStorage.getItem('token')}`}
		})
		.then((data) => {
			console.log('data sub sub' , data.data);
			setSuccessMessage('تم تأكيد معلومات الدفع بنجاح')

		})
		.catch((error) => {

		})
		.finally(() => {
			setnext(false);
			closeBtnRef.current.click();
			modalRef.current.style.display = 'none !important';
			props.refetchSubjectData(prev => !prev);
		})
	};
	return (
		<>
			{btn}
			<ToastError 
			ErrorMessage={errorMessage}
			successMessage={successMessage}
			setSuccessMessage={setSuccessMessage}
			setErrorMessage={setErrorMessage}
			/>
			<div
				class='modal fade'
				id='exampleModal'
				tabindex='-1'
				ref={modalRef}
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h5 class='modal-title text-dark' id='exampleModalLabel'>
								تاكيد معلومات الدفع
							</h5>
						</div>

						<div class='modal-body text-dark'>
							{!next ? (
								<div className='w-100 ' style={{ textAlign: 'right' }}>
									<p>
										<span>اسم الكورس :</span>
										<span>{name}</span>
									</p>
									<p>
										<span>السعر :</span>
										<span>{price}</span>
									</p>
									{

										props?.isForLibarary ? 
										<div className="my-2">عزيزي الطالب يرجى إرفاق صورة الحوالة المالية المرسلة عن طريق شركة الهرم أو سيرياتيل كاش أو أي جهة معتمدة لإرسال الأموال داخل الجمهورية العربية السورية بقيمة قسم المكتبة هذا للبيانات أدناه: </div>
										:
										<div className="my-2">عزيزي الطالب يرجى إرفاق صورة الحوالة المالية المرسلة عن طريق شركة الهرم أو سيرياتيل كاش أو أي جهة معتمدة لإرسال الأموال داخل الجمهورية العربية السورية بقيمة الكورس للبيانات أدناه: </div>
									}
									<div>
										<div className='mb-1'>
											اسم المستقبل: <strong>خالد محمد نجيب البشير</strong>

										</div>

										<div>

											رقم الهاتف : <strong>0934521276</strong>
										</div>
									</div>

									<form>
										<div className='mb-3  mt-5'>
											<div className='mb-4'>
												<label className='fw-bold fs-5'>
													صورة ايصال الدفع{' '}
												</label>
											</div>
											<div
												className='parent mb-5 text-center '
												style={{
													position: 'relative',
													height: '300px',
													border: '1px solid rgba(0,0,0,0.1)',
													borderRadius: '50%',
												}}>
												{img && (
													<img height={'100%'} width={'100%'} src={img} />
												)}
												{!img && <span style={{ lineHeight: '300px' }}>
													اضغط لإضافة الصورة
												</span>}
												<input
													style={{
														position: 'absolute',
														height: '100%',
														top: '0',
														opacity: '0',
													}}
													autoComplete
													type='file'
													className=' form-control    '
													onChange={handleSelectImage}
												/>
											</div>
										</div>
									</form>

									{/* <div class='spinner-border' role='status'>
									<span class='visually-hidden'>Loading...</span>
								</div> */}
								</div>
							) : (
								<div className='w-100 ' style={{ textAlign: 'right' }}>
									<p>تم الطلب الرجاء الانتظار .......</p>
								</div>
							)}
						</div>
						{!next ? (
							<div class='modal-footer justify-content-between'>
								<button
									type='button'
									class='btn btn1'
									style={{ marginLeft: 'auto !important' }}
									data-bs-dismiss='modal'
									ref={closeBtnRef}
									>
									إلغاء
								</button>
								<button onClick={handleConfirm} disabled={!img} type='button' class='btn btn2'>
									تأكيد
								</button>
							</div>
						) : (
							<div class='modal-footer justify-content-between'>
								<button
									type='button'
									class='btn btn1'
									style={{ marginLeft: 'auto !important' }}
									data-bs-dismiss='modal'
									ref={closeBtnRef}>
									حسنا
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
