import React from 'react';
import { Link } from 'react-router-dom';

export default function QuizCard() {
	return (
		<div className='container'>
			<div className=' px py-3 pb-5 container'>
				<h3 className='fw-bold  my-5  ' style={{ color: '#044364' }}>
					الاختبارات
				</h3>
				<div className=' my-3'></div>
				<div className=' d-flex justify-content-around flex-wrap m-auto'>
					<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12  mt-2'>
						<Link to='final'>
							<h5 className='text-center text-white  fs-4 fw-bold'>
								اختبارات نهائية
							</h5>
						</Link>
					</div>

					{/* <div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12 mx-1  mt-2'>
						<Link to='up_level'>
							<h5 className='text-center text-white  fs-4 fw-bold'>
								فوق المستوى
							</h5>
						</Link>
					</div> */}
				</div>
			</div>
		</div>
	);
}
