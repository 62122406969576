import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Quiz_To_Logic from './Quiz_To_Logic';

import Layout from '../../pages/Layout/Layout';
import './Quiz_To.css';
import MainHeader from '../MainHeader/MainHeader';
import LayoutSideStudent from '../../pages/LayoutSide/LayoutSideStudent';
import { useEffect } from 'react';
export default function Quiz_To() {
	const { SubjectId } = Quiz_To_Logic();
	const { state } = useLocation();

	// console.log('state is ', state)

		// Sroll to top
		useEffect(() => {
			const intervalId = setInterval(() => {
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
			}, 1);
	
			setTimeout(() => {
				clearInterval(intervalId);
			}, 50);
		}, []);

	if (window.location.pathname.includes('myquizes')) {
		return (
			<Layout>
				<div className='container  ' style={{ paddingTop: '50px' }}>
					<h3 className='fw-bold mb-4 text-center my-3 mb-5'>
						<span style={{ color: '#044364' }}>الإختبارات </span>
					</h3>

					<div className=' row flex-column flex-md-row text-center mt-4 justify-content-center justify-content-sm-around '>
						<div className='quiz_card_sub  col-8 col-sm-8 col-md-4 mx-auto   border rounded-x shadow my-2'>
							<Link className='text-white' to='lessons' state={{ SubjectId }}>
								<p className='fw-bold fs-5'>اختبارات الدروس</p>
							</Link>
						</div>

						<div className='col-8  quiz_card_sub  col-sm-8 col-md-4 mx-auto  border rounded-x shadow '>
							<Link className='text-white' to='units' state={{ SubjectId }}>
								<p className='fw-bold fs-5 '>اختبارات الوحدات</p>
							</Link>
						</div>
					</div>
				</div>
			</Layout>
		);
	} else {
		return (
			<div className='container  ' style={{ paddingTop: '50px' }}>
				<h3 className='fw-bold mb-4 text-center mb-5'>
					<span style={{ color: '#044364' }}>الإختبارات </span>
				</h3>

				<div className='row flex-column flex-md-row text-center mt-4 justify-content-center justify-content-sm-around '>
					<div className='quiz_card_sub  col-8 col-sm-8 col-md-4 mx-auto   border rounded-x shadow my-2'>
						<Link
							className='text-white'
							to='lessons'
							state={{ sub_id: state?.subject_Id }}>
							<p className='fw-bold fs-5'>اختبارات الدروس</p>
						</Link>
					</div>

					<div className='col-8  quiz_card_sub  col-sm-8 col-md-4 mx-auto  border rounded-x shadow my-2'>
						<Link
							className='text-white '
							to='units'
							state={{ sub_id: state?.subject_Id }}>
							<p className='fw-bold fs-5 '>اختبارات الوحدات</p>
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
