import React, { useEffect } from 'react'

import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher'
import StudentForTeacherLogic from './StudentForTeacherLogic';

const StudentForTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
    const {
        TeachersStudent,
      } = StudentForTeacherLogic();
  return (
        <LayoutSideTeacher desc={"الطالب"}>
         {TeachersStudent  && <h1>{TeachersStudent.message}</h1>}
        </LayoutSideTeacher>
  )
}

export default StudentForTeacher