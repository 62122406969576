import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_lesson_comment_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
import DeleteModal from '../../../../DeleteModal';
import Pagination from '../../../../Pagination/Pagination';
import Show_All_Comments_Logic from './Show_All_Comments_Logic';

export default function Show_All_Comments() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const {
		deletComment,
		loading,
		dispatch,
		data,
		SubjectId,
		isLoading,
		isError,
		error,
		refetch,
		handlePageClick,
		page,
	} = Show_All_Comments_Logic();

	return (
		<div className='container my-5'>
			{isLoading ? (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			) : (
				<>
					<div
						style={{ width: '100%', textAlign: 'center', margin: '20px auto' }}>
						<table className='  table-bordered table_style w-100 text-center  table-striped '>
							<thead>
								<tr>
									<th className='first_child'>#</th>
									<th> التعليق </th>
									<th> اسم الطالب </th>
									<th> التوقيت</th>
									<th className='last_child'></th>
								</tr>
							</thead>
							{data?.data?.data?.data && (
								<tbody style={{ lineHeight: '60px' }}>
									{data?.data?.data?.data?.map((comment, index) => (
										<tr key={comment.id}>
											<td>{index}</td>
											<td> {comment.comment} </td>
											<td> {comment.student_name} </td>
											<td> {comment.date_time} </td>

											<td>
												<button
													type='button'
													class='btn btn2'
													data-bs-toggle='modal'
													data-bs-target='#exampleModal'
													onClick={() =>
														setSelectedAutomatedQuizIe(comment.id)
													}>
													حذف
												</button>
											</td>
											{/* <td><Link to={`edit_comment/${comment.id}`}   className='btn btn-success' >  تعديل التعليق </Link></td> */}
											{/* {(index===(data?.data?.length-1))&&<td> <button className='btn btn-danger' onClick={()=>{dispatch(delete_unit_by_id({UnitId:Unit.unit_id,SubjectId}))}}> حذف الوحدة  </button> </td> }   */}
										</tr>
									))}
								</tbody>
							)}

							{isError && (
								<div class='d-flex justify-content-center'>
									<label className='text-danger'>
										{error?.response?.data?.message
											? error?.response?.data?.message
											: `  هناك مشكلة ما رمز الخطأ: ${error.response.status}`}
									</label>
								</div>
							)}
						</table>
					</div>

					{data?.data?.data?.total >= 10 ? (
						<Pagination
							data={data?.data?.data}
							handlePageClick={handlePageClick}
						/>
					) : (
						<></>
					)}
				</>
			)}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا التعليق ؟'}
				handleDelete={() => deletComment(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
