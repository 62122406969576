import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
	delete_traditional_quiz_by_traditional_quiz_id,
	get_all_final_traditional_quizzes_by_unit_id,
} from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import { get_all_above_level_traditional_quizzes_by_unit_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Error from '../alerts/Error/Error';
import DeleteModal from '../DeleteModal';
import Show_all_up_level_Quiz_Logic from './Show_all_up_level_Quiz_Logic';

export default function Show_all_up_level_Quiz() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const { data, dispatch, UnitId, deleteQuiz, loading } =
		Show_all_up_level_Quiz_Logic();
	console.log('uniiiit id ', UnitId);
	return (
		<div className=' container my-5'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{data?.isError && <Error errorText={data.errorMessage} />}
			{data.data && (
				<table className=' w-100 table_style table-bordered  mt-3   text-center'>
					<thead>
						<tr>
							<th className='first_child'> اسم الاختبار </th>
							<th> مدة الاختبار </th>
							<th> نقاط الاختبار </th>
							<th></th>
							<th></th>
							<th></th>
							<th className='last_child'></th>
						</tr>
					</thead>
					<tbody>
						{data?.data?.map((Quiz) => (
							<tr key={Quiz.quiz_id}>
								<td> {Quiz.quiz_name} </td>
								<td> {Quiz.quiz_duration} </td>
								<td> {Quiz.quiz_points} </td>
								<td>
									{' '}
									<Link to={`edit/${Quiz.quiz_id}`} className='btn btn1'>
										{' '}
										تعديل{' '}
									</Link>{' '}
								</td>
								<td>
									<button
										type='button'
										class='btn btn2'
										data-bs-toggle='modal'
										data-bs-target='#exampleModal'
										onClick={() => setSelectedAutomatedQuizIe(Quiz.quiz_id)}>
										حذف
									</button>
								</td>
								<td>
									{' '}
									<Link
										to={`add_traditional_quiz_questions_files/${Quiz.quiz_id}`}
										className='btn btn3'>
										{' '}
										أضف أسئلة{' '}
									</Link>{' '}
								</td>
								<td>
									{' '}
									<Link
										to={`show_traditional_quiz_questions_files/${Quiz.quiz_id}`}
										className='btn btn1'>
										{' '}
										عرض الأسئلة{' '}
									</Link>{' '}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			<Link to={`add_quiz`} className='btn btn1 mx-auto mt-5'>
				{' '}
				إنشاء اختبار جديد{' '}
			</Link>
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا الاختبار ؟'}
				handleDelete={() => deleteQuiz(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
