import  { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { GetData } from '../../../../customhook/getDatawithAuth';


export default function Show_Lesson_Answear_Logic() {
    const { QuizId,StudentId } = useParams();
   
    // const data = useSelector(state => state.lessonTest.get_automated_quiz_questions_by_automated_quiz_and_student_id);
  
  
    const {getDatafrom, progress,IsLoading,IsError,data}  = GetData(`/get_automated_quiz_questions_by_automated_quiz_and_student_id?student_id=${StudentId}&autometed_quiz_id=${QuizId}`)
   
  
    useEffect(() => {
      getDatafrom();
    }, [])
  
    return {
       IsLoading,IsError,data,QuizId,StudentId
    }
}
