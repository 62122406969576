import React from 'react';
import { useLocation } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
import SubTitle from '../../components/SubTitle/SubTitle';
import Layout from '../Layout/Layout';
import './EmbedPdf.css';

const EmbedPdf = () => {
	const { state } = useLocation();
	console.log('state is isi ', state);
	return (
		<Layout>
			{/* <MainHeader /> */}
			{
				state?.bookName && 
				<SubTitle title={state?.bookName} />

			}
			<div className='container my-5   '>
				{state && (
					<iframe
						id='fred'
						title='PDF in an i-Frame'
						src={state?.url}
						frameborder='0'
						scrolling='auto'
						style={{ height: '105vh !important' }}
					/>
				)}
			</div>
		</Layout>
	);
};

export default EmbedPdf;
