import './ButtonM.css';

const ButtonM = ({ text, onClick, className, style = {}, children }) => {
	return (
		<div
			className={`buttonM buttonM-2 ${className}`}
			onClick={onClick}
			style={style}>
			{text}

			{children}
		</div>
	);
};

export default ButtonM;
