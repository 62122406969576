import React from 'react';

import Img from '../../assets/maincontent/nav.png';
const OfferDetails = ({ image, desc, index }) => {
	return (
		<>
			{index % 2 == 0 ? (
				<div className='row offer_con'>
					<div
						className='col-lg-4 col-md-5 col-sm-12  col-12 text-center '
						style={{
							alignContent: 'center',
							alignItems: 'center',
						}}>
						<img
							style={{
								width: '100%',
								marginButtom: '9%',
								height: '300px',
								marginLeft: 'auto !important',
								display: 'block',
							}}
							className='offer-image-container'
							src={image}
						/>
					</div>

					<div
						className={`col-lg-8 col-md-6 col-sm-12 text-center  col-12 offer_details_content  `}>
						{/* <h3 className='my-3 offer_title'>اسم العرض</h3> */}
						<p className='offer_desc text-center mx-auto fs-2 '>{desc} </p>
					</div>
				</div>
			) : (
				<div className='row'>
					{' '}
					<div
						className='col-lg-4 col-md-5 col-sm-12  col-12  text-center'
						style={{
							alignContent: 'center',
							alignItems: 'center',
						}}>
						<img
							style={{
								width: '100%',
								marginButtom: '9%',
								height: '300px',
								marginLeft: 'auto !important',
								display: 'block',
							}}
							className='offer-image-container'
							src={image}
						/>
					</div>
					<div
						className={`col-lg-8 col-md-6 col-sm-12  col-12  text-center  align-items-center justify-contnet-center offer_details_content `}>
						<p className='offer_desc text-center mx-auto fs-2 '>{desc} </p>
					</div>
				</div>
			)}
		</>
	);
};

export default OfferDetails;
