import React from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
const ToastSuccessError = ({
  successMessage,
  ErrorMessage,
  setSuccessMessage,
  setErrorMessage,
  clearMsg,
}) => {
  const dispatch = useDispatch();
  const showSuccess = () => {
    toast.success(successMessage, {
      position: "top-right",
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    // setSuccessMessage("");
    dispatch(clearMsg());
  };

  const showError = () => {
    toast.error(ErrorMessage, {
      position: "top-right",
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
    // setErrorMessage("");
    dispatch(clearMsg());
  };
  return (
    <div>
      {successMessage?.length > 0 && showSuccess()}
      {ErrorMessage?.length > 0 && showError()}
    </div>
  );
};

export default ToastSuccessError;
