import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import {  useForm } from "react-hook-form";
import { useUploadForm } from '../../customhook/Uploadfiles';
export default function Create_unit_automated_quiz_Logic({isAboveLevel,isFinal}) {

    
    const { getValues ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        QuizeName:'',
        points:'',
        descriptionQuize:'',
        duration:'', 
      }});

      const {UnitId} = useParams();

      const { isSuccess:create_unit_automated_quiz_form_success, uploadForm:create_unit_automated_quiz_func, progress:create_unit_automated_quiz_form_progress ,IsError:create_unit_automated_quiz_form_error,data:create_unit_automated_quiz_form_data,IsLoading:create_unit_automated_quiz_form_Loading } = useUploadForm('/create_automated_quiz');

      const onSubmit = async (data) => {

        const create_unit_automated_quiz_form = new FormData(); 
        create_unit_automated_quiz_form.append('unit_id',UnitId);
        create_unit_automated_quiz_form.append('isFinal',isFinal);
        create_unit_automated_quiz_form.append('duration',data.duration);
        create_unit_automated_quiz_form.append('isAboveLevel',isAboveLevel);
        create_unit_automated_quiz_form.append('nameOfQuiz',data.QuizeName);
        create_unit_automated_quiz_form.append('points', data.points);
        create_unit_automated_quiz_form.append('description', data.descriptionQuize)
        create_unit_automated_quiz_form.append('transable', 0)
         await   create_unit_automated_quiz_func(create_unit_automated_quiz_form);
       };
     







      
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    create_unit_automated_quiz_form_error,
    create_unit_automated_quiz_form_progress,
    create_unit_automated_quiz_form_success,
    create_unit_automated_quiz_form_Loading,
    onSubmit
    }
}
