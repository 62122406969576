import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../Layout/Layout'
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher'
import GuardianStudentTeacherLogic from './GuardianStudentTeacherLogic'

const GuardianStudentTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
    const params = useParams();
    const  {
        gurdianStudent,
      }=GuardianStudentTeacherLogic()
  return (
    <Layout>
    <LayoutSideTeacher desc={"الطالب"}>
    
     {gurdianStudent && <h1>{gurdianStudent.message}</h1>}
    </LayoutSideTeacher>
</Layout>
    
  )
}

export default GuardianStudentTeacher