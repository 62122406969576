import React from 'react';
import { Link } from 'react-router-dom';
import './Students.css';

export default function ({ src, name, studentId, subjectId, isVisas }) {
	return (
		<div>
			<div
				className='card rounded shadow p-5 my-4  pb-4'
				style={{
					height: isVisas ? 'auto' : '17rem !important',
					marginLeft: '0% !important',
				}}>
				<div className={`name-image ${!isVisas && 'mr16'}`}>
					<img
						loading='lazy'
						src={`${process.env.REACT_APP_STORAGE_URL}${src}`}
						className='w-100 h-100 named-image'
						style={{ marginRight: '18% !important' }}
					/>
				</div>
				<h6 className='m-auto mt-4'>{name}</h6>

				<div className='my-2'></div>
				{isVisas ? null : (
					<div>
						<Link
							// state={{s: '5'}}
							//   to={`${to}/${student.student_id}`}
							to={`/options/quizes/typequiz/${studentId}`}
							onClick={(event) => event.stopPropagation()}
							className='btn btn1 w-100'
							state={{ subject_Id: subjectId }}>
							عرض الإختبارات
						</Link>
						<div className='mt-2' />
						<Link
							// state={{s: '5'}}
							//   to={`${to}/${student.student_id}`}
							to={`/studentprofilebyid/${studentId}`}
							onClick={(event) => event.stopPropagation()}
							className='btn btn2 w-100'
							state={{ subject_Id: subjectId }}>
							بروفايل الطالب
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}
