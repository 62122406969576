import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getType } from './getType';
import { updateLessonAttachmentAPI } from './LessonAttachmentAPI';

function EditModal({ element, lessonId, setRefresh, refresh }) {
	//********************************************************* */
	let formData = new FormData();
	const [Loading, setLoading] = useState(false);
	const { register, reset, handleSubmit } = useForm();
	console.log('element-->', element);
	//********************************************************* *
	// const editTask = async (taskId, formData) => {
	//   setLoading(true);
	//   await axios.post(
	//     `${process.env.REACT_APP_API_URL}/edit_student_day_task_by_student_day_task_id/${taskId}`,
	//     formData,
	//     {
	//       headers: {
	//         Authorization: "Bearer " + localStorage.getItem("token"),
	//       },
	//     }
	//   );
	//   setLoading(false);
	//   setRefresh(!refresh);
	// };
	//********************************************************* */
	useEffect(() => {
		reset({
			id: element?.id,
			title: element?.title,
			lesson_id: element?.lesson_id,
			type: element?.type,
		});
	}, [element]);
	//********************************************************* */
	return (
		<div
			className='modal fade'
			id='EditAttatchmentModal'
			tabIndex='-1'
			aria-labelledby='exampleModalLabel'
			aria-hidden='true'>
			<div className='modal-dialog  bg-white'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							تعديل
						</h5>
					</div>
					<div className='modal-body'>
						<form
							onSubmit={handleSubmit((data) => {
								// console.log("file name-->", data.file);
								let cData = {
									...data,
									type: getType(data?.file[0]?.name) || data.type,
									file: data.file[0],
								};

								console.log('cdata-->', cData);
								let formData = new FormData();
								for (let key in cData) {
									if (key === 'file' && cData.file !== undefined)
										formData.append(key, cData[key]);
									else if (key != 'file') formData.append(key, cData[key]);
								}
								if (cData.type < 4)
									updateLessonAttachmentAPI(data.id, formData, setLoading);
								else alert('file type not supported');
							})}>
							<div>
								<label htmlFor='' className=''>
									العنوان
								</label>
								<input
									type='text'
									className='form-control mt-2'
									name='task'
									// onChange={(event) => setTitle(event.target.value)}
									{...register('title', {
										required: 'هذا الحقل مطلوب',
									})}
								/>
							</div>
							<div>
								<label htmlFor='' className=''>
									الملف
								</label>
								<input
									type='file'
									className='form-control mt-2'
									name='task'
									{...register('file')}
								/>
							</div>
							<div className='text-center mt-3'>
								{Loading ? (
									<div class='text-center' role='status'>
										<span class='spinner-grow text-primary'></span>
									</div>
								) : (
									<button type='submit' className='btn btn1 w-75'>
										حفظ
									</button>
								)}
							</div>
						</form>
					</div>
					<div className='modal-footer'>
						<button type='button' className='btn btn2' data-bs-dismiss='modal'>
							الغاء
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default EditModal;
