import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_teacher_subjects } from "../../../../redux-toolkit/reducers/Subjects/SubjectsSlice";

export default function Show_All_Subjects_Logic() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.subjects.get_teacher_subjects);
  useEffect(() => {
    dispatch(get_teacher_subjects());
  }, []);
  console.log("dataaaaa", data);

  return {
    data,
  };
}
