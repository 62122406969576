const genericType = (arrayOfTypes) => (name) =>
  arrayOfTypes.some((v) => name.endsWith(v));

const pdfType = genericType(["pdf"]);

const imageType = genericType(["png", "jpg", "jpeg"]);

const soundType = genericType(["mp3"]);

export const getType = (name) => {
  if (name)
    return pdfType(name) ? 2 : imageType(name) ? 1 : soundType(name) ? 3 : 4;
};
