import React from 'react';
import './index.css';

function Button({
	text,
	className,
	onclick,
	disabled = false,
	backgroundColor,
}) {
	return (
		<div className={`subject__subscribeButton ${className && className}`}>
			<button
				disabled={disabled}
				onClick={onclick}
				style={{ backgroundColor: backgroundColor }}>
				{text}
			</button>
		</div>
	);
}

export default Button;
