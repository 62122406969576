import React, { useState, useEffect } from 'react';
import './index.css';
import bg from '../../assets/lesson/bg.png';
import Slider from '../../components/slider';
import UnitLessons from '../../components/unitLessons';
import LeaveComment from '../../components/LeaveComment';
import Button from '../../components/button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';

import book from '../../assets/unit/book (2).png';
import point from '../../assets/unit/point.png';
import MainComment from '../../components/mainComment';

// import UnitLogic from './unitLogic';
// import axios from 'axios';
import Quiz from '../../components/Quiz';

import {
	clearMsg,
	getError,
	getSuccess,
	postCommentUnit,
} from '../../redux-toolkit/reducers/PostCommentUnit/PostCommentUnitSlice';
import UnitLogic from './unitLogic';
import axios from 'axios';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import {
	setError,
	setSuccess,
	getSuccess as getSuccessN,
	getError as getErrorN,
} from '../../redux-toolkit/reducers/notification';
import Spacer from '../../components/spacer';
import MainHeader from '../../components/MainHeader/MainHeader';
import { isVisible } from '@testing-library/user-event/dist/utils';
import ToastError from '../../components/ToastSuccessError/ToastError';
import AccordionCustom from '../../components/Accordion';
import SubTitle from '../../components/SubTitle/SubTitle';
function UnitScreen() {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	const {
		unitId,
		unitDetails,
		state,
		data,
		lessons,
		FinalTraditionalQuizzes,
		AboveLevelTraditionalQuizzes,
		loading,
	} = UnitLogic();
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const { id } = useParams();
	const navigate = useNavigate();
	const [finalAutomatedQuize, setFinalAutomatedQuize] = useState([]);
	const [loadingV, setloadingV] = useState(true);

	const [aboveLevelAutomatedQuizes, setAboveLevelAutomatedQuizes] = useState(
		[]
	);

	console.log(loading);
	const token = localStorage.getItem('token');
	const [pagination, setpagination] = useState(3);
	const [prevPagination, setprevPagination] = useState(0);

	const next = () => {
		if (pagination < data?.data?.length) {
			setpagination(pagination + 3);
			setprevPagination(prevPagination + 3);
		}
	};
	const prev = () => {
		if (prevPagination >= 3) {
			setpagination(pagination - 3);
			setprevPagination(prevPagination - 3);
		}
	};
	const onclick = () => {
		setloadingV(false);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/add_student_unit_exit_visa`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						unit_id: id,
					},
				}
			)
			.then((res) => {
				setloadingV(true);
				console.log(res);
				setSuccessMessage(res.data.data);
			})
			.catch((err) => {
				setloadingV(true);
				err?.response?.status == 410
					? // ? dispatch(setError('تم الطلب سابقا'))
					  setErrorMessage(err.response.data.message)
					: setErrorMessage(err?.response?.mesage);
			});
	};
	console.log('unit detailssssssssssssss', unitDetails);
	console.log(FinalTraditionalQuizzes);
	// java-course.online/api/get_all_above_level_automated_quiz_by_unit_id?unit_id=211&limit=10

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_all_final_automated_quiz_by_unit_id?unit_id=${id}&limit=19`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('unit quizez', data.data.data);
				setFinalAutomatedQuize(data.data.data);
			})
			.catch((error) => {
				console.log('error unit quizez', error);
			});

		//get_all_above_level_automated_quiz_by_unit_id?unit_id=211&limit=10

		// axios
		// 	.get(
		// 		`${process.env.REACT_APP_API_URL}/get_all_above_level_automated_quiz_by_unit_id?unit_id=${id}&limit=19`,
		// 		{
		// 			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		// 		}
		// 	)
		// 	.then((data) => {
		// 		console.log(
		// 			'unit automated automated automated ',
		// 			'id',
		// 			id,
		// 			data.data.data,
		// 			'token token ',
		// 			localStorage.getItem('token')
		// 		);
		// 		console.log(
		// 			`${process.env.REACT_APP_API_URL}/get_all_above_level_automated_quiz_by_unit_id?unit_id=${id}&limit=19`
		// 		);
		// 		setAboveLevelAutomatedQuizes(data.data.data);
		// 	})
		// 	.catch((error) => {
		// 		console.log('error unit quizez', error);
		// 	});
	}, []);
	const handleNavigate = () => {
		navigate('/allComments', {
			state: { url: `${process.env.REACT_APP_API_URL}/get_all_comments_of_unit_by_unit_id/${unitId?.id}` },
		});
	};
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='unitscreen container'>
				{/* <ScreenTitle
					// title={state.state}
					secondTitle='عدد الدروس'
					secondTitleNum={unitDetails && unitDetails.lessons_count}
					thirdTitle='عدد النقاط'
					thirdTitleNum={unitDetails && unitDetails.points}
					// completTitle={unitDetails && unitDetails.unit_name}
					startDate={unitDetails && unitDetails.start_date}
					endDate={unitDetails && unitDetails.end_date}
				/> */}
				<div className=' mt-5 mb-2'>
					<h2 className='fw-bold unit_title_main'>
						<span style={{ color: '#EF0000' }}>{state.state}</span>-
						<span style={{ color: '#044364' }}>
							{unitDetails && unitDetails.unit_name}
						</span>
					</h2>
					<div>
						<img src={book} alt='book' width={'25px'}></img>
						<span className='mx-2'>
							{' '}
							عدد الدروس:{unitDetails && unitDetails.lessons_count}
						</span>
						<img className='mx-2' src={point} alt='point' width={'25px'}></img>
						<span className='mx-2'>
							عدد النقاط: {unitDetails && unitDetails.points} نقطة{' '}
						</span>
					</div>
				</div>
				<div className='row my-3'>
					<div className='col-lg-6 col-12'>
						<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							ماذا ستتعلم في هذا القسم:
						</h4>

						<p className='my-4'>
							{unitDetails && unitDetails.unit_description}
						</p>
					</div>
					<div className='col-lg-6 col-12'>
						{' '}
						<div className='subject__videoScection__video'>
							<iframe
								style={{ borderRadius: '20px' }}
								className='Iframe_video unit_iframe_video'
								// style={{width:'500px',height:'350px'}}
								src={process.env.REACT_APP_STORAGE_URL + unitDetails.unit_video}
								title='YouTube video'
								allowFullScreen></iframe>
						</div>
					</div>
				</div>

				<SubTitle title={"متطلبات القسم"} />
				<div className='row justify-content-center text-center my-5'>
					{/* <h4 style={{ color: '#044364' }} className='fw-bold my-3'>
						متطلبات الدورة
					</h4> */}

					
					{unitDetails &&
						unitDetails?.requirements?.map((req, index) => {
							return <p key={index}>{req}</p>;
						})}
					{/* <p>إمكانية إستخام الكمبيوتر بشكل عادي</p>
					<p>معرفة نظرية بماهية البرمجة</p> */}
				</div>
					<SubTitle title={'محتوى القسم'} />
				<div className='row justify-content-center text-center my-5'>
					{/* <h4 style={{ color: '#044364' }} className='fw-bold my-3'>
						محتوى القسم
					</h4> */}

					{lessons?.length != 0 && (
						<>
							<UnitLessons lessons={lessons} />
						</>
					)}
				</div>
				<div className=''>
					{
						// 	<h4 style={{ color: '#044364' }} className='fw-bold my-5'>
						// 	أسئلة شائعة :
						// </h4>
						unitDetails?.faqs?.length > 0 &&

					<SubTitle title={"الأسئلة الشائعة"} />
					}
				

					{unitDetails?.faqs && (
						<AccordionCustom data={unitDetails?.faqs} backgroundImage={bg} />
					)}
				</div>

				{FinalTraditionalQuizzes?.length != 0 && (
					<>
						<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							اختبارات وحدة {unitDetails?.unit_name} التقليدية النهائية
						</h4>
						<div
							className='quiz_section  py-4 row	 justify-content-center '
							style={{ justifyContent: 'center' }}>
							<Slider gap={30} isOne={FinalTraditionalQuizzes.length <= 1}>
								{FinalTraditionalQuizzes &&
									FinalTraditionalQuizzes.map((quiz, index) => (
										<Quiz
											id={quiz.quiz_id}
											key={1}
											pointsNum={quiz.quiz_points}
											quizName={quiz.quiz_name}
											quizTime={quiz.quiz_duration}
										/>
									))}
							</Slider>
						</div>
					</>
				)}

				{AboveLevelTraditionalQuizzes?.length != 0 && (
					<>
						<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							اختبارات وحدة {unitDetails?.unit_name} التقليدية فوق المستوى
						</h4>
						<div
							className='quiz_section row py-4	 '
							style={{ justifyContent: 'center' }}>
							<Slider gap={50} isOne={AboveLevelTraditionalQuizzes.length <= 1}>
								{AboveLevelTraditionalQuizzes &&
									AboveLevelTraditionalQuizzes.map((quiz, index) => (
										<Quiz
											id={quiz.quiz_id}
											key={1}
											pointsNum={quiz.quiz_points}
											quizName={quiz.quiz_name}
											quizTime={quiz.quiz_duration}
										/>
									))}
							</Slider>
						</div>
					</>
				)}

				{finalAutomatedQuize.length != 0 && (
					<>
						<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							اختبارات وحدة {unitDetails?.unit_name} المؤتمتة النهائية
						</h4>
						<div
							className='quiz_section row py-4	 '
							style={{ justifyContent: 'center' }}>
							<Slider gap={50} isOne={finalAutomatedQuize.length <= 1}>
								{finalAutomatedQuize.map((finalQuiz, index) => {
									return (
										<Quiz
											isAutomated
											pointsNum={finalQuiz.quiz_points}
											quizName={finalQuiz.quiz_name}
											quizTime={finalQuiz.quiz_duration}
											id={finalQuiz.id}
										/>
									);
								})}
							</Slider>
						</div>
					</>
				)}

				{aboveLevelAutomatedQuizes.length != 0 && (
					<>
						<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
							اختبارات وحدة {unitDetails?.unit_name} المؤتمتة النهائية
						</h4>
						<div
							className='quiz_section row py-4	 '
							style={{ justifyContent: 'center' }}>
							<Slider gap={50} isOne={aboveLevelAutomatedQuizes.length <= 1}>
								{aboveLevelAutomatedQuizes.map((finalQuiz, index) => {
									return (
										<Quiz
											isAutomated
											pointsNum={finalQuiz.quiz_points}
											quizName={finalQuiz.quiz_name}
											quizTime={finalQuiz.quiz_duration}
											id={finalQuiz.id}
										/>
									);
								})}
							</Slider>
						</div>
					</>
				)}

				<div className='mt-2' />

				<div className='comments'>
					{data?.data?.length ? (
						<>
							<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
								أبرز التعليقات
							</h4>
							{data?.data?.map((el, index) => {
								if (index < pagination && prevPagination <= index)
									return (
										<MainComment
											// img={el?.student_image}
											img={
												el?.student_image
													? process.env.REACT_APP_STORAGE_URL +
													  el?.student_image
													: process.env.REACT_APP_STORAGE_URL + el?.user_image
											}
											// name={"اسم الطالب"}
											name={el?.student_name ? el?.student_name : el?.user_name}
											// name={el.student_image}
											date={el?.date_time}
											// date={"12/5/2022"}
											// text="lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus "
											text={el.comment ? el?.comment : el?.opinion}
										/>
									);
							})}
							<p
								onClick={handleNavigate}
								className='btn btn-outline-info CursorLinkopinion w-100 '>
								عرض المزيد
							</p>
						</>
					) : (
						null
						// <p className='p-3 text-center'>لا يوجد تعليقات الى هذه اللحظة</p>
					)}
					{data?.data?.length < 3 ? (
						<> </>
					) : (
						data?.data?.length > 0 && (
							<div className='row justify-content-center'>
								<Button
									text={<span className='text-danger'>السابق</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={prev}
									backgroundColor='white'
								/>
								<Button
									text={<span className='text-danger'>التالي</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={next}
									backgroundColor='white'
								/>
							</div>
						)
					)}
				</div>
				{/* <ToastSuccessError
					successMessage={SuccessMsg || SuccessMsgN}
					ErrorMessage={ErrorMsg || ErrorMsgN}
					clearMsg={clearMsg}
				/> */}
				<ToastError
					ErrorMessage={errorMessage}
					successMessage={successMessage}
					setErrorMessage={setErrorMessage}
					setSuccessMessage={setSuccessMessage}
				/>
				<LeaveComment
					postDataComment={postCommentUnit}
					unitId={id}
					loading={loading}
				/>
			</div>
		</Layout>
	);
}

export default UnitScreen;
