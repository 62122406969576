import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Error from '../alerts/Error/Error';
import StudentsLogic from './StudentsLogic';
import SubStudents from './SubStudents';
import SubStudents2 from './SubStudents2';
export default function Students({ to, api, Title }) {
	const { students, IsLoading, IsError } = StudentsLogic({ api, Title });

	useEffect(() => {
		console.log('الاختبارات ');
	}, []);
	return (
		<>
			<h3 className='fw-bold mb-4 text-center mt-5'>
				<span style={{ color: '#044364' }}>الإختبارات </span>
			</h3>
			{IsError && (
				<Error
					errorText={
						IsError?.response?.data?.message
							? IsError?.response?.data?.message
							: IsError.message
					}
				/>
			)}

			{IsLoading ? (
				<div className='w-100 text-center my-5'>
					<div class='spinner-border' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			) : null}

			{!IsError && students?.data && (
				<div className=''>
					{/* <div className=' d-flex flex-wrap justify-content-evenly'>
              
                    {
                        info.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        
        </div> */}
					{/* <div className='  d-flex flex-wrap justify-content-evenly'>
              
                    {
                        info.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        
        </div> */}
					{/* <div className='  d-flex flex-wrap justify-content-around'>
        {
                        info1.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents2  src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        </div> */}
					{/* <div className='  d-flex flex-wrap justify-content-around'>
        {
                        info1.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents2  src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
                    
        </div> */}

					<div className='  d-flex flex-wrap justify-content-around container'>
						{students.data && (
							<table className=' table table_style  mt-3   text-center  table-bordered  table-striped '>
								<thead>
									<tr>
										<th className='first_child'> اسم المادة </th>
										<th> اسم القسم </th>
										<th> صورة المادة </th>
										<th>عدد الوحدات </th>
										<th>عدد الدروس</th>
										<th>الوصف</th>
										<th>النقاط</th>
										<th>السعر</th>
										<td>عدد الوحدات</td>
										<td className='last_child'> </td>
									</tr>
								</thead>
								<tbody className=' shadow'>
									{students.data?.map((subject) => (
										<tr key={subject.subject_id}>
											<td> {subject.subject_name} </td>
											<td> {subject.section_name} </td>
											<td>
												<div
													style={{
														width: '100%',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														height: '100%',
													}}>
													<img
														loading='lazy'
														className='img-fluid'
														src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
														alt='Girl in a jacket'
														style={{
															objectFit: 'cover',
															width: '100%',
															height: '12vh',
														}}
													/>
												</div>
											</td>
											<td> {subject.number_of_units_in_the_subject} </td>
											<td> {subject.number_of_lessons} </td>
											<td>
												{subject?.subject_description?.length > 15 && '...'}{' '}
												{subject?.subject_description?.slice(0, 15)}{' '}
											</td>
											<td> {subject.points} </td>
											<td> {subject.price} </td>
											<td> {subject.number_of_units_in_the_subject} </td>
											<td>
												{' '}
												<Link
													to={`/studentsQuizez/${subject.subject_id}`}
													className='btn btn1'>
													{' '}
													عرض الطلاب{' '}
												</Link>{' '}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						)}

						{/* {
              IsLoading ? 
              <div className="w-100 text-center my-5">

            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
              </div>
              : 
              null 
            } */}
						{/* {students?.data?.map((student) => {
              return (
                <Link
                  to={`${to}/${student.student_id}`}
                  key={student.student_id}
                  className=" my-col"
                >
                  <SubStudents2
                    src={
                      student.user_image
                        ? student.user_image
                        : student.student_image
                    }
                    name={student?.name ? student.name : student.student_name}
                  />
                </Link>
              );
            })} */}
					</div>

					{!IsError && !students?.data?.length && (
						<Error errorText='لم يقم الطلاب بتقديم اختبارات تقليدية أو مؤتمتة بعد' />
					)}
				</div>
			)}
		</>
	);
}
