import React, { useEffect, useState , useRef} from "react";
import { useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { add_automated_quiz_question_with_its_options } from "../../redux-toolkit/reducers/Unit_test/UnitSlice";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { PostData } from "../../customhook/postJsonData";
export default function Add_automated_quiz_quistions_Logic() {
  const { QuizId } = useParams();

  const question = useRef(null);
  const firstOption = useRef(null);
  const [isFirstTrue,setIsFirstTrue] = useState(true);

  const secondOption = useRef(null);
  const [isSecondTrue,setIsSecondTrue] = useState(false);
  
  const fourthOption = useRef(null);
  const thirdOption = useRef(null);

  const [isThirdTrue,setIsThirdTrue] = useState(false);



  const [isFourthTrue,setIsFourthTrue] = useState(false);


  const dispatch = useDispatch();
  // const data = useSelector(state=>state.unitTest.add_automated_quiz_question_with_its_options)
  const {
    control,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      question: "",
      correct: 0,
      point: 1,
      options: [
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
        { answear: "", is_true: 0 },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "options",
  });

  const {
    PostData: add_automated_quiz_question_with_its_options,
    isSuccess,
    progress,
    IsLoading,
    IsError,
  } = PostData("/add_automated_quiz_question_with_its_options");

  console.log('is it an error broo?????',IsError);
  const onSubmit = async (data) => {
    
    data.options[data.correct].is_true = 1;
    
    console.log('data ffffffffffffffff', data.options.filter(option => option.answear != ''))
    let lastOptionsForSendToDB = [];
    const answers = [

      firstOption.current,
      secondOption.current,
      thirdOption.current,
      fourthOption.current,
    ];



    const lastOptionsToSendToDB = data.options.map((option,index) => {
      return {
        answear: answers[index],
        is_true: option.is_true
      }
    })
    const formdata = {
      question: question.current,
      point: Number(data.point),
      options: lastOptionsToSendToDB.filter(option => option.answear != ''),
      automated_quiz_id: Number(QuizId),
      question_note: data.question_note,
    };

    add_automated_quiz_question_with_its_options({ formdata });
  };

  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    onSubmit,
    fields,
    isSuccess,
    IsLoading,
    IsError,
    watch,
    question,
    firstOption,
    secondOption,
    thirdOption,
    fourthOption
  };
}
