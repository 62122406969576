import axios from 'axios';
import React, {useEffect, useState} from 'react';
import  Button  from '../../components/button/index';
import { useParams } from 'react-router-dom';
import Questino from '../../components/questino';
import TitleSection from '../../components/titleSection';



const AboveAutomatedUnit = (props) => {

    const {id} = useParams();
    const [questions,setQuestions] = useState();
    const [studendAnswers,setStudentAnswers] = useState([]);
    const [finishExam,setFinishExam] = useState(false);
    const [isAnswering,setIsAnswering] = useState(false);
    const [isAnsweredBefore , setIsAnsweredBefore] = useState(false);
    const [correctingData,setCorrectingData] = useState();

    useEffect(() => {

        console.log('quiz id',id);

        axios.get(`${process.env.REACT_APP_API_URL}/get_all_automated_quiz_questions_with_options_by_automated_quiz/${id}`
        ,{}, {headers: {"Authorization": localStorage.getItem('token')}})
        .then(data => {
            setQuestions(data.data.data);
            console.log('questions for automated quiz',data.data.data)
        })
        .catch(error => {
            console.log('error inside quiz questions',error);
        })

    },[])

    const hanldeStudentAnswer = (questionID,optionID) => {

        const copyAnswers = [...studendAnswers];
        let isAnsweredBefore = false ;

        for(let i = 0 ; i < copyAnswers.length; i++){

            if(copyAnswers[i].question_id == questionID){

                isAnsweredBefore = true;

                copyAnswers[i].answer_id = optionID;
            }
        }

        if(!isAnsweredBefore) {
            copyAnswers.push({question_id: questionID , answer_id: optionID})
        }

        console.log('student answers ==> ',copyAnswers)
        setStudentAnswers(copyAnswers);

    }


    
    const postAnswers = () => {
        setIsAnswering(true);

        let answersArrayToPost = [];
        for(let i = 0; i < studendAnswers.length ; i++){
            answersArrayToPost.push({question_id: studendAnswers[i].question_id , option_id: studendAnswers[i].answer_id});
        }
        const Data = {
           "answers":answersArrayToPost
        }

        console.log('array of answers', answersArrayToPost);
        axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


        axios.post(`${process.env.REACT_APP_API_URL}/post_the_the_answer_for_automated_quizzes_question`,Data, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Accept": 'application/json'
            }
        })
        .then(data => {
            console.log('lesson automated quize',data);   
            
            setCorrectingData(data.data);

            setFinishExam(true);
        })
        .catch(error => {
            console.log('error inside lesson quize',error);    
            setIsAnsweredBefore(true);
            setFinishExam(false);
        })
        .finally(() => {
            setIsAnswering(false);
        })
    
        
        console.log('asnswersssss',studendAnswers);
    }

    return <>
     <TitleSection title='اسئلة الوحدة المؤتمتة فوق المستوى' />
        <div className="container">
            {
                questions && questions?.map((question,index) => {
                    return    <Questino 
                    showAnswersToUser={false}
                    key={index}
                    hanldeStudentAnswer={hanldeStudentAnswer}
                    questionId={question.id}
                    question={question.question}
                    options={question.options}
                    marks={question.point}
                    />
                })
            }


            {
    !isAnswering ?  
    (  (studendAnswers?.length == questions?.length) && ( !finishExam ?
        <Button 
        text={isAnsweredBefore ? "لقد قمت بتقديم هذا الاختبار سابقا" : "إنهاء الاختبار" }
        className={`lessonsSecreen__FinishExamBtns`}
        onclick={postAnswers}
        disabled={isAnsweredBefore}
        /> 
        : 
        <Button text={" النتيجة " + correctingData?.result  } className="examResultBtn" />
    )
   
    )
    : (<div style={{ margin: '20px auto !important',textAlign: 'center',paddingTop: '50px',width: '100%'}} >
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    </div>
                )
            }

        </div>
        </>
}

export default AboveAutomatedUnit;