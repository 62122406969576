import React, { useEffect, useState } from 'react';
import './index.css';
import TitleSection from '../../components/titleSection';

import Layout from '../Layout/Layout';
import StudentArchiveLogic from './studentArchiveLogic';
import { yearFilter } from './getTime';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';

const StudentArchiveScreen = () => {
	const [Total, setTotal] = useState({});
	const [error, setError] = useState();
	const [Url, setUrl] = useState(
		`${process.env.REACT_APP_API_URL}/get_total_study_hours`
	);
	const [Subject, setSubject] = useState([]);
	const [Time_start, setTime_start] = useState('');
	const [Time_end, setTime_end] = useState('');
	const [Filter, setFilter] = useState(yearFilter());
	const {
		states,
		renderInfos,
		handleChange,
		achivmentTotlaAPI,
		achivmentSubjectAPI,
	} = StudentArchiveLogic(setTotal, setUrl, setSubject, setFilter, setError);
	const subjectURL = `${process.env.REACT_APP_API_URL}/get_student_subjects_with_achievement_by_start_time_and_end_time`;
	//********************************************************************************/
	useEffect(() => {
		achivmentTotlaAPI(Url)();
		achivmentSubjectAPI(subjectURL)(Filter);
	}, []);
	//********************************************************************************/
	useEffect(() => {
		// setBg(Math.floor(Math.random()*16777215).toString(16))
	}, []);
	//

	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	const renderSubject = Subject?.map((sub, index) => (
		<div
			className={`circleInfo `}
			style={{
				backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(
					16
				)}`,
			}}
			id={sub.subject_id}>
			{/* <img src={process.env.REACT_APP_STORAGE_URL + sub.subject_image} alt="" /> */}
			<p>{sub.subject_name}</p>
			<p>{sub.total_student_duration}</p>
		</div>
	));
	//********************************************************************************/

	return (
		<Layout>
			<LayoutSideStudent desc={'الانجازات'}>
				<TitleSection title='المراحل التي قمت باجتيازها' />
				<div className='studenArchiveScreen'>
					<h3 className='maintitle'>الرجاء تحديد المدة الزمنية</h3>
					<div className='chooseTimeLine'>
						<div className='checkboxesContainer'>
							{states.map((st, index) => {
								return (
									<div className='checkboxCon'>
										<input
											type='checkbox'
											value={st.value}
											checked={st.checked}
											onChange={(e) => handleChange(e)}
										/>
										<p>{st.text}</p>
									</div>
								);
							})}
						</div>
					</div>

					<>
						<form onSubmit={(e) => e.preventDefault()}>
							{renderInfos ? (
								<div className='choosDateContainer'>
									<div className='datepicker'>
										<p>من تاريخ</p>
										<input
											type='date'
											required
											onChange={(e) => setTime_start(e.target.value)}
										/>
									</div>

									<div className='datepicker'>
										<p>الى تاريخ</p>
										<input
											type='date'
											required
											onChange={(e) => setTime_end(e.target.value)}
										/>
									</div>
								</div>
							) : (
								''
							)}
							{states.some((e) => e.checked) ? (
								<button
									className='btn btn-warning mt-2'
									onClick={() => {
										achivmentTotlaAPI(Url).bind(null, {
											time_start: Time_start,
											time_end: Time_end,
											...Filter,
										})();
										achivmentSubjectAPI(subjectURL).bind(null, {
											time_start: Time_start,
											time_end: Time_end,
											...Filter,
										})();
									}}>
									بحث
								</button>
							) : (
								''
							)}
						</form>
					</>
					{Total?.total_subject_lessons_duration ? (
						<div className='infos'>
							<div className='circleInfoAll'>
								<span>عدد ساعات الدراسة الكلية:</span>
								<span>{Total.total_subject_lessons_duration}</span>
							</div>

							<div className='circleInfoAll'>
								<span>عدد الساعات المنجزة:</span>
								<span>{Total.total_student_duration}</span>
							</div>
							{renderSubject}
						</div>
					) : (
						<>
							{!error && (
								<div className='text-center' role='status'>
									<span className='spinner-grow'></span>
								</div>
							)}
						</>
					)}
					{error && (
						<div className='d-flex justify-content-center align-items-center w-100'>
							<div
								className='alert alert-danger w-100 text-center'
								role='alert'>
								{error}
							</div>
						</div>
					)}
				</div>
			</LayoutSideStudent>
		</Layout>
	);
};

export default StudentArchiveScreen;
