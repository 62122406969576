import { getUnitComment } from '../../redux-toolkit/reducers/UnitComment/UnitCommentSlice';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { getLoading } from '../../redux-toolkit/reducers/PostCommentUnit/PostCommentUnitSlice';

export default function UnitLogic() {
	const unitId = useParams();
	const [unitDetails, setUnitDetails] = useState({});
	const state = useLocation();
	const token = localStorage.getItem('token');
	const dispatch = useDispatch();
	const UnitComment = useSelector((state) => state.unitComment);
	const loading = useSelector(getLoading);
	console.log('UnitComment', UnitComment);
	useEffect(() => {
		dispatch(getUnitComment(unitId.id));
	}, []);
	const data = UnitComment?.post?.data;

	const [lessons, setLessons] = useState([]);
	const [FinalTraditionalQuizzes, setFinalTraditionalQuizzes] = useState([]);
	const [AboveLevelTraditionalQuizzes, setAboveLevelTraditionalQuizzes] =
		useState([]);

	const getUnitDetails = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_unit_details_by_unit_id/${unitId.id}`
		)
			.then((result) => {
				console.log(result);
				return result.json();
			})
			.then((data) => {
				setUnitDetails(data.data);
				// console.log('unit details',data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllLessons = async () => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id?limit=10`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						unit_id: unitId.id,
					},
				}
			)
			.then((data) => {
				setLessons(data.data.data);
				console.log(data.data.data);
			})
			.catch((error) => console.log('error unit', error));
	};
	const getAllFinalTraditionalQuizzes = async () => {
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_all_final_traditional_quizzes_by_unit_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						unit_id: unitId.id,
						limit: 10,
					},
				}
			)
			.then((data) => {
				setFinalTraditionalQuizzes(data.data.data);
				console.log(data.data.data);
			})
			.catch((error) => console.log('error unit', error));
	};
	// const getAllAboveLevelTraditionalQuizzes = async () => {
	// 	axios
	// 		.get(
	// 			`${process.env.REACT_APP_API_URL}/get_all_above_level_traditional_quizzes_by_unit_id`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 				params: {
	// 					unit_id: unitId.id,
	// 					limit: 10,
	// 				},
	// 			}
	// 		)
	// 		.then((data) => {
	// 			setAboveLevelTraditionalQuizzes(data.data.data);
	// 			console.log(data.data.data);
	// 		})
	// 		.catch((error) => console.log('error unit', error));
	// };

    // const getAllLessons = async () => {
    //     axios.get(`https://java-course.online/api/get_all_lessons_by_unit_id?limit=10`,
    //     {
    //         params: {
    //           'unit_id': unitId.id
    //         }
    //       })
    //     .then(data =>{setLessons(data.data.data);console.log(data.data.data)})
    //     .catch(error => console.log('error unit',error));

 
    // }

	useEffect(() => {
		// console.log('unit id',unitId.id);

		getUnitDetails();
		getAllLessons();
		getAllFinalTraditionalQuizzes();
		// getAllAboveLevelTraditionalQuizzes();
		// console.log("ddddddddddddddddddddddddddddddddddddddllllllllllllllllllllll")
		// console.log(state);
	}, []);

	return {
		unitId,
		unitDetails,
		state,
		data,
		lessons,
		FinalTraditionalQuizzes,
		AboveLevelTraditionalQuizzes,
		loading,
	};
}
