import {
	createAsyncThunk,
	createSlice,
	createDraftSafeSelector,
} from '@reduxjs/toolkit';

export const postCommentUnit = createAsyncThunk(
	'unit/postCommentUnitwrite',
	async ({ unitId, token, opinion }, thunkAPI) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/post_unit_comment`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						comment: opinion,
						unit_id: unitId,
					}),
				}
			);
			let data = await response.json();
			console.log('dataCommentSlice', data);
			if (response.ok) {
				console.log('response from postOpinionSlice', response);
				//   localStorage.setItem("token", data.token)
				window.location.reload();
				return { ...data, token: token, opinion: opinion };
			} else {
				console.log(data);
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
	isError: '',
	isSuccess: '',
};

export const PostCommentUnitSlice = createSlice({
	name: 'unit',
	initialState,
	reducers: {
		clearMsg(state, action) {
			console.log('clear');
			state.isError = '';
			state.isSuccess = '';
		},
	},
	extraReducers: {
		[postCommentUnit.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[postCommentUnit.fulfilled]: (state, action) => {
			state.loading = false;
			console.log('fulfilled_postOpinion', action.payload);
			state.post = action.payload;
			state.isSuccess = 'تم التعليق بنجاح';
		},
		[postCommentUnit.rejected]: (state, action) => {
			console.log('rejected_aboutUs', action);
			state.loading = false;
			state.error = action.payload;
			state.isError = action.payload?.message || 'حدث خطأ بالشبكة';
		},
	},
});

export const getSuccess = createDraftSafeSelector(
	(state) => state.postUnit,
	(opinion) => opinion.isSuccess
);

export const getError = createDraftSafeSelector(
	(state) => state.postUnit,
	(opinion) => opinion.isError
);
export const getLoading = createDraftSafeSelector(
	(state) => state.postUnit,
	(unit) => unit.loading
);

export const { clearMsg } = PostCommentUnitSlice.actions;
export default PostCommentUnitSlice.reducer;
