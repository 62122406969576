import React, { useState, useEffect } from 'react';
import Layout from './../Layout/Layout';
import LibrarySite from '../../components/LibrarySite/LibrarySite';

export default function LibrarySitePage() {
	const [data, setData] = useState([]);
	const [laoding, setlaoding] = useState(true);

	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL}/get_website_library_photo_and_description`
		)
			.then((response) => response.json())
			.then((actualData) => {
				console.log('library sec', actualData);
				setlaoding(false);
				setData(actualData);
			})
			.then((error) => {
				setlaoding(false);
				console.log('error library', error);
			});
		// ).then(res=>res.json()).then(res=>setData(res));

		// console.log("library site info",response)
	}, []);
	// console.log("library site" , data);

	const dataLibrary = data.data;
	console.log('data library', dataLibrary);
	return (
		<Layout>
			{/* <MainHeader /> */}
			{laoding ? (
				<div className='text-center my-5'>
					<div class='spinner-border  mx-auto' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				</div>
			) : (
				<LibrarySite dataLibrary={dataLibrary} />
			)}

			{/* <WinterCoursesPage  dataLibrary={dataLibrary}/> */}
		</Layout>
	);
}
