import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SubjectCards from '../../components/subjectCards';
import SubjectCard from '../../components/SubjectCard';

import Layout from '../Layout/Layout';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';
import MainHeader from '../../components/MainHeader/MainHeader';
function SubjectsPage() {
	// *****************************************************
	const [subjects, setsubjects] = useState([]);
	const token = localStorage.getItem('token');
	const [loading, setloading] = useState(true);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_all_saved_student_subjects`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((response) => {
				console.log(response.data.data);
				setsubjects(response.data.data);
				setloading(false);
			})
			.catch((error) => {
				console.log(error);
				setloading(false);
			});
	}, [token]);

	// *****************************************************
	
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={' المحفوظات'}>
				<div className='container text-center'>
					<h4 style={{ color: '#044364' }} className='fw-bold my-5'>
						المحفوظات
					</h4>
					{loading ? (
						<div className='text-center'>
							<div class='spinner-border mx-auto' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						</div>
					) : (
						<div
							className='row  sections position-relative'
							style={{ justifyContent: 'space-around' }}>
							{subjects.length > 0 ? (
								subjects.map((subject) => <SubjectCard subject={subject} />)
							) : (
								<div className='w-100 text-center '>
									<h3 className='my-5'>{'لا يوجد  محفوظات'}</h3>
								</div>
							)}
						</div>
					)}
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}

export default SubjectsPage;
