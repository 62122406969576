import React from 'react';
import './NumbersTarekeSite.css';
import groupicon from '../../assets/tareke-numbers/community-students.png';
import books_stack_of_three from '../../assets/tareke-numbers/student.png';
import imageThree from '../../assets/tareke-numbers/presentation-skill.png';
import SubTitle from '../SubTitle/SubTitle';

export default function NumbersTarekeSite({ data }) {
	return (
		<>
			{/* start numbers tareke site   */}
			<div className='text-center my-5'>
				{/* <h2 className='fw-bold my-5'>
					<span style={{ color: '#044364' }}> أرقام المنصة </span>
				</h2> */}
				<SubTitle title={'أرقام المنصة'} />
			</div>

			<div className='numbers-tareke-site  text-center my-4 shadow rounded'>
				<div
					className='row  gap-5 '
					style={{ justifyContent: 'center', padding: '40px 0' }}>
					<div className='col-sm-5 col-md-3 col-7  col-lg-2 text-center  mx-auto my-2'>
						<img alt='group' src={groupicon} width='60%' height={'100px'} />
						<h2 className='mt-2'> {data?.number_of_students} +</h2>
						<p className='text-black fs-4'> طالب و طالبة</p>
					</div>
					<div className='col-sm-5 col-md-3 col-7  col-lg-2 text-center  mx-auto  my-2'>
						<img
							alt='books-stack-of-three'
							src={books_stack_of_three}
							width='60%'
							height={'100px'}
						/>
						<h2 className='mt-2'> {data?.number_of_lessons} +</h2>
						<p className='text-black fs-4'> درس و محاضرة</p>
					</div>
					<div className='col-sm-5 col-md-3 col-7	 col-lg-2 text-center  mx-auto  my-2'>
						<img
							alt='communication'
							src={imageThree}
							width='60%'
							height={'100px'}
						/>
						<h2 className='mt-2'> {data?.number_of_subjects} +</h2>
						<p className='text-black fs-4'> مادة</p>
					</div>
				</div>
			</div>
			{/* end numbers tareke site   */}
		</>
	);
}
