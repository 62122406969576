import React from 'react'
import { Link } from 'react-router-dom';
import Error from '../../../alerts/Error/Error';
import './Student_Details.css'
import Student_Details_Logic from './Student_Details_Logic'
export default function Student_Details() {

  const {  loading_student_exit,
    IsError_student_exit,
    data_student_exit,
    loading_student_profile,
    IsError_student_profile,
    data_student_profile
    ,handelpostvisa
    ,postvisaloading,
    postvisaError
    } = Student_Details_Logic() ;


  return (
    <div className='container-fluid Student_Details text-center'>
      {/* <h2 className='titleStudent_Details'>التأشيرات</h2> */}

      {loading_student_profile ? 
      <div className='w-100 text-center my-5'>
      <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
      </div> 
      </div>
      :
      <div class="container">
        <div class="row">
          <div class="col-sm">
          </div>
          <div class="col-sm d-flex gap-4 flex-column justify-content-center">
   <img loading="lazy"  className='studentimg' src='/logo192.png' 

   />
        اسم الطالب {data_student_profile?.data?.name}   
          </div>


          <div class="col-sm d-flex gap-4  flex-column justify-content-center">
          
            <Link className='text-white button_show_profile' to='studentprofile'>
          عرض بروفايل الطالب
          </Link>
          
          <Link className='text-white button_show_profile_Gardian' to='guardianprofile'>
          بروفايل ولي الأمر
          </Link>
          </div>


        </div>
     {data_student_exit?.data&&<h2 className='titleStudent_Details rounded-x'> التأشيرات</h2>}  
        {data_student_exit?.data?.map(oneStudent=>{
          return<div class="row my-2">
          <div class="col-sm unit_title">
            اسم المادة
          </div>
          <div class="col-sm d-flex gap-4 flex-column justify-content-center">
     {oneStudent?.subject_name}
          </div>
          <div class="col-sm ">
          {oneStudent&&<button className='w-100 text-white button_post_visa' onClick={()=>{handelpostvisa({
    subject_id :oneStudent.subject_id ,
     student_id:oneStudent.student_id
})}} >
          إعطاء تأشيرة
          </button>}
          </div>
        </div>
        })}  




      </div>}

      {postvisaError&&<Error  errorText={postvisaError?.response?.data?.message ?  postvisaError?.response?.data?.message :   postvisaError.message} />}


    </div>
  )
}
