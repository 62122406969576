import React, { useEffect } from 'react'
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';
import StudentTraditionalAnsTeacherLogic from './StudentTraditionalAnsTeacherLogic'

const StudentTraditonalAnsTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
  const  {
    traditionalQuizAns
  } = StudentTraditionalAnsTeacherLogic();
  return (
    <Layout>
    <LayoutSideTeacher desc={"الطالب"}>
    
     {traditionalQuizAns && <h1>{traditionalQuizAns.message}</h1>}
    </LayoutSideTeacher>
</Layout>
  )
}

export default StudentTraditonalAnsTeacher