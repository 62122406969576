import React, { useRef, useState, useEffect } from 'react';
import './index.css';

import Lesson from '../../components/lesson';

import { Accordion } from 'react-bootstrap';

import MainHeader from '../../components/MainHeader/MainHeader';

import Button from '../../components/button/index';

import Layout from '../Layout/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import languages from '../../assets/subjects/languages.svg';
import lock from '../../assets/icons/lock.png';
import {
	clearMsg,
	getError,
	getLoading,
	getSubjectComment,
	getSuccess,
	postSubjectComment,
	SubjectCommentsSelector,
} from '../../redux-toolkit/reducers/SubjectComments/SubjectCommentsSlice';

import SubjectLogic from './subjectLogic';

import LeaveComment from '../../components/LeaveComment';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import ToastError from '../../components/ToastSuccessError/ToastError';
import Toast from '../../components/ToastSuccessError/Toast';

import MainComment from '../../components/mainComment';
import ButtonM from '../../components/ButtonM/ButtonM';
import SubTitle from '../../components/SubTitle/SubTitle';
import ModalSubsecrip from './modalSubsecrip';
function SubjectScreen() {
	// Sroll to top
	const [ErrorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState();
	const [loadingg, setloadingg] = useState(false);
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	const handleToast = (e) => {
		e.preventDefault();
		setErrorMessage('عذرا يجب الاشتراك بالكورس لتتمكن من الدخول لأقسامه');
	};

	const handleToastClosed = (e) => {
		e.preventDefault();
		setErrMsg(
			'لايمكن الدخول الى الوحده \n  لاسباب المحتمله: انت غير مشترك بالمادة  \n  يجب ان تنهي الوحدات السابقه اولا '
		);
	};
	const {
		subjectData,
		openLessons,
		subjectUnits,
		SliderRef,
		handleSubscribtion,
		getUnitLessones,
		UnitLessones,
		data,
		setStatus,
		status,
		subjectId,
		isLoadingSubject,
		loadinLessones,
		setRefect
	} = SubjectLogic();
	//************************************************************************************************ */
	const dispatch = useDispatch();
	const { id } = useParams();
	const subjectComments = useSelector(SubjectCommentsSelector);
	//   console.log("params", param);
	const successMsg = useSelector(getSuccess);
	const errorMsg = useSelector(getError);
	const loading = useSelector(getLoading);
	const role = localStorage.getItem('role');
	console.log(loading);
	const navigate = useNavigate();
	const [ErrMsg, setErrMsg] = useState();
	const [paginationComment, setpaginationComment] = useState(3);
	const [prevPaginationComment, setprevPaginationComment] = useState(0);

	const nextComment = () => {
		if (paginationComment < subjectComments.length) {
			setpaginationComment(paginationComment + 3);
			setprevPaginationComment(prevPaginationComment + 3);
		}
	};
	const prevComment = () => {
		if (prevPaginationComment >= 3) {
			setpaginationComment(paginationComment - 3);
			setprevPaginationComment(prevPaginationComment - 3);
		}
	};

	const handleNavigate = () => {
		navigate('/allComments', {
			state: { url: `${process.env.REACT_APP_API_URL}/get_all_comments_by_subject_id/${subjectId?.id}` },
		});
	};

	//************************************************************************************************ */
	//************************************************************************************************ */
	useEffect(() => {
		dispatch(getSubjectComment(id));
	}, []);
	//************************************************************************************************ */

	// ************************************************************

	if (isLoadingSubject) {
		return (
			<Layout>
				<div className='w-100 my-5 text-center'>
					<div class='spinner-border' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<Layout>
			<div className='subjectScreenn'>
				<ToastError
					ErrorMessage={ErrorMessage}
					setErrorMessage={setErrorMessage}
					successMessage={successMessage}
					setSuccessMessage={setSuccessMessage}
				/>
				<Toast
					ErrorMessage={ErrMsg}
					setErrorMessage={setErrMsg}
					successMessage={successMessage}
					setSuccessMessage={setSuccessMessage}
				/>
				{/* <MainHeader /> */}

				<div className='section_header  py-2 '>
					<div className='section_header_layer' />
					<div className='position-relative'>
						<h3 className='fw-bold  mt-5 mb-2 text-white '>
							{subjectData?.subject_name && subjectData.subject_name}
						</h3>
						<div className=' text-white fs-4 mb-5 animated_bottom_border f-bold'>
							{subjectData?.subject_description}
						</div>
						<div className='course_infos_container px-2'>
							<div className='right-content_subject_infos'>
								{subjectData?.subject_req && (
									<div className='whatyouwilllearn_container'>
										<h5 className='text-white fs-3'>المتطلبات : </h5>

										<ul style={{ width: '100%' }} className='text-white p-0'>
											{subjectData.subject_req.map((req) => {
												return <li className='my-3 fs-5 '>{req}</li>;
											})}
										</ul>
									</div>
								)}

								<div className='course_infos'>
									{/* <div className='course_info_itself'>
										<img src={languages} alt='languages' width={'15%'} />

										<span className='mx-1'> اللغات :</span>
										<span className='mx-'> عربية-انكليزية</span>
									</div> */}
									<div className='course_info_itself'>
										{/* <img src={languages} alt='languages' width={'15%'} /> */}
										<span className='mx-2'> عدد الأقسام :</span>
										<span className='mx-1'>
											{subjectData?.number_of_units_in_the_subject &&
												subjectData.number_of_units_in_the_subject}
										</span>
									</div>
									<div className='course_info_itself'>
										{/* <img src={languages} alt='languages' width={'15%'} /> */}
										<span className='mx-2 '>عدد الدروس :</span>
										<span className='mx-1'>
											{subjectData?.number_of_lessons &&
												subjectData.number_of_lessons}
										</span>
									</div>
								</div>
							</div>

							<div className='text-white image-left_side_container_subject_infos'>
								<img
									className='mx-auto image_subject_itself'
									src={`${process.env.REACT_APP_STORAGE_URL}/${
										subjectData?.subject_image && subjectData.subject_image
									}`}
									alt=''
									width={'300px'}
								/>

								<div className='text-white my-2 fs-3'>
									السعر <strong>{subjectData?.price}</strong>
								</div>

								{subjectData && !subjectData?.has_student_subscriped && (
									// localStorage.getItem('token') &&
									// localStorage.getItem('role') == 1 &&
									<ModalSubsecrip
									refetchSubjectData={setRefect}
										name={subjectData?.subject_name}
										price={subjectData?.price}
										btn={
											localStorage.getItem('token') &&
											localStorage.getItem('role') == 1 ? (
												
											!subjectData?.has_student_subscriped && 
											!subjectData?.payment_status == '1' 
											?

											<button
											type='button'
											className='  my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
											>
												اشتراك
											</button> 
												: 
																						<button
											type='button'
											className='btn btn-success'
											data-bs-toggle='modal'
											data-bs-target='nothing'
											// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
											>
												جاري مراجعة معلومات الحوالة
											</button> 
												

											) : (
												<button
													type='button'
													className='  my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
													onClick={() => navigate('/signin/student')}>
													اشتراك
												</button>
											)
										}
									/>
								)}

								<ButtonM
									text={'التواصل مع الاستاذ'}
									onClick={() =>
										window.open(subjectData?.subject_subsription_url)
									}
									className='subscribtion_course_btn my-2 mx-0'
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='subject__videoScection p-0 '>
					{/* <div className='subject__videoScection__explanation'>
						<h1>
							<img src={chalkImg} alt='title' /> فيديو تعريفي بالمادة
						</h1>
						<p className='mx-4 mt-5' style={{ fontSize: '1.2rem' }}>
							{subjectData.subject_description}
						</p>
					</div> */}
					<div
						className='top_video_course_container requirments_container shadow border rounded my-5'
						style={{ maxWidth: '900px' }}>
						<div className='subject__videoScection__explanation text-black text-center w-100 '>
							<SubTitle title={'عن الكورس'} />
							<p
								className='mx-md-4 mt-5'
								style={{ fontSize: '1.5rem', lineHeight: '1.475' }}>
								{subjectData.subject_description}
							</p>
						</div>

						{subjectData?.introductry_video ? (
							<div className='video-container mx-auto  '>
								<div className=' my-4 mx-auto  video_container '>
									<iframe
										className='video_section'
										src={`${process.env.REACT_APP_STORAGE_URL}/${subjectData?.introductry_video}`}
										title='YouTube video'
										allowFullScreen></iframe>{' '}
								</div>
							</div>
						) : (
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						)}
					</div>
				</div>

				<div className='teacher_infos_container shadow border rounded'>
					<div className='text-center'>
						<h1 className='mb-5' style={{ color: '#000' }}>
							المدرب لهذه الدورة{' '}
							{subjectData?.teacher_name && subjectData.teacher_name}
						</h1>
						<img
							style={{
								borderRadius: '50%',
								border: '2px solid #dc3545',
								width: '150px',
								height: '150px',
								boxShadow:
									'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
							}}
							src={`${process.env.REACT_APP_STORAGE_URL}/${
								subjectData?.subject_image && subjectData.teacher_image
							}`}
							alt='title'
						/>
						<p className='mx-sm-5 w-md-50 mt-5 text-black fs-5'>
							{subjectData?.teacher_description &&
								subjectData.teacher_description}
						</p>

						{/* <div className='subject__videoScection__video'> */}
						{/* <img
							style={{
								width: '380px',
								marginLeft: '40px',
								height: '300px',
								borderRadius: '15px',
							}}
							src={`${process.env.REACT_APP_STORAGE_URL}/${
								subjectData?.teacher_image && subjectData.teacher_image
							}`}
							alt="teacher's pic"
						/> */}

						{subjectData?.teacher_url && (
							<ButtonM
								text={'تواصل مع الأستاذ'}
								onClick={() => window.open(subjectData?.teacher_url)}
								className='mt-4 mx-auto'
							/>
							// <p
							// 	className='btn btn-danger my-5 contact_trainer_btn    '
							// 	style={{ borderRadius: '20px' }}>
							// 	<a
							// 		style={{ fontSize: '1.2rem' }}
							// 		className='text-white mx-auto p-2 '
							// 		href={subjectData?.teacher_url}>
							// 		تواصل مع الاستاذ
							// 	</a>
							// </p>
						)}
					</div>
				</div>

				{openLessons?.length > 0 && (
					<SubTitle title={'الدروس المفتوحة'} className='mt-3' />
				)}

				{openLessons?.length > 0 && (
					<div className='lessons_container_content shadow border rounded'>
						<div className='lessons__container  row justify-content-center my-3 mx-auto'>
							{openLessons &&
								openLessons?.map((lesson, index) => {
									return (
										<>
											{index % 3 == 0 ? (
												<div className='col-md-12 col-12 my-2'>
													<Lesson
														// access={lesson.access}
														btnType={'save'}
														id={lesson.lesson_id}
														lessonImage={lesson?.lesson_image}
														key={id}
														btnText='البدء بالدرس'
														lesson_points={lesson.lesson_points}
														lessonName={lesson.lesson_name}
														lessonNum={lesson.lesson_order}
														lessonTime={lesson.lesson_duration}
														lesson_description={lesson.lesson_description}
													/>
												</div>
											) : (
												<div className='col-md-6 col-12 my-2'>
													<Lesson
														// access={lesson.access}
														btnType={'save'}
														id={lesson.lesson_id}
														lessonImage={lesson?.lesson_image}
														key={id}
														btnText='البدء بالدرس'
														lesson_points={lesson.lesson_points}
														lessonName={lesson.lesson_name}
														lessonNum={lesson.lesson_order}
														lessonTime={lesson.lesson_duration}
														lesson_description={lesson.lesson_description}
													/>
												</div>
											)}
										</>
									);
								})}
						</div>
					</div>
				)}

				{subjectUnits?.length > 0 && (
					<div className='sectiones_course  mx-auto my-5'>
						{subjectUnits?.length > 0 && (
							// <h3 className='mx-5 my-5' style={{ color: '#044364' }}>
							// 	أقسام الكورس :
							// </h3>

							<SubTitle title={'أقسام الكورس'} />
						)}

						{
							<div className='units__container shadow border rounded'>
								<Accordion defaultActiveKey={['0']}>
									{subjectUnits &&
										subjectUnits.map((subjectunit, index) => {
											return (
												<Accordion.Item
													style={{ borderRadius: '10px', margin: '10px 0' }}
													eventKey={index}
													key={index}
													disabled
													onClick={() => {
														getUnitLessones(subjectunit.unit_id);
														setStatus('loading');
													}}>
													<Accordion.Header>
														<p
															className='unit_name_itself'
															style={{
																width: '40%',
																textAlign: 'right',
																fontSize: '1.2rem',
																fontWeight: '550',
															}}>
															{subjectunit.unit_name}
														</p>
														{localStorage.getItem('token') ? (
															subjectunit?.access && subjectData?.has_student_subscriped  ? (
																<Link
																	to={`/unitt/${subjectunit.unit_id}`}
																	className='mt-4'
																	state={
																		subjectData?.subject_name &&
																		subjectData.subject_name
																	}>
																	<ButtonM>ذهاب الى القسم</ButtonM>
																</Link>
															) : (
																<div className='mt-4'>
																	<ButtonM
																		text={'ذهاب الى القسم'}
																		onClick={handleToast}></ButtonM>
																</div>
															)
														) : (
															<div className='mt-4'>
																<ButtonM text={'ذهاب الى القسم'} onClick={handleToast}>
																	{/* <button onClick={handleToast}>
																	</button> */}
																</ButtonM>
															</div>
														)}
													</Accordion.Header>
													<Accordion.Body>
														{subjectunit?.access &&
														localStorage.getItem('token') ? (
															<div className='row'>
																{UnitLessones?.length > 0 ? (
																	UnitLessones?.map((lesson, index) =>
																		status === 'data' ? (
																			<>
																				{index % 3 == 0 ? (
																					<div
																						className='col-lg-12 col-md-12 col-sm-12 col-12 text-center'
																						key={index}>
																						{subjectData?.has_student_subscriped ? (
																							<Link
																								to={`/lesson/${lesson.lesson_id}`}
																								className='text-black'>
																								<img
																									style={{
																										borderRadius: '10px',
																										width: '120px',
																										height: '120px',
																										boxShadow:
																											'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
																									}}
																									src={`${
																										process.env
																											.REACT_APP_STORAGE_URL
																									}/${
																										lesson?.lesson_image &&
																										lesson.lesson_image
																									}`}
																									alt=''
																								/>
																								<div
																									className='unit_description_itself'
																									style={{
																										width: '55%',
																										textAlign: 'center',
																										margin: 'auto',
																									}}>
																									<span
																										style={{ color: '#044364' }}
																										className='lesson__number mx-1'>
																										{lesson.lesson_name}:
																									</span>
																									<span className='lesson__number'>
																										{lesson.lesson_description}
																									</span>
																								</div>
																							</Link>
																						) : (
																							<>
																								<img
																									style={{
																										borderRadius: '10px',
																										width: '120px',
																										height: '120px',
																										boxShadow:
																											'0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
																									}}
																									src={`${
																										process.env
																											.REACT_APP_STORAGE_URL
																									}/${
																										lesson?.lesson_image &&
																										lesson.lesson_image
																									}`}
																									alt=''
																								/>
																								<div
																									className='unit_description_itself'
																									style={{
																										width: '55%',
																										textAlign: 'center',
																										margin: 'auto',
																									}}>
																									<span
																										style={{ color: '#044364' }}
																										className='lesson__number mx-1'>
																										{lesson.lesson_name}:
																									</span>
																									<span className='lesson__number'>
																										{lesson.lesson_description}
																									</span>
																								</div>
																							</>
																						)}
																					</div>
																				) : (
																					<div
																						className='col-lg-6 col-md-6 col-sm-12 col-12 mt-3 text-center'
																						key={index}>
																						{subjectData?.has_student_subscriped ? (
																							<Link
																								to={`/lesson/${lesson.lesson_id}`}
																								className='text-black'>
																								<img
																									style={{
																										borderRadius: '15px',
																										width: '100px',
																										height: '100px',
																									}}
																									src={`${
																										process.env
																											.REACT_APP_STORAGE_URL
																									}/${
																										lesson?.lesson_image &&
																										lesson.lesson_image
																									}`}
																									alt=''
																								/>
																								<div className='mt-2'>
																									<span
																										style={{ color: '#044364' }}
																										className='lesson__number mx-1'>
																										{lesson.lesson_name}:
																									</span>
																									<span className='lesson__number'>
																										{lesson.lesson_description}
																									</span>
																								</div>
																							</Link>
																						) : (
																							<>
																								<img
																									style={{
																										borderRadius: '15px',
																										width: '100px',
																										height: '100px',
																									}}
																									src={`${
																										process.env
																											.REACT_APP_STORAGE_URL
																									}/${
																										lesson?.lesson_image &&
																										lesson.lesson_image
																									}`}
																									alt=''
																								/>
																								<div className='mt-2'>
																									<span
																										style={{ color: '#044364' }}
																										className='lesson__number mx-1'>
																										{lesson.lesson_name}:
																									</span>
																									<span className='lesson__number'>
																										{lesson.lesson_description}
																									</span>
																								</div>
																							</>
																						)}
																					</div>
																				)}
																			</>
																		) : (
																			<div>loading .......</div>
																		)
																	)
																) : loading ? (
																	<div class='spinner-border' role='status'>
																		<span class='sr-only'>Loading...</span>
																	</div>
																) : loadinLessones ? (
																	<div className='text-center'>
																		<div class='spinner-border' role='status'>
																			<span class='sr-only'>Loading...</span>
																		</div>
																	</div>
																) : (
																	<div className='jlkjlkk'>
																		لم يتم إضافة دروس لهذا القسم بعد
																	</div>
																)}
															</div>
														) : (
															<p>
																يجب الاشتراك بهذا الكورس لتستطيع الدخول لهذا
																القسم ... تواصل مع الاستاذ الآن للاشتراك بهذا
																الكورس{' '}
																{
																	// <div
																	// 	className='btn btn-success'
																	// 	onClick={() =>
																	// 		window.open(subjectData?.teacher_url)
																	// 	}>
																	// 	تواصل
																	// 	</div>
																	<button
																		type='button'
																		className='  my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
																		data-bs-toggle='modal'
																		data-bs-target='#exampleModal'
																		// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
																	>
																		اشتراك
																	</button>
																}
																{/* {index == 0 */}
																{/* ? 'يجب الاشتراك بهذا الكورس لتستطيع الدخول	لهذا القسم' */}
																{/* : 'هذه الوحدة مقفلة لأنك لم تقم بانهاءالوحدة السابقة أو لم تحصل بعد على تأشيرة خروج من قبل الأستاذ .. الرجاء طلب تأشيرة الخروج من الوحدةالسابقة في حال كنت قد أنهيت جميع دروسها ومن ثم	انتظار الأستاذ لقبول طلبك .'} */}
															</p>
														)}
													</Accordion.Body>
												</Accordion.Item>
											);
										})}
								</Accordion>
							</div>
						}
					</div>
				)}

				{/* <TitleSection title='أبرز التعليقات' />

				<Slider isComments page={'subject'} id={id}>
					{subjectComments?.map((item, index) => (
						<Comment comment={item} key={index} />
					))}
				</Slider> */}
				<div className='comments container'>
					<SubTitle title={'أبرز التعليقات'} />
					{subjectComments && subjectComments?.length > 0 ? (
						<>
							{/* <h4 style={{ color: '#044364' }} className='fw-bold my-3'>
								أبرز التعليقات
							</h4> */}
							{subjectComments?.map((el, index) => {
								if (index < paginationComment && prevPaginationComment <= index)
									return (
										<MainComment
											name={el?.student_name}
											date={el?.date_time}
											text={el?.comment}
											img={`${process.env.REACT_APP_STORAGE_URL}/${el?.student_image}`}
										/>
									);
							})}
							{/* <p
								onClick={handleNavigate}
								className='btn btn-info CursorLinkopinion' style={{marginRight: 'auto !important'}} >
								عرض المزيد
							</p> */}
							<ButtonM
								onClick={handleNavigate}
								text='عرض المزيد'
								className={'showMore_subject_btn'}
							/>
						</>
					) : (
						<p
							style={{ borderRadius: '10px' }}
							className='p-3 text-center border w-50 w-sm-75 m-auto my-5'>
							لا يوجد تعليقات الى الأن
						</p>
					)}
					{subjectComments?.length <= 3 ? (
						<> </>
					) : (
						subjectComments?.length > 0 && (
							<div className='row justify-content-center'>
								<Button
									text={<span className='text-danger'>السابق</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={prevComment}
									backgroundColor='white'
								/>
								<Button
									text={<span className='text-danger'>التالي</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={nextComment}
									backgroundColor='white'
								/>
							</div>
						)
					)}
				</div>
				{role == 1 && (
					<LeaveComment
						postDataComment={postSubjectComment}
						subjectId={id}
						loading={loading}
					/>
				)}
				<ToastSuccessError
					successMessage={successMsg}
					ErrorMessage={errorMsg}
					clearMsg={clearMsg}
				/>
				{/* <div className='text-center'>
					{subjectData?.subject_subsription_url &&
						!subjectData?.has_student_subscriped && (
							<p
								className='btn btn-danger my-5    '
								style={{
									width: '19.5rem',
									borderRadius: '20px',
									padding: '20px 20px',
								}}>
								<a
									style={{ fontSize: '1.2rem' }}
									className='text-white mx-auto my-4'
									href={subjectData?.subject_subsription_url}
									target='_blank'>
									{`اشترك الآن في كورس  ${subjectData?.subject_name} `}
								</a>
							</p>
						)}
				</div> */}
			</div>
		</Layout>
	);
}

export default SubjectScreen;
