import React from 'react'
import { Outlet } from 'react-router-dom'
import Showtypetest from '../../components/Show_type_test/Show_Type_test'

export default function Create_test() {
  return (<>
<Showtypetest isCreateQuiz={true} />
</>
  )
}
