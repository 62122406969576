import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { GetData } from '../../../../customhook/getDatawithAuth';
import {useForm} from 'react-hook-form'
import { useUploadForm } from '../../../../customhook/Uploadfiles';
export default function Show_Asnwear_Unit_Tranditiosnal_Logic() {
  const {QuizId,StudentId} = useParams();
  console.log('quiz id , student id',QuizId,StudentId)
  const {getDatafrom, isSuccess, progress,IsLoading,IsError,data}  = GetData(`/get_student_traditional_quiz_answers_by_student_id_and_tq_id?traditional_quiz_id=${QuizId}&student_id=${StudentId}`)
  const {getDatafrom:Get_Qustions_Quiz, isSuccess:qustions_Quiz_success,IsLoading:qustions_Quiz_IsLoading ,IsError:qustions_Quiz_IsError,data:qustions_Quiz_data}  = GetData(`/get_traditional_quiz_questions_files_by_id/${QuizId}`)
  const {uploadForm:post_correction_Quiz, isSuccess:post_correction_success,IsLoading:post_correction_IsLoading ,IsError:post_correction_IsError,data:post_correction_data} = useUploadForm('/correcting_traditional_quiz_answer_by_student_id_and_tq_id')
  const { register  ,handleSubmit, formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
    question:'',
  answer:'',
  transable:0
}});

console.log('blaaaaaaaaaaaaaa');

const onSubmit = data => {
   const formdata = new FormData();
   formdata.append('teacher_notes',data.teacher_notes);
   formdata.append('deserved_mark',data.deserved_mark);
   formdata.append('traditional_quiz_id',QuizId);
   formdata.append('student_id',StudentId);

   post_correction_Quiz(formdata);
}
    useEffect(()=>{
      getDatafrom(); 
      Get_Qustions_Quiz();
    },[])

  return {
    isSuccess, progress,IsLoading,IsError,data,
    Get_Qustions_Quiz,qustions_Quiz_IsLoading,qustions_Quiz_IsError,qustions_Quiz_data
    ,post_correction_success,post_correction_IsLoading,post_correction_IsError
    ,
    register,
errors,
isDirty,
isValid
    ,onSubmit,handleSubmit
  }
}