import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_unit_faqs_by_id } from '../../../../../redux-toolkit/reducers/Units/UnitSlice';
import DeleteModal from '../../../../DeleteModal';
import Show_Unit_Faqs_Logic from './Show_Unit_Faqs_Logic';
export default function Show_Unit_Faqs() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const {
		dispatch,
		data,
		SubjectId,
		isLoading,
		isError,
		error,
		refetch,
		loading,
		deleteFaq,
	} = Show_Unit_Faqs_Logic();
	console.log('Data123', data);
	return (
		<div className='container my-5'>
			{isLoading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{
				<div className=' '>
					{data?.data?.data.length > 0 && (
						<table className='table_style table-bordered text-center w-100   table-striped mb-5 '>
							<thead>
								<tr>
									<th className='first_child'> #</th>
									<th> السؤال </th>
									<th> الجواب</th>
									<th></th>
									<th className='last_child'></th>
								</tr>
							</thead>
							{data?.data?.data && (
								<tbody style={{ lineHeight: '60px' }}>
									{data?.data?.data?.map((faq, index) => (
										<tr key={faq.id}>
											<td>{index}</td>
											<td> {faq.question} </td>
											<td> {faq.answer} </td>

											<td>
												<Link
													to={`edit_unit_faqs/${faq.id}`}
													className='btn btn1'>
													{' '}
													تعديل{' '}
												</Link>{' '}
											</td>
											<td>
												<button
													type='button'
													class='btn btn2'
													data-bs-toggle='modal'
													data-bs-target='#exampleModal'
													onClick={() => setSelectedAutomatedQuizIe(faq.id)}>
													حذف
												</button>
											</td>
											{/* <td><Link to={`edit_unit/${faq.unit_id}`}   className='btn btn-success' >  تعديل الوحدة </Link></td> */}
											{/* {(index===(data?.data?.length-1))&&<td> <button className='btn btn-danger' onClick={()=>{dispatch(delete_unit_by_id({UnitId:Unit.unit_id,SubjectId}))}}> حذف الوحدة  </button> </td> }   */}
										</tr>
									))}
								</tbody>
							)}
							{isError && (
								<div class='d-flex justify-content-center'>
									<label className='text-danger'>{error.message}</label>
								</div>
							)}
						</table>
					)}
					<Link className='btn btn1' to={`add_unit_faqs`} state={{ SubjectId }}>
						أضف سؤال شائع
					</Link>
				</div>
			}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا السؤال ؟'}
				handleDelete={() => deleteFaq(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
