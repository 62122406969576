import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import bg from './bg.png';
import img from '../../assets/AboutUs/Mask Group 2.png';
import { Fade } from 'react-reveal';
function UnitCard(props) {
	const { unit } = props;
	const unit_id = unit.unit_id;
	const { state } = useLocation();
	const subjectId = state?.id;
	// console.log(subject);
	return (
		<div
			className='col-lg-4 col-md-6 col-sm-9 col-xs-11 col-11 '
			style={{ maxWidth: '350px' }}>
			<Fade right>
				<Link
					className='card section_Card mx-auto    my-3 '
					to={`/lessones?id=${unit_id}`}
					state={{ id: subjectId, name: unit.unit_name }}>
					<img
						className='Terms_images mb-1'
						// variant="top"
						width={'100%'}
						height='250px'
						// src={
						// 	process.env.REACT_APP_STORAGE_URL +
						// 	subsection.sub_section_image
						// }
						src={process.env.REACT_APP_STORAGE_URL + unit?.unit_cover}
						// src={`${process.env.REACT_APP_STORAGE_URL}/${unit.unit_cover}`}
					/>
					<div className='section_desc'>
						<h4 class=' section_title_itself  w-100 rounded-x '>
							{unit.unit_name}
						</h4>
						<p
							style={{ color: 'black', fontSize: '1rem' }}
							className='section_desc_itself'>
							{unit.unit_description}
						</p>
					</div>

					{/* <Button variant="primary fw-bold">اختيار</Button> */}
				</Link>
			</Fade>
		</div>
	);
}

export default UnitCard;
