import axios from 'axios';
import React, { useState } from 'react';
import { deleteLessonAttachmentAPI } from './LessonAttachmentAPI';

function DeleteModal({ fileId, refresh, setRefresh }) {
	//**************************************************** */
	//**************************************************** */
	return (
		<div
			className='modal fade h-75'
			id='deleteAttatchmentModal'
			tabIndex='-1'
			aria-labelledby='exampleModalLabel'
			aria-hidden='true'>
			<div className='modal-dialog  bg-white'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							تأكيد الحذف
						</h5>
					</div>
					<div className='modal-body'>
						<p className='h4'>هل تريد بالتأكيد حذف هذا الملف؟</p>
					</div>
					<div className='modal-footer d-flex justify-content-start'>
						<button
							type='button'
							className='btn btn2'
							data-bs-dismiss='modal'
							onClick={deleteLessonAttachmentAPI.bind(
								null,
								fileId,
								setRefresh,
								refresh
							)}>
							موافق
						</button>
						<button type='button' className='btn btn1' data-bs-dismiss='modal'>
							إلغاء
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteModal;
