import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
const LessonRequiermentInput = ({ data }) => {
  const [req, setReq] = useState([]);
  const [value, setValue] = useState();
  const deleteFromArray = (array, index) => {
    const x = array.filter((r, i) => index !== i);
    setReq(x);
  };
  useEffect(() => {
    console.log("inside");
    data(req);
  }, [req]);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <InputGroup className="mt-3 w-25 ">
        <Form.Control
          placeholder="المتطلبات"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
        />
        <Button
          variant="primary"
          id="button-addon2"
          className="rounded"
          onClick={() => {
            setReq([...req, value]);
            setValue("");
          }}
        >
          إضافة
        </Button>
      </InputGroup>
      <div
        style={{
          width: "15vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {req.map((re, i) => (
          <div
            style={{
              width: "15vw",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "10px",
            }}
            className=" fs-4"
          >
            {re}
            <Button
              variant="danger"
              className="me-2"
              onClick={deleteFromArray.bind(null, req, i)}
            >
              X
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LessonRequiermentInput;
