import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './LibrarySite.css';
// import bg from './bg.png';
// import Image from '../../assets/maincontent/nav.png';
import img from '../../assets/AboutUs/Mask Group 2.png';
import ButtonM from '../ButtonM/ButtonM';
import ModalSubsecrip from '../../pages/subjectScreen/modalSubsecrip';
import { useState } from 'react';
function SectionCard({ id, name, image, is_free, price, access, index , payment_status , desc }) {
	//******************************************************************* */
	//******************************************************************* */
	//******************************************************************* */
	const [isactive, setIsActive] = React.useState(
		localStorage.getItem('is_active') || 0
	);

	const [refect,setRefect] = useState(true);

	const navigate = useNavigate();
	return (
		<>
			{/* <Link to={`/LibrarySite/${id}`}>
      <div className="card " style={{ borderRadius: "16px" }}>
        <div className="card-body" style={{ padding: "0px" }}>
          <div className=" h-100 w-100" style={{backgroundColor:"#235C70",borderRadius:"25px"}}>
              <img
                src={`${process.env.REACT_APP_STORAGE_URL}/${image}`}
                className="img-fluid h-50 w-100 IMG_LIBRARY_SITE"
                style={{ objectFit: "cover",borderRadius:"25px",padding:'15px' }}
              />
           
            <p className="text-white text-center mt-2 h5 mt-1">{name}</p>
            {false ? (
              <p className="text-white mt-2 text-center">عنصر مجاني</p>
            ) : (
              <button className="btn btn-primary ">اشترك</button>
            )}
          </div>
        </div>
      </div>
      </Link> */}
	  {/* <ModalSubsecrip isForLibarary /> */}
			{index % 2 == 0 ? (
				<>
					<div className=' library_section_card shadow border rounded d-flex align-items-center justify-content-center '>
						{/* {isactive == 0 ? <div className='locked'></div> : null} */}

						<div
							className=''
							style={{
								alignItems: 'center',
								maxWidth: '1000px',
								margin: '0px auto',
							}}>
							<div className='top_content'>
								<p className=' mb-4 h5  fw-bold' style={{ textAlign: 'right' }}>
									{name}
								</p>
								<div className="text-end h5 fw-bold">
								السعر: {price == 0 ? "مجاني" : price}
								</div>
								<p
									style={{
										textAlign: 'right',
										lineHeight: '1.875',
										paddingBottom: '10px',
									}}>
									{/* بيئة ترعى الإبداع والتفوق معتمدة على نظام تعليمي يستند إلى
									التكنولوجيا المتطورة والذكاء الاصطناعي ليتمكن طالب الشهادتين
									الاعدادية والثانوية بفرعيها الأدبي والعلمي من مواصلة تعليمه
									ومتابعة مواده الدراسية بيئة ترعى الإبداع والتفوق معتمدة على
									نظام تعليمي يستند إلى التكنولوجيا المتطورة والذكاء الاصطناعي
									ليتمكن طالب الشهادتين الاعدادية والثانوية بفرعيها الأدبي
									والعلمي من مواصلة تعليمه ومتابعة مواده الدراسية */}
								</p>
								<div className='text-center' style={{zIndex: '9999999'}}>
								 <ModalSubsecrip
									refetchSubjectData={setRefect}
									name={name}
									price={'12000'}
									isForLibarary
									libraryId={id}
										btn={
										is_free == 1 ? <Link to={`/LibrarySite/${id}`} state={{desc: desc}}>
												<ButtonM text={'عرض'}  />
											</Link>
										:
										localStorage.getItem('token') &&
										localStorage.getItem('role') == 1 ? (
											
										// !subjectData?.has_student_subscriped && 
										!access && 
										!payment_status == '1' 
										?

										<button
										type='button'
										className='  my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
										data-bs-toggle='modal'
										data-bs-target='#exampleModal'
										// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
										>
											اشتراك
										</button> 
											: 
										<button
										type='button'
										className='btn btn-success'
										data-bs-toggle='modal'
										data-bs-target='nothing'
										// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
										>
											جاري مراجعة معلومات الحوالة
										</button> 
											

										) : (
											<button
												type='button'
												className='my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
												onClick={() => navigate('/signin/student')}>
												اشتراك
											</button>
										)
									}
									/>
									
								</div>
							</div>
							<div className=''>
								<img
									src={`${process.env.REACT_APP_STORAGE_URL}/${image}`}
									// src={img}
									className=' IMG_LIBRARY_SITE shadow'
									style={{
										objectFit: 'cover',
										borderRadius: '15px',
									}}
								/>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className=' library_section_card shadow border rounded d-flex align-items-center justify-content-center '>
						{/* {isactive == 0 ? <div className='locked'></div> : null} */}

						<div
							className=' '
							style={{
								alignItems: 'center',
								maxWidth: '1000px',
								margin: '0px auto',
							}}>
							<div className=''>
								<img
									src={`${process.env.REACT_APP_STORAGE_URL}/${image}`}
									// src={img}
									className=' IMG_LIBRARY_SITE shadow'
									style={{
										objectFit: 'cover',
										borderRadius: '15px',
									}}
								/>
							</div>
							<div className='top_content'>
								<p className=' mb-4 h5  fw-bold' style={{ textAlign: 'right' }}>
									{name}
								</p>
								<div className="text-end h5 fw-bold">
									السعر: {price == 0 ? "مجاني" : price}
								</div>
								<p
									style={{
										textAlign: 'right',
										lineHeight: '1.875',
										paddingBottom: '10px',
									}}>
									{/* بيئة ترعى الإبداع والتفوق معتمدة على نظام تعليمي يستند إلى
									التكنولوجيا المتطورة والذكاء الاصطناعي ليتمكن طالب الشهادتين
									الاعدادية والثانوية بفرعيها الأدبي والعلمي من مواصلة تعليمه
									ومتابعة مواده الدراسية بيئة ترعى الإبداع والتفوق معتمدة على
									نظام تعليمي يستند إلى التكنولوجيا المتطورة والذكاء الاصطناعي
									ليتمكن طالب الشهادتين الاعدادية والثانوية بفرعيها الأدبي
									والعلمي من مواصلة تعليمه ومتابعة مواده الدراسية */}
								</p>
								<div className='text-center'>
								<ModalSubsecrip
									refetchSubjectData={setRefect}
									name={name}
									price={'12000'}
									isForLibarary
									libraryId={id}
										btn={
											is_free == 1 ? <Link to={`/LibrarySite/${id}`} state={{desc: desc}}>
												<ButtonM text={'عرض'}  />
											</Link>
										:
										localStorage.getItem('token') &&
										localStorage.getItem('role') == 1 ? (
											
										// !subjectData?.has_student_subscriped && 
										!access && 
										!payment_status == '1' 
										?

										<button
										type='button'
										className='my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
										data-bs-toggle='modal'
										data-bs-target='#exampleModal'
										// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
										>
											اشتراك
										</button> 
											: 
										<button
										type='button'
										className='btn btn-success'
										data-bs-toggle='modal'
										data-bs-target='nothing'
										// onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}
										>
											جاري مراجعة معلومات الحوالة
										</button> 
											

										) : (
											<button
												type='button'
												className='my-2 mx-0 border-0 px-5 mx-5  subscribtion_course_btn buttonM buttonM-2	 py-2'
												onClick={() => navigate('/signin/student')}>
												اشتراك
											</button>
										)
									}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

export default SectionCard;
