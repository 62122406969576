import {
	createAsyncThunk,
	createDraftSafeSelector,
	createSlice,
} from '@reduxjs/toolkit';
import { Navigate, useNavigate } from 'react-router-dom';

const users = { 1: 'student', 2: 'teacher', 3: 'father' };

export const fetchSignIn = createAsyncThunk(
	'SIGN/signin',
	async (formdata, thunkAPI) => {
		try {
			const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formdata),
			});
			console.log(response);
			let data = await response.json();
			if (response.ok) {
				localStorage.setItem('role', Number(formdata.role_id));
				localStorage.setItem('token', data.data.token);

				return { token: data.data.token, role_id: Number(formdata.role_id) };
			} else {
				console.log(data);
				console.log(response);
				return thunkAPI.rejectWithValue(data.message);
			}
		} catch (e) {
			console.log(e.message);
			return thunkAPI.rejectWithValue(e.message || e.response.message);
		}
	}
);

// export const fetchSignIn = createAsyncThunk(
//   "SIGN/signin",
//   async ({
//     username,
//      password,
//       email,
//       class:classstu,
//     image,
//       gender,
//     certificates,
//      school,
//       weaknesses_material,
//      languages,
//       strong_material,
//      name,
//      father_name ,
//        mother_name ,
//       birthday ,
//       address ,
//       city
//       }, thunkAPI) => {
//     try {
//       const response = await fetch(
//         "http://mywaystudent.icrcompany.net/api/signup",
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({
//               username,
//               password,
//                email,
//                classstu,
//              image,
//                gender,
//              certificates,
//               school,
//                weaknesses_material,
//               languages,
//                strong_material,
//               name,
//               father_name ,
//                 mother_name ,
//                birthday ,
//                address ,
//                city
//           }),
//         }
//       )
//       let data = await response.json()
//       console.log("data", data)
//       if (response.status === 200) {
//           console.log(response);
//         localStorage.setItem("token", data.token)
//         return { ...data, username: name, email: email }
//       } else {
//           console.log(data);
//         return thunkAPI.rejectWithValue(data)
//       }
//     } catch (e) {
//       console.log("Error", e.response.data)
//       return thunkAPI.rejectWithValue(e.response.data)
//     }
//   }
// )

export const fetchSignUP = createAsyncThunk(
	'SIGN/signup',
	async (
		{
			username,
			password,
			email,
			class: classstu,
			image,
			gender,
			certificates,
			school,
			weaknesses_material,
			languages,
			strong_material,
			name,
			father_name,
			mother_name,
			birthday,
			address,
			city,
		},
		thunkAPI
	) => {
		try {
			const response = await fetch(
				'http://mywaystudent.icrcompany.net/api/signup',
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						username,
						password,
						email,
						classstu,
						image,
						gender,
						certificates,
						school,
						weaknesses_material,
						languages,
						strong_material,
						name,
						father_name,
						mother_name,
						birthday,
						address,
						city,
					}),
				}
			);
			let data = await response.json();

			if (response.status === 200) {
				localStorage.setItem('token', data.token);
				return { ...data, username: name, email: email };
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

const initialState = {
	token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
	role_id: localStorage.getItem('role')
		? Number(localStorage.getItem('role'))
		: 0,
	signin: {
		isFetching: false,
		isSuccess: false,
		isError: false,
		errorMessage: null,
		successMsg: '',
		errorMsg: '',
	},
};

const SIGNSLICE = createSlice({
	name: 'SIGN',
	initialState,
	reducers: {
		logout: (state) => {
			state.token = null;
			state.role_id = 0;
		},
		signupdone: (state, action) => {
			state.token = action.payload.token;
			state.role_id = action.payload.role_id;
		},
		clearMsg(state, action) {
			state.signin.errorMsg = '';
			state.signin.successMsg = '';
		},
	},
	extraReducers: {
		[fetchSignIn.fulfilled]: (state, action) => {
			state.token = action.payload.token;
			state.role_id = action.payload.role_id;
			state.signin.isSuccess = true;
			state.signin.isFetching = false;
			state.signin.errorMessage = '';
			state.signin.successMsg = 'تم تسجيل الدخول بنجاح';
		},
		[fetchSignIn.pending]: (state, action) => {
			state.signin.isFetching = true;
			state.signin.isError = false;
			state.signin.errorMessage = '';
		},
		[fetchSignIn.rejected]: (state, action) => {
			state.signin.isFetching = false;
			state.signin.isError = true;
			state.signin.errorMessage = action.payload;
			console.log('error----->', action.payload);
			state.signin.errorMsg = action.payload;
		},
	},
});
export const getSuccess = createDraftSafeSelector(
	(state) => state.SIGN,
	(SIGN) => SIGN.signin.successMsg
);

export const getError = createDraftSafeSelector(
	(state) => state.SIGN,
	(SIGN) => SIGN.signin.errorMsg
);
export const { signupdone, logout, clearMsg } = SIGNSLICE.actions;

export default SIGNSLICE.reducer;
