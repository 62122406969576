import React, { useEffect, useState } from 'react';
import HowSiteCanHelpYou from '../../../components/HowSiteCanHelpYou/HowSiteCanHelpYou';
import LeaveComment from '../../../components/LeaveComment';
import LibrarySiteMin from '../../../components/LibrarySiteMin/LibrarySiteMin';
import MainContent from '../../../components/MainContent/MainContent';
import NumbersTarekeSite from '../../../components/NumbersTarekeSite/NumbersTarekeSite';
import OpinionTarekeStudents from '../../../components/OpinionTarekeStudents/OpinionTarekeStudents';
import Sections from '../../../components/sections/Sections';
import SiteNewspaper from '../../../components/SiteNewspaper/SiteNewspaper';
import ModerestOffers from '../../../components/StartModerestOffers/ModerestOffers';
import VideoHowToUseSite from '../../../components/VideoHowToUseSite/VideoHowToUseSite';
import WhoAreWe from '../../../components/WhoAreWe/WhoAreWe';
import { SwiperSlide } from 'swiper/react';
import Roll from 'react-reveal/Roll';
import './Main_home.css';
import { Link } from 'react-router-dom';
import SwiperSliders from '../../../components/SwiperSlider';
import SubTitle from '../../../components/SubTitle/SubTitle';
import ButtonM from '../../../components/ButtonM/ButtonM';
import {
	postOpinionfun,
	getSuccess,
	getError,
	clearMsg,
	getLoading,
} from '../../../redux-toolkit/reducers/PostOpinion/postOpinionSlice';
import Main_Home_Logic from './Main_Home_Logic';
import { useDispatch, useSelector } from 'react-redux';
import ToastSuccessError from '../../../components/ToastSuccessError/ToastSucessError';
import SliderOffers from '../../../components/SliderOffers/SliderOffers';
import HomeImg from '../../../assets/maincontent/nav.png';
import axios from 'axios';
export default function Main_Home() {
	const { homeData } = Main_Home_Logic();
	const SucessMsgSelector = useSelector(getSuccess);
	const ErrorMsgSelector = useSelector(getError);
	const loading = useSelector(getLoading);
	const [offers, setOffers] = useState([]);
	const [heightImage, setheightImage] = useState('270px');

	useEffect(() => {
		if (window.screen.width <= 667) setheightImage('260px');
		if (window.screen.width <= 500) {
			setheightImage('230px');
		}
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_last_offers?limit=19&local=ar`)
			.then((offers) => {
				console.log('offers'.repeat(20), offers.data.data.data);
				setOffers(offers.data.data.data);
			});
	}, []);
	// console.log(SucessMsgSelector);
	// console.log(ErrorMsgSelector);
	//************************************************************* */
	//************************************************************* */
	//************************************************************* */

	return (
		<>
			<ToastSuccessError
				successMessage={SucessMsgSelector}
				ErrorMessage={ErrorMsgSelector}
				clearMsg={clearMsg}
			/>
			<MainContent data={homeData?.post?.data} />

			<WhoAreWe data={homeData?.post?.data} />

			{/* <ModerestOffers data={homeData?.post?.data} /> */}
			{offers && offers.length > 0 ? (
				<>
					<div className='text-center'>
						{/* <h2 className='fw-bold mb-4'>
					<span style={{ color: '#044364' }}>أحدث العروض</span>
				</h2> */}
						<SubTitle title={'أحدث العروض'} />
					</div>
					<div className='mx-5 show_all_btn  mb-5'>
						{/* {isComments && ( */}
						{/* )} */}
					</div>
					<SwiperSliders >
						{offers.map((offer, index) => {
							return (
								<div>
									<SwiperSlide >
										<div className='ofeer_card  text-center  justify-content-center align-items-center'>
											{/* <Link to={`/${offer.offer_id}`} state={{ url: null }}> */}
											<img
												id={offer.offer_id}
												style={{ height: heightImage }}
												src={
													process.env.REACT_APP_STORAGE_URL + offer.offer_image
												}
												alt='image'
											/>
											{/* </Link> */}
										</div>
									</SwiperSlide>
								</div>
							);
						})}
					</SwiperSliders>
					<div className='container'>

						<Link className=' ' to='/offers'>
							<ButtonM text={'عرض الكل'} className='mx-auto mb-2 mt-4' />
						</Link>
					</div>
				</>
			) : null}

			<VideoHowToUseSite data={homeData?.post?.data} />

			<Sections data={homeData?.post?.data} />

			<LibrarySiteMin data={homeData?.post?.data} />
			{/* <SiteNewspaper data={homeData?.post?.data} /> */}

			<NumbersTarekeSite data={homeData?.post?.data} />
			<HowSiteCanHelpYou />

			<OpinionTarekeStudents />

			{localStorage.getItem('role') == 1 && (
				<LeaveComment postDataComment={postOpinionfun} loading={loading} />
			)}
		</>
	);
}
