
import { useEffect, useRef, useState } from "react";
import {  useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUploadForm } from "../../customhook/Uploadfiles";
let allowedExtension = ['application/pdf'];

export default function Add_traditional_quiz_Logic({isFinal,isAboveLevel}) {
  const navigate = useNavigate();
    const { getValues ,register, handleSubmit,formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        QuizeName:'',
        points: '',
        descriptionQuize:'',
        duration:'', 
        questions_file: ''
      }});

      const {QuizId,UnitId} = useParams();


      const { isSuccess:create_traditional_quiz_questions_success, uploadForm:create_traditional_quiz_questions_files, progress:create_traditional_quiz_questions_progress ,IsError:create_traditional_quiz_questions_error,data:create_traditional_quiz_questions_data } = useUploadForm("/create_unit_traditional_quiz");
  
     useEffect(()=>{
     console.log(create_traditional_quiz_questions_success)
      if(create_traditional_quiz_questions_success===true)
         {
          navigate(-1)
         }

     },[create_traditional_quiz_questions_success])









      const onSubmit = async (data) => {
        console.log(typeof(data.points))
        const create_traditional_quiz_questions_form = new FormData(); 
        create_traditional_quiz_questions_form.append('unit_id',UnitId);
        create_traditional_quiz_questions_form.append('isFinal',isFinal);
        create_traditional_quiz_questions_form.append('duration',data.duration);
        create_traditional_quiz_questions_form.append('isAboveLevel',isAboveLevel);
        create_traditional_quiz_questions_form.append('nameOfQuiz',data.QuizeName);
        create_traditional_quiz_questions_form.append('points', data.points);
        create_traditional_quiz_questions_form.append('correction_Ladder_file',correction_Ladder_file );
        create_traditional_quiz_questions_form.append('description', data.descriptionQuize)
        create_traditional_quiz_questions_form.append('transable', 0)
        create_traditional_quiz_questions_form.append('questions_file', data.questions_file[0])
        
         await   create_traditional_quiz_questions_files(create_traditional_quiz_questions_form);
       };
     



      const [correction_Ladder_file_error,setcorrection_Ladder_file_error] = useState('');
      const [correction_Ladder_file,setcorrection_Ladder_file]=useState(null)
      const correction_Ladder_file_Ref = useRef();



      const handlecorrection_Ladder_file= (e)=>{
    
      if( e.target.files[0].size/(1024*1024)<8 && allowedExtension.indexOf(e.target.files[0].type)>-1)
      {
        setcorrection_Ladder_file_error('')
        setcorrection_Ladder_file( e.target.files[0])
    }
    else
    {
        setcorrection_Ladder_file(null);
        setcorrection_Ladder_file_error('يرجى اختيار ملف بلاحقة pdf ');
        correction_Ladder_file_Ref.current.value='';
    }

    }
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    handlecorrection_Ladder_file,
    correction_Ladder_file,
    correction_Ladder_file_Ref,
    correction_Ladder_file_error,
    create_traditional_quiz_questions_error,
    create_traditional_quiz_questions_progress,
    create_traditional_quiz_questions_success,
    QuizId,
    onSubmit
    }

}
