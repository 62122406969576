import axios from "axios";

  export default async function  fetchcommentsbylessonid ({queryKey})
 {
     
console.log(queryKey);

 
 const data = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_comments_of_lesson_by_lesson_id/${queryKey[1]}?limit=${queryKey[2]}&page=${queryKey[3]}` ,
 {
  headers: {
    'Authorization': `Bearer ${queryKey[4]}` 
  } 
});

console.log(data);


   return  data;
 } 