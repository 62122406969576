import React from 'react';
import './Testinlesson.css';

import correctimage from './../../assets/Testinlesson/check-mark.png';
import uncorrectimage from './../../assets/Testinlesson/close.png';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
// import LayoutSideStudent from '../../pages/LayoutSide/LayoutSideStudent';
import MainHeader from '../MainHeader/MainHeader';
export default function Testinlesson() {
	const { state } = useLocation();
	if (localStorage.getItem('role') == '1') {
		return (
			<>
				<Layout>
					{/* <MainHeader /> */}
					{/* <LayoutSideStudent> */}
					<div className='container-fluid Testinlessonfluid overflow-hidden'>
						<div className='container mx-auto overflow-hidden  Testinlesson'>
							<div className='m-4 row justify-content-center align-items-center '>
								<div className='mx-2 TestinLessonOptionCard  p-2 col-sm-2  col-5 mx-0 text-center    rounded-x '>
									<Link to='corrected'>
										<img
											style={{
												width: '100%',
												maxWidth: '60%',
												height: 'auto',
											}}
											src={correctimage}
											alt=''
										/>

										<p className='p-1 fw-bold'>مصحح </p>
									</Link>
								</div>

								<div className='TestinLessonOptionCard  mx-2 p-2 col-sm-2 mx-0 TestinLessonOptionCard col-5 text-center    rounded-x'>
									<Link to='uncorrected'>
										<img
											style={{
												width: '100%',
												maxWidth: '60%',
												height: 'auto',
											}}
											src={uncorrectimage}
											alt=''
										/>
										<p className='p-1 fw-bold'>غير مصحح</p>
									</Link>
								</div>
							</div>
						</div>
					</div>
					{/* </LayoutSideStudent> */}
				</Layout>
			</>
		);
	} else {
		return (
			<>
				{/* <h3 className='fw-bold mb-4 text-center my-3'>
					<span style={{ color: '#044364' }}>الإختبارات </span>
				</h3> */}
				<div
					className=' quiz_card_sub text-center   mx-auto my-5 '
					style={{ width: '25%' }}>
					<h5 className='text-center text-white  fs-4 fw-bold'>تقليدي </h5>
				</div>
				<div className='row'>
					<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
						<div
							className=' quiz_card text-center    mx-auto my-5 '
							style={{
								width: '50%',
								backgroundColor: 'white',
								border: '3px solid rgba(0,0,0,0.05)',
							}}>
							<Link to='corrected' state={{ sub_id: state.sub_id }}>
								<h5
									className='text-center   fs-4 fw-bold'
									style={{ color: '#044364' }}>
									مصحح{' '}
								</h5>
							</Link>
						</div>
					</div>
					<div className='col-lg-6 col-md-6 col-sm-12 col-12'>
						<div
							className=' quiz_card text-center   mx-auto my-5 '
							style={{
								width: '50%',
								backgroundColor: 'white',
								border: '3px solid rgba(0,0,0,0.05)',
							}}>
							<Link to='uncorrected' state={{ sub_id: state.sub_id }}>
								<h5
									className='text-center   fs-4 fw-bold'
									style={{ color: '#EF0000' }}>
									غير مصحح{' '}
								</h5>
							</Link>
						</div>
					</div>
				</div>
			</>
		);
	}
}
