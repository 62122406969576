import React, { useEffect, useState } from 'react'
import {  useDispatch,useSelector } from 'react-redux';


import { useParams } from 'react-router-dom';
import { get_all_units_by_subject_id_with_auth } from '../../redux-toolkit/reducers/Units/UnitSlice';

export default function Select_Unit_Logic() {

  const dispatch = useDispatch();

    

  const {SubjectId}= useParams();
 

  
  const  data = useSelector(state=>state.units.get_all_units_by_subject_id_with_auth)


  useEffect(()=>{
    dispatch(get_all_units_by_subject_id_with_auth({SubjectId}));
  },[])
  console.log(data)
  return{
    data,SubjectId
  }
   
}
