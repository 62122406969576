import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { clearMsg } from '../../../../redux-toolkit/reducers/notification';
import Error from '../../../alerts/Error/Error';
import ToastSuccessError from '../../../ToastSuccessError/ToastSucessError';
import Edit_Lesson_Logic from './Edit_Lesson_Logic';
import axios from 'axios';

export default function Edit_Lesson() {
	const [loading, setLoading] = React.useState(false);
	const successMessage = useSelector((state) => state.notification.isSuccess);
	const errorMessage = useSelector((state) => state.notification.isError);
	const {
		register,
		errors,
		isDirty,
		isValid,
		handleSubmit,
		edit_lesson_error,
		edit_lesson_progress,
		edit_lesson_success,
		edit_lesson_loading,
		onSubmit,
		handlecover,
		cover_Ref,
		cover_error,
		reset,
		LessonId,
		lessondataerror,
		lessondataIserror,
	} = Edit_Lesson_Logic({ setLoading });
	useEffect(() => console.log('errirs', errors));

	useEffect(() => {
		// console.log('Lesson ID ID ID' , LessonId);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_lesson_by_lesson_id/${LessonId}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((lesson) => {
				console.log('lesson data', lesson.data.data);
				reset(lesson.data.data);
			})
			.catch((error) => {
				console.log('error happend while fetching  lesson details', error);
			});
	}, []);

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={errorMessage}
				clearMsg={clearMsg}
			/>
			<div className='container Add_traditional_quiz card shadow p-5 my-5'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					تعديل الدرس
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='name'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الدرس
						</label>
						<input
							type='text'
							className='form-control'
							id='lesson_name'
							{...register('lesson_name', { required: true })}
						/>
						{errors.name && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							نقاط الدرس
						</label>
						<input
							type='number'
							{...register('lesson_points', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.points && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='first_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الأول
						</label>
						<input
							type='url'
							{...register('lesson_first_video', { required: false })}
							className='form-control'
							id='first_video'
						/>
						{errors.first_video && (
							<p className='text-danger'>الفيديو الأول مطلوب</p>
						)}
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة
						</label>
						<input
							type='time'
							className='form-control'
							id='duration'
							{...register('lesson_duration', { required: true })}
						/>
						{errors.duration && (
							<div className='text-danger'>Invalid input</div>
						)}
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='second_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الثاني
						</label>
						<input
							type='url'
							{...register('lesson_second_video')}
							className='form-control'
							id='second_video'
						/>
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='isFree'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							هل هو مجاني
						</label>
						<br />
						<select id='isFree' {...register('isFree', { required: true })}>
							<option value='1'>نعم</option>
							<option value='0'>لا</option>
						</select>
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='third_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الثالث
						</label>
						<input
							type='url'
							{...register('lesson_third_video')}
							className='form-control'
							id='third_video'
						/>
					</div>

					{/* <div className="col-md-4">
            <label htmlFor="requirements" className="form-label">
              {" "}
              المتطلبات
            </label>
            <input
              type="text"
              placeholder="رياضيات,فيزياء,"
              {...register("requirements", { required: true })}
              className="form-control"
              id="requirements"
            />
            {errors.start_date && <p className="text-danger">Invalid input</p>}
          </div> */}

					{/* <div className="col-md-4">
            <label htmlFor="start_date" className="form-label">
              {" "}
              تاريخ البداية
            </label>
            <input
              type="date"
              {...register("start_date", { required: true })}
              className="form-control"
              id="start_date"
            />
            {errors.start_date && <p className="text-danger">Invalid input</p>}
          </div> */}
					{/* <div className="col-md-4">
            <label htmlFor="end_date" className="form-label">
              تريخ الانتهاء{" "}
            </label>
            <input
              type="date"
              {...register("end_date", { required: true })}
              className="form-control"
              id="end_date"
            />
            {errors.end_date && <p className="text-danger">Invalid input</p>}
          </div> */}

					<div className='col-md-6'>
						<label
							htmlFor='cover'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اختر صورة الغلاف
						</label>
						<input
							ref={cover_Ref}
							title='يرجى اختيار صورة'
							onChange={handlecover}
							accept='image/jpeg, image/jpg, image/png,image/bmp'
							type='file'
							class='form-control'
							id='cover'
							aria-label='file example'
						/>
						{cover_error ? (
							<div className='text-danger'>{cover_error}</div>
						) : (
							<></>
						)}
					</div>
					<div className='col-md-12'>
						<label
							htmlFor='what_we_will_learn'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							ماذا سوف نتعلم؟
						</label>
						<input
							onChange={handlecover}
							type='text'
							class='form-control'
							id='what_we_will_learn'
							{...register('what_we_will_learn', { required: true })}
						/>
						{errors.what_we_will_learn && (
							<div className='text-danger'>Invalid input</div>
						)}
					</div>
					<div className='col-md-12'>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							وصف الدرس
						</label>
						<textarea
							{...register('lesson_description', { required: true })}
							className='form-control '
							id='description'
						/>
						{errors.description && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-12'>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364' }} >
							ترتيب الدرس
						</label>
						<input
							{...register('lesson_order')}
							className='form-control '
							id='description'
						/>
						{/* {errors.description && <p className='text-danger'>Invalid input</p>} */}
					</div>

					{edit_lesson_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{ width: `${edit_lesson_progress}%` }}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>{edit_lesson_progress}% </label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12 text-center mt-5'>
						{loading ? (
							<span class='spinner-grow'></span>
						) : (
							<button className='btn btn1  w-50 mx-auto' type='submit'>
								تعديل الدرس
							</button>
						)}
						{lessondataIserror && <Error errorText={lessondataerror} />}
						{edit_lesson_error && (
							<label className='text-center w-100 text-danger'>
								{edit_lesson_error?.response
									? edit_lesson_error.response.data.message
									: edit_lesson_error.message}
							</label>
						)}
					</div>
					{edit_lesson_success && (
						<div class='alert alert-success text-center' role='alert'>
							تم تعديل الوحدة بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
