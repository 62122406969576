import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_traditional_quiz_questions_files_by_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import axios from 'axios';
export default function Show_Traditionalquiz_Questions_Files_Logic() {
	const [loading, setloading] = useState();
	const deleteQuestion = (id) => {
		setloading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz_question_file_by_id/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};

	const dispatch = useDispatch();
	const data = useSelector(
		(state) => state.unitTest.get_traditional_quiz_questions_files_by_id
	);

	const { QuizId } = useParams();

	useEffect(() => {
		dispatch(get_traditional_quiz_questions_files_by_id({ QuizId }));
	}, []);
	return {
		dispatch,
		data,
		QuizId,
		loading,
		deleteQuestion,
	};
}
