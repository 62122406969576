import React from "react";
import { Link } from "react-router-dom";

function AttatchmentSectionFile({
  img,
  title,
  btn1Text,
  btn2Text,
  btn1function,
  btn2function,
  link,
}) {
  return (
    <div className="">
      <div className="text-center">
        <img
          src={img}
          alt=""
          className="img-fluid w-50 h-25"
          id="AtttchmentImage"
        />
        <br />
        <span className="fs-2">{title}</span>
      </div>
      <div className="">
        <div className="text-center">
          <button className="btn btn-primary mt-1" onClick={btn1function}>
            <a href={link} download="pdf_file" className="text-white">
              {btn1Text}
            </a>
          </button>
          {btn2Text ? (
            <Link
              to="/audioplayer"
              className="text-white btn btn-warning me-1 mt-1"
            >
              {"تشغيل"}
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default AttatchmentSectionFile;
