import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getOpinion = createAsyncThunk(
	'opinion/getAllopinion',
	async (_, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_users_opinions?limit=50&local=en`
			);
			const data = await res.json();
			if (res.ok) {
				return data;
			} else {
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
};

export const OpinionSlice = createSlice({
	name: 'opinion',
	initialState,
	reducers: {},
	extraReducers: {
		[getOpinion.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[getOpinion.fulfilled]: (state, action) => {
			state.loading = false;
			state.post = action.payload;
		},
		[getOpinion.rejected]: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default OpinionSlice.reducer;
