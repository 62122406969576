import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_all_lessons_by_unit_id_with_auth } from '../../../../redux-toolkit/reducers/Lesson/LessonSlice';
import { useQuery } from 'react-query';
import fetchAllLessonByUnitIdWithAuth from '../../../../redux-toolkit/api/Lesson/fetchAllLessonByUnitIdWithAuth';
export default function Show_All_Lesson_For_Quiz_Logic() {
    // const data = useSelector(state=>state.lesson.get_all_lessons_by_unit_id_with_auth) 
    // const {UnitId}= useParams();
  
   const dispatch = useDispatch();




   const [DeleteState,setDeleteState]=useState({isloading:false,isError:null,isSuccess:false}) 
   const [page, setPage] = useState(1);
 
   function handlePageClick(data) {
     setPage(data.selected + 1);
   }
 
   const Authdata = useSelector(state => state.SIGN.token)
     const {UnitId}= useParams();
   
 



 
    const {data ,error,isLoading,refetch } = useQuery(['getallLessonsbyUnitidWithAuth', 10,UnitId,page,Authdata], fetchAllLessonByUnitIdWithAuth, { enabled: true, });
 







  
  // useEffect(()=>{
  //   dispatch(get_all_lessons_by_unit_id_with_auth({UnitId}));
  // },[])
  
  
      
        
        return { UnitId,dispatch,isLoading,error,  data}
}
