import React, { useEffect } from 'react';
import './index.css';

import ArchivedLessons from '../../components/archivedLessons';
import Layout from '../Layout/Layout';

const ArchivedScreen = () => {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<div className='archivedScreen'>
				<div className='archivedscreen__title'>
					{/* <img src={chalks} alt="" /> */}
					<h3>مادة الرياضيات</h3>
				</div>

				<ArchivedLessons />
			</div>
		</Layout>
	);
};

export default ArchivedScreen;
