import React from 'react';
import Create_Automated_Quiz_Lesson_Logic from './Create_Automated_Quiz_Lesson_Logic';
import katex from "katex";
import ReactQuill from 'react-quill';
import { Editor } from "@tinymce/tinymce-react";
import "katex/dist/katex.min.css";
window.katex = katex;

export default function Create_Automated_Quiz_Lesson() {
	const {
		register,
		errors,
		isDirty,
		isValid,
		handleSubmit,
		create_unit_automated_quiz_form_error,
		create_unit_automated_quiz_form_progress,
		create_unit_automated_quiz_form_success,
		QuizId,
		questionText,
		onSubmit,
	} = Create_Automated_Quiz_Lesson_Logic();
	return (
		<>
			<div className='container Add_traditional_quiz  card_quiz_create'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					إنشاء إختبار جديد
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation my-5 mx-auto'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='َQuizeName'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الإختبار
						</label>
						<input
							type='text'
							className='form-control'
							id='َQuizeName'
							{...register('QuizeName', { required: true })}
						/>
						{errors.QuizeName && <p className='text-danger'>Invalid input</p>}
					</div>

					<label htmlFor="َQuizeName" className="form-label">النص العام للاختبار المؤتمت</label>
					<ReactQuill
						style={{height: '300px', marginBottom: '120px'}}
						theme={"snow"}
						onChange={(e) => questionText.current = e}
						value={questionText.current}
						modules={Editor.modules}
						formats={Editor.formats}
						bounds={".app"}
						placeholder={'أدخل النص ...'}
						/> 

					<div className='col-md-4'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الدرجة
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control degreeinput'
							id='degree'
						/>
						{errors.degree && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='descriptionQuize '
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الوصف
						</label>
						<input
							type='text'
							{...register('descriptionQuize', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.descriptionQuize && (
							<p className='text-danger'>Invalid input</p>
						)}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة
						</label>
						<input
							type='number'
							{...register('duration', { required: true })}
							className='form-control'
							id='duration'
						/>
						{errors.duration && <p className='text-danger'>Invalid input</p>}
					</div>

					{create_unit_automated_quiz_form_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{
									width: `${create_unit_automated_quiz_form_progress}%`,
								}}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>
								{create_unit_automated_quiz_form_progress}%{' '}
							</label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12 -auto'>
						<button
							disabled={!isDirty || !isValid}
							className=' btn1'
							style={{
								borderRadius: '10px ',
								minWidth: '150px ',
								padding: '10px 5px',
							}}
							type='submit'>
							رفع الاختبار
						</button>

						{create_unit_automated_quiz_form_error && (
							<label className='text-center w-100 text-danger'>
								{create_unit_automated_quiz_form_error?.response
									? create_unit_automated_quiz_form_error.response.data.message
									: create_unit_automated_quiz_form_error.message}
							</label>
						)}
					</div>
					{create_unit_automated_quiz_form_success && (
						<div class='alert btn1 text-center' role='alert'>
							تمت إضافة الاختبار بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}


Editor.modules = {
	/*toolbar: [
	  [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
	  [{size: []}],
	  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
	  [{'list': 'ordered'}, {'list': 'bullet'}, 
	   {'indent': '-1'}, {'indent': '+1'}],
	  ['link', 'image', 'video', 'formula'],
	  ['clean']
	],*/
	toolbar: [
	  ["bold", "italic", "underline", "strike"], // toggled buttons
	  ["blockquote", "code-block"],
  
	  [{ list: "ordered" }, { list: "bullet" }],
	  [{ script: "sub" }, { script: "super" }], // superscript/subscript
	  [{ header: [1, 2, 3, false] }],
  
	  ["link", "image", "formula"],
  
	  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
	  [{ align: [] }],
  
	  ["clean"] // remove formatting button
	],
	clipboard: {
	  // toggle to add extra line breaks when pasting HTML:
	  matchVisual: false
	}
  };