import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_all_lessons_by_unit_id_with_auth } from "../../../../redux-toolkit/reducers/Lesson/LessonSlice";
import fetchAllLessonByUnitIdWithAuth from "../../../../redux-toolkit/api/Lesson/fetchAllLessonByUnitIdWithAuth";

export default function Show_All_Lesson_Logic() {
  const [DeleteState, setDeleteState] = useState({
    isloading: false,
    isError: null,
    isSuccess: false,
  });
  const [page, setPage] = useState(1);

  function handlePageClick(data) {
    setPage(data.selected + 1);
  }

  const Authdata = useSelector((state) => state.SIGN.token);
  const { UnitId } = useParams();

  const { data, error, loading, refetch } = useQuery(
    ["getallLessonsbyUnitidWithAuth", 10, UnitId, page, Authdata],
    fetchAllLessonByUnitIdWithAuth,
    { enabled: true }
  );

  const handleDeleteLesson = async ({ lessonid }) => {
    await fetch(
      `${process.env.REACT_APP_API_URL}/delete_last_lesson/${lessonid}?unit_id=${UnitId}`,
      {
        headers: {
          Authorization: `Bearer ${Authdata}`,
          Accept: "application/json",
          "Content-Encoding": "gzip , deflate, br",
        },
        method: "DELETE",
      }
    )
      .then((res) => {
        console.log(res, "result");
        if (res.ok) {
          setDeleteState((prev) => {
            return { ...prev, isError: null, isSuccess: false };
          });
          refetch();
        } else throw new Error("there is an error");
      })
      .catch((err) => {
        return;
      })
      .finally(() => {
        setDeleteState((prev) => {
          return { ...prev, isloading: true };
        });
      });
  };

  return { UnitId, handleDeleteLesson, error, data, loading ,refetch };
}
