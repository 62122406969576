import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Selectl_Unit_For_Lessons_Logic from './Selectl_Unit_For_Lessons_Logic';
import PopUp from '../../../popup/popup';
import Button from 'react-bootstrap/Button';
export default function Selectl_Unit_For_Lessons() {
	const { data } = Selectl_Unit_For_Lessons_Logic();
	const [pop, setPop] = React.useState(false);
	const [popData, setPopData] = React.useState(null);

	useEffect(() => {
		document.body.style.overflowX = 'auto';

		return () => {
			document.body.style.overflowX = 'inherited';
		};
	}, []);
	return (
		<div className='text-center mx-3 my-5'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{
				<div>
					<table className='table-bordered  w-100 mt-3 table_style  text-center table-striped '>
						<thead>
							<tr>
								<th className='first_child'>#</th>
								<th> اسم الوحدة </th>
								<th> رابط الفيديو </th>
								<th> الترتيب </th>
								<th> وصف الوحدة </th>
								<th> غلاف الوحدة </th>
								<th> عدد الدروس </th>
								<th> متطلبات الوحدة</th>
								<th> مدة الدروس </th>
								<th> </th>
								<th className='last_child'> </th>
							</tr>
						</thead>
						{data?.data && (
							<tbody>
								{data?.data?.map((Unit, index) => (
									<tr key={Unit.unit_id}>
										<td>{index}</td>
										<td style={{ overflow: 'auto' }}> {Unit.unit_name} </td>
										<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
											<iframe
												src={`${process.env.REACT_APP_STORAGE_URL}${Unit.unit_video}`}
												width='200px'
												height='200px'
												allowfullscreen='allowfullscreen'></iframe>
										</td>
										<td>
											<div style={{ overflow: 'auto', maxWidth: '10vw' }}>
												{Unit.order}
											</div>
										</td>
										<td style={{ overflowX: 'scroll', maxWidth: '10vw' }}>
											{Unit?.unit_description?.length > 15 && '...'}{' '}
											{Unit?.unit_description?.slice(0, 15)}{' '}
										</td>
										<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
											<div
												style={{
													width: '100%',
													height: '100%',
												}}>
												<img
													loading='lazy'
													className='img-fluid'
													src={`${process.env.REACT_APP_STORAGE_URL}${Unit.unit_cover}`}
													alt='Girl in a jacket'
													style={{
														objectFit: 'cover',
														width: '200px',
														height: '150px',
													}}
												/>
											</div>
										</td>
										<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
											{Unit.lessons_count}
										</td>
										<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
											{Unit?.requirements?.map((requirment) => (
												<label>{requirment}</label>
											))}
										</td>
										<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
											{Unit.lessons_sum_duration}
										</td>
										<td>
											<Button
												style={{ border: 'none' }}
												className='btn2'
												onClick={() => {
													setPop(true);
													setPopData(Unit);
												}}>
												التفاصيل
											</Button>
										</td>
										<td>
											<Link
												to={`${Unit.unit_id}/show_lessons`}
												className='btn btn1'
												style={{ margin: '12%' }}>
												عرض الدروس
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						)}
						{data.isError && (
							<div class='d-flex justify-content-center'>
								<label className='text-danger'>{data.errorMessage}</label>
							</div>
						)}
					</table>

					{/* {data?.data?.total < 10  ? <> </> : <Pagination  data={data?.data} handlePageClick={handlePageClick} page={page} />  }   */}
				</div>
			}
			<PopUp show={pop} onHide={() => setPop(false)} data={popData} />
		</div>
	);
}
