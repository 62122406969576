import React, { useState, useRef } from 'react';
import Show_Automated_Quiz_Options_Logic from './Show_Automated_Quiz_Options_Logic';
import rightAnswer from '../../assets/icons/rightcheckbox.png';
import wrongAnswer from '../../assets/icons/wrongcheckbox.png';
import Error from '../alerts/Error/Error';
import { delete_automated_quiz_question_with_its_options_by_automated_quiz_question } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import ToastError from '../ToastSuccessError/ToastError';
import ReactQuill from 'react-quill';
import katex from 'katex';
import { Editor } from '@tinymce/tinymce-react';
import 'katex/dist/katex.min.css';
window.katex = katex;

export default function Show_Automated_Quiz_Options() {
	const {
		data,
		dispatch,
		QuizId,
		get_all_automated_quiz_questions_with_options_by_automated_quiz,
	} = Show_Automated_Quiz_Options_Logic();
	console.log(data);

	const [question, setQuestion] = useState('');
	const [questioData, setQuestionData] = useState();
	const [answerData, setAnswerData] = useState();
	const [isEditing, setIsEditing] = useState(false);

	const [errorMessage, setErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [whatEdit, setWhatEdit] = useState('');
	const questionRef = useRef();
	const answerRef = useRef();

	const handleQuestionChange = (e) => {
		const value = e.target.value;

		setQuestion(value);

		// cosole.log(question)
	};

	const handleEditQuestion = (question) => {
		// console.log(question);

		setWhatEdit('question');

		setQuestion(question.question);

		questionRef.current = question.question;

		setQuestionData(question);

		console.log('qu4estion', question);
	};

	const handlePostEditsForQuestion = () => {
		setIsEditing(true);
		const dataToSend = {
			point: questioData.point,
			automated_quiz_question_id: questioData.id,
			transable: 0,
			question: questionRef.current,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/edit_automated_quiz_question_by_id`,
				dataToSend,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('success edit question', data);
				setSuccessMessage('تمت عملية تعديل السؤال بنجاح');
				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch((error) => {
				console.log('error edit question', error);
				setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى');
			})
			.finally(() => {
				console.log('finally');
				setIsEditing(false);
			});
	};

	const handlePostEditsForAnswer = () => {
		setIsEditing(true);

		const dataToSend = {
			aqq_option_id: answerData.id,
			answear: answerRef.current,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}/edit_automated_quiz_question_option_by_id`,
				dataToSend,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				console.log('success edit answer', data);
				setSuccessMessage('تمت عملية تعديل الجواب بنجاح');
				setTimeout(() => {
					window.location.reload();
				}, 100);
			})
			.catch((error) => {
				console.log('error edit answer', error);
				setErrorMessage('حدث خطأ ما يرجى المحاولة مرة اخرى');
			})
			.finally(() => {
				console.log('finally');
				setIsEditing(false);
			});
	};

	const handleEditAnswer = (answear) => {
		setWhatEdit('answer');

		setAnswerData(answear);

		answerRef.current = answear.answear;
	};

	return (
		<div>
			<ToastError
				successMessage={successMessage}
				ErrorMessage={errorMessage}
				setErrorMessage={setErrorMessage}
				setSuccessMessage={setSuccessMessage}
			/>
			<div className='lesson__questions'>
				<ol type='1' className='questions__container'>
					{data.isFetching && (
						<div class='d-flex justify-content-center'>
							<div class='spinner-grow text-dark' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					{!data.isError &&
						data.data?.map((quistion, index) => (
							<li className={`question`}>
								<div className='question__container'>
									<div className='topsection'>
										<h4
											className='mx-3'
											style={{ color: '#044364 ' }}
											dangerouslySetInnerHTML={{ __html: quistion.question }}>
											{}
										</h4>
										<span className='fs-5'> {quistion.point} نقطة </span>
										<i
											style={{
												cursor: 'pointer',
												margin: '0px 5px',
											}}
											title='حذف'
											onClick={() =>
												dispatch(
													delete_automated_quiz_question_with_its_options_by_automated_quiz_question(
														{ subQuizId: quistion.id }
													)
												).then(() =>
													dispatch(
														get_all_automated_quiz_questions_with_options_by_automated_quiz(
															{ QuizId }
														)
													)
												)
											}
											class='fa fa-trash text-danger fs-2 mr-auto'
											aria-hidden='true'></i>

										<p
											type='button'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'>
											<EditOutlined
												style={{
													fontSize: '30px !important',
													margin: '0px 15px',
												}}
												onClick={() => handleEditQuestion(quistion)}
												className='fs-2 '
											/>
										</p>
									</div>

									<div className='answers'>
										{quistion?.options?.map((answer, id) => {
											return (
												<div
													className={`answerItself shadow border rounded py-2 my-3 ${
														answer.is_true == '1' ? 'rightAnswer' : null
													} }`}>
													<p
														type='button'
														data-bs-toggle='modal'
														data-bs-target='#exampleModal'>
														<EditOutlined
															style={{
																fontSize: '30px !important',
																margin: '0px 15px',
																color: 'balck !important',
															}}
															onClick={() => handleEditAnswer(answer)}
															className='fs-2 '
														/>
													</p>

													<label
														dangerouslySetInnerHTML={{
															__html: answer.answear,
														}}></label>
													<input
														type='radio'
														className={` mx-2 ${
															answer.checked || answer.status
																? 'hidecheckbox'
																: ''
														}`}
														style={{ width: '20px', height: '20px' }}
														checked={answer.is_true == '1'}
														value={answer.text}
														name={index}
													/>
													<div className='statusImage'>
														{answer.status ? (
															<img loading='lazy' src={rightAnswer} alt='' />
														) : null}
														{!answer.status && answer.checked ? (
															<img loading='lazy' src={wrongAnswer} alt='' />
														) : null}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</li>
						))}

					{data.isError && <Error errorText={data.errorMessage} />}
				</ol>
			</div>

			<div
				class='modal fade'
				id='exampleModal'
				tabindex='-1'
				aria-labelledby='exampleModalLabel'
				aria-hidden='true'>
				<div class='modal-dialog'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h5 class='modal-title' id='exampleModalLabel'>
								{' '}
								{isEditing
									? 'جاري التعديل ...'
									: whatEdit == 'question'
									? 'تعديل نص السؤال'
									: 'تعديل الجواب'}
							</h5>
							{/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
						</div>
						<div class='modal-body'>
							{whatEdit == 'question' ? (
								<ReactQuill
									style={{ height: '300px', marginBottom: '120px' }}
									theme={'snow'}
									onChange={(e) => (questionRef.current = e)}
									value={questionRef.current}
									modules={Editor.modules}
									formats={Editor.formats}
									bounds={'.app'}
									placeholder={'أدخل تعديلاتك هنا ...'}
								/>
							) : (
								<ReactQuill
									style={{ height: '300px', marginBottom: '120px' }}
									theme={'snow'}
									onChange={(e) => (answerRef.current = e)}
									value={answerRef.current}
									modules={Editor.modules}
									formats={Editor.formats}
									bounds={'.app'}
									placeholder={'أدخل تعديلاتك هنا ...'}
								/>
							)}

							{/* <textarea 
        type="text" 
        className='form-control' 
        style={{minHeight: '120px'}} 
        value={question} onChange={(e) => handleQuestionChange(e)} 
        /> */}
						</div>
						<div
							class='modal-footer d-flex w-100'
							style={{ justifyContent: 'space-between !important' }}>
							{isEditing ? (
								<div className='w-100 text-center py-3'>
									<div class='spinner-border' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							) : (
								<>
									<button
										type='button'
										class='btn btn2'
										data-bs-dismiss='modal'>
										إغلاق
									</button>
									{whatEdit == 'question' ? (
										<button
											type='button'
											class='btn btn1'
											onClick={() => handlePostEditsForQuestion()}>
											حفظ التعديلات
										</button>
									) : (
										<button
											type='button'
											class='btn btn1'
											onClick={() => handlePostEditsForAnswer()}>
											حفظ التعديلات
										</button>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

Editor.modules = {
	/*toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video', 'formula'],
      ['clean']
    ],*/
	toolbar: [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block'],

		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ header: [1, 2, 3, false] }],

		['link', 'image', 'formula'],

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],

		['clean'], // remove formatting button
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};
