import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { delete_unit_by_id } from '../../../../redux-toolkit/reducers/Units/UnitSlice';
import Error from '../../../alerts/Error/Error';
import PopUp from '../../../popup/popup';
import Show_All_Unit_Logic from './Show_All_Unit_Logic';
import { Button } from 'react-bootstrap';
export default function Show_All_Unit() {
	const { dispatch, data, SubjectId } = Show_All_Unit_Logic();
	const [pop, setPop] = React.useState(false);
	const [popData, setPopData] = React.useState(null);
	const [selectedUnitSubjectId , setSelectedUnitSubjectId] = React.useState({});
	return (
		<div className='container my-5'>
			{data.isError && <Error errorText={data.errorMessage} />}
			{data?.data && (
				<div>
					{data.isFetching && (
						<div class='d-flex justify-content-center'>
							<div class='spinner-grow text-dark' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					{
						<>
							<table className=' text-center w-100 table_style table-bordered table-striped mt-4'>
								<thead>
									<tr>
										<th className='first_child'>#</th>
										<th> اسم الوحدة </th>
										<th> رابط الفيديو </th>
										<th> وصف الوحدة </th>
										<th> غلاف الوحدة </th>
										<th> عدد الدروس </th>
										<th> متطلبات الوحدة</th>
										<th> مدة الدروس </th>
										<th className='last_child'></th>
									</tr>
								</thead>
								{data?.data && (
									<tbody>
										{data?.data?.map((Unit, index) => (
											<tr key={Unit.unit_id}>
												<td>{index}</td>
												<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
													{Unit.unit_name}
												</td>
												<td style={{ overflow: 'auto ', maxWidth: '10vw' }}>
													{console.log(index, 'video', Unit.unit_video)}
													<iframe
														src={`${process.env.REACT_APP_STORAGE_URL}${Unit.unit_video}`}
														width='100%'
														height='100%'
														allowfullscreen='allowfullscreen'></iframe>
												</td>

												<td
													style={{
														overflow: 'auto',
													}}>
													<label
														style={{
															maxWidth: '150px',
															maxHeight: '200px',
															overflowY: 'auto',
														}}>
														{Unit?.unit_description}
													</label>
												</td>
												<td
													style={{
														overflow: 'auto',
														maxWidth: '10vw',
														padding: '0',
													}}>
													<div
														style={{
															width: '100%',
															height: '100%',
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}>
														<img
															loading='lazy'
															className='img-fluid'
															src={`${process.env.REACT_APP_STORAGE_URL}/${Unit.unit_cover}`}
															alt='Girl in a jacket'
															style={{
																objectFit: 'cover',
																width: '200px',
																height: '150px',
															}}
														/>
													</div>
												</td>
												<td style={{ overflow: 'auto', maxWidth: '10vw' }}>
													{Unit.lessons_count}
												</td>
												<td>
													<label
														style={{
															maxWidth: '150px',
															maxHeight: '200px',
															overflowY: 'auto',
														}}>
														{Unit?.requirements}
													</label>
												</td>
												<td> {Unit.lessons_sum_duration} </td>
												<td
													style={{
														display: 'flex',
														flexDirection: 'column',
														gap: '15px',
													}}>
													<Button
														className='btn3 border-0 m-1'
														onClick={() => {
															setPop(true);
															setPopData(Unit);
														}}>
														التفاصيل
													</Button>
													<Link
														to={`${Unit.unit_id}/comments`}
														className='btn btn1 m-1'>
														{' '}
														التعليقات
													</Link>{' '}
													<Link
														to={`${Unit.unit_id}/unit_faqs`}
														className='btn btn3 m-1'>
														{' '}
														الأسئلة الشائعة
													</Link>{' '}
													<Link
														to={`${Unit.unit_id}/show_lessons`}
														className='btn btn1 m-1'>
														{' '}
														عرض الدروس{' '}
													</Link>{' '}
													<Link
														to={`edit_unit/${Unit.unit_id}`}
														className='btn btn2 m-1'>
														{' '}
														تعديل الوحدة{' '}
													</Link>
													{
															<>
																{' '}
																<button
																	className='btn btn-danger'
																	data-bs-toggle="modal" 
																	data-bs-target="#exampleModal"
																	onClick={() => {
																		setSelectedUnitSubjectId({
																			UnitId: Unit.unit_id,
																			SubjectId,
																		});
																	}}>
																	{' '}
																	حذف الوحدة{' '}
																</button>{' '}
															</>
														}
												</td>
											</tr>
										))}
									</tbody>
								)}
							</table>
						</>
					}
				</div>
			)}
			<Link
				className='btn btn1 my-4 '
				style={{ width: '10vw' }}
				to={`add_unit`}
				state={{ SubjectId }}>
				أضف وحدة جديدة
			</Link>

			<PopUp show={pop} onHide={() => setPop(false)} data={popData} />

			<div style={{ margin: '30x auto' }} />

			<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">تأكيد عملية الحذف</h5>
					{/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
				</div>
				<div class="modal-body">
					عند حذف هذه الوحدة لن يكون بالإمكان استردادها بعد ذلك هل أنت متأكد من أنك تريد إتمام عملية الحذف ؟
				</div>

				<div class="modal-footer">
					<button type="button" class="btn btn-success" data-bs-dismiss="modal">إلغاء</button>
					<button 
					type="button" 
					class="btn btn-danger" 
					onClick={() => dispatch(delete_unit_by_id(selectedUnitSubjectId))}
					>تأكيد
					</button>

				</div>
				</div>
			</div>
			</div>
		</div>
	);
}
