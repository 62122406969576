import  {  useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import {get_all_units_by_subject_id_with_auth} from './../../../../redux-toolkit/reducers/Units/UnitSlice'

export default function Show_All_Unit_Logic() {
  

  const dispatch = useDispatch();
    
  
    const {SubjectId}= useParams();

  const  data = useSelector(state=>state.units.get_all_units_by_subject_id_with_auth)
 
 
     
  useEffect(()=>{
    dispatch(get_all_units_by_subject_id_with_auth({SubjectId}));
  },[])
  
  
      
        
        return {  SubjectId,dispatch, data}
}
