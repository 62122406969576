import axios from "axios";
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export const getLessonAttachmentAPI = async (id, setState, setLoading) => {
  setLoading(true);
  try {
    const res = await api.get(
      `${process.env.REACT_APP_API_URL}/get_lesson_attachment/${id}`,
      {headers: {
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
        "Access-Control-Allow-Origin" : '*'
      }}
    );
    console.log(res.data.data);

    setState(res.data.data);
  } catch (e) {
    alert(e.response.data.message);
  }
  setLoading(false);
};

export const deleteLessonAttachmentAPI = async (
  ItemId,
  setRefresh,
  refresh
) => {
  console.log("item-->", ItemId);
  try {
    const res = await api.delete(
      `${process.env.REACT_APP_API_URL}/delete_lesson_attachment/${ItemId}`,
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}}
    ).then(data => {
      alert('تمت عملية الحذف بنجاح')
      window.location.reload();
    })
    .catch(error => {
      alert('حدثت مشكلة ما يرجى المحاولة لاحقا')
    });
    setRefresh(!refresh);
  } catch (e) {
    alert(e.response.data.message);
  }
};

export const updateLessonAttachmentAPI = async (ItemId, data, setLoading) => {
  // for (var pair of data.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  setLoading(true);
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}/update_lesson_attachment/${ItemId}`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}}
    );
    alert("نم تحديث الملف بنجاح");
    window.location.reload();
  } catch (e) {
    console.log(e);
    alert(e.response.data.message);
  }
  setLoading(false);
};

export const addLessonAttachmentAPI = async (data, setLoading) => {
  setLoading(true);
  try {
    const res = await api.post(
      `${process.env.REACT_APP_API_URL}/add_lesson_attachment`,
      data,
      {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}}
    );
    alert("تم رفع الملف بنجاح");
    window.location.reload();
  } catch (e) {
    alert(e.response.data.message);
  }
  setLoading(false);
};
