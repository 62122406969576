import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GetData } from '../../../../customhook/getDatawithAuth';

export default function ShowAsnwearUnitTranditiosnalLogic() {
	const { QuizId } = useParams();

	const { getDatafrom, isSuccess, progress, IsLoading, IsError, data } =
		GetData(`/get_student_traditional_quiz_answers_by_tq_id/${QuizId}`);
	// const {
	// 	getDatafrom: Get_Qustions_Quiz,
	// 	isSuccess: qustions_Quiz_success,
	// 	IsLoading: qustions_Quiz_IsLoading,
	// 	IsError: qustions_Quiz_IsError,
	// 	data: qustions_Quiz_data,
	// } = GetData(`/get_traditional_quiz_questions_files_by_id/${QuizId}`);

		const {
		getDatafrom: Get_Qustions_Quiz,
		isSuccess: qustions_Quiz_success,
		IsLoading: qustions_Quiz_IsLoading,
		IsError: qustions_Quiz_IsError,
		data: qustions_Quiz_data,
	} = GetData(`/get_traditional_quiz_by_quiz_id/${QuizId}`);
	// https://backend.middleeastacademy.edu.sy/api/get_traditional_quiz_by_quiz_id/43
	useEffect(() => {
		getDatafrom();
		Get_Qustions_Quiz();
	}, []);

	return {
		isSuccess,
		progress,
		IsLoading,
		IsError,
		data,
		Get_Qustions_Quiz,
		qustions_Quiz_success,
		qustions_Quiz_IsLoading,
		qustions_Quiz_IsError,
		qustions_Quiz_data,
	};
}
