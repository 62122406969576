import {createSlice,createAsyncThunk} from '@reduxjs/toolkit'
















export const  get_teacher_subjects = createAsyncThunk(
    'subjects/get_teacher_subjects', 
    async(_,thunkApi)=>{
      const {SIGN} = thunkApi.getState();
    try{
        const res = await fetch (`${process.env.REACT_APP_API_URL}/get_teacher_subjects` ,
        {  headers:{'Authorization': `Bearer ${SIGN.token}`} }) ;
        const isJson = res.headers.get('content-type')?.includes('application/json');
        const data   =   isJson ? await res.json() : null;
         
         if(res.status===200){
              return data.data;
         }
         else {
             return thunkApi.rejectWithValue( data?.message || `هناك خطأ ما رقم الخطأ: ${res.status}`);
         }
    }
    catch(error){
        return thunkApi.rejectWithValue(error.message === 'Unexpected end of JSON input' ? "لا يوجد مواد" : error.message )
    }
});




















const initialState={
 
    get_teacher_subjects:{
        isFetching : false,
        isSuccess : false,
        isError:false,
        errorMessage:null,
        data:null
    }


}

export const SubjectsSlice = createSlice({
  name: 'subjects',
  initialState,
  reducers: {
   
    
  },
  extraReducers:{





// get teacher subjects

    [get_teacher_subjects.pending]: (state,action)=>{
        state.get_teacher_subjects.isFetching =true;
        state.get_teacher_subjects.isError=false;
        state.get_teacher_subjects.errorMessage=null
    },
    [get_teacher_subjects.fulfilled]: (state,action)=>{
        state.get_teacher_subjects.isFetching = false;
        state.get_teacher_subjects.isError=false;
        state.get_teacher_subjects.errorMessage=null;
        state.get_teacher_subjects.data = action.payload;
    },
    [get_teacher_subjects.rejected]:  (state,action)=>{
        console.log(action);
        state.get_teacher_subjects.isFetching=false;
        state.get_teacher_subjects.isError=true;
        state.get_teacher_subjects.errorMessage=action.payload
    },









  
  }
})


export default SubjectsSlice.reducer