import React, { useEffect, useState } from 'react';
import Layout from '../Layout/Layout';
import SideBar from '../SideBar/SideBar';
import './Reports.css';

import { Link } from 'react-router-dom';

const Reports = () => {
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [guardianStudents, setGuardianStudents] = useState([]);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setPageNumber(1);
	}

	useEffect(() => {
		async function getData() {
			const actualData = await fetch(
				`${process.env.REACT_APP_API_URL}/get_student_by_guardian`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			).then((response) => response.json());
			setGuardianStudents(actualData);

			console.log('actual Dataaaaaaaaaaaaaaaaaaaa', actualData);
		}
		getData();
	}, []);

	return (
		<Layout>
			<div className='sideBar__relative'>
				<SideBar />
			</div>

			<h1 className='w-100 text-center my-4'>التقارير</h1>
			<div className='profileScreen'>
				<div className='reports_container'>
					{/* <div className='topcontent'>
						<div className='userImage'>
							<img
								src={`${process.env.REACT_APP_STORAGE_URL}${studentDetails?.data[0]?.student_image}`}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>اسم المستخدم</h3>
					</div> */}

					{/* {studentDetailsError && <Error errorText={studentDetailsError} />} */}

					{guardianStudents?.data &&
						guardianStudents?.data &&
						guardianStudents?.data?.map((item) => {
							return (
								<>
									{/* <div className='topcontent'>
						<div className='userImage'>
							<img
								src={`${process.env.REACT_APP_STORAGE_URL}${item?.student_image}`}
								style={{
									width: '100%',
									height: '100%',
									objectFit: 'cover',
									borderRadius: '50%',
								}}
							/>
						</div>
						<h3 className='userName'>{item?.student_name}</h3>
					</div> */}
									<div className='card' style={{ width: '16rem' }}>
										<img
											src={`${process.env.REACT_APP_STORAGE_URL}${item?.student_image}`}
											className='card-img-top'
											alt='...'
											style={{ height: '150px' }}
										/>
										<div className='card-body'>
											<h3 className='card-title text-center w-100'>
												{item?.student_name}
											</h3>
											{/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
											<Link
												to={`/subjectsReports/${item.student_id}`}
												class='btn btn-success w-100'>
												مشاهدة التقارير
											</Link>
										</div>
									</div>

									{/* <div className={`bottomContent`} style={{marginTop: '0px'}}>
							<div className='leftContent'>
								<ul>
									<li>
										<div>الاسم</div>
										<div>{item?.student_name}</div>
									</li>
									<li>
										<div>اسم الاب</div>
										<div>{item?.student_father_name}</div>
									</li>
									<li>
										<div>اسم الام</div>
										<div>{item?.student_mother_name}</div>
									</li>
									
									<li>
										<div>العنوان</div>
										<div>{item?.student_address}</div>
									</li>
									<li>
										<div>المحافظة</div>
										<div>{item?.student_city}</div>
									</li>
								</ul>
								<div className='title'>المعلومات الشخصية</div>
							</div>

		

						</div> */}
								</>
							);
						})}

					{/* { role == 1 && (
						<Button
							text={'تعديل كلمة السر'}
							className='editPasswordBtn'
							onclick={() => setEditPassword(!editPassword)}
						/>
					)} */}
				</div>
			</div>
		</Layout>
	);
};

export default Reports;
