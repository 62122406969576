import { useState } from "react";
import axios from "axios";

import { useSelector } from 'react-redux'


export const PostData = (url) => {
  const token = useSelector(state=>state.SIGN.token)

  const [data,setData]= useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
 const [IsLoading,setIsLoading]=useState(false);
 const [IsError,setIsError] = useState('');
  const PostData = async ({formdata}) => {
    setIsLoading(true);
    console.log(formdata);

    axios.withCredentials = true;

    setIsLoading(true)
    await axios.post(`${process.env.REACT_APP_API_URL}`+url, formdata, {
      
    
      headers: {
       
        
        'Authorization': `Bearer ${token}`,
       
   
        
        "Accept": "application/json",
        'Content-Encoding': 'gzip , deflate, br'
       
      },
  

      onUploadProgress: (progressEvent) => {
        const progress =  Math.floor((progressEvent.loaded * 100) / progressEvent.total)
       
        setProgress(progress);
      }
    }).then(res=>{
      if(res.status === 200)
      {
        const {data} = res;
       
        setIsError('');
        setIsSuccess(true)
        setProgress(0)
        setTimeout(()=>setIsSuccess(false),4000)
       
     return  setData(data);
        
      }
    
    }).catch(error=>{
       
      setProgress(0);
   
      return  setIsError(error)
    }
      
      ).finally(() => setIsLoading(false));
   
  };

  return { PostData, isSuccess, progress,IsLoading,IsError,data };
};
