import React , {useEffect , useState} from 'react'

const ProfessorsLogic = () => {

    const [Professors,setProfessors] = useState()
    useEffect(() => {
        async function getData() {
          const id=1;
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_all_teachers_for_specific_student`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":`Bearer ${localStorage.getItem('token')}`
            }
          }
          ).then(response => response.json());
          setProfessors(actualData?.data);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa professorsssLogic",actualData) 
        }
        getData()
      }, [])

  return {
    Professors,
  }
}

export default ProfessorsLogic