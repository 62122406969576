import React, { useEffect } from 'react'
import {useDispatch,useSelector} from 'react-redux'
import { getPosts } from '../../../redux-toolkit/reducers/Home/HomeSlice';
export default function Main_Home_Logic() {
    const dispatch= useDispatch();
    useEffect(()=>{
      dispatch(getPosts())
         
    },[])
    const homeData =  useSelector(state => state.home);


    useEffect(() => {
      document.title   = document.title + " | الرئيسية";  
    }, []);

  return {
    homeData
  }
}
