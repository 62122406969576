import React, { useEffect, useState } from "react";
import { GetData } from "../../customhook/getDatawithAuth";
import { useUploadForm } from "../../customhook/Uploadfiles";
import {
  setError,
  setSuccess,
} from "../../redux-toolkit/reducers/notification";

export default function ProfileLogic({ Title, setLoading }) {
  const [editPassword, setEditPassword] = useState(false);

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");

  const {
    getDatafrom: getdatateacher,
    IsLoading: teacherDetailsIsLoading,
    IsError: teacherDetailsError,
    data: teacherDetails,
  } = GetData(`/get_teacher_profile`);

  const {
    uploadForm: updatepassword,
    IsLoading: updatepasswordLoading,
    IsError: updatepasswordIsError,
    data,
    isSuccess: successupdatepassword,
  } = useUploadForm("/update_teacher_password", {
    setLoading,
    setSuccess,
    setError,
    SuccessMsg: "تم إضافة الدرس",
    redirectURL: "..",
  });

  useEffect(() => {
    document.title = Title + " || الملف الشخصي";
    getdatateacher();
  }, []);

  const UpdatePassword = async (e) => {
    e.preventDefault();

    const formdate = new FormData();
    formdate.append("new_password", newpassword);
    formdate.append("old_password", oldpassword);

    return await updatepassword(formdate);
  };

  useEffect(() => {
    if (successupdatepassword) setOldPassword("");
    setNewPassword("");
  }, [successupdatepassword]);

  return {
    editPassword,
    setEditPassword,
    teacherDetailsIsLoading,
    teacherDetailsError,
    teacherDetails,
    oldpassword,
    setOldPassword,
    newpassword,
    setNewPassword,
    UpdatePassword,
    updatepasswordIsError,
  };
}
