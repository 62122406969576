import axios from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

function AddTaskModal({ days, setRefresh, refresh }) {
	//   ********************************************************* */
	let formData = new FormData();
	//   console.log(element);
	const [Task, setTask] = useState('');
	const [Start_time, setStart_time] = useState('');
	const [End_time, setEnd_time] = useState('');
	const [DayId, setDayId] = useState(1);
	const [Loading, setLoading] = useState(false);
	//********************************************************* *
	const AddTask = async (formData) => {
		setLoading(true);
		await axios
			.post(
				`${process.env.REACT_APP_API_URL}/add_student_day_task_by_day_id`,
				formData,
				{
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('token'),
					},
				}
			)
			.finally(() => {
				setTimeout(() => {
					window.location.reload();
				}, 700);
			});
		setLoading(false);
		setRefresh(!refresh);
	};
	//********************************************************* */
	const renderOptions = days?.map((day) => {
		return (
			<option id={day.id} value={day.id}>
				{day.day}
			</option>
		);
	});
	//********************************************************* */
	return (
		<div
			className='modal fade'
			id='AddTask'
			tabIndex='-1'
			aria-labelledby='exampleModalLabel'
			aria-hidden='true'>
			<div className='modal-dialog  bg-white'>
				<div className='modal-content'>
					<div className='modal-header'>
						<h5 className='modal-title' id='exampleModalLabel'>
							إضافة مهمة
						</h5>
					</div>
					<div className='modal-body'>
						<form
							onSubmit={(event) => {
								event.preventDefault();

								formData.append('task', Task);
								formData.append('start_time', Start_time + ':00');
								formData.append('end_time', End_time + ':00');
								console.log('start-->', Start_time);
								console.log('end_time-->', End_time);
								formData.append('day_id', DayId);
								AddTask(formData);
								// setRefresh(true);
							}}>
							<div>
								<label htmlFor=''>اليوم</label>
								<select
									onChange={(e) => setDayId(e.target.value)}
									className='form-select'
									aria-label='Default select example'>
									{renderOptions}
								</select>
							</div>
							<div>
								<label htmlFor='' className=''>
									اسم المهمة
								</label>
								<input
									type='text'
									className='form-control mt-2'
									required
									value={Task}
									name='task'
									onChange={(event) => setTask(event.target.value)}
								/>
							</div>
							<div>
								<label htmlFor='' className=''>
									وقت البدء
								</label>
								<input
									type='time'
									required
									className='form-control mt-2'
									value={Start_time}
									name='start_time'
									onChange={(event) => setStart_time(event.target.value)}
								/>
							</div>
							<div>
								<label htmlFor='' className=''>
									وقت الانتهاء
								</label>
								<input
									type='time'
									name='end_time'
									required
									value={End_time}
									className='form-control mt-2'
									onChange={(event) => setEnd_time(event.target.value)}
								/>
							</div>
							<div className='text-center mt-3'>
								{Loading ? (
									<span className='spinner-grow text-primary'></span>
								) : (
									<button type='submit' className='btn btn-primary w-75'>
										حفظ
									</button>
								)}
							</div>
						</form>
					</div>
					<div className='modal-footer'>
						<button
							type='button'
							className='btn btn-secondary'
							data-bs-dismiss='modal'>
							الغاء
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default AddTaskModal;
