import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {useForm} from 'react-hook-form'
import { add_unit_faqs } from '../../../../../redux-toolkit/reducers/Units/UnitSlice';
import { useParams } from 'react-router-dom';
export default function Add_Unit_Faqs_Logic() {
  
    const {UnitId} = useParams();
    const dispatch=useDispatch();
    const { register , handleSubmit, formState: { errors ,isDirty, isValid } } = useForm({mode: "onChange",defaultValues:{
        question:'',
      answer:'',
      transable:0
    }});
    const onSubmit = data => {
       const formdata = new FormData();
       formdata.append('question',data.question);
       formdata.append('answer',data.answer);
       formdata.append('transable',data.transable);
       formdata.append('unit_id',UnitId);

      dispatch(add_unit_faqs({formdata}))
    }
  
    const data=useSelector((state)=>state.units.add_unit_faqs)
    
  return {data,register,errors,isDirty,isValid,handleSubmit,onSubmit}
}
