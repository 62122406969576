import React from 'react';
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import fetchsections from "../../redux-toolkit/api/fetchsectionapi";


export default function SectionsLogic() {
    const [page,setPage] =useState(1);
    const {isLoading, isError, data, error} = useQuery(['sections',page], fetchsections);
    const listRef = useRef();  
    function handlePageClick (data,qw,er)
    {
        console.log(qw);
        console.log(er);
        console.log(data);
     listRef.current.scrollIntoView()    
     setPage(data.selected+1);
    }
    return {
        listRef,isLoading, isError, data, error,handlePageClick,page
    }
}
