import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getpoints } from '../../redux-toolkit/reducers/GetPonits/GetPoints';

const MyPointsLogic = () => {
	const dispatch = useDispatch();
	const points = useSelector((state) => state.points);
	const loading = useSelector((state) => state.points.loading);
	useEffect(() => {
		dispatch(getpoints());
	}, []);
	return {
		points,
		loading,
	};
};

export default MyPointsLogic;
