import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectComment } from '../../redux-toolkit/reducers/SubjectComments/SubjectCommentsSlice';
import { postSubscribtionStudent } from '../../redux-toolkit/reducers/PostStudentSubscribtion/PostStudentSubscribtionSlice';

export default function SubjectLogic() {
	const SliderRef = useRef();
	const subjectId = useParams();
	const [refetch,setRefect] = useState(true);
	// const id =1;
	const dispatch = useDispatch();
	const subjectComment = useSelector((state) => state.subjectComment);
	console.log(subjectId);
	// console.log("subjectComment",subjectComment);
	useEffect(() => {
		dispatch(getSubjectComment(subjectId));
	}, []);

	const data = subjectComment?.post?.data;
	//  console.log("dataSubject",data)

	const handleSubscribtion = () => {
		// const subject_id=1
		dispatch(postSubscribtionStudent(subjectId));
	};

	const [subjectData, setSubjectData] = useState({});
	const [isLoadingSubject, setIsLoadingSubject] = useState(true);
	const [openLessons, setOpenLessons] = useState([]);
	const [subjectUnits, setSubjectUnits] = useState([]);
	const [UnitLessones, setUnitLessones] = useState([]);
	const [loadinLessones, setloadinLessones] = useState(false);
	const [status, setStatus] = useState('loading');

	const getMainSubjectData = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_subject_by_subject_id/${subjectId.id}?local=ar`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				// console.log(res);
				return res.json();
			})
			.then((x) => {
				// console.log('x',x);
				setSubjectData({ ...x.data });
				setIsLoadingSubject(false);
			})
			.catch((err) => {
				console.log(err);
			});
		// const data = response.json();
		// console.log(data);
	};

	const getOpenLessons = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_open_lessons_by_subject_id?subject_id=${subjectId.id}&local=en&limit=10`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				const openLes = data;
				// console.log('opent lessons from getopen lesson function ' , data?.data);
				setOpenLessons(data?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getSubjectUnits = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_all_units_by_subject_id?subject_id=${subjectId.id}&limit=10&local=ar`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((data) => {
				setSubjectUnits(data?.data);
				// console.log('data for units', data);
			})
			.catch((err) => {
				console.log(err);
			});

		// console.log('subject units', response.data);
	};
	const getUnitLessones = async (unit_id) => {
		setUnitLessones([]);
		setloadinLessones(true);

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id?limit=10&unit_id=${unit_id}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				setloadinLessones(false);
				console.log('ers unit lessons ', res);
				if (res.status == 410) {
				}
				return res.json();
			})
			.then((data) => {
				setUnitLessones(data?.data);
				setloadinLessones(false);
				setStatus('data');
				// console.log('dada yala yala', data?.data);
				// console.log('dada yala yala', status);
			})
			.catch((err) => {
				setloadinLessones(false);
				setUnitLessones([]);
				console.log('error inside unit lessons', err);
			});

		// console.log('subject units', response.data);
	};

	useEffect(() => {
		// console.log(subjectId.id);

		// getMainSubjectData();
		getOpenLessons();
		getSubjectUnits();

		// console.log(subjectData);
	}, []);

	useEffect(() => {
		getMainSubjectData();
	},[refetch])

	return {
		subjectData,
		openLessons,
		subjectUnits,
		SliderRef,
		handleSubscribtion,
		UnitLessones,
		getUnitLessones,
		status,
		setStatus,
		data,
		subjectId,
		isLoadingSubject,
		loadinLessones,
		setRefect
	};
}
