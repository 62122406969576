import React from 'react';
import { Link } from 'react-router-dom';
import Error from '../../../alerts/Error/Error';
import Show_All_Materials_Logic from './Show_All_Subjects_Logic';

export default function Show_All_Subjects() {
	const { data } = Show_All_Materials_Logic();
	console.log('data222', data);
	return (
		<div className='container my-5'>
			{/*data.isFetching&&<div class="d-flex justify-content-center">
<div class="spinner-grow text-dark" role="status">
<span class="visually-hidden">Loading...</span>
</div>
</div>*/}
			{data.isError && <Error errorText={data.errorMessage} />}
			{data.data && (
				<table className='w-100 table-bordered table_style  mt-3 table-striped   text-center'>
					<thead>
						<tr>
							<th className='first_child'> اسم المادة </th>
							<th> اسم القسم </th>
							<th> صورة المادة </th>
							<th>عدد الوحدات في المادة</th>
							<th>عدد الدروس</th>
							<th>الوصف</th>
							<th>النقاط</th>
							<th>السعر</th>
							<th>عدد الوحدات</th>
							<th className='last_child'></th>
						</tr>
					</thead>
					<tbody>
						{data.data?.map((subject) => (
							<tr key={subject.subject_id}>
								<td> {subject.subject_name} </td>
								<td> {subject.section_name} </td>
								<td>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '100%',
										}}>
										<img
											className='img-fluid'
											src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
											alt='Girl in a jacket'
											style={{
												objectFit: 'cover',
												width: '100%',
												height: '12vh',
											}}
										/>
									</div>
								</td>
								<td> {subject.number_of_units_in_the_subject} </td>
								<td> {subject.number_of_lessons} </td>
								<td>
									{subject?.subject_description?.length > 15 && '...'}{' '}
									{subject?.subject_description?.slice(0, 15)}{' '}
								</td>
								<td> {subject.points} </td>
								<td> {subject.price} </td>
								<td> {subject.number_of_units_in_the_subject} </td>
								<td>
									{' '}
									<Link
										to={`showallunit/${subject.subject_id}`}
										className='btn btn1'>
										{' '}
										عرض الوحدات{' '}
									</Link>{' '}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
