import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Pagination from '../Pagination/Pagination';
import AttatchmentSectionFile from './AttatchmentSectionFile';
import img from '../../assets/library-site/img.png';
import './index.css';
import { Link } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
import MainHeader from '../MainHeader/MainHeader';
import './index.css';
import SubTitle from '../SubTitle/SubTitle';
import ButtonM from '../ButtonM/ButtonM';
function SectionFiles() {
	//*************************************************** */
	const param = useParams();
	const [Page, setPage] = useState(1);
	const [loading, setloading] = useState(true);
	const [dataLibrary, setDataLibrary] = useState('');
	//*************************************************** */
	const {state} = useLocation();
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	useEffect(() => {
		fetch(
			`${process.env.REACT_APP_API_URL}/get_website_library_photo_and_description`
		)
			.then((response) => response.json())
			.then((actualData) => {
				console.log('library sec', actualData);
				// setlaoding(false);
				setDataLibrary(actualData);
			})
			.then((error) => {
				// setlaoding(false);
				console.log('error library', error);
			});
		// ).then(res=>res.json()).then(res=>setData(res));

		// console.log("library site info",response)
	}, []);
	const getBooksBySection = async ({ queryKey }) => {
		const { data } = await axios.get(
			`${process.env.REACT_APP_API_URL}/get_books_by_website_library_section_id/${param.id}?page=${queryKey[1]}&limit=5`
		);

		console.log(data.data.data.data);
		setloading(false);
		return data.data;
	};
	const { data, isLoading } = useQuery(
		['sectionFiles', Page],
		getBooksBySection
	);
	const openInNewTab = (url) => {
		window.open(url, '_blank');
	};
	const handlePageClick = (data, qw, er) => {
		setPage(data.selected + 1);
	};
	//*************************************************** */
	console.log(data);
	const renderBooks =
		data?.data &&
		data.data.map((book) => {
			// console.log(book)
			return (
				<>
				<div className='col-12  col-md-12 col-lg-12 align-items-center justify-content-center '>
						<p
							className='mx-auto mt-3 text-center '
							style={{
								fontSize: ' 1.2rem',
							}}
							dangerouslySetInnerHTML={{
								__html: state?.desc,
							}}></p>
					</div>
					<div className='text-center my-3'>
						<SubTitle title={book.title} />
						<div
							className='mx-auto section_hover text-center my-3 shadow border rounded  container  col-12 '
							style={{
								maxWidth: '900px',
							}}
							key={book.id}
							id={book.id}>
							{/* <h3>{book.title}</h3> */}

							<img src={img} className='bookImage_itself' alt='book_img' />
							<p style={{ lineHeight: '1.675' }} className=''>
								كتب لغات البرمجة .. لغة البرمجة (بالإنجليزية: Programming
								language)‏، هي مجموعة من الأوامر، تكتب وفق قواعد تُحَدَّد بواسطة
								لغة البرمجة، ومن ثُمَّ تمر هذه الأوامر بعدة مراحل إلى أن تنفذ
								على جهاز الحاسوب. تقسم لغات البرمجة بناء على قربها من اللغات
								الإنسانية إلى: لغة عالية المستوى (قريبة من اللغة التي يفهمها
								البشر مثل: السي شارب، البايثون، الجافا، سي ++. لغة منخفضة
								المستوى (قريبة من لغة الآلة) مثل: لغة التجميع. وتقسم أحياناً
								بناء على الأغراض المرغوبة من اللغة المستخدمة. هناك لغات صُممت
								لكي تعمل على أجهزة معينة، مثل أن تقوم شركة ما بإنتاج جهاز حاسوب
								أو معالج مركزي (وحدة معالجة مركزية)، وتوفر له دليل استعمال يحتوي
								على الأوامر التي تنفذ عليه، وهناك لغات أخرى أكثر عمومية تعمل
								بشكل مستقل عن نوع الآلة، أي أنها تعمل ضمن آلة افتراضية، مثل لغة
								جافا..
							</p>
							<p className='btn_library mx-auto my-3 '>
								<Link
									to={'/EmbedPdf'}
									state={{
										url: `${process.env.REACT_APP_STORAGE_URL}/${book.pdf_link}`,
										bookName: book?.title
									}}
									className='text-white w-100'>
									<ButtonM text={'فتح'} className='mx-0 btn_hover' />
								</Link>
							</p>

							{/* link={`${process.env.REACT_APP_STORAGE_URL}/${book.pdf_link}`} */}
						</div>
					</div>
				</>
			);
		});
	//*************************************************** */
	return (
		<Layout>
			{/* <MainHeader /> */}
			{loading ? (
				<div className='text-center my-3'>
					<div class='spinner-border  mx-auto' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				</div>
			) : (
				<>
					{/* <h2 className='mx-auto text-center my-5 fw-bold'>قسم البرمجة</h2> */}
					{/* <SubTitle title={'قسم البرمجة'} /> */}
					<SubTitle title={'مكتبة الموقع'} />
					<div className='container-fluid '>
						<div className='row g-2 justify-content-center '>
							<div className='col-10  col-md-10 col-lg-10 align-items-center justify-content-center text-dark '>
								<p
									className='mx-auto mt-3 text-center '
									style={{
										fontSize: ' 1.2rem',
									}}
									dangerouslySetInnerHTML={{
										__html: dataLibrary?.website_library_description,
									}}></p>
							</div>
						</div>
					</div>

					<div className='container'>
						<div className='row gx-5 gy-3'>{renderBooks}</div>
						<div className='my-4'>
							<Pagination
								data={data}
								handlePageClick={handlePageClick}
								page={Page}
							/>
						</div>
					</div>
				</>
			)}
		</Layout>
	);
}

export default SectionFiles;
