import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_traditional_quiz_question_file_by_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Error from '../alerts/Error/Error';
import DeleteModal from '../DeleteModal';
import Show_Traditionalquiz_Questions_Files_Logic from './Show_Traditionalquiz_Questions_Files_Logic';

export default function Show_Traditionalquiz_Questions_Files() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const { data, dispatch, QuizId, loading, deleteQuestion } =
		Show_Traditionalquiz_Questions_Files_Logic();

	return (
		<div className='container'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{!data.isError && data && (
				<div class='table-responsive'>
					<table
						className='  text-center table_style table-bordered table-striped  my-5'
						style={{ width: '100%' }}>
						<thead>
							<tr>
								<th className='first_child'> رقم الصورة </th>
								<th> الصورة </th>
								<th className='last_child'></th>
							</tr>
						</thead>
						<tbody>
							{data?.data?.map((File, index) => (
								<tr key={File.image_id}>
									<td> {index} </td>
									<td>
										{' '}
										<a
											target='blank'
											href={` ${process.env.REACT_APP_STORAGE_URL}${File.image_link}`}>
											{' '}
											<img
												src={`${process.env.REACT_APP_STORAGE_URL}${File.image_link}`}
												alt='imag'
												style={{ width: '20vw', height: '20vh' }}
											/>{' '}
										</a>{' '}
									</td>

									<td>
										<button
											type='button'
											class='btn btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={() => setSelectedAutomatedQuizIe(File.image_id)}>
											حذف الصورة{' '}
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذه الصورة ؟'}
				handleDelete={() => deleteQuestion(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
			<div className='my-5'>
				{data.isError && <Error errorText={data.errorMessage} />}
			</div>
		</div>
	);
}
