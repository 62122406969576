import React, { useEffect, useState } from 'react';
import './index.css';

import Layout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import Lesson from '../../components/lesson';
import { useParams } from 'react-router-dom';
import {
	clearMsg,
	getError,
	getSuccess,
} from '../../redux-toolkit/reducers/notification';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import MainHeader from '../../components/MainHeader/MainHeader';

const SubjectsScreen = (props) => {
	// const { units } = SubjectsLogic();

	//  get subject id from url
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	const subject_id = urlParams.get('id');

	//************************************************ */
	useParams();
	const [lessones, setLessones] = useState();
	const [units, setUnits] = useState([]);
	const [lessones_finishes, setlessones_finishes] = useState([]);
	const [lessnGreen, setLessonGreen] = useState(0);
	const [loading, setloading] = useState(true);
	const token = localStorage.getItem('token');

	const errorMsg = useSelector(getError);
	const successMsg = useSelector(getSuccess);

	const getLessones = async () => {
		setloading(true);
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_all_student_saved_lessons_of_student_saved_subject_by_student_saved_subject_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						student_saved_subject_id: subject_id,
						limit: 20,
					},
				}
			)
			.then((response) => {
				setloading(false);
				console.log(response.data.data);
				setLessones(response.data.data);
			})
			.catch((err) => {
				setloading(false);
				console.log(err);
			});
	};

	useEffect(() => {
		getLessones();
	}, []);
	//************************************************ */
	const getUnits = async () => {
		console.log('unitsss');
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_count_of_units_with_finished_count_by_subject_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						subject_id: subject_id,
					},
				}
			)
			.then((res) => {
				// setUnits(res.data)
				console.log('unitss', res.data.data);
				setUnits(res.data.data);

				console.log(units);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getUnits();
	}, []);

	//************************************************ */
	const [unit_id, setunit_id] = useState(1);

	const getFinishLessones = async () => {
		await axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_count_of_lessons_with_finished_count_by_unit_id`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						unit_id: unit_id,
					},
				}
			)
			.then((res) => {
				// setUnits(res.data)
				console.log('lesson finishes', res.data.data);
				setLessonGreen(res.data.data.student_lesson_count);
				let temp = [];
				for (let i = 0; i < res.data.data.total_number_of_lessons; i++)
					temp.push(1);
				setlessones_finishes(temp);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		getFinishLessones();
	}, [unit_id]);

	//************************************************ */

	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='subjectsScreen container text-center'>
				<h4 style={{ color: '#044364' }} className='fw-bold my-5'>
					الدروس المحفوظة
				</h4>
				{/* <LessonTitle /> */}
				{/* <Spacer  /> */}

				{/* <h3 className='Subjects__Title'>عدد الدروس المنجزة</h3> */}
				{/* <h3 className='Subjects__Title'>عدد الدروس المنجزة</h3> */}

				{loading ? (
					<div className='text-center'>
						<div class='spinner-border' role='status'>
							<span class='sr-only'>Loading...</span>
						</div>
					</div>
				) : (
					<div className='row' style={{ justifyContent: 'space-around' }}>
						{lessones
							? lessones?.data?.map((lesson) => (
									<div className='col-lg-3 mx-2 my-2 col-md-4 col-sm-6 col-12 gab-3 text-center'>
										<Lesson
											isSaved={lesson.is_saved}
											btnType={'delete'}
											id={lesson.lesson_id}
											key={lesson.lesson_id}
											btnText='الذهاب الى الدرس'
											lessonName={lesson.subject_name}
											lessonNum={lesson.lesson_name}
											lessonTime={lesson.lesson_duration}
											lessonImage={lesson.lesson_image}
											starsNum={35}
										/>
									</div>
							  ))
							: 'لا يوجد دروس محفوظة'}
					</div>
				)}

				{/* <Spacer /> */}

				{/* <div className='archivedUnitsContainer'>
        {
          units.map((unit,index) => {
            return <ArchivedUnit isArchived={unit.isArchived} name={unit.name} />
          })
        }
        </div> */}
				{/* <TitleSection title="المراحل التي قمت بانجازها" /> */}
				{/* <TitleSection title="المراحل التي قمت بانجازها" /> */}

				{/* <h3 className='Subjects__Title'>عدد الوحدات</h3>
				<div className='archivedUnitsContainer'>
					{units?.total_units && units?.total_units?.length > 0
						? units.total_units?.map((unit, index) => {
								return (
									<div
										onClick={() => {
											setunit_id(unit);
											setlessones_finishes([]);
										}}>
										<ArchivedUnit
											number={index + 1}
											isArchived={index < units?.student_unit_count}
											key={index}
										/>
								
									</div>
								);
						  })
						: 'انت غير مشترك في هذه الوحدة'}
				</div>
				
				<h3 className='Subjects__Title'>عدد الدروس</h3>
				<div className='archivedUnitsContainer'>
					{lessones_finishes && lessones_finishes.length > 0
						? lessones_finishes.map((unit, index) => {
								return (
									<ArchivedUnit
										isArchived={lessnGreen > index}
										number={index + 1}
										key={index}
									/>
								);
						  })
						: 'انت غير مشترك في هذه الدروس'}
				</div> */}
				{/* <ContactTeacher toRight /> */}
				<ToastSuccessError
					ErrorMessage={errorMsg}
					successMessage={successMsg}
					clearMsg={clearMsg}
				/>
			</div>
		</Layout>
	);
};

export default SubjectsScreen;
