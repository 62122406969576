
import React,{useEffect,useState} from 'react'

const AutomatedQuizTeacherLogic = () => {
    const [AutomateQuiz,setAutomateQuiz] = useState()
    const id=6;
    useEffect(() => {
        async function getData() {
          const actualData = await fetch(
          `${process.env.REACT_APP_API_URL}/get_automated_quiz_by_unit_id_and_student_id`,{
            method:"GET",
            headers:{
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization":"Bearer 2|vbqCqitJbBoF43KXGQ4emaMPazD1xVqLcp3W2LGs"
            }
          }
          ).then(response => response.json());
          setAutomateQuiz(actualData);
      
          console.log("actual Dataaaaaaaaaaaaaaaaaaaa TeacherStudnetssprofile",actualData) 
        }
        getData()
      }, [])
  return {
    AutomateQuiz
  }
}

export default AutomatedQuizTeacherLogic