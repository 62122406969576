import axios from "axios";

  export default async function  fetchAllUnitsFaqs ({queryKey})
 {
     


 const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_faqs_by_unit_id/${queryKey[1]}?limit=${queryKey[2]}` ,
 {
  headers: {
    'Authorization': `Bearer ${queryKey[3]}` 
  } 
});




   return  data;
 } 