import React, { useEffect } from 'react'
import Layout from '../Layout/Layout';
import LayoutSideTeacher from '../LayoutSide/LayoutSideTeacher';
import AutomatedQuizTeacherLogic from './AutomatedQuizTeacherLogic'

const AutomatedQuizTeacher = () => {
        // Sroll to top 
        useEffect(() => {
          const intervalId = setInterval(() =>{
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          },1);
  
          setTimeout(() => {
            clearInterval(intervalId);
          },50)
        
  
        },[])
    const {AutomateQuiz} = AutomatedQuizTeacherLogic();
  return (
 <LayoutSideTeacher desc={"الطالب"}>
    
     {AutomateQuiz && <h1>{AutomateQuiz.message}</h1>}
    </LayoutSideTeacher>
  )
}

export default AutomatedQuizTeacher