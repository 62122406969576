import React, { useEffect, useState } from 'react';
import MainComment from '../../components/mainComment';
// import ScreenTitle from '../../components/ScreenTitle';
// import userImg from '../../assets/icons/profile.svg';s
import './index.css';
import Layout from '../Layout/Layout';

// import {useDispatch , useSelector} from 'react-redux'
// import { getAllComments } from '../../redux-toolkit/reducers/AllComments/AllCommentsSlice';

import Pagination from '../../components/Pagination/Pagination';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import Notification from '../../components/Notification/Notification';
import fetchNotificationApi from '../../redux-toolkit/api/fetchNotificationApi';

function AllNotification() {
	//************************************************************************************** */
	const loca = useLocation();
	// const url = loca.state.url;
	const [page, setPage] = useState(1);
	// console.log("url-->", url);
	//************************************************************************************** */
	const { isLoading, isError, data, error, refetch } = useQuery(
		['comments', page, '?limit=5'],
		fetchNotificationApi
	);
	// console.log('databbbbbbbb', data);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}
	//************************************************************************************** */

	const notificationState = (notification) => {
		// in case of student
		if (localStorage.getItem('role') == 1) {
			switch (notification.state) {
				case 1:
					return `/myquizes/lessons/automatedquiz/${notification.needed_id}`;
					break;

				default:
					return '';
			}
		}

		// in case of teacher
		if (localStorage.getItem('role') == 2) {
			switch (notification.state) {
				case 1:
					return `/options/quizes/typequiz/${notification.student_id}/lessons/automatedquiz/${notification.needed_id}`;
					break;

				default:
					return '';
			}
		}
	};
	return (
		<Layout>
			<div className='allcommentsScreen' style={{display: 'block'}}>
				<h4 className='fw-bold mt-5 text-center mx-5'>
					<span style={{ color: '#044364' }}>{'الإشعارات'}</span>
				</h4>
				{isLoading && (
					<div className='text-center my-5 w-100'>
						<div class='spinner-border' role='status'>
							<span class='visually-hidden'>Loading...</span>
						</div>
					</div>
				)}
				<div className='mx-2 '>
					{!isLoading && data?.data?.length > 0
						? data?.data?.map((noti) => (
								// <MainComment
								// 	// img={el?.student_image}
								// 	// img={userImg}
								// 	img={el?.sender_image}
								// 	notification={'yes'}
								// 	// name={"اسم الطالب"}
								// 	name={el?.sender_name}
								// 	// name={el.student_image}
								// 	date={el?.date_time}
								// 	// date={"12/5/2022"}
								// 	// text="lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus "
								// 	text={el?.message}
								// />

								<Notification
									linkto={noti.clickable == 1 ? notificationState(noti) : ''}
									message={noti.message}
									isRead={noti.has_seen}
									image={noti.sender_image}
									id={noti.id}
									timeStamp={noti.created_at}
									notificatoin_id={noti.id}
									timestamp={noti.created_at}
									refetch={refetch}
								/>
						  ))
						: null}

					{data?.data?.length == 0 && !isLoading ? (
						<div className='text-center mt-5'>
							<h3 className='mt-5'>لا يوجد إشعارات</h3>
						</div>
					) : null}
				</div>

				<div className='my-4' />

				{data?.total <= 5 ? (
					<> </>
				) : (
					!isLoading && (
						<Pagination
							data={data}
							handlePageClick={handlePageClick}
							page={page}
						/>
					)
				)}
				<div className='my-4' />
			</div>
		</Layout>
	);
}

export default AllNotification;
