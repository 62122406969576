import axios from "axios";

export default async function fetchNotificationApi({ queryKey }) {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/get_user_notification?limit=5?page=${queryKey[1]}`, {
    params: { limit: 9, local: "en" },
    headers:{
        "Authorization":`Bearer ${localStorage.getItem('token')}`
    }
  });
  // console.log("fetched", data);
  return data.data;
}