import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useUploadForm } from "../../../../customhook/Uploadfiles";
import { get_lesson_by_lesson_id } from "../../../../redux-toolkit/reducers/Lesson/LessonSlice";
import {
  setError,
  setSuccess,
} from "../../../../redux-toolkit/reducers/notification";

let allowedExtension = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];
export default function Edit_Lesson_Logic({ setLoading }) {
  const { SubjectId, LessonId, UnitId } = useParams();

  const {
    data: lessondata,
    errorMessage: lessondataerror,
    isError: lessondataIserror,
    isFetching: lessondataIsfetching,
  } = useSelector((state) => state.lesson.get_lesson_by_lesson_id);

  const [cover_error, setcover_error] = useState("");
  const [cover, setcover] = useState(null);
  const cover_Ref = useRef();
  const dispatch = useDispatch();
  const {
    reset,
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      points: "",
      first_video: "",
      second_video: "",
      second_video: "",
      isFree: 1,
      start_date: "",
      duration: "",
      end_date: "",
      requirements: "",
      transable: "0",
      what_we_will_learn: "",
      lesson_order: ''
    },
  });

  console.log(lessondata, "iddddddddddddddddd");

  // useEffect(() => {
  //   dispatch(get_lesson_by_lesson_id({ LessonId }));
  // }, []);

  // useEffect(() => {
  //   if (lessondata) {
  //     setValue("name", lessondata.unit_name);
  //     setValue("description", lessondata.unit_description);
  //     setValue("first_video", lessondata.unit_video);
  //     setValue("start_date", lessondata.start_date);
  //     setValue("end_date", lessondata.end_date);
  //     setValue("requirements", lessondata.requirements);
  //     setValue("points", lessondata.points);
  //   }
  // }, [lessondata]);

  const {
    isSuccess: edit_lesson_success,
    uploadForm: edit_lesson,
    progress: edit_lesson_progress,
    IsError: edit_lesson_error,
    data: edit_lesson_data,
    IsLoading: edit_lesson_loading,
  } = useUploadForm(`/update_lesson/${LessonId}`, {
    setLoading,
    setSuccess,
    setError,
    SuccessMsg: "تم تعديل الدرس",
    redirectURL: "..",
  });

  const onSubmit = async (data) => {
    const edit_lesson_form = new FormData();
    console.log("errorrrs", errors);
    console.log("dataaaa", data);
    edit_lesson_form.append("name", data.lesson_name);
    edit_lesson_form.append("description", data.lesson_description);
    edit_lesson_form.append("points", data.lesson_points);

    if(data.lesson_order != ''){
      edit_lesson_form.append("lesson_order", data.lesson_order);
    }

 
      edit_lesson_form.append("first_video", data.lesson_first_video);
    

    data.second_video &&
      edit_lesson_form.append("second_video", data.lesson_second_video);
    data.third_video &&
      edit_lesson_form.append("third_video", data.lesson_third_video);


    edit_lesson_form.append("isFree", data.isFree);
    edit_lesson_form.append("requirements", data.requirements);
    edit_lesson_form.append("subject_id", SubjectId);
    edit_lesson_form.append("transable", 0);
    edit_lesson_form.append("duration", data.lesson_duration);
    edit_lesson_form.append("unit_id", UnitId);
    edit_lesson_form.append("what_we_will_learn", data.what_we_will_learn);
    
    if(cover){
      edit_lesson_form.append("cover", cover);
    }

    for (var pair of edit_lesson_form.entries()) {
      console.log("formData", pair[0] + " - " + pair[1]);
    }

    await edit_lesson(edit_lesson_form);
  };

  const handlecover = (e) => {
    if (
      e.target.files[0].size / (1024 * 1024) < 8 &&
      allowedExtension.indexOf(e.target.files[0].type) > -1
    ) {
      setcover_error("");
      setcover(e.target.files[0]);
    } else {
      setcover(null);
      setcover_error("يرجى اختيار صورة بلاحقة jpg,png ");
      cover_Ref.current.value = "";
    }
  };
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    edit_lesson_error,
    edit_lesson_progress,
    edit_lesson_success,
    edit_lesson_loading,
    onSubmit,
    handlecover,
    cover,
    cover_Ref,
    cover_error,
    reset,
    LessonId,
    lessondataerror,
    lessondataIserror,
  };
}
