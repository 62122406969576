import React from 'react';
import { Link } from 'react-router-dom';

function SubjectCard(props) {
	const subject = props.subject;
	const student_saved_subject_id = subject.student_saved_subject_id;
	console.log(subject);
	return (
		<Link
			to={`/subjectss?id=${student_saved_subject_id}`}
			className='card section_hover mx-2  px-0   my-3 col-md-6 col-lg-4 col-sm-8  col-xs-10 col-11 text-center'
			style={{ height: '420px ', maxWidth: '360px' }}>
			<div
				className=' text-center  section_desc  section_desc_itself'
				style={{ padding: '0px', borderRadius: '25px' }}>
				<img
					// src={
					// 	process.env.REACT_APP_STORAGE_URL +
					// 	section.section_image
					// }
					src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
					width='100%'
					height='250px'
				/>
				<h4 class='  section_title_itself rounded-x fw-bold '>
					{subject.subject_name}
				</h4>
				<p className='text-dark fs-5 section_desc_itself'>
					يحتوي هذا القسم على العديد من الكورسات المهمة
				</p>
			</div>
		</Link>
	);
}

export default SubjectCard;
