import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_all_final_traditional_quizzes_by_unit_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';

export default function Show_all_traditional_test_Logic() {
	const { UnitId } = useParams();
	const dispatch = useDispatch();
	const [loading, setloading] = useState();
	useEffect(() => {
		dispatch(get_all_final_traditional_quizzes_by_unit_id({ UnitId }));
	}, []);
	const deleteQuiz = (id) => {
		setloading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};
	const data = useSelector(
		(state) => state.unitTest.get_all_final_traditional_quizzes_by_unit_id
	);

	return { dispatch, data, UnitId, loading, deleteQuiz };
}
