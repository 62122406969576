import React, { useEffect, useState } from 'react';
import MainComment from '../../components/mainComment';

import './index.css';
import Layout from '../Layout/Layout';
import Button from '../../components/button';

import { useLocation } from 'react-router-dom';
import fetchComments from '../../redux-toolkit/api/fetchCommentsapi';
import { useQuery } from 'react-query';
import MainHeader from '../../components/MainHeader/MainHeader';

function AllComments() {
	const [pagination, setpagination] = useState(10);
	const [prevPagination, setprevPagination] = useState(0);
	//************************************************************************************** */
	const loca = useLocation();
	const url = loca.state.url;
	const [page, setPage] = useState(1);
	// console.log("url-->", url);
	//************************************************************************************** */
	const { isLoading, isError, data, error } = useQuery(
		['comments', page, url, '?limit=10'],
		fetchComments
	);

	useEffect(() => {
		console.log('comments data', data);
	}, [data]);

	function handlePageClick(data) {
		setPage(data.selected + 1);
	}
	const next = () => {
		if (pagination < data?.data?.length) {
			setpagination(pagination + 10);
			setprevPagination(prevPagination + 10);
		}
	};
	const prev = () => {
		if (prevPagination >= 10) {
			setpagination(pagination - 10);
			setprevPagination(prevPagination - 10);
		}
	};
	//************************************************************************************** */

	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='allcommentsScreen container ' style={{display: 'block'}}>
				<h4 className='fw-bold mt-2'>
					<span style={{ color: '#044364' }}>التعليقات</span>
				</h4>
				{isLoading ? (
					<div className='text-center mt-2'>
						<div class='spinner-border' role='status'>
							<span class='sr-only'>Loading...</span>
						</div>
					</div>
				) : (
					<>
						<div className='comments'>
							{data?.data &&
								data?.data?.map((el, index) => {
									if (index < pagination && prevPagination <= index)
										return (
											<MainComment
												// img={el?.student_image}
												img={
													el?.student_image
														? process.env.REACT_APP_STORAGE_URL +
														  el?.student_image
														: process.env.REACT_APP_STORAGE_URL + el?.user_image
												}
												// name={"اسم الطالب"}
												name={
													el?.student_name ? el?.student_name : el?.user_name
												}
												// name={el.student_image}
												date={el?.date_time}
												// date={"12/5/2022"}
												// text="lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus lorem ipsum lorem impus "
												text={el.comment ? el?.comment : el?.opinion}
											/>
										);
								})}
						</div>

						{data?.total < 10 ? (
							<> </>
						) : (
							// <Pagination
							// 	data={data}
							// 	handlePageClick={handlePageClick}
							// 	page={page}
							// />
							<div className='row justify-content-center'>
								<Button
									text={<span className='text-danger'>السابق</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={prev}
									backgroundColor='white'
								/>
								<Button
									text={<span className='text-danger'>التالي</span>}
									className={`lessonsSecreen__FinishExamBtns col-md-2 col-7`}
									onclick={next}
									backgroundColor='white'
								/>
							</div>
						)}
					</>
				)}
			</div>
		</Layout>
	);
}

export default AllComments;
