import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { delete_lesson_faqs_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
import Show_All_Lesson_Faqs_Logic from './Show_All_Lesson_Faqs_Logic';
import './Show_All_Lesson_Faqs.css';
import DeleteModal from '../../../../DeleteModal';
export default function Show_All_Lesson_Faqs() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const {
		dispatch,
		data,
		isLoading,
		isError,
		error,
		refetch,
		LessonId,
		loading,
		deleteFaq,
	} = Show_All_Lesson_Faqs_Logic();

	return (
		<div className='container my-5 '>
			{isLoading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{
				<>
					<table className=' table-bordered  table_style mt-3 w-100 table-striped  text-center'>
						<thead>
							<tr>
								<th className='first_child'>#</th>
								<th> السؤال </th>
								<th> الجواب</th>
								<th></th>
								<th className='last_child'></th>
							</tr>
						</thead>
						{data?.data?.data && (
							<tbody>
								{data?.data?.data?.map((faq, index) => (
									<tr key={faq.id}>
										<td>{index}</td>
										<td> {faq.question} </td>
										<td> {faq.answer} </td>

										<td>
											<Link
												to={`edit_lesson_faqs/${faq.id}`}
												className='btn btn1'>
												{' '}
												تعديل{' '}
											</Link>{' '}
										</td>

										<td>
											<button
												type='button'
												class='btn btn2'
												data-bs-toggle='modal'
												data-bs-target='#exampleModal'
												onClick={() => setSelectedAutomatedQuizIe(faq.id)}>
												حذف
											</button>
										</td>
									</tr>
								))}
							</tbody>
						)}
						{isError && (
							<div class='d-flex justify-content-center'>
								<label className='text-danger'>{error.message}</label>
							</div>
						)}
					</table>
					<Link
						className='btn btn1 mt-4'
						to={`add_lesson_faqs`}
						state={{ LessonId }}>
						أضف سؤال شائع
					</Link>
				</>
			}
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا السؤال ؟'}
				handleDelete={() => deleteFaq(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
