import {
	createAsyncThunk,
	createDraftSafeSelector,
	createSlice,
} from '@reduxjs/toolkit';

export const postCommentfun = createAsyncThunk(
	'comment/postCommentwrite',
	async ({ comment, lesson_id }, thunkAPI) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/post_lesson_comment`,
				{
					method: 'POST',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					body: JSON.stringify({
						comment,
						lesson_id,
					}),
				}
			);
			let data = await response.json();
			console.log('dataCommentSlice', data);
			if (response.ok) {
				console.log('response from postOpinionSlice', response);
				//   localStorage.setItem("token", data.token)
				window.location.reload();
				return data;
			} else {
				console.log(data);
				return thunkAPI.rejectWithValue(data);
			}
		} catch (e) {
			console.log('Error', e.response.data);
			return thunkAPI.rejectWithValue(e.response.data);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
	isError: '',
	isSuccess: '',
};

export const PostCommentLessonSlice = createSlice({
	name: 'comment',
	initialState,
	reducers: {
		clearMsg(state, action) {
			console.log('clear');
			state.isError = '';
			state.isSuccess = '';
		},
	},
	extraReducers: {
		[postCommentfun.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[postCommentfun.fulfilled]: (state, action) => {
			state.loading = false;
			console.log('fulfilled_postOpinion', action.payload);
			state.post = action.payload;
			state.isSuccess = 'تم التعليق بنجاح';
		},
		[postCommentfun.rejected]: (state, action) => {
			console.log('rejected_aboutUs', action);
			state.loading = false;
			state.error = action.payload;
			state.isError = action.payload?.message || 'حدث خطأ بالشبكة';
		},
	},
});

export const getSuccess = createDraftSafeSelector(
	(state) => state.postComment,
	(postComment) => postComment.isSuccess
);

export const getError = createDraftSafeSelector(
	(state) => state.postComment,
	(postComment) => postComment.isError
);
export const getLoading = createDraftSafeSelector(
	(state) => state.postComment,
	(postComment) => postComment.loading
);

export const { clearMsg } = PostCommentLessonSlice.actions;
export default PostCommentLessonSlice.reducer;
