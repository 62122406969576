import React from 'react';

function DeleteModal(props) {
	const { messagConfirm, handleDelete, isDeleting } = props;
	return (
		<div
			class='modal fade'
			id='exampleModal'
			tabindex='-1'
			aria-labelledby='exampleModalLabel'
			aria-hidden='true'>
			<div class='modal-dialog'>
				<div class='modal-content'>
					<div class='modal-header'>
						<h5 class='modal-title' id='exampleModalLabel'>
							{messagConfirm}
						</h5>
					</div>
					{isDeleting ? (
						<div class='modal-body'>
							<div className='w-100 text-center'>
								<div class='spinner-border' role='status'>
									<span class='visually-hidden'>Loading...</span>
								</div>
							</div>
						</div>
					) : null}
					<div class='modal-footer justify-content-between'>
						<button
							type='button'
							class='btn btn1'
							style={{ marginLeft: 'auto !important' }}
							data-bs-dismiss='modal'>
							لا
						</button>
						<button type='button' class='btn btn2' onClick={handleDelete}>
							نعم
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DeleteModal;
