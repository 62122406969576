import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {get_unit_comment_by_id, update_comment_for_unit } from '../../../../../redux-toolkit/reducers/Units/UnitSlice';
import { update_lesson_faqs_by_id } from '../../../../../redux-toolkit/reducers/Lesson/LessonSlice';
export default function Edit_Comment_Logic() {
  const unitComment = useSelector(state => state.units.get_unit_comment_by_id);
  const updatecomment = useSelector(state => state.units.update_comment_for_unit)

  const { CommentId } = useParams();
  const dispatch = useDispatch();


  const { register, setValue, handleSubmit, formState: { errors, isDirty, isValid } } = useForm({
    mode: "onChange", defaultValues: {
      comment: '',

    }
  });

  useEffect(() => {
    dispatch(get_unit_comment_by_id({ CommentId }));
  }, [])



 
  useEffect(() => {
    if (unitComment.data) {
      setValue('comment', unitComment.data.comment);
    }
    console.log(unitComment);
  }, [unitComment.data])

  const onSubmit = data => {
    const formdata = new FormData();
    formdata.append('comment', data.comment);

    dispatch(update_comment_for_unit({ formdata, CommentId }))
  }

  const data = useSelector((state) => state.units.add_unit_faqs)

  return { data, register, errors, isDirty, isValid, handleSubmit, onSubmit, updatecomment }
}
