import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit';

const slicer = createSlice({
	name: 'notification',
	initialState: {
		isSuccess: '',
		isError: '',
	},
	reducers: {
		clearMsg(state, action) {
			console.log('clear');
			state.isSuccess = '';
			state.isError = '';
		},
		setSuccess(state, action) {
			state.isSuccess = action.payload;
		},
		setError(state, action) {
			console.log('inside setError--->', action.payload);
			state.isError = action.payload;
		},
	},
});
export const getSuccess = createDraftSafeSelector(
	(state) => state.notification,
	(notify) => notify.isSuccess
);
export const getError = createDraftSafeSelector(
	(state) => state.notification,
	(notify) => notify.isError
);

export const { clearMsg, setError, setSuccess } = slicer.actions;
export default slicer.reducer;
