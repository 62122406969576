import React from 'react'
import Edit_Automated_Quiz_Unit_Logic from './EditautomatedquizLessonLogic';
import katex from "katex";
import { Editor } from "@tinymce/tinymce-react";
import "katex/dist/katex.min.css";
import ReactQuill from 'react-quill';
window.katex = katex;

export default function EditautomatedquizLesson() {

    

    const {register,
        errors,
        isDirty,
        isValid,
        handleSubmit,
        create_unit_automated_quiz_form_error,
        create_unit_automated_quiz_form_progress,
        create_unit_automated_quiz_form_success,
        QuizId,
        questionText,
        onSubmit}=Edit_Automated_Quiz_Unit_Logic();
        return (
            <>
                <div className='container Add_traditional_quiz'>
                  <div className='mt-5' />
                <form onSubmit={handleSubmit(onSubmit)} className="row g-3 needs-validation" noValidate>
          <div className="col-md-4">
            <label htmlFor="َQuizeName" className="form-label">اسم الكويز</label>
            <input type="text" className="form-control" id="َQuizeName" {...register("nameOfQuiz", { required: true })  }   />
            {errors.QuizeName && <p className='text-danger'>Invalid input</p>}
          </div>

          <label htmlFor="َQuizeName" className="form-label">النص العام للاختبار المؤتمت</label>
      <ReactQuill
          style={{height: '300px', marginBottom: '120px'}}
          theme={"snow"}
          onChange={(e) => questionText.current = e}
          value={questionText.current}
          modules={Editor.modules}
          formats={Editor.formats}
          bounds={".app"}
          placeholder={'أدخل النص ...'}
        /> 

          <div className="col-md-4">
            <label htmlFor="points" className="form-label">الدرجة</label>
            <input type="number"  {...register("points", { required: true })  }    className="form-control degreeinput" id="degree"   />
            {errors.degree && <p className='text-danger'>Invalid input</p>}
          </div>
        
          <div className="col-md-4">
            <label htmlFor="descriptionQuize" className="form-label">الوصف</label>
            <input type="text" {...register("description", { required: true })  }   className="form-control" id="descriptionQuize"   />
            {errors.descriptionQuize && <p className='text-danger'>Invalid input</p>}
          </div>
        
          <div className="col-md-4">
            <label htmlFor="duration" className="form-label">المدة</label>
            <input type="number"  {...register("duration", { required: true })  }   className="form-control" id="duration"   />
            {errors.duration && <p className='text-danger'>Invalid input</p>}
          </div>
        
          
          
          {create_unit_automated_quiz_form_progress ?  <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar" style={{width:`${create_unit_automated_quiz_form_progress}%`}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                    <label className='text-center'>{create_unit_automated_quiz_form_progress}% </label>
                  </div> : <></>
                   }
                   
        
          <div className="col-12">
            <button className="btn btn-success" type="submit">رفع الاختبار</button>
            {create_unit_automated_quiz_form_success && <div class="alert alert-success text-center" role="alert"/>}
            {create_unit_automated_quiz_form_error&&<label className='text-center w-100 text-danger'>{create_unit_automated_quiz_form_error?.response ?   create_unit_automated_quiz_form_error.response.data.message :  create_unit_automated_quiz_form_error.message  }</label>}
          </div>
          {/* {create_unit_automated_quiz_form_success&&<div class="alert alert-success text-center" role="alert">
        تمت إضافة أسئلة الوحدة بنجاح
        </div>}  */}
        </form>
        </div>
            </>
          )
}

Editor.modules = {
  /*toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ],*/
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ header: [1, 2, 3, false] }],

    ["link", "image", "formula"],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],

    ["clean"] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};