import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './Add_traditional_quiz.css';
import TraditionalQuizForm_Logic from './Add_traditional_quiz_Logic';

export default function Add_traditional_quiz({ isFinal, isAboveLevel }) {
	const {
		handlecorrection_Ladder_file,
		register,
		errors,
		isDirty,
		isValid,
		correction_Ladder_file_Ref,
		correction_Ladder_file_error,
		create_traditional_quiz_questions_progress,
		create_traditional_quiz_questions_error,
		create_traditional_quiz_questions_success,
		onSubmit,
		QuizId,
		handleSubmit,
	} = TraditionalQuizForm_Logic({ isFinal, isAboveLevel });

	return (
		<>
			<div className='container Add_traditional_quiz card_quiz_create'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					إنشاء إختبار جديد
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation my-5'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='َQuizeName'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الإختبار
						</label>
						<input
							type='text'
							className='form-control'
							id='َQuizeName'
							{...register('QuizeName', { required: true })}
						/>
						{errors.QuizeName && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-4'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							النقاط
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control degreeinput'
							id='degree'
						/>
						{errors.degree && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='descriptionQuize'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							الوصف
						</label>
						<input
							type='text'
							{...register('descriptionQuize', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.descriptionQuize && (
							<p className='text-danger'>Invalid input</p>
						)}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة
						</label>
						<input
							type='number'
							{...register('duration', { required: true })}
							className='form-control'
							id='duration'
						/>
						{errors.duration && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='mb-3'>
						<label
							htmlFor='correction_Ladder_file'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							سلم التصحيح
						</label>
						<input
							ref={correction_Ladder_file_Ref}
							title='يرجى اختيار ملف من نوع pdf'
							onChange={handlecorrection_Ladder_file}
							accept='application/pdf'
							type='file'
							class='form-control w-50'
							id='correction_Ladder_file'
							aria-label='file example'
							required
						/>
						{correction_Ladder_file_error ? (
							<div className='text-danger'>{correction_Ladder_file_error}</div>
						) : (
							<></>
						)}
					</div>

					<div className='mb-3'>
						<label
							htmlFor='questions_file'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							ملف الأسئلة
						</label>
						<input
							// ref={correction_Ladder_file_Ref}
							title='يرجى اختيار ملف من نوع pdf'
							// onChange={handlecorrection_Ladder_file}
							{...register('questions_file', {required: true})}
							accept='application/pdf'
							type='file'
							class='form-control w-50'
							id='questions_file'
							aria-label='file example'
							
						/>
						{errors.questions_file ? (
							<div className='text-danger'>يرجى إدخال ملف أسئلة الاختبار</div>
						) : (
							<></>
						)}
					</div>

					{create_traditional_quiz_questions_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{
									width: `${create_traditional_quiz_questions_progress}%`,
								}}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>
								{create_traditional_quiz_questions_progress}%{' '}
							</label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12'>
						<button
							disabled={!isDirty || !isValid || correction_Ladder_file_error}
							className={`btn1 ${(!isDirty || !isValid || correction_Ladder_file_error) && 'btn-dark'}`}
							type='submit'
							style={{
								borderRadius: '10px ',
								minWidth: '150px ',
								padding: '10px 5px',
							}}>
							رفع الاختبار
						</button>

						{create_traditional_quiz_questions_error && (
							<label className='text-center w-100 text-danger'>
								{create_traditional_quiz_questions_error?.response
									? create_traditional_quiz_questions_error.response.data
											.message
									: create_traditional_quiz_questions_error.message}
							</label>
						)}
					</div>
					{create_traditional_quiz_questions_success && (
						<div class='alert alert-success text-center' role='alert'>
							تمت إضافة أسئلة الوحدة بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
