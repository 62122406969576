import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_all_above_level_traditional_quizzes_by_unit_id } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import axios from 'axios';
export default function Show_all_up_level_Quiz_Logic() {
	const { UnitId } = useParams();
	const [loading, setloading] = useState();
	const dispatch = useDispatch();

	const data = useSelector(
		(state) => state.unitTest.get_all_above_level_traditional_quizzes_by_unit_id
	);
	const deleteQuiz = (id) => {
		setloading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_traditional_quiz/${id}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(false));
	};

	useEffect(() => {
		dispatch(get_all_above_level_traditional_quizzes_by_unit_id({ UnitId }));
	}, []);
	console.log(data);
	return { dispatch, data, UnitId, deleteQuiz, loading };
}
