import React from 'react';
import SectionCard from './SectionCard';

function SecionCardList({ sections , desc }) {
	//******************************************************** */

	if (sections.length == 0) {
		return (
			<div className='text-center'>
				<h3 className='mt-5 mb-5'>لايوجد اقسام</h3>
			</div>
		);
	}
	const renderSections = sections.map((section, index) => {
		return (
				<SectionCard id={section.id} {...section} desc={desc} index={index} />
		);
	});
	//******************************************************** */
	return (
		<div className='container mt-3'>
			<div className='d-flex w-100 flex-wrap justify-content-center gap-5 align-items-center library_section_card'>{renderSections}</div>
		</div>
	);
}

export default SecionCardList;
