import React from 'react';
import './index.css';

import quiz from '../../assets/QuizCard/quiz-test.png';
import { Link } from 'react-router-dom';

function Quiz({
	pointsNum,
	quizName,
	isAutomated = false,
	quizTime,
	abovelevel = false,
	id,
}) {
	return (
		<div className='col-10 col-sm-8 col-md-5 col-lg-4 text-center'>
			<div className={` quizCard row justify-content-center `}>
				<div className='col-12 quizimg_section my-2 mx-auto'>
					<img src={quiz} alt='quiz' width={'50%'} height='100%'></img>
				</div>
				<div className='col-12 quizdetailes_section py-3 '>
					<h5>{quizName}</h5>

					<p>
						مدة الانجاز : <span className=''></span>
						{quizTime}
					</p>
					<p>
						عدد النقاط : <span className=''></span>
						{pointsNum}
					</p>
				</div>
				<div className='col-12 quizstart_section py-3'>
					{abovelevel ? (
						<Link to={`/aboveAutomatedUnit/${id}`} className='Link text-center'>
							<p classname='text-white '>{'البدء بالاختبار'}</p>
						</Link>
					) : (
						<Link
							to={isAutomated ? `/automatedUnit/${id}` : `/examm/${id}`}
							state={{ quizname: quizName }}
							classname='Link text-center'>
							<p className='text-white '>{'البدء بالاختبار'}</p>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
}

export default Quiz;
