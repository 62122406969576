import React from 'react';
import MainHeader from '../../components/MainHeader/MainHeader';
import Layout from '../Layout/Layout';
import login from '../../assets/signinPage/Login-pana.png';
import Button from '../../components/sharedButton';
import './style.css';
import { Link } from 'react-router-dom';
import ButtonM from '../../components/ButtonM/ButtonM';
import SubTitle from '../../components/SubTitle/SubTitle';
function SigninPage({ text }) {
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='  mt-5 text-center  '>
				{/* <h5 className='mb-5  mx-auto py-1  title_login'> {text}</h5> */}
				<SubTitle title={text} />
			</div>
			<div className='row justify-content-center card_signin my-5 mx-auto'>
				<div
					className='col-lg-6 col-md-6 col-sm-10 col-12 text-center d-flex flex-column align-items-center justify-content-center '>
					<div className='mt-5 mx-auto '>
						{text == 'دخول' ? (
							// <Button text={'تسجيل دخول كطالب'} url={'/signin/student'} />
							<Link to={'/signin/student'}><ButtonM text="تسجيل دخول كطالب" /></Link>
							) : (
								// <Button text={'إنشاء حساب طالب'} url={'/signup/student'} />
								<Link to={'/signup/student'}><ButtonM text="إنشاء حساب طالب" /></Link>
							)}
					</div>
					<div className='mt-5'>
						{text == 'دخول' ? (
							// <Button text={'تسجيل دخول كإستاذ'} url={'/signin/teacher'} />
							<Link to={'/signin/teacher'}><ButtonM text="تسجيل دخول كإستاذ" /></Link>
							) : (
								 <Link to={'/signup/teacher'}><ButtonM text="إنشاء حساب إستاذ" /></Link>
							
								)}
								 {/* <Button text={'إنشاء حساب إستاذ'} url={'/signup/teacher'} /> */}
					</div>
				</div>
				<img
					className=' col-lg-5 col-md-5 col-sm-10 col-12 my-5 mx-2'
					src={login}
					alt='login'
				/>
			</div>
		</Layout>
	);
}

export default SigninPage;
