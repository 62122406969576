import React, { useEffect } from 'react';
import Layout from '../../pages/Layout/Layout';
import './AboutUs.css';
import AboutUsLogic from './AboutUsLogic';
import { Fade, Zoom } from 'react-reveal';
import { useSelector } from 'react-redux';
import img1 from '../../assets/AboutUs/Mask Group 2.png';
import image1 from '../../assets/AboutUs/1.png'
import image2 from '../../assets/AboutUs/2.png'
import image3 from '../../assets/AboutUs/3.png'
import image4 from '../../assets/AboutUs/4.png'
import image5 from '../../assets/AboutUs/5.png'
import image6 from '../../assets/AboutUs/6.png'

import {
	getError,
	getLoading,
	getSuccess,
	clearMsg,
} from '../../redux-toolkit/reducers/PostContactUs/PostContactUsSlice';
import ToastSuccessError from '../ToastSuccessError/ToastSucessError';
import SubTitle from '../SubTitle/SubTitle';
import ButtonM from '../ButtonM/ButtonM';

export default function AboutUs() {
	//********************************************************** */
	const loading = useSelector(getLoading);
	const successMsg = useSelector(getSuccess);
	const errorMsg = useSelector(getError);
	//********************************************************** */
	//********************************************************** */
	const {
		aboutUs,
		stateContactUs,
		register,
		onSubmit,
		formState: { errors },
		handleSubmit,
	} = AboutUsLogic();

	return (
		<div>
			<ToastSuccessError
				successMessage={successMsg}
				ErrorMessage={errorMsg}
				clearMsg={clearMsg}
			/>
			{/* backgroun image */}
			{/* <div className='Aboutuss'></div> */}
			{/* vidoe aboutus */}
			<div className='container my-5'>
				<div className='  '>
					<SubTitle title={'فيديو توضيحي عن الأكاديمية'} />
					<Zoom>
						<div className=' my-4 mx-auto container main_video_section  '>
							{/* <iframe src="//www.youtube.com/embed/YE7VzlLtp-4" title="YouTube video" allowFullScreen></iframe> */}
							<iframe
								className='vidio_ifram video_aboutsus_itself'
								frameborder='0'
								marginwidth='0'
								marginheight='0'
								src={`${process.env.REACT_APP_STORAGE_URL}/${aboutUs?.post?.data?.main_video}`}
								title='YouTube video'
								allowFullScreen></iframe>
						</div>
					</Zoom>
				</div>
			</div>
			{/* who we are */}
			<div className='container mt-5'>
				<div className='text-center'>
					{/* <h3 className='text-danger fs-1'>من نحن ؟</h3> */}
					<SubTitle title={'من نحن'} />
					<p
						dangerouslySetInnerHTML={{
							__html: aboutUs?.post?.data?.aboutUs,
						}}
						className='fs-2 mt-5 '></p>
				</div>
			</div>

			{/* our goals */}
			{/* <p>{aboutUs?.post?.data?.the_vision}</p> */}
			{/* <p>{aboutUs?.post?.data?.the_goal}</p> */}

			<div className='container mt-5'>
				<div className='text-center '>
					{/* <h3 className='text-danger fs-2'>اهدافنا</h3> */}
					<SubTitle title={'اهدافنا'} />
					<p
						dangerouslySetInnerHTML={{
							__html: aboutUs?.post?.data?.the_goal,
						}}
						className='fs-2 mt-5'></p>
				</div>
			</div>

			<div className='container mt-5'>
				<div className='text-center '>
					{/* <h3 className='text-danger fs-2'>رؤيتنا</h3> */}
					<SubTitle title='رؤيتنا' />
					<p
						dangerouslySetInnerHTML={{
							__html: aboutUs?.post?.data?.the_vision,
						}}
						className='fs-2 mt-5'></p>
				</div>
			</div>

			<div className='container  mt-5'>
				<div className='text-center'>
					{/* <h3 className='text-danger fs-2'>ماذا نقدم ؟</h3> */}
					<SubTitle title={'ماذا نقدم ؟'} />
				</div>
			</div>

			<div>
				<div className='container mt-5 offers-aboutUs shadow rounded'>
					<Fade left>
						<div className='row justify-content-center'>
							<div className='col-11 col-lg-3 col-md-6 col-sm-8 mt-5 mx-5 TY_card_aboutUs'>
								<div className='card shadow'>
									<img
										src={image1}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>
											لطلاب الصف الأول وصولا للشهادة الثانوية بفرعيها العلمي والأدبي حسب المنهاج السوري المقترح من قبل وزارة التعليم الفاضلة
										</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>

							<div className='col-11 col-lg-3 col-md-6 col-sm-8 mt-5 mx-5 TY_card_aboutUs  '>
								<div className='card shadow'>
									<img
										src={image2}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>

																						حيث نبتكر العديد من الطرق والأساليب التعليمية لايصال المعلومات بسهولة وسلاسة

										</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>

							<div className='col-11 col-lg-3 col-md-6 col-sm-8 mt-5 mx-5 my-3 TY_card_aboutUs'>
								<div className='card shadow'>
									<img
										src={image3}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>
										نوفر اختبارات بنوعيها (تقليدي - أتمتة)  لضمان فهم الكورسات من قبل الطلاب والمتدربين 
										</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>
						</div>
					</Fade>
					<Fade right>
						<div className='row justify-content-center'>
							<div className='col-11 col-lg-3 col-md-6 col-sm-8 mt-5 mx-5 TY_card_aboutUs'>
								<div className='card shadow'>
									<img
										src={image4}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>
										في العديد من المجالات المهنية مثل البرمجة والتصميم واللغات وغيرها من المجالات المهمة

										</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>

							<div className='col-11 col-lg-3 col-md-6 col-sm-8 mt-5 mx-5 TY_card_aboutUs '>
								<div className='card shadow'>
									<img
										src={image5}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>
											حيث سهولة الوصول للكورسات يعتبر من أهم الميزات التي نوفرها في أكاديمة الشرق الأوسط التعليمية
											</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>

							<div className='col-11 col-lg-3 col-md-6 col-sm-8  mt-5 mx-5 TY_card_aboutUs'>
								<div className='card shadow'>
									<img
										src={image6}
										style={{ maxHeight: '320px', minHeight: '300px', objectFit: 'cover' }}
										className='card-img-top'
										alt='...'
									/>
									<div className='card-body text-center'>
										{/* <h5 className='card-title text-danger'>Card title</h5> */}
										<p className='card-text'>
											لطلاب الصف الأول وصولا للشهادة الثانوية بفرعيها العلمي والأدبي حسب المنهاج السوري المقترح من قبل وزارة التعليم الفاضلة

										</p>
										{/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
									</div>
								</div>
							</div>
						</div>
					</Fade>
				</div>
			</div>

			<div className='container mt-5'>
				<form
					className='border p-4 w-85 mx-auto m-4 rounded-x mt-5 shadow'
					onSubmit={handleSubmit(onSubmit)}>
					<h4 className='p-4 text-center'>
						{' '}
						في حال وجود أي مشكلة يرجى التواصل معنا{' '}
					</h4>
					<div className='row mb-3 justify-content-center'>
						{' '}
						<label htmlFor='inputEmail3' className='col-sm-2 col-form-label'>
							الاسم:
						</label>
						<div className='col-sm-5'>
							{' '}
							<input
								type='text'
								className='form-control'
								id='inputEmail3'
								{...register('name', { required: true })}
							/>
							<error>
								{errors.name?.type === 'required' && 'مطلوب ادخال الاسم'}
							</error>
						</div>
					</div>

					<div className='row mb-3 justify-content-center'>
						<label
							htmlFor='alertsomemessage'
							className='col-sm-2 col-form-label'>
							الايميل:
						</label>
						<div className='col-sm-5'>
							{' '}
							<input
								type='email'
								className='form-control'
								id='alertsomemessage'
								{...register('email', {
									pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
								})}
							/>
							<p className='text-muted fs-6'>الايميل (اختياري)</p>
							<error>
								{' '}
								{errors.email?.type === 'pattern' &&
									'Entered email is in wrong format'}
							</error>
						</div>
					</div>

					<div className='row mb-3 justify-content-center'>
						<label htmlFor='inputmessage' className='col-sm-2 col-form-label'>
							الرسالة:
						</label>{' '}
						<div className='col-sm-5'>
							{' '}
							<textarea
								className='form-control'
								id='inputmessage'
								rows='3'
								style={{ resize: 'none' }}
								{...register('text', { required: true })}
							/>{' '}
							<error>
								{' '}
								{errors.text?.type === 'required' && 'مطلوب ادخال التعليق'}
							</error>
						</div>
					</div>
					{loading ? (
						<div class=' w-100 text-center ' role='status'>
							<span class='spinner-grow text-primary'></span>
						</div>
					) : (
						<div className='d-flex justify-content-center align-items-center '>
							{' '}
							<ButtonM text={'إرسال'} type='submit' className=''>
								{' '}
							</ButtonM>
						</div>
					)}
					{/* {stateContactUs?.isSuccess && (           <h3 className="text-center text-success mt-3">
            your comment is succesfful
          </h3>
        )}  */}
				</form>
			</div>
		</div>
	);
}
