import React from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
import LayoutSide1 from '../../pages/LayoutSide/LayoutSide1';
import LayoutSideStudent from '../../pages/LayoutSide/LayoutSideStudent';
import MainHeader from '../MainHeader/MainHeader';
import book from '../../assets/unit/book (2).png';
import clock from '../../assets/icons/clock.png';
import coin from '../../assets/icons/coin.png';

const SubscribtionDetails = () => {
	const location = useLocation();
	console.log('locatio', location);
	const { state } = location;
	console.log('staet', state);
	const info = state.item;
	return (
		<Layout>
			{/* <MainHeader /> */}
			{localStorage.getItem('role') == 3 && (
				<LayoutSide1 desc={'تفاصيل الاشتراك'}>
					<div className='container mt-5'>
						<div className='d-flex flex-column'>
							<div className='d-flex flex-row justify-content-between  align-items-center'>
								<div className='fw-bold d-flex justify-content-center gap-3 align-items-center flex-column '>
									<h3>{info?.subscription_name}</h3>
									<img
										className='me-3 ms-3 rounded-3  subscrbtion_image_details'
										alt='Subscriptions_icon_title'
										style={{
											width: '70%',
											height: '300px',
											margin: '0px auto',
										}}
										src={`${
											process.env.REACT_APP_STORAGE_URL +
											'/' +
											info?.subscription_image
										}`}
									/>
								</div>
								{/* <p className='fw-bold '><span>5000</span><span className='mx-2'>sp</span>   </p> */}
							</div>
							{/* <p className='p-4'>{info?.subscription_description}</p> */}

							<div className='d-flex flex-row justify-content-between  '>
								<p className='fw-bold '></p>
							</div>
						</div>
					</div>
				</LayoutSide1>
			)}
			{localStorage.getItem('role') == 1 && (
				<LayoutSideStudent desc={'تفاصيل الاشتراك'}>
					<div className='container mt-5'>
						<div className='d-flex flex-column'>
							<div className='d-flex flex-row justify-content-between  align-items-center'>
								<div className='fw-bold d-flex justify-content-center gap-3 align-items-center flex-column mx-auto'>
									<h3>{info?.subscription_name}</h3>
									<img
										className='me-3 ms-3 rounded-3  subscrbtion_image_details'
										alt='Subscriptions_icon_title'
										style={{
											width: '70%',
											height: '300px',
											margin: '0px auto',
										}}
										src={`${
											process.env.REACT_APP_STORAGE_URL +
											'/' +
											info?.subscription_image
										}`}
									/>
								</div>
								{/* <p className='fw-bold '><span>5000</span><span className='mx-2'>sp</span>   </p> */}
							</div>
							{/* <p className='p-4'>{info?.subscription_description}</p> */}

							<div className='d-flex flex-row justify-content-between  '>
								<p className='fw-bold '></p>
							</div>
						</div>
					</div>
				</LayoutSideStudent>
			)}
			{localStorage.getItem('role') != 1 && localStorage.getItem('role') != 3 && (
				<div className='container mt-5'>
					<div className='d-flex flex-column'>
						<div className='d-flex flex-row justify-content-between  align-items-center'>
							<div className='fw-bold d-flex justify-content-center gap-3 align-items-center flex-column mx-auto'>
								<h3>{info?.subscription_name}</h3>
								<img
									className='me-3 ms-3 rounded-3 subscrbtion_image_details'
									alt='Subscriptions_icon_title'
									style={{
										width: '70%',
										height: '300px',
										margin: '0px auto',
									}}
									s
									src={`${
										process.env.REACT_APP_STORAGE_URL +
										'/' +
										info?.subscription_image
									}`}
								/>
							</div>
							{/* <p className='fw-bold '><span>5000</span><span className='mx-2'>sp</span>   </p> */}
						</div>
						{/* <p className='p-4'>{info?.subscription_description}</p> */}

						<div className='d-flex flex-row justify-content-between  '>
							<p className='fw-bold '></p>
						</div>
					</div>
				</div>
			)}

			<div
				className='row  my-5 mx-5 justify-content-space-between'
				style={{ justifyContent: 'space-around' }}>
				<div className='col-lg-5 col-md-6 col-sm-10 col-12 my-5'>
					<div className='row '>
						<div className='col-12 col-xs-6 col-sm-6 col-lg-4 my-2 text-right'>
							<img
								className='mx-2'
								src={clock}
								alt='point'
								width={'30px'}></img>
							<span className='mx-2'> 2:00:00 </span>
						</div>
						<div className='col-12 col-xs-6 col-sm-6 col-lg-3 my-2'>
							<img src={book} alt='book' width={'35px'}></img>
							<span className='mx-1'> 9 محاضرات</span>
						</div>
						<div className='col-12  col-sm-12 col-lg-5	 my-2'>
							<img src={coin} alt='coin' width={'35px'}></img>
							<span className='mx-1'>{info?.subscription_price}</span>
							<span className=''> ليرة سورية</span>
						</div>
					</div>
					<div className='mt-5'>{info?.subscription_description}</div>
				</div>
				<div className='col-lg-4 col-md-6 col-sm-10 col-12 teacher_card '>
					<h5 className='fw-bold mb-4'>
						<span style={{ color: '#044364' }}> المحاضر:</span>
					</h5>
					<p>اسم المحاضر</p>
					<p>
						حاصل على إجازة في كلية الهندسة المعلوماتية جامعة دمشق حاصل على إجازة
						في كلية الهندسة المعلوماتية جامعة دمشق
					</p>
					<p
						className='btn btn-danger my-5     '
						style={{ width: '100%', borderRadius: '10px' }}>
						<a
							style={{ fontSize: '1.1rem' }}
							className='text-white mx-auto p-2 '
							href=''>
							للتواصل مع الأستاذ لفتح الكورس
						</a>
					</p>
				</div>
			</div>
			<a
				href='/'
				className='Subscribe_now p-2 my-4 d-block text-dark text-center w-50 mx-auto rounded-x fw-bolder fs-4'
				style={{
					backgroundColor: '#F6B41B',
					textDecoration: 'none',
				}}>
				اشترك الآن
			</a>
		</Layout>
	);
};

export default SubscribtionDetails;
