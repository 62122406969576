import React from 'react';
import SubTitle from '../SubTitle/SubTitle';
import './HowSiteCanHelpYou.css';

export default function HowSiteCanHelpYou() {
	return (
		<>
			{/* start how site can help you   */}
			<div className='how-site-can-help-you container-fluid'>
				<div
					className='row  justify-content-center mx-md-2 px-md-5'
					style={{ padding: '40px 0' }}>
					<div className='col-md-12 col-12  text-center'>
						{/* <h3 className='mt-3 text-danger '>
							كيف تساعدك المنصة في النجاح وتحقيق أحلامك؟
						</h3> */}
						<SubTitle title={'كيف تساعدك المنصة في النجاح وتحقيق أحلامك ؟'} />
						<p className='fs-4 mt-5'>
							عبر أكاديمية الشرق الأوسط يمكنك متابعة الكورسات التعليمية من أي
							مكان في العالم ... تحتوي المنصة على الكثير من الكورسات التعليمية
							في شتى المجالات ويتم شرحها عن طريق مجموعة منتقاة من المدربين
							المحترفين الذين يوصلون المعلومات بطريقة سلسة ومفهومة بالنسبة
							للجميع
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
