import React from 'react';
import { useParams } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
import WinterCourses from '../../components/wintercourses/WinterCourses';
import Layout from '../Layout/Layout';
export default function WinterCoursesPage() {
	const subSectionID = useParams();

	return (
		<Layout>
			<div className='sections'>
				{/* <MainHeader /> */}
				<WinterCourses subSectionId={subSectionID} />
			</div>
		</Layout>
	);
}
