import  { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getrules } from '../../redux-toolkit/reducers/Rules/RulesSlice';

export default function RulesLogic() {
     const dispatch= useDispatch()
  const rules =  useSelector(state =>state.rule);  
  useEffect(()=>{
    dispatch(getrules())
  },[])
return { rules };


   
  
}
