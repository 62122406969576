/* popup for lessons*/

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function PopUp2(props) {
	console.log(props.data);
	const { data } = props;
	return (
		<Modal
			animation
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			scrollable>
			<Modal.Header closeButton>
				<Modal.Title
					className='text1'
					id='contained-modal-title-vcenter'
					style={{ width: '100%' }}>
					تفاصيل الدرس
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='container row'>
					<p className='col-2 mb-3 text1'>اسم الدرس: </p>
					<p className='col-6 mb-3 text2'> {data?.lesson_name}</p>
					<p className='col-2 mb-3 text1'>ترتيب الدرس: </p>
					<p className='col-2 mb-3 text2'>{data?.lesson_order}</p>

					<p className='col-2 mb-3 text1'>وصف الدرس:</p>
					<p className='col-6 mb-3 text2'>{data?.lesson_description}</p>
					<p className='col-2 mb-3 text'>مدة الدرس: </p>
					<p className='col-2 mb-3 text2'> {data?.lesson_duration}</p>
					<p className='col-3 mb-3 text1'>ماذا سوف نتعلم: </p>
					<p className='col-9 mb-3 text2'> {data?.what_we_will_learn}</p>

					<p className='col-4 mb-3 text1'> صورة الدرس</p>
					<div className='col-6'>
						<img
							className='img-fluid'
							src={`${process.env.REACT_APP_STORAGE_URL}/${data?.lesson_image}`}
							alt='Girl in a jacket'
							style={{
								objectFit: 'cover',
								width: '100%',
								height: '200px',
							}}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className='text-end w-100'>
					<Button className='btn1' onClick={props.onHide}>
						خروج
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
