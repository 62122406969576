import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetData } from "../../customhook/getDatawithAuth";
import { useRef } from "react";
import { useUploadForm } from "../../customhook/Uploadfiles";
import axios from "axios";

export default function Edit_Automated_Quiz_Unit_Logic() {


  
  const {
    getValues,
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      nameOfQuiz: "",
      points: 0,
      description: "",
      duration: "",
      unit_id: '',
      automated_quiz_text: '',
      isAboveLevel: '',
      isFinal: '',
      points: '',
    },
  });
  const questionText = useRef(null);
  const { QuizId } = useParams();


  const dispatch = useDispatch();
  console.log('quiz id is for edit',QuizId);

  const { isSuccess:edit_unit_automated_quiz_form_success, 
    uploadForm:edit_unit_automated_quiz_func, 
    progress:edit_unit_automated_quiz_form_progress ,
    IsError:edit_unit_automated_quiz_form_error,
    data:edit_unit_automated_quiz_form_data,
    IsLoading:edit_unit_automated_quiz_form_Loading } = 
  useUploadForm(`/edit_automated_quiz/${QuizId}`);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/get_automated_quiz_by_id/${QuizId}`)
    .then(data => {
      reset(data.data.data)
      if(data.data.data.automated_quiz_text){
        questionText.current = data.data.data.automated_quiz_text;
      }
      // console.log('data ddd',data.data)
    })
  },[])
  

  // edit_automated_quiz

  //  const { data}  GetData();

  //   useEffect(()=>{
  //     dispatch(get_traditional_quiz_by_traditional_quiz_id(QuizId))
  //    },[])

  //    useEffect(()=>{
  //      if(data.data)
  //      {
  //      setValue('QuizeName',data.data.quiz_name);
  //      setValue('points',data.data.quiz_points);
  //      setValue('descriptionQuize',data.data.quiz_description);
  //      setValue('duration',data.data.quiz_duration);
  //      setcorrection_Ladder_file(data.data)
  //      }
  //  },[data])

  const onSubmit = async (data) => {
    // console.log(typeof data.points);
    const edit_unit_automated_quiz_form = new FormData();
    // edit_unit_automated_quiz_form.append("unit_id", data.unit_id);
    edit_unit_automated_quiz_form.append("isFinal", data.isFinal);
    edit_unit_automated_quiz_form.append("duration", data.duration);
    edit_unit_automated_quiz_form.append("isAboveLevel", data.isAboveLevel);
    edit_unit_automated_quiz_form.append("nameOfQuiz", data.nameOfQuiz);
    edit_unit_automated_quiz_form.append("points", data.points);
    edit_unit_automated_quiz_form.append(
      "description",
      data.description
    );

    edit_unit_automated_quiz_form.append("transable", 0);

    if(questionText.current != ''){
      questionText.current && edit_unit_automated_quiz_form.append('automated_quiz_text',questionText.current)
    }

    await edit_unit_automated_quiz_func(edit_unit_automated_quiz_form)

  };

  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    questionText,
    QuizId,
    onSubmit,
  };
}
