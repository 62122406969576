import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import fetchautomatedfinalquiz from '../../redux-toolkit/api/unit_test/fetchautomatedfinalquiz';
import { useParams } from 'react-router-dom';
import { GetData } from '../../customhook/getDatawithAuth';
import axios from 'axios';

export default function ShowallautomatedfinalQuizLogic() {
	const dispatch = useDispatch();
	const { UnitId } = useParams();
	const [loadin, setloadin] = useState();

	const DeleteQuiz = (id) => {
		setloadin(true);
		axios
			.delete(`${process.env.REACT_APP_API_URL}/delete_automated_quiz/${id}`, {
				headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
			})
			.then((res) => {
				setloadin(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => {
				setloadin(false);
			});
	};

	console.log(UnitId);
	const {
		getDatafrom,
		isSuccess,
		progress,
		IsLoading,
		IsError,
		data: final_automated_quiz_by_unit_id,
	} = GetData(`/get_all_final_automated_quiz_by_unit_id?unit_id=${UnitId}`);

	// const {isLoading, isError, data, error,refetch} = useQuery(['finalautomatedquiz',UnitId,page,Auth.token], fetchautomatedfinalquiz,{
	//   enabled: true,
	// });

	useEffect(() => {
		getDatafrom();
	}, []);

	return {
		DeleteQuiz,
		loadin,
		IsError,
		final_automated_quiz_by_unit_id,
		IsLoading,
		dispatch,
		getDatafrom,
	};
}
