import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './index.css';
// Import Swiper styles

import { Pagination } from 'swiper';
// import './styles.css';

// import required modules
import { Navigation, Mousewheel, Keyboard } from 'swiper';
import { Children } from 'react';

export default function SwiperSliders(props) {
	const [numberItems, setnumberItems] = useState(2);
	useEffect(() => {
		if (window.screen.width <= 667) setnumberItems(1);
	});

	return (
		<div className='mx-3'>
			<Swiper
				cssMode={true}
				pagination={true}
				navigation={true}
				mousewheel={true}
				keyboard={true}
				modules={[Navigation, Pagination, Mousewheel, Keyboard]}
				className='mySwiper'
				slidesPerView={numberItems}
				spaceBetween={10}>
				{props.children}
			</Swiper>
		</div>
	);
}
