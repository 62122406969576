import React from 'react';
import { Outlet } from 'react-router-dom';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import './Layout.css';
const Layout = (props) => {
	return (
		<div className='layout'>
			<Header />

			<div className='children'>
				<Outlet />
				{props.children}
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
