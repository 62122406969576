import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllComments } from '../../redux-toolkit/reducers/AllComments/AllCommentsSlice';
import { useNavigate, useParams } from 'react-router-dom';

import { getLoading } from '../../redux-toolkit/reducers/PostCommentLesson/PostCommentLessonSlice';
import axios from 'axios';
import {
	setError,
	setSuccess,
} from '../../redux-toolkit/reducers/notification';

export default function LessonLogic() {
	const unitId = useParams();
	const [arr, setArr] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);
	const [finishExam, setFinishExam] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const comments = useSelector((state) => state.allComments);
	const loading = useSelector(getLoading);
	// console.log("comments Lesson",comments)
	const commentLesson = comments?.post?.data;

	useEffect(() => {
		dispatch(getAllComments(unitId.id));
	}, []);
	const [lessonDetails, setLessonDetails] = useState({});

	const [questionToShow, setQuestionsToShow] = useState(null);
	const [showQuestionPopup, setShowQuestionPopup] = useState(false);
	const [vidQueAnswered, setVidQueAnswered] = useState([]);
	const [showAnswers, setShowAnswers] = useState(false);
	const [showedQuestions, setShowedQuestions] = useState([]);
	const [currentTimeOfVideo, setCurrentTimeOfVideo] = useState(0);
	const [loadingBtn, setloadingBtn] = useState(true);
	const [isVideoLoaded, setIsVideoLoaded] = useState(false);
	const [errmess, seterrmess] = useState('');
	const [sucmess, setsuccmess] = useState('');
	const [errorNextAccess, setErrorNextAccess] = useState(null);
	const [UnitLessones, setUnitLessones] = useState([]);
	const videoRef = useRef();
	const [nextId,setNextId] = useState(undefined);
	const token = localStorage.getItem('token');
	// console.log(token);
	const lessonId = useParams();

	const handleDownloadPdf = () => {};

	const handleOpenPdf = (e) => {};

	const handledownloadMp3 = () => {};

	const handleOpenMp3 = () => {};

	const checkIfQuestionWasShoed = (id) => {
		for (let i = 0; i < showedQuestions.length; i++) {
			if (showedQuestions[i] == id) {
				return true;
			}
		}
		return false;
	};

	// const checkIfThereISAQuestionsInGivenTime = (time) => {
	// 	for (let i = 0; i < videoQuestions.length; i++) {
	// 		// check for the question time and if it was not shoed on the screen
	// 		if (
	// 			videoQuestions[i].question_time == time &&
	// 			!checkIfQuestionWasShoed(videoQuestions[i].id)
	// 		) {
	// 			// console.log('Showed questions',checkIfQuestionWasShoed(videoQuestions[i].id))

	// 			setShowedQuestions((prev) => [...prev, videoQuestions[i].id]);

	// 			setQuestionsToShow(videoQuestions[i]);
	// 			break;
	// 		}
	// 	}
	// };

	const fetchLessonDatails = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_lesson_by_lesson_id/${lessonId.id}&local=ar`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				setLessonDetails(data.data);
				console.log(data.data);
				setTimeout(() => {
					setIsVideoLoaded(true);
				}, 4200);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getNextLesson = async () => {
		const response = await axios
			.post(
				`${process.env.REACT_APP_API_URL}/give_access_to_the_next_lesson_by_lesson_id/${lessonId.id}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			)

			.then((data) => {
				setloadingBtn(true);
				// navigate(-2) &&
				setsuccmess('تم فتح الدرس التالي');
				// dispatch(setSuccess('تم فتح الدرس التالي'))
				// console.log(data.data);
			})
			.catch((error) => {
				setloadingBtn(true);
				seterrmess(error?.response?.data?.message);
				setErrorNextAccess(error?.response?.data?.message);
				// dispatch(setError(error?.response?.data?.message));
				console.log(error);
			});
	};

	const getUnitLessones = async (unit_id) => {
		// setUnitLessones([]);
		// setNextId(undefined)
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/get_all_lessons_by_unit_id?limit=10&unit_id=${unit_id}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
			}
		)
			.then((res) => {
				console.log('ers unit lessons ', res);
				if (res.status == 410) {
				}
				return res.json();
			})
			.then((data) => {
				// console.log('data unitsssssssssssssssss ', data.data)
				setUnitLessones([...data?.data]);

				const lessons_for_id = data?.data;

				const nextLessonId = data.data.map((lesson,index) => {
					if(lesson.lesson_id == lessonId.id && lessons_for_id.length != (index-1) ){
						// console.log('Next id '.repeat(20) , lessons_for_id[index+1].lesson_id)
						setNextId(lessons_for_id[index+1].lesson_id);
						return lessons_for_id[index+1].lesson_id ; 
					}
				})

				// setNextId(nextLessonId);

				// console.log('dada yala yala', data?.data);
				// console.log('dada yala yala', status);
			})
			.catch((err) => {
				// setUnitLessones([]);
				console.log('error inside unit lessons', err);
			});

		// console.log('subject units', response.data);
	};

	// const handleVideoPlay = (e) => {
	// 	// console.log(e);
	// 	// console.log(Math.floor(e.target.currentTime));
	// 	// console.log('on time update event current time ',e.target.currentTime)
	// 	checkIfThereISAQuestionsInGivenTime(Math.floor(e.target.currentTime));
	// 	setCurrentTimeOfVideo(e.target.currentTime);
	// };

	const showQuestionOnTheScreen = () => {
		videoRef.current.pause();
		videoRef.current.removeAttribute('controls');
		setShowQuestionPopup(true);
	};

	const hideQuestionOnTheScreen = (answredQue) => {
		videoRef.current.play();
		videoRef.current.setAttribute('controls', 'controls');
		setShowQuestionPopup(false);

		// setVidQueAnswered([...vidQueAnswered,answredQue])
	};

	const handleConfirmQuestion = () => {};

	const handleOnSeeking = (e) => {
		e.preventDefault();
		// console.log('seeking event',e.target.currentTime);
		// e.target.currentTime = currentTimeOfVideo;
		// e.target.play();
		// console.log('seeked',e)
	};

	useEffect(() => {
		if (questionToShow) {
			// 1- pause the video
			// 2- hide the video controls to prevent user from changing the video time
			// 3- show the model wich is will represent the quesion
			// ShowQuesionOnTheScreen all of these missions

			showQuestionOnTheScreen();
		}
	}, [questionToShow]);

	useEffect(() => {
		fetchLessonDatails();

		// console.log(lessonId)
	}, []);

	return {
		arr,
		finishExam,
		commentLesson,
		lessonDetails,
		showQuestionPopup,
		vidQueAnswered,
		showAnswers,
		currentTimeOfVideo,
		videoRef,
		lessonId,
		handleDownloadPdf,
		handleOpenPdf,
		handledownloadMp3,
		checkIfQuestionWasShoed,
		// checkIfThereISAQuestionsInGivenTime,
		// handleVideoPlay,
		setFinishExam,
		handleOpenMp3,
		// videoQuestions,
		setVidQueAnswered,
		hideQuestionOnTheScreen,
		questionToShow,
		setShowAnswers,
		loading,
		getNextLesson,
		loadingBtn,
		setloadingBtn,
		isVideoLoaded,
		setsuccmess,
		seterrmess,
		errmess,
		sucmess,
		errorNextAccess,
		UnitLessones,
		getUnitLessones,
		nextId
	};
}
