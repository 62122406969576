import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';
import Error from '../../../alerts/Error/Error';
import Add_Automated_Quiz_Quistions_For_Lesson_Logic from './Add_Automated_Quiz_Quistions_For_Lesson_Logic';
import ReactQuill from 'react-quill';
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

export default function Add_Automated_Quiz_Quistions_For_Lesson() {
	const {
		register,
		errors,
		isDirty,
		isValid,
		onSubmit,
		fields,

		watch,
		handleSubmit,
		question,
		firstOption,
		secondOption,
		thirdOption,
		fourthOption,
	} = Add_Automated_Quiz_Quistions_For_Lesson_Logic();

	const [ar, setAr] = useState([1, 2, 3, 4]);
	// useEffect(() => {
	//   // console.lot('state is', state)
	// },[state])

	const data = useSelector(
		(state) => state.unitTest.add_automated_quiz_question_with_its_options
	);
	console.log('data is', data);
	const handleChange = (e) => {
		console.log('editor quill value is => ', e);
	};
	const [editorhtml, SetEditorHtml] = useState('');

	return (
		<>
			<div className='container Add_traditional_quiz'>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation'
					noValidate>
					<div className='col-md-12'>
						<label
							htmlFor='question'
							className='form-label fs-4'
							style={{ color: '#044364' }}>
							السؤال
						</label>

						{/* <h1>test quill editor</h1> */}
						<ReactQuill
							style={{
								height: '300px',
								marginBottom: '120px',
								textAlign: 'right',
							}}
							theme={'snow'}
							onChange={(e) => (question.current = e)}
							value={question.current}
							modules={Editor.modules}
							formats={Editor.formats}
							bounds={'.app'}
							placeholder={'أدخل السؤال ...'}
						/>
					</div>

					<div className='col-12'>
						<label
							htmlFor='point'
							className='form-label fs-4'
							style={{ color: '#044364' }}>
							الإجابات
						</label>
						{fields.map((item, index) => {
							
							return (
								<div className='row mb-g' key={item.id}>
									<div className='col-md-12 col-12 d-flex align-items-center justify-content-center gap-2'>
										<input
											defaultChecked={index === 0}
											type='radio'
											{...register('correct', { required: true })}
											value={index}
											style={{
												width: '25px',
												height: '25px',
											}}
										/>{' '}
										<ReactQuill
											style={{ height: '300px', marginBottom: '120px' }}
											theme={'snow'}
											onChange={(e) => {
												if (index == 3) {
													fourthOption.current = e;
												} else if (index == 0) {
													firstOption.current = e;
												} else if (index == 1) {
													secondOption.current = e;
												} else if ((index = 2)) {
													thirdOption.current = e;
												}
											}}
											// value={fourthOption.current}
											modules={Editor.modules}
											formats={Editor.formats}
											bounds={'.app'}
											placeholder={'أدخل الجواب  ...'}
										/>
									</div>
								</div>
							);
						})}
					</div>
					<div className='col-md-12 text-center'>
						<label
							htmlFor='question_note'
							className='form-label fs-4'
							style={{ color: '#ef0000' }}>
							ملاحظة السؤال
						</label>
						<input
							type='text'
							className='form-control w-50 mx-auto'
							id='question_note'
							{...register('question_note', { required: true })}
						/>
						{errors.question_note && (
							<p className='text-danger'>ملاحظة السؤال مطلوب</p>
						)}
					</div>
					<div className='col-12 text-center'>
						{data.isFetching ? (
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						) : (
							<button
								disabled={!isDirty || !isValid}
								className=' btn1  my-2'
								style={{
									minWidth: '150px',
									borderRadius: '10px',
									padding: '10px 5px',
								}}
								type='submit'>
								إضافة السؤال
							</button>
						)}

						{data.isError && <Error errorText={data.errorMessage} />}
					</div>
					{data.isSuccess && (
						<div class='alert alert-success text-center' role='alert'>
							تمت إضافة السؤال بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}

Editor.modules = {
	toolbar: [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		['blockquote', 'code-block'],

		[{ list: 'ordered' }, { list: 'bullet' }],
		[{ script: 'sub' }, { script: 'super' }], // superscript/subscript
		[{ header: [1, 2, 3, false] }],

		['link', 'image', 'formula'],

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],

		['clean'], // remove formatting button
	],
	clipboard: {
		// toggle to add extra line breaks when pasting HTML:
		matchVisual: false,
	},
};
