import axios from "axios";
import React, { useEffect, useState } from "react";
import { isError } from "react-query";
import { Link, useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
// import Error from "../alerts/Error/Error";
// import StudentsLogic from "./StudentsLogic";
// import SubStudents from "./SubStudents";
// import SubStudents2 from "./SubStudents2";

export default function StudentSubjects({ to, api, Title }) {
//   const { students, IsLoading, IsError } = StudentsLogic({ api, Title });

    const [subjects,setSubjects] = useState([]); 
    const [IsLoading,setIsLoading] = useState(false);
    const [IsError,setIsError] = useState(false);
    const params = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios.get(`${process.env.REACT_APP_API_URL}/get_student_subjects_by_id/${params.id}`,
    {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
    .then(data => {
        console.log('dada',data.data)
        setSubjects(data)
    })
    .catch(error => {
        if(error.response.data.message){
            setIsError(error.response.data.errors[0]);
        }else {
            setIsError('حدث خطأ ما يرجى المحاولة مرة أخرى')
        }
        console.log('error',error);
    })
    .finally(() => {
        setIsLoading(false);


    })


    
    // console.log('params',params)
    // console.log("الاختبارات ")
  },[])


  	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
  return (
    <Layout>
      {/* {IsError && (
        <Error
          errorText={
            IsError?.response?.data?.message
              ? IsError?.response?.data?.message
              : IsError.message
          }
        />
      )} */}

        {
            IsLoading ? 
            <div className='w-100 text-center my-5'>
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        : null
        }

      {(
        <div className="">
          
          {/* <div className=' d-flex flex-wrap justify-content-evenly'>
              
                    {
                        info.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        
        </div> */}
          {/* <div className='  d-flex flex-wrap justify-content-evenly'>
              
                    {
                        info.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        
        </div> */}
          {/* <div className='  d-flex flex-wrap justify-content-around'>
        {
                        info1.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents2  src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        </div> */}
          {/* <div className='  d-flex flex-wrap justify-content-around'>
        {
                        info1.map((e,i)=>{
                            return(
                                <div className=' my-col'>
                                <SubStudents2  src={e.src} names={e.names}/>
                                </div>
                            )
                        })
                    }
    
        </div> */}
          <div className="  d-flex flex-wrap justify-content-around container">
            
          {subjects.data && (
        <table className=" table border  mt-3 teacherTable  text-center">
          <thead>
            <tr>
              <th> اسم المادة </th>
              <th> صورة المادة </th>
              <th>عدد الوحدات في المادة</th>
              <th>عدد الدروس</th>
              <th>الوصف</th>
              <th>النقاط</th>
              <th>السعر</th>
              <td>عدد الوحدات</td>
            </tr>
          </thead>
          <tbody>
            {subjects.data.data?.map((subject) => (
              <tr key={subject.subject_id}>
                <td> {subject.subject_name} </td>
                <td>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img loading="lazy" 
                      className="img-fluid"
                      src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
                      alt="Girl in a jacket"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "12vh",
                      }}
                    />
                  </div>
                </td>
                <td> {subject.number_of_units_in_the_subject} </td>
                <td> {subject.number_of_lessons} </td>
                <td>
                  {subject?.subject_description?.length > 15 && "..."}{" "}
                  {subject?.subject_description?.slice(0, 15)}{" "}
                </td>
                <td> {subject.points} </td>
                <td> {subject.price} </td>
                <td> {subject.number_of_units_in_the_subject} </td>
                <td>
                  {" "}
                  <Link
                    to={`/options/quizes/typequiz/${params.id}`}
                    // onClick={(e) => {
                    //     e.preventDefault(); 
                    //     window.location.replace(`/`)
                    //     window.location.replace(`/options/quizes/typequiz/${params.id}`)
                    // }}
                    state={{subject_Id: subject.subject_id}}
                    className="btn btn-success"
                  >
                    {" "}
                    عرض إختبارات المادة{" "}
                  </Link>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
            {
                IsError && <div className="w-100 text-center my-5">
                    <div class="alert alert-danger shadow mx-auto" style={{maxWidth: '500px'}} role="alert">
                        {IsError}
                    </div>
                </div>
            }
            
            {/* {
              IsLoading ? 
              <div className="w-100 text-center my-5">

            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
              </div>
              : 
              null 
            } */}
            {/* {students?.data?.map((student) => {
              return (
                <Link
                  to={`${to}/${student.student_id}`}
                  key={student.student_id}
                  className=" my-col"
                >
                  <SubStudents2
                    src={
                      student.user_image
                        ? student.user_image
                        : student.student_image
                    }
                    name={student?.name ? student.name : student.student_name}
                  />
                </Link>
              );
            })} */}
          </div>

          {/* {!IsError && !students?.data?.length && (
            <Error errorText="لم يقم الطلاب بتقديم اختبارات تقليدية أو مؤتمتة بعد" />
          )} */}
        </div>
      )}
    </Layout>
  );
}
