import React, { useEffect } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import Fade from 'react-reveal';
import './wintercourses.css';
import WinterCoursesLogic from './WinterCoursesLogic';

export default function WinterCourses() {
	const subSectionId = useParams();
	const { page, handlePageClick, isLoading, isError, data, error } =
		WinterCoursesLogic(subSectionId);

	// console.log('sub section id from winter courses' , subSectionId)
	useEffect(() => {
		console.log('dodo', data);
	}, [isLoading]);

	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);

	const location = useLocation();
	console.log('location', location);
	const state = location?.state;
	console.log('state', state?.item);

	console.log('dataaaaa', data?.data);

	return (
		<div className='winter_courses'>
			<div
				className='container-fluid p-2  position-relative  '
				//  style={{marginBottom:'100px',backgroundImage: `url("../../assets/wintercourses/background_winter_courses.png")`,backgroundRepeat:'no-repeat' ,backgroundSize:'cover',backgroundPosition:'center',backgroundImage:'linear-gradient(to bottom , #2CABA8 , #1d7472)' }}
			>
				<h2 className='fw-bold my-5 text-center '>
					<span style={{ color: '#044364' }}>
						كورسات {state?.item?.section_name}
					</span>
				</h2>
				{/* <img   
                     src={wintercourses}
                     alt='wintercourses' 
                     className='position-absolute'
                     style={{
                             left:'8%',
                             top:'51px',
                             maxWidth:'10%'
                     }}
                     />   */}
			</div>

			<div className='  container p-2 text-center'>
				<div className='row  sections justify-content-around  align-content-center flex-column flex-md-row   g-4 flex-wrap '>
					{isLoading ? (
						<div className='d-flex justify-content-center align-items-center'>
							<div
								className='spinner-border text-secondary text-center'
								role='status'>
								<span className='visually-hidden'></span>
							</div>
						</div>
					) : (
						<>
							{data?.data &&
								data?.data.length > 0 &&
								data?.data?.map((subject, index) => {
									return (
										<div className='   my-3 col-md-6 col-lg-3 col-sm-10  col-xs-12 col-12  '>
											<Fade>
												<Link
													key={index}
													className='card mx-1  section_hover    '
													to={`/subjectt/${subject.subject_id}`}
													style={{ overflow: 'hidden', height: '430px' }}>
													<img
														className=' terms mb-1'
														// variant="top"
														width={'100%'}
														height='200px'
														style={{ objectFit: 'cover' }}
														src={
															process.env.REACT_APP_STORAGE_URL +
															subject.subject_image
														}
														// src={img}
													/>
													<div
														className=' text-center  section_desc d-flex flex-column align-items-center justify-content-center  section_desc_itself'
														style={{ textAlign: 'right', flex: 1 }}>
														<h4
															style={{ color: 'black' }}
															class='  section_title_itself fs-5 rounded-x fw-bold '>
															{subject.subject_name}
														</h4>
														<p className='text-dark  section_desc_itself my-1'>
															{subject?.subject_description?.slice(0, 29)}
															...
														</p>
														
														<div className='text-dark section_desc_itself d-flex justify-content-around w-100  mt-2 '>
															<strong>
																<p className="text-decoration-line-through text-danger" style={{width: 'fit-content !important'}} >100000</p>
																</strong>
															<strong>
																{subject?.price}
																</strong>
																
														</div>
													</div>
												</Link>
											</Fade>
										</div>
									);
								})}
						</>
					)}
				</div>
			</div>

			{/* {!data?.data ? null : (data?.data?.total <= 8  ? null : <Pagination data={data} handlePageClick={handlePageClick} page={page} /> ) } */}
		</div>
	);
}
