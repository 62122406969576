import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
	delete_automated_quiz_by_automated_quiz,
	delete_traditional_quiz_by_traditional_quiz_id,
	get_all_above_level_automated_quiz_by_unit_id,
} from '../../redux-toolkit/reducers/Unit_test/UnitSlice';
import Error from '../alerts/Error/Error';
import DeleteModal from '../DeleteModal';
import ShowallautomateduplevelQuizLogic from './ShowallautomateduplevelQuizLogic';

export default function ShowallautomateduplevelQuiz() {
	const [selectedAutomatedQuizeId, setSelectedAutomatedQuizIe] = useState();
	const { data, dispatch, UnitId, deleteQuiz, loading } =
		ShowallautomateduplevelQuizLogic();

	console.log(data);

	return (
		<div className='container my-5'>
			{data.isFetching && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}
			{data.isError && <Error errorText={data.errorMessage} />}
			{data?.data?.length > 0 && (
				<div class='table-responsive'>
					<table className=' w-100 table_style text-center  table-bordered table-striped'>
						<thead>
							<tr>
								<th className='first_child'> اسم الاختبار </th>
								<th> مدة الاختبار </th>
								<th> نقاط الاختبار </th>
								<th></th>
								<th></th>
								<th></th>
								<th className='last_child'></th>
							</tr>
						</thead>
						<tbody style={{ lineHeight: '60px' }}>
							{data.data?.map((Quiz) => (
								<tr key={Quiz.quiz_id}>
									<td> {Quiz.quiz_name} </td>
									<td> {Quiz.quiz_duration} </td>
									<td> {Quiz.quiz_points} </td>
									<td>
										{' '}
										<Link to={`edit/${Quiz.id}`} className='btn btn1'>
											{' '}
											تعديل{' '}
										</Link>{' '}
									</td>
									<td>
										<button
											type='button'
											class='btn btn2'
											data-bs-toggle='modal'
											data-bs-target='#exampleModal'
											onClick={() => setSelectedAutomatedQuizIe(Quiz.id)}>
											حذف
										</button>
									</td>
									<td>
										{' '}
										<Link
											to={`Add_automated_quiz_questions/${Quiz.id}`}
											className='btn btn3'>
											{' '}
											أضف أسئلة{' '}
										</Link>{' '}
									</td>
									<td>
										{' '}
										<Link
											to={`show_automated_quiz_questions_options/${Quiz.id}`}
											className='btn btn1'>
											{' '}
											عرض الأسئلة{' '}
										</Link>{' '}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
			<Link className='btn btn1 mx-auto mt-5' to='create_unit_automated_quiz'>
				أضف اختبار جديد
			</Link>
			<DeleteModal
				messagConfirm={'	هل تريد حذف هذا الاختبار ؟'}
				handleDelete={() => deleteQuiz(selectedAutomatedQuizeId)}
				isDeleting={loading}
			/>
		</div>
	);
}
