import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from '../Layout/Layout';
import SideBar from '../SideBar/SideBar';
import './SubjectsReports.css';

const SubjectsReports = () => {
    const params = useParams();
    const [subjects,setSubjects] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const [error,setError] = useState(undefined);

    useEffect(() => {
        
        axios.get(`${process.env.REACT_APP_API_URL}/get_all_subject_reports_by_student_id?student_id=${params.studentID}&limit=10`,
        {headers: {"Authorization": `Bearer ${localStorage.getItem('token')}`}})
        .then(data => {
            console.log('data',data.data.data.data);
            setSubjects(data.data.data.data);
            
        })
        .catch(error => {
            console.log('error',error);
            if(error.response.data.message){
                setError(error.response.data.message);
            }else {
                setError('لم يتم تسجيل اي تقارير حتى الان')
            }
        })
        .finally(() => {
            setIsLoading(false)
        })

    },[]) 
    return <>
        <Layout>
        <div className='sideBar__relative'>
            <SideBar />
        </div>

        {
            isLoading ? 
            <div className="w-100 p-5 text-center">
            <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
            </div> : 
            null
        }

        <div className="container">
            <div className="responsive_table">
        {
            error ? <h1 className="w-100 text-center my-5">{error}</h1> :
            <table className=" table border  mt-3 teacherTable  text-center" style={{width: 'fit-content'}}>
          <thead>
            <tr>
              <th> المادة </th>
            </tr>
          </thead>
          <tbody>
            {subjects?.map((subject) => (
              <tr key={subject.subject_id}>
                <td>  
                <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img loading="lazy" 
                      className="img-fluid"
                      src={`${process.env.REACT_APP_STORAGE_URL}/${subject.subject_image}`}
                      alt="Girl in a jacket"
                      style={{
                        objectFit: "cover",
                        width: "200px",
                        height: "200px",
                        borderRadius: '25px',
                        boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px'
                      }}
                    />
                    <h2>{subject.subject_name}</h2>

                  </div>
                </td>
     
                <td>
                  {" "}
                  <Link
                    to={`/unitsReports/${subject.subject_id}`}
                    state={{student_id: params.studentID}}
                    className="btn btn-success"
                  >
                    {" "}
                    عرض التفاصيل{" "}
                  </Link>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        }
        
        </div>
        </div>
        </Layout>
    </>
}

export default SubjectsReports;