import axios from "axios";
import React, { useState, useEffect } from "react";
import Task from "./Task";
import SeeMoreModal from "./SeeMoreModal";
import EditModal from "./EditModal";
import AddTaskModal from "./AddTaskModal";
import DeleteModal from "./DeleteModal";
// import './index.css';

const OurTable = () => {
  const [Refresh, setRefresh] = useState(false);
  const [TableData, setTableData] = useState([]);
  const [SeeMoreModalData, setSeeMoreModalData] = useState({ tasks: [] });
  const [EditModalElement, setEditModalElement] = useState({
    task: "",
    start_time: "",
    end_time: "",
  });
  const [Days, setDays] = useState([]);
  const [DeleteTaskId, setDeleteTaskId] = useState("");
  const [Loading, setLoading] = useState(false);
  //****************************************************************/
  const getTableData = async () => {
    setLoading(true);
    const data = await axios.get(
      `${process.env.REACT_APP_API_URL}/get_all_days_with_tasks`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    setLoading(false);
    setTableData(data.data.data);
  };

  const tasksStyle = (element, index) => {
    return index < 4 ? (
      <Task
        element={element}
        setEditModalElement={setEditModalElement}
        setDeleteTaskId={setDeleteTaskId}
      />
    ) : (
      ""
    );
  };

  const extractTasksFromDays = (tasks) => {
    return tasks.map(tasksStyle);
  };

  const getDays = (tasks) => {
    return tasks.map((task) => {
      return (
        <>
          <tr id={task.day_id}>
            <td className="h4 ">{task.day_name}</td>
            {extractTasksFromDays(task.tasks)}

            {task.tasks.length > 3 ? (
              <td>
                <div className="text-center">
                  <button
                    className="btn btn-success"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={setSeeMoreModalData.bind(null, task)}
                  >
                    {" "}
                    عرض المزيد..
                  </button>
                </div>
              </td>
            ) : (
              ""
            )}
          </tr>
        </>
      );
    });
  };

  const getAllDaysApi = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/get_all_days`
    );
    setDays(res.data.data);
  };
  //****************************************************************/
  useEffect(() => {
    getTableData();
    getAllDaysApi();
  }, [Refresh]);

  //****************************************************************/

  //check if data table is arrived
  if (Loading && TableData.length === 0)
    return (
      <div className=" text-center mt-5" >
        <span className="spinner-border text-primary" role="status"></span>
      </div>
    );
  //show table with data
  return (
    <>
      <div className="table-responsive">
        <table className="table" style={{width: 'fit-content', margin: '20px auto'}}>
          <tbody>{getDays(TableData)}</tbody>
        </table>
      </div>
      <div className="text-center">
        <button
          className="w-75 btn btn-success lead mt-2"
          data-bs-toggle="modal"
          data-bs-target="#AddTask"
        >
          إضافة مهمة جديدة
        </button>
      </div>
      <SeeMoreModal records={SeeMoreModalData} />
      <EditModal
        setRefresh={setRefresh}
        element={EditModalElement}
        refresh={Refresh}
      />
      <AddTaskModal days={Days} refresh={Refresh} setRefresh={setRefresh} />
      <DeleteModal
        taskId={DeleteTaskId}
        refresh={Refresh}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default OurTable;
