import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../../pages/Layout/Layout';
import LayoutSideStudent from '../../../../pages/LayoutSide/LayoutSideStudent';
import MainHeader from '../../../MainHeader/MainHeader';
import LessonQuizesLogic from './LessonQuizesLogic';

export default function LessonQuizes({ api, isUnit = false }) {
	const [api_url, setApiURl] = useState(api);

	useEffect(() => {
		if (isUnit) {
			console.log('gg'.repeat(50));
			setApiURl('get_unit_automated_quizzes_by_student_id');
		}
	}, []);

	const { isSuccess, progress, IsLoading, IsError, data } = LessonQuizesLogic({
		api_url,
	});

	console.log('erreor rrrrrrrrrrr', IsError?.response?.data?.message);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={'الاختبارات المؤتمتة '}>
				<div className='text-center'>
					<h3 className='fw-bold  mt-5 container ' style={{ color: '#044364' }}>
						الإختبارات
					</h3>
				</div>

				<div className='container-fluid '>
					<div
						className=' quiz_card_sub text-center   mx-auto my-5 '
						style={{ width: '25%' }}>
						<h5 className='text-center text-white  fs-4 fw-bold'>مؤتمت</h5>
					</div>
					{IsLoading && (
						<div className='d-flex justify-content-center'>
							<div className='spinner-grow text-dark' role='status'>
								<span className='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}
					{data?.data && (
						<div className='container '>
							<div
								className='row justify-content-center point_card py-5 my-5'
								style={{ fontSize: '1.2rem' }}>
								<span className='col-3 text-black text-center my-3 '>
									اسم الاختبار
								</span>
								<span className='col-3 text-black text-center my-3 '>
									{window.location.pathname == '/myquizes/units/automatedquiz'
										? 'اسم الوحدة'
										: ' وصف الاختبار'}
								</span>

								<span
									className=' col-2 text-center my-3'
									style={{ color: '#044364' }}>
									النتيجة
								</span>

								<span
									className=' col-4 text-center my-3'
									style={{ color: '#3B3B3B', opacity: '0.5' }}>
									تاريخ التقديم
								</span>
								{data?.data?.map((quiz, index) => (
									<>
										<Link
											state={{ quizDetailes: quiz }}
											className='col-3 text-black text-center my-3 '
											key={quiz.id}
											to={`automatedquiz/${quiz.id}`}>
											<span className=' '> {quiz.quiz_name} </span>
										</Link>
										<span
											className=' col-3 text-center my-3'
											style={{ color: '#044364' }}>
											{window.location.pathname ==
											'/myquizes/units/automatedquiz'
												? 'اسم الوحدة'
												: quiz?.description.slice(0, 30) + ' .....'}
										</span>
										<span
											className=' col-2 text-center my-3'
											style={{ color: '#044364' }}>
											{' '}
											{quiz.quiz_mark}%{' '}
										</span>

										<span
											className=' col-4 text-center my-3'
											style={{ color: '#3B3B3B', opacity: '0.5' }}>
											{' '}
											{quiz.quiz_date}{' '}
										</span>
									</>
								))}
							</div>
						</div>
					)}{' '}
					{IsError && (
						<div
							className='row justify-content-space-between point_card py-5 my-5 container mx-auto'
							style={{ fontSize: '1.2rem' }}>
							<label className='text-danger'>
								{IsError?.response?.data?.message
									? IsError.response.data.message
									: IsError.message}
							</label>
						</div>
					)}
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}
