import React, { useEffect } from 'react';

import Layout from '../Layout/Layout';

import Zoom from 'react-reveal/Zoom';
import Brain from '../../assets/profileStudent/Group 344.png';
import InstagramImage from '../../assets/profileStudent/save (1).png';
import Token from '../../assets/profileStudent/Group 345.png';

import { Link, useNavigate } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';

import User from '../../assets/profileStudent/Group 347.png';
import balance from '../../assets/balance.svg';

const ProfileTeacher = () => {
	const navigate = useNavigate();
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='container profile_student'>
				<div className='row justify-content-center'>
					<div className='col-lg-4 col-md-6 col-sm-8 col-xs-8 col-9'>
						<div
							className=' section_card hover_effect_dash_teacher  my-5 '
							style={{ cursor: 'pointer' }}>
							<div className=' dashbord_card  justify-content-center '>
								<a
									to='options'
									onClick={() => navigate('/options')}
									className='text-center'>
									<img
										src={User}
										style={{
											width: '50%',
											height: '120px',
											margin: '0px auto',
										}}
										className='card-img-top'
										alt='...'
									/>{' '}
									<h5
										style={{ color: '#EF0000' }}
										className='card-title  fw-bold mt-5'>
										الملف الشخصي
									</h5>
								</a>
							</div>
						</div>

						<div
							className='  section_card hover_effect_dash_teacher   my-5 '
							style={{ cursor: 'pointer' }}>
							<div className=' dashbord_card      justify-content-center '>
								<a
									to='quizes'
									onClick={() => navigate('/options/quizes')}
									className='text-center'>
									<img
										src={Brain}
										style={{
											width: '50%',
											height: '120px',
											margin: ' auto',
										}}
										className='card-img-top'
										alt='...'
									/>

									<h5
										style={{ color: '#EF0000' }}
										className='card-title  fw-bold mt-5'>
										الاختبارات
									</h5>
								</a>
							</div>
						</div>
					</div>
					{/* <div
						className='col-lg-4 col-md-4 col-sm-8 col-xs-8 col-9'
						style={{
							alignContent: 'center',
							alignItems: 'center',
							marginTop: '20%',
						}}></div> */}
					<div className='col-lg-4 col-md-6 col-sm-8 col-xs-10 col-9'>
						{/* <Zoom bottom>
							<div className=' section_card  my-5 '>
								<div className=' dashbord_card row     justify-content-center '>
									<a
										onClick={() => navigate('/options/visas')}
										className='text-center'>
										<img
											src={Elearning}
											style={{
												width: '50%',
												height: 'auto',
												margin: '0px auto',
											}}
											className='card-img-top'
											alt='...'
										/>

										<h5
											style={{ color: '#EF0000' }}
											className='card-title  fw-bold mt-5'>
											تأشيرات الخروج
										</h5>
									</a>
								</div>
							</div>
						</Zoom>{' '} */}

						<div
							className=' section_card hover_effect_dash_teacher my-5 '
							style={{ cursor: 'pointer' }}>
							<div className=' dashbord_card      justify-content-center'>
								<a
									onClick={() => navigate('/options/create_quiz')}
									className='text-center'>
									<img
										src={Token}
										style={{
											width: '50%',
											height: '120px',
											margin: '0px auto',
										}}
										className='card-img-top'
										alt='...'
									/>
									<h5
										style={{ color: '#EF0000' }}
										className='card-title  fw-bold mt-5'>
										إنشاء اختبار
									</h5>{' '}
								</a>
							</div>
						</div>

						<div
							className=' section_card hover_effect_dash_teacher my-5'
							style={{ cursor: 'pointer' }}>
							<div className=' dashbord_card      justify-content-center '>
								<a
									onClick={() => navigate('/options/mysubjects')}
									className='text-center'>
									<img
										src={InstagramImage}
										style={{
											width: '50%',
											height: '120px',
											margin: '0px auto',
										}}
										className='card-img-top'
										alt='...'
									/>
									<h5
										style={{ color: '#EF0000' }}
										className='card-title  fw-bold mt-5'>
										مادتي
									</h5>
								</a>
							</div>
						</div>


						<div
							className=' section_card hover_effect_dash_teacher my-5'
							style={{ cursor: 'pointer' }}>
							<div className=' dashbord_card      justify-content-center '>
								<a
									onClick={() => navigate('/options/balance')}
									className='text-center'>
									<img
										src={balance}
										style={{
											width: '50%',
											height: '120px',
											margin: '0px auto',
										}}
										className='card-img-top'
										alt='...'
									/>
									<h5
										style={{ color: '#EF0000' }}
										className='card-title  fw-bold mt-5'>
										الأرباح
									</h5>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ProfileTeacher;
