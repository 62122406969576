import React, { useEffect } from 'react';
import Layout from '../../pages/Layout/Layout';
import Rules from '../../components/rules/Rules';
export default function TermsandConditionsPage() {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<Rules />
		</Layout>
	);
}
