import React, { useEffect } from 'react';
import MainHeader from '../../components/MainHeader/MainHeader';
import ScientificBaccalaureate from '../../components/ScientificBaccalaureate/ScientificBaccalaureate';
import Layout from '../Layout/Layout';

export default function ScientificBaccalaureatePage() {
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	return (
		<Layout>
			<div className='sections'>
				{/* <MainHeader /> */}
				<ScientificBaccalaureate />
			</div>
		</Layout>
	);
}
