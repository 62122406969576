import axios from "axios";

  export default async function  fetchcommentsbyunitid ({queryKey})
 {
     


 
 const data = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_comments_of_unit_by_unit_id/${queryKey[1]}?limit=${queryKey[2]}&page=${queryKey[3]}` ,
 {
  headers: {
    'Authorization': `Bearer ${queryKey[4]}` 
  } 
});


console.log(data);

   return  data;
 } 