import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SubjectCards from '../../components/subjectCards';
import Layout from '../Layout/Layout';
import LayoutSideStudent from '../LayoutSide/LayoutSideStudent';
import MainHeader from '../../components/MainHeader/MainHeader';
import Bounce from 'react-reveal/Bounce';
function StudentSubject() {
	// *****************************************************
	const [subjects, setsubjects] = useState([]);
	const token = localStorage.getItem('token');
	const [loading, setloading] = useState(true);
	const url = window.location.search;
	const urlParams = new URLSearchParams(url);
	const subject_id = urlParams.get('id');

	useEffect(() => {
		setloading(true);
		axios
			.get(`${process.env.REACT_APP_API_URL}/get_student_subjects`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					limit: 10,
				},
			})
			.then((response) => {
				setloading(false);
				console.log(response.data.data);
				setsubjects(response.data.data);
			})
			.catch((error) => {
				setloading(false);
				console.log(error);
			});
	}, []);

	// *****************************************************

	return (
		<Layout>
			{/* <MainHeader /> */}

			<LayoutSideStudent desc={' المواد'}>
				<div className='container text-center my-5'>
					<h3 style={{ color: '#044364' }} className='fw-bold my-3'>
						الكورسات المشترك بها
					</h3>
					{loading ? (
						<div className='text-center mt-5'>
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						</div>
					) : (
						<div
							className='row  position-relative mt-5'
							style={{ justifyContent: 'space-around' }}>
							{subjects.length > 0 ? (
								subjects.map((subject) => <SubjectCards subject={subject} />)
							) : (
								<div className='w-100 text-center '>
									<h3 className='mt-5'>{'لا يوجد  لديك كورسات'}</h3>
								</div>
							)}
						</div>
					)}
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}

export default StudentSubject;
