import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPosts } from "../../redux-toolkit/reducers/AboutUs/AboutsUsSlice";
import { postContactUs } from "../../redux-toolkit/reducers/PostContactUs/PostContactUsSlice";
import { useForm } from "react-hook-form";

const AboutUsLogic = () => {
  //************************************************************************************** */
  const dispatch = useDispatch();
  const aboutUs = useSelector((state) => state.aboutUs);
  //************************************************************************************** */
  //************************************************************************************** */
  useEffect(() => {
    dispatch(getPosts());
  }, []);

  const stateContactUs = useSelector((state) => state.postContact);
  console.log("stateContactUs ", stateContactUs);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    console.log("yesssssssssssssssssssssss", data);

    const Data = {
      name: data?.name,
      email: data?.email,
      message: data?.text,
    };
    //  disptach(signupUser(Data));
    dispatch(postContactUs(Data));
  };

  return {
    aboutUs,
    stateContactUs,
    onSubmit,
    register,
    formState: { errors },
    handleSubmit,
  };
};

export default AboutUsLogic;
