import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { GetData } from "../../customhook/getDatawithAuth";
export default function Edit_Automated_Quiz_Unit_Logic({
  isFinal,
  isAboveLevel,
}) {
  const {
    getValues,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      QuizeName: "",
      points: 0,
      descriptionQuize: "",
      duration: "",
    },
  });

  const { QuizId } = useParams();
  const dispatch = useDispatch();
  console.log('quiz id is for edit',QuizId);
  //  const { data}  GetData();

  //   useEffect(()=>{
  //     dispatch(get_traditional_quiz_by_traditional_quiz_id(QuizId))
  //    },[])

  //    useEffect(()=>{
  //      if(data.data)
  //      {
  //      setValue('QuizeName',data.data.quiz_name);
  //      setValue('points',data.data.quiz_points);
  //      setValue('descriptionQuize',data.data.quiz_description);
  //      setValue('duration',data.data.quiz_duration);
  //      setcorrection_Ladder_file(data.data)
  //      }
  //  },[data])

  const onSubmit = async (data) => {
    console.log(typeof data.points);
    const create_unit_automated_quiz_form = new FormData();
    create_unit_automated_quiz_form.append("unit_id", 1);
    create_unit_automated_quiz_form.append("isFinal", isFinal);
    create_unit_automated_quiz_form.append("duration", data.duration);
    create_unit_automated_quiz_form.append("isAboveLevel", isAboveLevel);
    create_unit_automated_quiz_form.append("nameOfQuiz", data.QuizeName);
    create_unit_automated_quiz_form.append("points", data.points);
    create_unit_automated_quiz_form.append(
      "description",
      data.descriptionQuize
    );
    create_unit_automated_quiz_form.append("transable", 0);
  };

  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,

    QuizId,
    onSubmit,
  };
}
