import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import './Show_Asnwear_Unit_Tranditiosnal.css';
import Show_Asnwear_Unit_Tranditiosnal_Logic from './Show_Asnwear_Unit_Tranditiosnal_Logic';
import Button from '../../../button';
import clock from '../../../../assets/icons/clock.png';
import point from '../../../../assets/icons/point.png';
export default function Show_Asnwear_Unit_Tranditiosnal({ corrected }) {
	const {
		isSuccess,
		IsLoading,
		IsError,
		data,
		Get_Qustions_Quiz,
		qustions_Quiz_IsLoading,
		qustions_Quiz_IsError,
		qustions_Quiz_data,
		updatefaqs,
		register,
		onSubmit,
		errors,
		isDirty,
		isValid,
		handleSubmit,
		post_correction_success,
		post_correction_IsLoading,
		post_correction_IsError,
	} = Show_Asnwear_Unit_Tranditiosnal_Logic();
	const { state } = useLocation();
	const quizDetailes = state.quizDetailes;
	const params = useParams();
	const [loading, setloading] = useState(true);
	useEffect(() => {
		// console.log('yes '.repeat(100));
		// console.log( 'question quiz data' , data);
		// console.log('noooooooooooo'.repeat(50));
		// console.log('params pa pa pa ', params);
		console.log('state is state is ', state);

		axios
			.get(
				`${process.env.REACT_APP_API_URL}/get_corrected_unit_traditional_quizzes_by_student_id?student_id=${params.QuizId}`,
				{
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				}
			)
			.then((data) => {
				setloading(false);
				console.log('q q q q q ', data);
			})
			.catch((erro) => {
				setloading(false);
			});

		// setInterval(() => {
		//     console.log('data dddddd',qustions_Quiz_data);
		// }, 800);
	}, []);

	return (
		<div className='container Show_Asnwear_Unit_Tranditiosnal text-center my-5'>
			<h1 className='fw-bold mb-4'>
				<span style={{ color: '#044364' }}>{quizDetailes?.quiz_name}</span>
			</h1>
			<div className='row justify-content-center'>
				<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
					<img src={clock} alt='book' width={'30px'}></img>

					<span className='mx-2 fs-3'>
						{' '}
						مدة الاختبار :
						{'د' + quizDetailes?.quiz_duration &&
						quizDetailes?.quiz_duration > 60
							? Math.floor(quizDetailes?.quiz_duration / 60)
							: quizDetailes?.quiz_duration}
						دقيقة
					</span>
				</div>
				<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
					<img className='mx-2' src={point} alt='point' width={'30px'}></img>

					<span className='mx-2 fs-3'>
						عدد النقاط: {quizDetailes?.quiz_points && quizDetailes?.quiz_points}{' '}
						نقطة{' '}
					</span>
				</div>
			</div>

			<div className='examScreen__explanatino'>
				<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
					<li>{quizDetailes && quizDetailes.quiz_description}</li>
				</ul>
			</div>
			<div className='Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center'>
				<h3 className=' mx-auto' style={{ color: '#044364' }}>
					أجوبة الطالب
				</h3>
				{loading ? (
					<div class='spinner-border' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				) : (
					<div className='container p-2 my-2'>
						<div
							id='carouselExampleIndicators'
							className=' Show_Asnwear_Unit_Tranditiosnal_carousel carousel  slide w-75 mx-auto my-4  position-relative    '
							data-bs-ride='carousel'>
							<div className='carousel-indicators'>
								{data?.data?.map((source, index) => {
									return (
										<button
											key={uuid()}
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide-to={index}
											className={` ${index === 0 ? 'active' : ''} `}
											aria-current='true'
											aria-label={`Slide ${index + 1}`}></button>
									);
								})}
							</div>
							<div className='carousel-inner '>
								{data?.data?.map((source, index) => {
									return (
										<div
											className={`carousel-item  ${
												index === 0 ? 'active' : ''
											} `}>
											<a
												rel='noreferrer'
												target='_blank'
												href={`${process.env.REACT_APP_STORAGE_URL}${source}`}>
												<img
													src={` ${process.env.REACT_APP_STORAGE_URL}${source}`}
													className='d-block w-100'
													alt='...'
												/>
											</a>
										</div>
									);
								})}
							</div>
							<button
								className='carousel-control-prev'
								type='button'
								data-bs-target='#carouselExampleIndicators'
								data-bs-slide='prev'>
								<span
									className='carousel-control-prev-icon'
									aria-hidden='true'></span>
								<span className='visually-hidden'>Previous</span>
							</button>
							<button
								className='carousel-control-next'
								type='button'
								data-bs-target='#carouselExampleIndicators'
								data-bs-slide='next'>
								<span
									className='carousel-control-next-icon'
									aria-hidden='true'></span>
								<span className='visually-hidden'>Next</span>
							</button>
						</div>
					</div>
				)}
			</div>

			<div className='Show_Asnwear_Unit_Tranditiosnal position-relative    p-4 mb-4 text-center'>
				<h3 className=' mx-auto' style={{ color: '#044364' }}>
					أسئلة الاختبار
				</h3>
				{loading ? (
					<div class='spinner-border' role='status'>
						<span class='sr-only'>Loading...</span>
					</div>
				) : (
					<div className='container p-2 my-2'>
						<div
							id='carouselExampleIndicators'
							className=' Show_Asnwear_Unit_Tranditiosnal_carousel mb-5 carousel  slide w-75 mx-auto my-4  position-relative    '
							data-bs-ride='carousel'>
							<div className='carousel-indicators'>
								{qustions_Quiz_data?.data?.map((source, index) => {
									return (
										<button
											key={uuid()}
											type='button'
											data-bs-target='#carouselExampleIndicators'
											data-bs-slide-to={index}
											className={` ${index === 0 ? 'active' : ''} `}
											aria-current='true'
											aria-label={`Slide ${index + 1}`}></button>
									);
								})}
							</div>
							<div className='carousel-inner '>
								{qustions_Quiz_data?.data?.map((source, index) => {
									return (
										<div
											className={`carousel-item  ${
												index === 0 ? 'active' : ''
											} `}>
											<a
												rel='noreferrer'
												target='_blank'
												href={`${process.env.REACT_APP_STORAGE_URL}${source.image_link}`}>
												<img
													src={` ${process.env.REACT_APP_STORAGE_URL}${source.image_link}`}
													className='d-block w-100'
													alt='...'
												/>
											</a>
										</div>
									);
								})}
							</div>
							<button
								className='carousel-control-prev'
								type='button'
								data-bs-target='#carouselExampleIndicators'
								data-bs-slide='prev'>
								<span
									className='carousel-control-prev-icon'
									aria-hidden='true'></span>
								<span className='visually-hidden'>Previous</span>
							</button>
							<button
								className='carousel-control-next'
								type='button'
								data-bs-target='#carouselExampleIndicators'
								data-bs-slide='next'>
								<span
									className='carousel-control-next-icon'
									aria-hidden='true'></span>
								<span className='visually-hidden'>Next</span>
							</button>
						</div>

						<div className='mt-5' />
						<div className='mt-5' />

						{/* <h1
						className='w-100 text-center my-3 mb-5 mt-5'
						style={{ color: '#044364' }}>
						العلامة المعطاة : {state?.deservedMark}
					</h1> */}
						<Button
							text={` النتيحة ${
								data?.result === '-1' ? '0' : state?.deservedMark + '/100'
							}  `}
							className='examResultBtn'
						/>
						<hr />

						<h1
							className='w-100 text-center mt-2 '
							style={{ color: '#ef0000' }}>
							الملاحظة : {state?.teacherNotice}
						</h1>
					</div>
				)}
			</div>

			{!corrected && (
				<>
					{post_correction_success && (
						<div class='alert alert-success text-center' role='alert'>
							تهانينا تم تصحيح الاختبار بنجاح
						</div>
					)}
					{post_correction_IsError && (
						<div className='alert alert-danger' role='alert'>
							{post_correction_IsError?.response?.data?.message
								? post_correction_IsError?.response?.data?.message
								: post_correction_IsError.message}
						</div>
					)}

					<div className='d-flex justify-content-center'>
						<div className='card shadow  py-4  login-card mb-5'>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className='mb-3'>
									<div className='mb-3'>
										<label className='fw-bold'>ملاحظة</label>
									</div>
									<input
										{...register('teacher_notes')}
										type='text'
										className='shadow w-100 main-input py-2 '
										style={{
											borderRadius: '10px',
											border: ' 1px solid #044364',
										}}
									/>
									{errors.teacher_notes && (
										<label className='text-center w-100 text-danger'>
											Invalid input
										</label>
									)}
								</div>

								<div className='mb-3'>
									<div className='mb-3'>
										<label className='fw-bold'>العلامة المستحقة</label>
									</div>
									<input
										style={{
											borderRadius: '10px',
											border: ' 1px solid #044364',
										}}
										{...register('deserved_mark', { required: true })}
										type='number'
										className='shadow w-100 main-input py-2 '
									/>
									{errors.deserved_mark && (
										<label className='text-center w-100 text-danger'>
											Invalid input
										</label>
									)}
								</div>
								<div className='mb-3'></div>
								{post_correction_IsLoading && (
									<div className='d-flex mb-3'>
										<div class='spinner-border m-auto' role='status'>
											<span class='sr-only'>Loading...</span>
										</div>
									</div>
								)}
								<div className='d-flex'>
									<button
										disabled={!isDirty || !isValid}
										type='submit'
										className='btn2 m-auto w-25 rounded text-white fw-bold py-2'
										style={{ cursor: 'pointer', border: 'none' }}>
										إرسال النتيجة
									</button>
								</div>
							</form>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
