import React from 'react';
import Layout from '../Layout/Layout';

const NotActive = () => {
	return (
		<Layout>
			<div className='my-5'>
				<div
					class='alert alert-success my-5 mx-auto h5 shadow position-relative text-center'
					style={{
						maxWidth: '600px',
						lineHeight: '1.3',
						paddingBottom: '50px',
					}}
					role='alert'>
					عذرا عزيزي الطالب .. نود إعلامك أن حسابك لا يزال مدرجا ضمن الحسابات
					الغير مفعلة لدينا <br />
					<br />
					لذا لن تكون قادرا على البدء بمسيرتك الدراسية إلى أن يتم تفعيل حسابك عن
					طريق إدراة المنصة
					<br />
					<br />
					<center>
						<strong>
							لتفعيل حسابك اضغط على{' '}
							<a href={'https://t.me/mywayedu'} target='_blank'>
								تفعيل الآن
							</a>
						</strong>
					</center>
					<img
						loading='lazy'
						style={{
							position: 'absolute',
							left: '50%',
							bottom: '-70px',
							transform: 'translate(-50%,0%)',
						}}
						width='100px'
						height='100px'
						src='https://img.icons8.com/external-smashingstocks-flat-smashing-stocks/66/000000/external-Verification-banking-and-finance-smashingstocks-flat-smashing-stocks.png'
					/>
				</div>
			</div>
		</Layout>
	);
};

export default NotActive;
