import React from 'react';
import { Link } from 'react-router-dom';
import Unit_Quiz_Coreected_Logic from './Unit_Quiz_Coreected_Logic';

export default function Unit_Quiz_Coreected({ api }) {
	const { isSuccess, progress, IsLoading, IsError, data } =
		Unit_Quiz_Coreected_Logic({ api });
	console.log(data);

	return (
		<div className='container  py-5'>
			<h3 className='fw-bold mb-4 text-center my-3'>
				<span style={{ color: '#044364' }}>الإختبارات </span>
			</h3>
			{IsLoading && (
				<div class='d-flex justify-content-center'>
					<div class='spinner-grow text-dark' role='status'>
						<span class='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{
				<div className=''>
					<table className='w-100  mt-3 table_style  table-bordered  table-striped  my-5   text-center'>
						<thead>
							<tr>
								<th className='first_child'>#</th>
								<th> اسم الاختبار </th>
								<th> الوصف</th>
								<th> المدة</th>
								<th>النقاط</th>
								<th>نوع الاختبار</th>
								<th className='last_child'> </th>
							</tr>
						</thead>
						{data?.data && (
							<tbody style={{ lineHeight: '50px' }}>
								{data?.data?.map((quiz, index) => (
									<tr key={quiz.quiz_id}>
										<td>{index}</td>
										<td> {quiz.quiz_name} </td>
										<td> {quiz.quiz_description} </td>
										<td> {quiz.quiz_duration} </td>
										<td> {quiz.quiz_points} </td>
										<td>
											{' '}
											{quiz.isAboveLevel == 1 ? 'فوق المستوى' : 'نهائي'}{' '}
										</td>

										<td>
											<Link
												to={`show/${quiz.quiz_id}`}
												className='btn btn1'
												state={{
													deservedMark: quiz.deserved_mark,
													teacherNotice: quiz.teacher_notes,
													quizDetailes: quiz,
												}}>
												{' '}
												عرض الاختبار
											</Link>
										</td>
									</tr>
								))}
							</tbody>
						)}
						{IsError && (
							<div class='d-flex justify-content-center'>
								<label className='text-danger'>
									{IsError?.response?.data?.message
										? IsError?.response?.data?.message
										: IsError.message}
								</label>
							</div>
						)}
					</table>
				</div>
			}
		</div>
	);
}
