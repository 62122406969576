import './App.css';
import { lazy, useEffect, useLayoutEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
// import { getPosts } from './redux-toolkit/reducers/Home/HomeSlice';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './pages/Profile/Profile';
import Subscribe from './pages/Subscribe/Subscribe';
import Offers from './pages/Offers/Offers';

import Student from './pages/Student/Student';
import Reports from './pages/Reports/Reports';
import ProfileStudent from './pages/ProfileStudent/ProofileStudent';

import MyPoints from './pages/MyPoints/MyPoints';
import Programme from './pages/Programme/Programme';
import Medals from './pages/Medals/Medals';
// import SubjectScreen from './pages/subjectScreen';
// import ProofileStudent from '../src/pages/ProfileStudent'

import AboutUsPage from './pages/Aboutus/AboutUs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import SubjectScreen from './pages/subjectScreen/index';
// import AllComments from './pages/';
// import HomePage from './pages/Home/Home';
import TermsandConditionsPage from './pages/TermsandConditions/TermsandConditions';
import ScientificBaccalaureatePage from './pages/ScientificBaccalaureate/ScientificBaccalaureate';
import WinterCoursesPage from './pages/WinterCourses/WinterCourses';
import LibrarySitePage from './pages/LibrarySite/LibrarySite';

import UnitScreen from './pages/unitScreen/index';
import LessonScreen from './pages/lessonScreen/index';
import ExamScreen from './pages/examScreen/index';
// import Program from './pages/program/index';
import ProfileScreen from './pages/profileScreenById/index';
import ExamsTableScreen from './pages/examTableScreen/index';
import AudioPlayerScreen from './pages/audioPlayer/index';
import SubjectsScreen from './pages/subjectsScreen/index';
import ArchivedScreen from './pages/archived/index';
import StudentArchiveScreen from './pages/studentArchive/index';
import ProfileTeacher from './pages/ProfileTeacher/ProfileTeacher';
import StudentForTeacher from './pages/StudentForTeacher/StudentForTeacher';
import OneStudentForTeacher from './pages/OneStudentForTeacher/OneStudentForTeacher';
import GuardianStudentTeacher from './pages/GuardianStudentTeacher/GuardianStudentTeacher';
import TraditionalQuizTeacher from './pages/TraditionalQuizTeacher/TraditionalQuizTeacher';
import StudentTraditonalAnsTeacher from './pages/StudentTraditionalAnsTeacher/StudentTraditonalAnsTeacher';
import AutomatedQuizTeacher from './pages/AutomatedQuizTeacher/AutomatedQuizTeacher';
import Professors from './components/Professors/Professors';
import Professorsabbreviation from './components/Professorsabbreviation/Professorsabbreviation';
import AllComments from './pages/allComments';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Signup from './pages/signup/signup';
import Signin from './pages/Signin/SignIn';

import Addtraditionalquiz from './components/Add_traditional_quiz/Add_traditional_quiz';
import LayoutSideTeacher from './pages/LayoutSide/LayoutSideTeacher';
import Options from './pages/options/Options';
import QuestionoModel from './pages/questionModel';
import UnitTest from './pages/UnitTest/UnitTest';
import Createtest from './pages/create_test/Create_test';
import Showalltraditionaltest from './pages/Show_all_traditional_test/Show_all_traditional_test';
import Edittraditionalquiz from './components/edit_traditional_quiz/Edit_traditional_quiz';
import Addtraditionaquizquistionsfiles from './components/Add_Traditiona_Quiz_Quistions_Files/Add_Traditiona_Quiz_Quistions_Files';
import Showtraditionalquizquestionsfiles from './components/Show_Traditionalquiz_Questions_Files/Show_Traditionalquiz_Questions_Files';
import QuizCard from './components/QuizCard/QuizCard';
import ShowalluplevelQuiz from './components/Show_all_up_level_Quiz/Show_all_up_level_Quiz';
import ShowallautomateduplevelQuiz from './components/Show_all_automated_uplevel_Quiz/ShowallautomateduplevelQuiz';
import ShowallautomatedfinalQuiz from './components/Show_all_automated_final_Quiz/ShowallautomatedfinalQuiz';
import Createunitautomatedquiz from './components/Create_unit_automated_quiz/Create_unit_automated_quiz';
import Addautomatedquizquistions from './components/Add_automated_quiz_quistions/Add_automated_quiz_quistions';
import Showautomatedquizoptions from './components/Show_Automated_Quiz_Options/Show_Automated_Quiz_Options';
import Editautomatedquizunit from './components/Edit_Automated_Quiz_Unit/Edit_Automated_Quiz_Unit';
import Showallunits from './components/teacher/Units/Show_All_Unit/Show_All_Unit';

import Addunit from './components/Add_Unit/Add_Unit';
import Quizto from './components/Quiz_To/Quiz_To';

import Selectunit from './components/Select_Unit/Select_Unit';
import Showallmaterialforquiz from './components/Show_All_Material_For_Quiz/Show_All_Material_For_Quiz';

// import Addunit from "./components/Add_Unit/Add_Unit";
// import Quizto from "./components/Quiz_To/Quiz_To";

// teacher imports
import AddLessonToUnit from './components/teacher/Lessons/Add_Lesson_To_Unit/Add_Lesson_To_Unit';
import ShowallSubjects from './components/teacher/Subjects/Show_All_Subjects/Show_All_Subjects';
import Showalllesson from './components/teacher/Lessons/Show_All_Lesson/Show_All_Lesson';
import Editunit from './components/teacher/Units/Edit_Unit/Edit_Unit';
import Selectlesson from './components/teacher/Lessons/Select_Lesson/Select_Lesson';
import EditLesson from './components/teacher/Lessons/Edit_Lesson/Edit_Lesson';
import ShowUnitFaqs from './components/teacher/Units/faqs/Show_Unit_Faqs/Show_Unit_Faqs';
import SelectlUnitForLesson from './components/teacher/Units/Selectl_Unit_For_Lessons/Selectl_Unit_For_Lessons';
import Showalllessonforquiz from './components/teacher/Lessons/Show_All_Lesson_For_Quiz/Show_All_Lesson_For_Quiz';
import Createautomatedquizlesson from './components/teacher/Lesson_Quiz/Create_Automated_Quiz_Lesson/Create_Automated_Quiz_Lesson';
import Addautomatedquizquistionsforlesson from './components/teacher/Lesson_Quiz/Add_Automated_Quiz_Quistions_For_Lesson/Add_Automated_Quiz_Quistions_For_Lesson';
import Addunitfaqs from './components/teacher/Units/faqs/Add_Unit_Faqs/Add_Unit_Faqs';
import Editunitfaqs from './components/teacher/Units/faqs/Edit_Unit_Faqs/Edit_Unit_Faqs';
import ShowLessonFaqs from './components/teacher/Lessons/faqs/Show_All_Lesson_Faqs/Show_All_Lesson_Faqs';
import Addlessonfaqs from './components/teacher/Lessons/faqs/Add_Lesson_Faqs/Add_Lesson_Faqs';
import Editlessonfaqs from './components/teacher/Lessons/faqs/Edit_Lesson_Faqs/Edit_Lesson_Faqs';
import CommentsLesson from './components/teacher/Lessons/comments/Show_All_Comments/Show_All_Comments';
import EditLessonComment from './components/teacher/Lessons/comments/Edit_Comment/Edit_Comment';
import UnitComment from './components/teacher/Units/comments/Show_All_Comments/Show_All_Comments';
import EditUnitComment from './components/teacher/Units/comments/Edit_Comment/Edit_Comment';
import ShowLessonAnswear from './components/teacher/Lesson_Quiz/Show_Lesson_Answear/Show_Lesson_Answear';
import ShowasnwearUnittranditiosnal from './components/teacher/Unit_Quiz/Show_Asnwear_Unit_Tranditiosnal/Show_Asnwear_Unit_Tranditiosnal';

import Students from './components/Students/Students';

import Main_Home from './pages/public/Main_Home/Main_Home';

import { Suspense } from 'react';
import Lesson_Quizes from './components/teacher/Lesson_Quiz/Lesson_Quizes/Lesson_Quizes';
import Coerrected_Or_Not from './components/Coerrected_Or_Not/Coerrected_Or_Not';
import Unit_Quiz_Coreected from './components/teacher/Unit_Quiz/Unit_Quiz_Coreected/Unit_Quiz_Coreected';
import Show_type_test from './components/Show_type_test/Show_Type_test';
import Student_Details from './components/teacher/visas/StudentDetails/Student_Details';
import ProfileDetailsTeacher from './pages/ProfileDetailsTeacher/ProfileDetailsTeacher';
import Layout from './pages/Layout/Layout';

import Testinlesson from './components/Testinlesson/Testinlesson';
import ProofileStudent from './pages/ProfileStudent/ProofileStudent';
import WinterCourses from './components/wintercourses/WinterCourses';
import ProfileStudentGuardian from './pages/ProfileStudentGuardian/ProfileStudentGuardian';
import ProfileGuardian from './pages/profileGuardian/ProfileGuardian';
import SectionFiles from './components/section_files';
import ProfileScreenByToken from './pages/profileScreenByToken';
import SubjectsPage from './pages/SubjectsPage';
import UnitsPage from './pages/UnitPage';
import StudentSubject from './pages/StudentSubject';
import LessonesPage from './pages/LessonesPage';
import AutomatedUnit from './pages/automatedUnit/automatedUnit';
import AboveAutomatedUnit from './pages/aboveAutomatedUnit/aboveAutomatedUnit';
import EmbedPdf from './pages/EmbedPdf/EmbedPdf';
//  new imports for student quizes

// *************************************************
import LessonQuizes from './components/Students/LessonQuiz/LessonQuizes/LessonQuizes';
import Show_LessonAnswear from './components/Students/LessonQuiz/ShowLessonAnswer/ShowLessonAnswer';
import UnitQuizCoreected from './components/Students/UnitQuiz/UnitQuizCorrected';
import ShowAsnwearUnitTranditiosnal from './components/Students/UnitQuiz/ShowAnwerUnitTaditional/ShowAnswerUnitTraditional';
import AllNotification from './pages/AllNotification';
import Subscriptions from './components/Subscriptions/Subscriptions';
import SubscribtionDetails from './components/SubscribtionDetails/SubscribtionDetails';
import TeacherAttatchment from './components/teacherAttatchment';
import Sections from './components/sections/Sections';
import SubjectsReports from './pages/SubjectsReports/SubjectsReports';
import UnitsReports from './pages/UnitsReports/UnitsReports';
import UnitReportDetails from './pages/UnitReportDetails/UnitReportDetails';
import UnitQuizReport from './pages/UnitQuizReports/UnitQuizReports';
import LessonQuizeReports from './pages/LessonQuizeReports/LessonQuizeReports';
import UnitAutomatedReports from './pages/UnitAutomatedReports/UnitAutomatedReports';
import UnitTraditionalReports from './pages/UnitTraditionalReports/UnitTraditionalReports';
import CommonQuestion from './components/CommonQuestion/CommonQuestion';
import SigninPage from './pages/signinPage';
import axios from 'axios';
import LoginAsStudent from './pages/LoginAsStudent/LoginAsStudent';
import LoginAsTeacher from './pages/LoginAsTeacher/LoginAsTeacher';
import ImageOpen from './pages/ImageOpen';
// *************************************************
// import LoginAsTeacher from './pages/LoginAsTeacher/LoginAsTeacher';
// import axios from 'axios';
// import LoginAsStudent from './pages/LoginAsStudent/LoginAsStudent';
import StudentsQuizez from './pages/StudentsQuizez/StudentsQuizez';
import NotActive from './pages/NotActive/NotActive';
import SonsExams from './pages/SonsExams/SonsExams';
import StudentSubjects from './pages/StudentSubjects/StudentSubjects';
import StudentsVisas from './pages/StudentsVisas/StudentsVisas';
import ProfileScreenById from './pages/profileScreenById/index';
import EditautomatedquizLesson from './components/EditautomatedquizLesson/EditautomatedquizLesson';
import TeacherBalance from './pages/TeacherBalance';

const Title = 'أكاديمية الشرق الأوسط';

export const ROLES = {
	guest: '0',
	student: '1',
	parent: '3',
	teacher: '2',
};

function App() {
	//  const dataAuth =  useSelector(state=>state.SIGN);

	const queryClient = new QueryClient();

	// iframe.classList.remove('.ytp-chrome-top-buttons');

	// const dispatch= useDispatch();
	// useEffect(()=>{

	//   if(dataAuth)

	//   dispatch(getPosts())
	// },[])

	// const dispatch= useDispatch();
	// useEffect(()=>{
	//   //get home siteInfo
	//   dispatch(getPosts())
	// },[])

	// check if the token is valid or not

	useEffect(() => {
		// if the use was student
		if (localStorage.getItem('role') == 10) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/get_student_by_token`, {
					headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
				})
				.then((response) => {
					// console.log('response get student by token',response.data.data);

					// set the account situation to know how to handle user permessions inside the website
					localStorage.setItem('is_active', response.data.data.is_active);

					if (
						response.data.data.is_active == 0 &&
						!window.location.pathname.includes('notactive')
					) {
						window.location.replace('/notactive');
					}
				})
				.then((data) => {
					console.log('data ', data);
				})
				.catch((error) => {
					// handleLogout();
					if (error.response.data.code == 410) {
						window.location.reload();
						localStorage.removeItem('token');
						localStorage.removeItem('role');
						if (localStorage.getItem('token2')) {
							localStorage.setItem('token', localStorage.getItem('token2'));
							localStorage.setItem('role', localStorage.getItem('role2'));
							localStorage.removeItem('token2');
							localStorage.removeItem('role2');
						}

						window.location.replace('/');
					}
					console.log('error get admin by token ', error.response.data.code);
				})
				.finally(() => {
					// console.log('finally i exit this');
					// setIsLoading(false);
				});
		}
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<Routes>
					{/* <Route path='/profileStudent' element={<ProofileStudent />} /> */}

					{/* my routes mohammed yosef */}
					<Route path='/allComments' element={<AllComments />} />
					<Route path='/profile' element={<Profile />} />
					{/* <Route path='/subscribe' element={<Subscribe />} /> */}
					<Route path='/offers' element={<Offers />} />

					<Route path='/student' element={<Student />} />
					<Route path='/reports' element={<Reports />} />
					<Route path='/profileStudent' element={<ProfileStudent />} />

					<Route path='/points' element={<MyPoints />} />
					<Route path='/programme' element={<Programme />} />
					<Route path='/medals' element={<Medals />} />
					<Route path='/sections' element={<Sections />} />
					<Route
						path='/subjectsReports/:studentID'
						element={<SubjectsReports />}
					/>
					<Route path='/unitsReports/:subjectId' element={<UnitsReports />} />
					<Route
						path='/unitReportDetails/:unitId'
						element={<UnitReportDetails />}
					/>
					<Route path='/unitquizreports/:unitId' element={<UnitQuizReport />} />
					<Route
						path='/lessonsquizreports/:unitId'
						element={<LessonQuizeReports />}
					/>
					<Route
						path='/unitautomatedreport/:unitId'
						element={<UnitAutomatedReports />}
					/>
					<Route
						path='/unittraditionalreports/:unitId'
						element={<UnitTraditionalReports />}
					/>

					{/* profile stuent by id */}
					<Route path='/profileScreen' element={<ProfileScreen />} />
					{/* profile student by token */}
					<Route path='/studentprofile' element={<ProfileScreenByToken />} />
					<Route path='/allSubjectsStudents' element={<WinterCourses />} />

					{/* new routes for student quizes */}
					{/*  Student quizes */}
					{/* ********************************************************************* */}
					<Route
						path='/loginasteacher/:teacherToken'
						element={<LoginAsTeacher />}
					/>
					<Route
						path='/loginasstudent/:studentToken'
						element={<LoginAsStudent />}
					/>
					<Route path='/notification' element={<AllNotification />} />

					{/* <Route path='subscribtion'>
						<Route index element={<Subscriptions />} />
						<Route
							path='subscribtionDetails/:id'
							element={<SubscribtionDetails />}
						/>
					</Route> */}

					{/* ------------------ */}

					<Route path='/myquizes'>
						<Route index element={<Quizto />} />
						<Route path='lessons'>
							<Route
								index
								element={
									<LessonQuizes api='/get_student_lesson_automated_quizzes' />
								}
							/>
							<Route
								path='automatedquiz/:QuizId'
								element={<Show_LessonAnswear />}
							/>
						</Route>
						<Route path='units'>
							<Route index element={<Show_type_test />} />
							<Route path='traditional_test_unit'>
								{/* <Route index element={<Testinlesson />} /> */}
								{/* <Route path='corrected'> */}
								<Route
									index
									element={
										<UnitQuizCoreected api='/get_student_corrected_unit_traditional_quizzes' />
									}
								/>
								<Route
									path='show/:QuizId'
									element={<ShowAsnwearUnitTranditiosnal />}
								/>
								{/* </Route> */}
								{/* <Route path='uncorrected'>
									<Route
										index
										element={
											<UnitQuizCoreected api='/get_student_uncorrected_unit_traditional_quizzes' />
										}
									/>
									<Route
										path='show/:QuizId'
										element={<ShowAsnwearUnitTranditiosnal />}
									/>
								</Route> */}
							</Route>
							<Route path='automatedquiz'>
								<Route
									index
									element={
										<LessonQuizes api='/get_student_unit_automated_quizzes' />
									}
								/>
								<Route
									path='automatedquiz/:QuizId'
									element={<Show_LessonAnswear />}
								/>
							</Route>
						</Route>
					</Route>

					<Route
						path='/loginasteacher/:teacherToken'
						element={<LoginAsTeacher />}
					/>

					<Route
						path='/loginasstudent/:studentToken/:isactive'
						element={<LoginAsStudent />}
					/>

					<Route path='notactive' element={<NotActive />} />

					<Route path='/sonsexams' element={<SonsExams />} />
					<Route path='/studentsubjects/:id' element={<StudentSubjects />} />

					{/* ********************************************************************* */}

					<Route
						path='/profileStudentGuardian'
						element={<ProfileStudentGuardian />}
					/>
					<Route path='/profileGuardian' element={<ProfileGuardian />} />

					{/* saved subject */}
					<Route path='/studentSubjects' element={<SubjectsPage />} />
					<Route path='/studentUnits' element={<UnitsPage />} />

					{/* all subjects */}
					<Route path='/studentsubject' element={<StudentSubject />} />
					<Route path='/lessones' element={<LessonesPage />} />

					<Route
						path='/studentsQuizez/:subjectId'
						element={<StudentsQuizez />}
					/>

					<Route
						path='studentprofilebyid/:StudentId'
						element={<ProfileScreenById putLayout={true} />}
					/>

					{/* <Route path='/studentprofile' element={<ProfileScreenByToken />} /> */}

					<Route path='/' element={<Layout />}>
						{/* Home page  */}
						<Route index element={<Main_Home />} />
						{/* teacher routes */}
						<Route
							path='create_unit_traditional_quiz'
							element={<Addtraditionalquiz />}
						/>

						<Route element={<RequireAuth allowedRoles={[ROLES.teacher]} />}>
							<Route path='options' element={<Options />}>
								<Route
									path='lessonAttatchment/:id'
									element={<TeacherAttatchment />}
								/>
								<Route 
								path="balance"
								element={<TeacherBalance />}
								/>
								<Route
									index
									element={<ProfileDetailsTeacher Title={Title} />}
								/>
								<Route path='offers' element={<Offers />} />
								<Route path='quizes'>
									<Route
										index
										element={
											<Students
												Title={Title + '| الاختبارات '}
												to='typequiz'
												// api='/get_all_students_that_have_quizzes_by_teacher'
												api='/get_teacher_subjects'
											/>
										}
									/>
									<Route path='typequiz/:StudentId'>
										<Route index element={<Quizto />} />
										<Route path='lessons'>
											<Route
												index
												element={
													<Lesson_Quizes api='/get_lesson_automated_quizzes_by_student_id_and_subject_id?' />
												}
											/>
											<Route
												path='automatedquiz/:QuizId'
												element={<ShowLessonAnswear />}
											/>
										</Route>
										<Route path='units'>
											<Route index element={<Show_type_test />} />
											<Route path='traditional_test_unit'>
												<Route index element={<Testinlesson />} />
												<Route path='corrected'>
													<Route
														index
														element={
															<Unit_Quiz_Coreected api='/get_unit_traditional_quizzes_by_student_id_and_subject_id?' />
														}
													/>
													<Route
														path='show/:QuizId'
														element={
															<ShowasnwearUnittranditiosnal corrected={true} />
														}
													/>
												</Route>
												<Route path='uncorrected'>
													<Route
														index
														element={
															<Unit_Quiz_Coreected api='/get_uncorrected_unit_traditional_quizzes_by_student_id_and_subject_id?' />
														}
													/>
													<Route
														path='show/:QuizId'
														element={
															<ShowasnwearUnittranditiosnal corrected={false} />
														}
													/>
												</Route>
											</Route>
											<Route path='automatedquiz'>
												<Route
													index
													element={
														<Lesson_Quizes api='/get_unit_automated_quizzes_by_student_id_and_subject_id?' />
													}
												/>
												<Route
													path='automatedquiz/:QuizId'
													element={<ShowLessonAnswear />}
												/>
											</Route>
										</Route>
									</Route>
								</Route>

								<Route path='visas'>
									<Route
										index
										element={
											<StudentsVisas
												Title={Title + '| التأشيرات'}
												to='student'
												api='/get_all_exit_visas_students_by_teacher'
											/>
										}
									/>
									<Route path='student/:StudentId'>
										<Route index element={<Student_Details />} />
										<Route path='studentprofile' element={<ProfileScreen />} />
									</Route>
								</Route>
								<Route path='mysubjects'>
									<Route
										index
										element={
											<ShowallSubjects Title={Title + ' | الاختبارات '} />
										}
									/>
									<Route path='showallunit/:SubjectId'>
										<Route index element={<Showallunits />} />
										<Route path=':UnitId/comments'>
											<Route index element={<UnitComment />} />
											<Route
												path='edit_comment/:CommentId'
												element={<EditUnitComment />}
											/>
										</Route>
										<Route path='edit_unit/:UnitId' element={<Editunit />} />
										<Route path=':UnitId/show_lessons'>
											<Route index element={<Showalllesson />} />
											<Route path='comments/:LessonId'>
												<Route index element={<CommentsLesson />} />
												<Route
													path='edit_comment/:CommentId'
													element={<EditLessonComment />}
												/>
											</Route>

											<Route path='add_lesson' element={<AddLessonToUnit />} />
											<Route
												path='edit_lesson/:LessonId'
												element={<EditLesson />}
											/>
											<Route path=':LessonId/Lesson_faqs'>
												<Route index element={<ShowLessonFaqs />} />
												<Route
													path='add_lesson_faqs'
													element={<Addlessonfaqs />}
												/>
												<Route
													path='edit_lesson_faqs/:faqId'
													element={<Editlessonfaqs />}
												/>
											</Route>
										</Route>
										<Route path=':UnitId/unit_faqs'>
											<Route index element={<ShowUnitFaqs />} />
											<Route path='add_unit_faqs' element={<Addunitfaqs />} />
											<Route
												path='edit_unit_faqs/:faqId'
												element={<Editunitfaqs />}
											/>
										</Route>

										<Route path='add_unit' element={<Addunit />} />
									</Route>
								</Route>

								<Route path='create_quiz'>
									<Route
										index
										element={<Showallmaterialforquiz Title={Title} />}
									/>
									<Route path=':SubjectId'>
										<Route index element={<Quizto />} />
										<Route path='units'>
											<Route index element={<Selectunit />} />
											<Route path='type_quiz/:UnitId'>
												<Route index element={<QuizCard />} />
												<Route path='up_level'>
													<Route index element={<Createtest />} />
													<Route path='traditional_test_unit'>
														<Route index element={<ShowalluplevelQuiz />} />
														<Route
															path='add_quiz'
															element={
																<Addtraditionalquiz
																	isFinal={0}
																	isAboveLevel={1}
																/>
															}
														/>
														<Route
															path='edit/:QuizId'
															element={
																<Edittraditionalquiz
																	isFinal={0}
																	isAboveLevel={1}
																/>
															}
														/>
														<Route
															path='add_traditional_quiz_questions_files/:QuizId'
															element={<Addtraditionaquizquistionsfiles />}
														/>
														<Route
															path='show_traditional_quiz_questions_files/:QuizId'
															element={<Showtraditionalquizquestionsfiles />}
														/>
													</Route>
													<Route path='automatedquiz'>
														<Route
															index
															element={<ShowallautomateduplevelQuiz />}
														/>
														<Route
															path='create_unit_automated_quiz'
															element={
																<Createunitautomatedquiz
																	isFinal={0}
																	isAboveLevel={1}
																/>
															}
														/>
														<Route
															path='show_automated_quiz_questions_options/:QuizId'
															element={<Showautomatedquizoptions />}
														/>
														<Route
															path='add_automated_quiz_questions/:QuizId'
															element={<Addautomatedquizquistions />}
														/>
														<Route
															path='edit/:QuizId'
															element={
																<Editautomatedquizunit
																	isFinal={0}
																	isAboveLevel={1}
																/>
															}
														/>
													</Route>
												</Route>
												<Route path='final'>
													<Route index element={<Createtest />} />
													<Route path='traditional_test_unit'>
														<Route index element={<Showalltraditionaltest />} />
														<Route
															path='add_quiz'
															element={
																<Addtraditionalquiz
																	isFinal={1}
																	isAboveLevel={0}
																/>
															}
														/>
														<Route
															path='edit/:QuizId'
															element={
																<Edittraditionalquiz
																	isFinal={1}
																	isAboveLevel={0}
																/>
															}
														/>
														<Route
															path='add_traditional_quiz_questions_files/:QuizId'
															element={<Addtraditionaquizquistionsfiles />}
														/>
														<Route
															path='show_traditional_quiz_questions_files/:QuizId'
															element={<Showtraditionalquizquestionsfiles />}
														/>
													</Route>

													<Route path='automatedquiz'>
														<Route
															index
															element={<ShowallautomatedfinalQuiz />}
														/>
														<Route
															path='create_unit_automated_quiz'
															element={
																<Createunitautomatedquiz
																	isFinal={1}
																	isAboveLevel={0}
																/>
															}
														/>
														<Route
															path='add_automated_quiz_questions/:QuizId'
															element={<Addautomatedquizquistions />}
														/>
														<Route
															path='edit/:QuizId'
															element={
																<Editautomatedquizunit
																	isFinal={1}
																	isAboveLevel={0}
																/>
															}
														/>
														<Route
															path='show_automated_quiz_questions_options/:QuizId'
															element={<Showautomatedquizoptions />}
														/>
													</Route>
												</Route>
											</Route>
										</Route>
										<Route path='lessons'>
											<Route index element={<SelectlUnitForLesson />} />
											<Route path=':UnitId/show_lessons'>
												<Route index element={<Showalllessonforquiz />} />
												<Route
													path='create_quiz/:LessonId'
													element={<Createautomatedquizlesson />}
												/>
												<Route
													path='add_questions/:QuizId'
													element={<Addautomatedquizquistionsforlesson />}
												/>
												<Route
													path='show_questions/:QuizId'
													element={<Showautomatedquizoptions />}
												/>
												<Route
													path='edit/:QuizId'
													element={<EditautomatedquizLesson />}
												/>
											</Route>
										</Route>
									</Route>
								</Route>
							</Route>
						</Route>
					</Route>
					<Route path='/EmbedPdf' element={<EmbedPdf />} />
					<Route path='automatedUnit/:id' element={<AutomatedUnit />} />
					<Route
						path='aboveAutomatedUnit/:id'
						element={<AboveAutomatedUnit />}
					/>

					{/* Parent Routes  */}

					{/* job for today */}
					<Route path='techerProfile' element={<ProfileTeacher />} />

					<Route path='studentForTeacher' element={<StudentForTeacher />} />
					<Route
						path='onestudentTeacher/:id'
						element={<OneStudentForTeacher />}
					/>
					<Route
						path='guradianStudentTeacher/:id'
						element={<GuardianStudentTeacher />}
					/>
					<Route
						path='traditionalQuizTeacher/:id'
						element={<TraditionalQuizTeacher />}
					/>
					<Route
						path='traditionalQuizAnsTeacher/:id'
						element={<StudentTraditonalAnsTeacher />}
					/>
					<Route
						path='automatedQuizTeacher'
						element={<AutomatedQuizTeacher />}
					/>

					<Route path='AboutUs' element={<AboutUsPage />} />

					{/* guest routes */}

					<Route path='signin'>
						<Route index element={<SigninPage text={'دخول'} />} />
						<Route
							path='student'
							element={<Signin text={'طالب'} role={'1'} />}
						/>
						<Route
							path='teacher'
							element={<Signin text={'إستاذ'} role={'2'} />}
						/>
					</Route>

					<Route path='signup'>
						<Route index element={<SigninPage text={'إنشاء حساب'} />} />
						<Route
							path='student'
							element={<Signup text={'طالب'} role={'1'} />}
						/>
						<Route
							path='teacher'
							element={<Signup text={'إستاذ'} role={'2'} />}
						/>
					</Route>

					<Route path='lesson/:id' element={<LessonScreen />} />

					<Route
						path='TermsandConditions'
						element={<TermsandConditionsPage />}
					/>
					<Route
						path='ScientificBaccalaureate/:id'
						element={<ScientificBaccalaureatePage />}
					/>
					<Route path='WinterCourses/:id' element={<WinterCoursesPage />} />
					<Route path='LibrarySite' element={<LibrarySitePage />}></Route>
					<Route path='LibrarySite/:id' element={<SectionFiles />} />

					<Route path='subjectt/:id' element={<SubjectScreen />} />
					<Route path='unitt/:id' element={<UnitScreen />} />

					{/* <Route path='allcomments' element={<AllComments />} /> */}
					<Route path='examm/:id' element={<ExamScreen />} />
					{/* <Route path='program' element={<Program />} /> */}

					<Route path='examtable' element={<ExamsTableScreen />} />
					<Route path='audioplayer' element={<AudioPlayerScreen />} />
					<Route path='subjectss' element={<SubjectsScreen />} />
					<Route path='archived' element={<ArchivedScreen />} />
					<Route path='studentarchive' element={<StudentArchiveScreen />} />

					<Route path='teachers' element={<Professors />} />

					<Route path='teacher/:id' element={<Professorsabbreviation />} />

					{/* parent routes  */}

					{/* student routes */}
					{/* <Route element={<RequireAuth allowedRoles={[ROLES.student]} />}>
<Route path='/subject' element={<SubjectInfo/>}/>
</Route> */}

					<Route
						path='/lessonAttatchment/:id'
						element={<TeacherAttatchment />}
					/>

					<Route path='/commonQuestion' element={<CommonQuestion />} />
					<Route path='/image' element={<ImageOpen />} />
				</Routes>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
