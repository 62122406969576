import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import Layout from '../../pages/Layout/Layout';
import LayoutSideStudent from '../../pages/LayoutSide/LayoutSideStudent';
import MainHeader from '../MainHeader/MainHeader';
export default function Show_type_test({isCreateQuiz}) {
	const { state } = useLocation();
	console.log('sta teee', state);
	if (localStorage.getItem('role') == '1') {
		return (
			<Layout>
				{/* <MainHeader /> */}
				<LayoutSideStudent desc='اختبارات الوحدة'>
					<div className=' px py-3 pb-5 container'>
						<h3 className='fw-bold mb-4 text-center my-3'>
							<span style={{ color: '#044364' }}>الإختبارات </span>
						</h3>
						<div className=' my-3'>
							{/* <div className='d-flex mb-3'>
							<img src={chalk} className='butter-fly' />
							<h4>اختبارات الوحدة</h4>
						</div> */}
						</div>
						<div className=' d-flex justify-content-around flex-wrap m-auto'>
							<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12  mt-2'>
								<Link to='automatedquiz'>
									{/* <img src={choose} className='w-50 m-auto' alt='' /> */}
									<h5 className='text-center text-white  fs-4 fw-bold'>
										مؤتمت
									</h5>
								</Link>
							</div>

							<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12 mx-1  mt-2'>
								<Link to='traditional_test_unit'>
									{/* <img src={book} className='w-50 m-auto' alt='' /> */}
									<h5 className='text-center text-white  fs-4 fw-bold'>
										تقليدي
									</h5>
								</Link>
							</div>
						</div>
					</div>
				</LayoutSideStudent>
			</Layout>
		);
	} else {
		return (
			<>
				<div className=' px py-3 pb-5 container'>
					<h3 className='fw-bold mb-4 text-center my-3'>
						<span style={{ color: '#044364' }}>الإختبارات </span>
					</h3>
					<div className=' my-3'>
						{/* <div className='d-flex mb-3'>
							<img src={chalk} className='butter-fly' />
							<h4>اختبارات الوحدة</h4>
						</div> */}
					</div>
					<div className=' d-flex justify-content-around flex-wrap m-auto'>
						<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12  mt-2'>
							<Link
								to='automatedquiz'
								state={state?.sub_id ? { sub_id: state.sub_id } : null}>
								{/* <img src={choose} className='w-50 m-auto' alt='' /> */}
								<h5 className='text-center text-white  fs-4 fw-bold'>مؤتمت</h5>
							</Link>
						</div>

						{
							isCreateQuiz ? 

						<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12 mx-1  mt-2'>
							<Link
								to='traditional_test_unit'
								state={state?.sub_id ? { sub_id: state.sub_id } : null}>
								{/* <img src={book} className='w-50 m-auto' alt='' /> */}
								<h5 className='text-center text-white  fs-4 fw-bold'>تقليدي</h5>
							</Link>
						</div>
						:
						<div className=' quiz_card_sub text-center col-lg-4 col-md-5 col-sm-5 col-12 mx-1  mt-2'>
							<Link
								to='traditional_test_unit/corrected'
								state={state?.sub_id ? { sub_id: state.sub_id } : null}>
								{/* <img src={book} className='w-50 m-auto' alt='' /> */}
								<h5 className='text-center text-white  fs-4 fw-bold'>تقليدي</h5>
							</Link>
						</div>
						}
					</div>
				</div>
			</>
		);
	}
}
