import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import fetchcommentsbylessonid from '../../../../../redux-toolkit/api/Lesson/fetchcommentsbylessonid';

export default function Show_All_Comments_Logic() {
	const dispatch = useDispatch();
	const [loading, setloading] = useState();
	const [page, setPage] = useState(1);

	function handlePageClick(data) {
		setPage((prev) => data.selected + 1);
	}

	const Authdata = useSelector((state) => state.SIGN.token);
	const { LessonId } = useParams();

	const { isLoading, isError, data, error, refetch } = useQuery(
		['commentslesson', LessonId, 10, page, Authdata],
		fetchcommentsbylessonid,
		{ enabled: true }
	);

	const deletComment = (id) => {
		setloading(true);
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/delete_lesson_comment_by_id/${id}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				}
			)
			.then((res) => {
				setloading(false);
				setTimeout(() => window.location.reload(), 600);
			})
			.catch((err) => setloading(true));
	};

	return {
		deletComment,
		loading,
		dispatch,
		data,
		isLoading,
		isError,
		error,
		refetch,
		handlePageClick,
		page,
		setPage,
	};
}
