import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get_all_automated_quiz_questions_with_options_by_automated_quiz } from '../../redux-toolkit/reducers/Unit_test/UnitSlice';

export default function Show_Automated_Quiz_Options_Logic() {
  const { QuizId } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(state => state.unitTest.get_all_automated_quiz_questions_with_options_by_automated_quiz);
  
 
  
  
  
  
  
  useEffect(() => {
    dispatch(get_all_automated_quiz_questions_with_options_by_automated_quiz({ QuizId }));
  }, [])

  return {
    data,dispatch,QuizId,get_all_automated_quiz_questions_with_options_by_automated_quiz
  }
}
