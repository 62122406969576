import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { clearMsg } from '../../../../redux-toolkit/reducers/notification';
import ToastSuccessError from '../../../ToastSuccessError/ToastSucessError';
import Edit_Unit_Logic from './Edit_Unit_Logic';

export default function Edit_Unit() {
	const [loading, setLoading] = React.useState(false);
	const successMessage = useSelector((state) => state.notification.isSuccess);
	const errorMessage = useSelector((state) => state.notification.isError);
	const {
		register,
		errors,
		isDirty,
		isValid,
		handleSubmit,
		edit_unit_error,
		edit_unit_progress,
		edit_unit_success,
		edit_unit_loading,
		onSubmit,
		handlecover,
		cover_Ref,
		cover_error,
	} = Edit_Unit_Logic({ setLoading });

	useEffect(() => {
		if (edit_unit_success) {
			alert('xyz');
		}
	}, [edit_unit_success]);

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={errorMessage}
				clearMsg={clearMsg}
			/>
			<div className='container Add_traditional_quiz my-5 card shadow p-5'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					تعديل الوحدة
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation'
					noValidate>
					<div className='col-md-4'>
						<label
							htmlFor='name'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الوحدة
						</label>
						<input
							type='text'
							className='form-control'
							id='name'
							{...register('name', { required: true })}
						/>
						{errors.name && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							نقاط الوحدة
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.points && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو
						</label>
						<input
							type='file'
							{...register('video', { required: false })}
							className='form-control'
							id='video'
							accept='video/mp4'
						/>
						{errors.video && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='isFree'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							هل هو مجاني
						</label>
						<br />
						<select id='isFree' {...register('isFree', { required: true })}>
							<option value='1'>نعم</option>
							<option value='0'>لا</option>
						</select>
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='start_date'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							{' '}
							تاريخ البداية
						</label>
						<input
							type='date'
							{...register('start_date', { required: true })}
							className='form-control'
							id='start_date'
						/>
						{errors.start_date && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-4'>
						<label
							htmlFor='end_date'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							تريخ الانتهاء{' '}
						</label>
						<input
							type='date'
							{...register('end_date', { required: true })}
							className='form-control'
							id='end_date'
						/>
						{errors.end_date && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='requirements'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							{' '}
							المتطلبات
						</label>
						<input
							type='text'
							placeholder='رياضيات,فيزياء,'
							{...register('requirements', { required: true })}
							className='form-control'
							id='requirements'
						/>
						{errors.start_date && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							وصف الوحدة
						</label>
						<textarea
							{...register('description', { required: true })}
							className='form-control '
							id='description'
						/>
						{errors.description && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-4'>
						<label
							htmlFor='cover'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اختر صورة الغلاف
						</label>
						<input
							ref={cover_Ref}
							title='يرجى اختيار صورة'
							onChange={handlecover}
							accept='image/jpeg, image/jpg, image/png'
							type='file'
							class='form-control'
							id='cover'
							aria-label='file example'
						/>
						{cover_error ? (
							<div className='text-danger'>{cover_error}</div>
						) : (
							<></>
						)}
					</div>

					<div className='col-md-12'>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364' }} >
							ترتيب الوحدة
						</label>
						<input
							{...register('unit_order')}
							className='form-control '
							id='description'
						/>
						{/* {errors.description && <p className='text-danger'>Invalid input</p>} */}
					</div>

					{edit_unit_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{ width: `${edit_unit_progress}%` }}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>{edit_unit_progress}% </label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12'>
						{loading ? (
							<span class='spinner-grow'></span>
						) : (
							<div className='text-center mt-3'>
								<button
									// disabled={!isDirty || !isValid || cover_error}
									className='btn btn1  w-50 mx-auto'
									type='submit'>
									تعديل الوحدة
								</button>
							</div>
						)}

						{edit_unit_error && (
							<label className='text-center w-100 text-danger'>
								{edit_unit_error?.response
									? edit_unit_error.response.data.message
									: edit_unit_error.message}
							</label>
						)}
					</div>
					{edit_unit_success && (
						<div class='alert alert-success text-center' role='alert'>
							تم تعديل الوحدة بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
