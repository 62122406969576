import React, { useState, useEffect } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import './ScientificBaccalaureate.css';

import { useDispatch, useSelector } from 'react-redux';

import fetchSubSections from '../../redux-toolkit/api/fetchSubSections';
import { useQuery } from 'react-query';
import Pagination from '../Pagination/Pagination';
import Bounce from 'react-reveal';

export default function ScientificBaccalaureate() {
	let SectionId = useParams();

	const [subSectionsAr, setSubSectionsAr] = useState([]);

	const dispatch = useDispatch();
	// const [ar,setaArr] = useState([1,2,3,4,5,6,7,8,9,10]);
	// const SubSectios =  useSelector(state => state.subSections);

	const [page, setPage] = useState(1);

	const { isLoading, isError, data, error } = useQuery(
		['sections', { page, SectionId }],
		fetchSubSections
	);
	console.log(data);

	function handlePageClick(data) {
		console.log('sections from api', data);
		setPage(data.selected + 1);
	}

	// useEffect(()=>{
	//   console.log('sectionns111222333',SectionId.id);
	//   dispatch(getSubSections(SectionId.id));
	// },[])

	// useEffect(() => {
	//   console.log('data from sbac',SubSectios);

	//   setSubSectionsAr([SubSectios.data?.data]);
	//   console.log(subSectionsAr);
	// },[]);

	// console.log("sub sections from our component" , SubSectios)
	// const arr = SubSectios.data;
	// console.log("subsctions aray",arr);

	console.log('baca', data);

	const location = useLocation();
	console.log('location', location);
	const state = location?.state;
	console.log('state', state?.item);
	return (
		<>
			<div className='text-center'>
				<h2 className='fw-bold mt-5'>
					<span style={{ color: '#044364' }}>
						{data?.data?.data[0]?.section_name}{' '}
					</span>
				</h2>
			</div>
			{/* <button onClick={()=>console.log(data)}>show</button> */}
			<div
				className='container-fluid   position-relative  '
				//  style={{marginBottom:'100px',backgroundImage: `url("../../assets/wintercourses/background_winter_courses.png")`,backgroundRepeat:'no-repeat' ,backgroundSize:'cover',backgroundPosition:'center',backgroundImage:'linear-gradient(to bottom , #2CABA8 , #1d7472)' }}
			>
				<div>
					<p className=' mx-4 text-white  main_title-winter_courses fw-bolder '>
						{/* <img className=' courses_winter_icon_title' src={chalks}   alt='courses_icon_title'/> */}
						{state?.item?.section_name}
					</p>
				</div>
				{/* <img   
                     src={wintercourses}
                     alt='wintercourses' 
                     className='position-absolute'
                     style={{
                             left:'8%',
                             top:'51px',
                             maxWidth:'10%'
                     }} */}
			</div>

			<div className=' ScientificBaccalaureate-container overflow-hidden container p-2 text-center my-4'>
				<div
					className='row  position-relative'
					style={{ justifyContent: 'space-around' }}>
					{isLoading ? (
						<div class='spinner-border text-secondary' role='status'>
							<span class='visually-hidden'>Loading...</span>
						</div>
					) : (
						data?.data?.data.map((subsection) => (
							<div className='    my-3 col-md-6 col-lg-3 col-sm-6  col-xs-10 col-8'>
								<Bounce>
									<Link
										className='card section_hover mx-2    '
										to={`/WinterCourses/${subsection.sub_section_id}`}
										state={{ item: subsection }}
										style={{ height: '350px ' }}>
										<div>
										<img
											alt={subsection.sub_section_name}
											src={
												process.env.REACT_APP_STORAGE_URL +
												subsection.sub_section_image
											}
												// src={img}
												width='100%'
												height='200px'
												className=''
											/>

											<div className='section_desc mt-4'>
												<h4
													style={{ color: 'black' }}
													class='  section_title_itself rounded-x fw-bold '>
													{subsection.sub_section_name}
												</h4>
												{/* <p
													style={{ color: 'black', fontSize: '1.3rem' }}
													className='section_desc_itself'>
													يحتوي هذا القسم على العديد من الكورسات المهمة
												</p> */}
											</div>
										</div>

										{/* <Button variant="primary fw-bold">اختيار</Button> */}
									</Link>
								</Bounce>
							</div>
						))
					)}
					{data?.code == 204 ? <h2>No Data🤷‍♂️</h2> : null}
				</div>
			</div>

			{!data?.data ? null : data?.data?.total <= 8 ? null : (
				<Pagination
					data={data?.data}
					handlePageClick={handlePageClick}
					page={page}
				/>
			)}
			<div className='my-5'></div>
		</>
	);
}
