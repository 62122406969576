import React from 'react';
import { toast } from 'react-toastify';
const Toast = ({
	successMessage,
	ErrorMessage,
	setSuccessMessage,
	setErrorMessage,
}) => {
	const showSuccess = () => {
		toast.success(successMessage, {
			position: 'top-right',
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
		});
		setSuccessMessage('');
	};

	const showError = () => {
		console.log('errrrrrr', ErrorMessage.split(' \n'));
		toast.error(
			<div dir='rtl' className='text-right'>
				{ErrorMessage.split('\n').map((i) => {
					return (
						<span>
							{i}
							<br />
						</span>
					);
				})}
			</div>,
			{
				position: 'top-right',
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			}
		);
		setErrorMessage('');
	};
	return (
		<div>
			{successMessage?.length > 0 && showSuccess()}
			{ErrorMessage?.length > 0 && showError()}
		</div>
	);
};

export default Toast;
