import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useUploadForm } from "../../../../customhook/Uploadfiles";
import {
  setError,
  setSuccess,
} from "../../../../redux-toolkit/reducers/notification";
let allowedExtension = ["image/jpeg", "image/jpg", "image/png", "image/bmp"];
export default function Add_Lesson_To_Unit_Logic({ setLoading }) {
  const { UnitId, SubjectId } = useParams();

  let navigate = useNavigate();

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      points: "",
      first_video: "",
      subject_id: "",
      second_video: "",
      third_video: "",
      transable: "0",
      duration: "",
      isFree: 0,
      auto_ordering: 1 ,
      lesson_order: null
    },
  });

  useEffect(() => {
    setValue("subject_id", SubjectId);
  }, []);

  const {
    isSuccess: add_lesson_success,
    uploadForm: add_lesson,
    progress: add_lesson_progress,
    IsError: add_lesson_error,
    data: add_lesson_data,
    IsLoading: add_lesson_loading,
  } = useUploadForm("/add_lesson", {
    setLoading,
    setSuccess,
    setError,
    SuccessMsg: "تم إضافة الدرس",
    redirectURL: "..",
  });

  useEffect(() => {
    if (add_lesson_success)
      navigate(
        `/options/mysubjects/showallunit/${SubjectId}/${UnitId}/show_lessons`
      );
  }, [add_lesson_success]);

  const onSubmit = async (data) => {
    const add_lesson_form = new FormData();
    console.log("dataaaaaa212", data.requirements);
    add_lesson_form.append("name", data.name);
    add_lesson_form.append("description", data.description);
    add_lesson_form.append("points", data.points);
    add_lesson_form.append("first_video", data.first_video);

    add_lesson_form.append('auto_ordering' , data.auto_ordering ? 1 : 0);
    
    if(!data.auto_ordering){
      add_lesson_form.append('lesson_order' , data.lesson_order );
    }

    data.second_video &&
      add_lesson_form.append("second_video", data.second_video);
    data.third_video && add_lesson_form.append("third_video", data.third_video);
    add_lesson_form.append("duration", data.duration);
    add_lesson_form.append("isFree", data.isFree);
    add_lesson_form.append("subject_id", SubjectId);

    add_lesson_form.append("requirements", data.requirements);
    add_lesson_form.append("unit_id", UnitId);
    add_lesson_form.append("transable", data.transable);
    add_lesson_form.append("cover", cover);
    add_lesson_form.append("what_we_will_learn", data.what_we_will_learn);

    for (var pair of add_lesson_form.entries()) {
      console.log(pair[0]+ ', ' + pair[1]); 
  }
    await add_lesson(add_lesson_form);
  };

  const [cover_error, setcover_error] = useState("");
  const [cover, setcover] = useState(null);
  const cover_Ref = useRef();

  const handlecover = (e) => {
    if (
      e.target.files[0].size / (1024 * 1024) < 8 &&
      allowedExtension.indexOf(e.target.files[0].type) > -1
    ) {
      setcover_error("");
      setcover(e.target.files[0]);
    } else {
      setcover(null);
      setcover_error("يرجى اختيار صورة بلاحقة jpg,png ");
      cover_Ref.current.value = "";
    }
  };
  return {
    register,
    errors,
    isDirty,
    isValid,
    handleSubmit,
    handlecover,
    cover,
    cover_Ref,
    cover_error,
    add_lesson_error,
    add_lesson_progress,
    add_lesson_success,
    add_lesson_loading,
    onSubmit,
    watch
  };
}
