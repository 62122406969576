import axios from "axios";

  export default async function  fetchAllLessonFaqs ({queryKey})
 {
     
console.log(queryKey);

 
 const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/get_all_faqs_by_lesson_id/${queryKey[1]}?limit=${queryKey[2]}` ,
 {
  headers: {
    'Authorization': `Bearer ${queryKey[3]}` 
  } 
});




   return  data;
 } 