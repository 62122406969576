import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useUploadForm } from '../../customhook/Uploadfiles';
import { signupdone } from '../../redux-toolkit/reducers/Auth/Auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
	setError,
	setSuccess,
} from '../../redux-toolkit/reducers/notification';

export default function SignupLogic() {
	const dispatch = useDispatch();
	const form = useRef(null);
	const [stepactiv, setstepactive] = useState(1);
	const [certificates, setcertificates] = useState([]);
	const [languages, setlanguages] = useState([]);
	const [selectedlang, setselectedlang] = useState([]);
	const [validcheck, setvalidCheck] = useState(false);
	const [error, seterror] = useState('');
	const navigator = useNavigate();
	const [rol_id, setrol_id] = useState();
	const [loading, setloading] = useState();
	const [successMessage,setSuccessMessage] = useState('');
	const [errorMessage,setErrorMessage] = useState('');

	useEffect(() => {
		console.log(window.location.pathname);
		window.location.pathname == '/signup/student' && setrol_id(1);
		window.location.pathname == '/signup/teacher' && setrol_id(2);
	}, []);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			username: '',
			email: '',
			phone_number: null,
			password: '',
			name: '',
			gender: 'MALE',
			image: '',
			is_accept: 0,
			country_id: null,
			role_id: rol_id,
		},
	});

	const onSubmit = async (data) => {
		setloading(true);
		const formdata = new FormData();

		formdata.append('username', data.username);
		formdata.append('email', data.email);
		formdata.append('phone_number', data.phone_number);
		formdata.append('password', data.password);
		formdata.append('name', data.name);
		formdata.append('gender', data.gender);
		formdata.append('image', data?.image[0]);
		formdata.append('is_accept', data.is_accept ? 1 : 0);
		formdata.append('country_id', data.country_id);
		formdata.append('role_id', rol_id);

		const dataform = {
			username: formdata.get('username'),
			email: formdata.get('email'),
			phone_number: formdata.get('phone_number'),
			password: formdata.get('password'),
			name: formdata.get('name'),
			image: formdata.get('image'),
			gender: formdata.get('gender'),
			country_id: formdata.get('country_id'),
			is_accept: formdata.get('is_accept'),
			role_id: formdata.get('role_id'),
		};

		axios
			.post(`${process.env.REACT_APP_API_URL}/signup`, formdata)
			.then((res) => {
				console.log('res is ........', res);

				setloading(false);
				localStorage.setItem('token', res.data?.data?.token);
				localStorage.setItem('role', rol_id);
		
				setSuccessMessage('تمت إنشاء حسابك بنجاح');

				setTimeout(() => {
					window.location.replace('/');
				}, 700);
			})
			.catch((err) => {
				console.log('error ', err)
				if(err.response.data.message) {
					setErrorMessage(err.response.data.message);
				}else {
					setErrorMessage('حدثت مشكلة ما يرجى المحاولة مرة أخرى');
					
				}
				setloading(false);
			});
	};

	return {
		register,
		handleSubmit,
		errors,
		onSubmit,
		isDirty,
		isValid,
		loading,
		watch,
		errorMessage,
		setErrorMessage,
		successMessage,
		setSuccessMessage
	};
}
