import React, { useState } from 'react';

import Layout from '../Layout/Layout';

import './Offers.css';
import OffersLogic from './OffersLogic';

import MainHeader from '../../components/MainHeader/MainHeader';
import OfferDetails from './OfferDetails';
import SubTitle from '../../components/SubTitle/SubTitle';
import Bounce from 'react-reveal';

const Offers = () => {
	const { offersData, data, page, handlePageClick, isError, error, isLoading } =
		OffersLogic();

	console.log('offersData', offersData);
	console.log('isError', error);
	return (
		<Layout>
			{/* <div className='sideBar__relative'>
              <SideBar />
       </div> */}

			{/* <MainHeader /> */}

			<div className='d-flex Profile'>
				<div className='d-flex side__left__content'>
					{/* <div className='d-flex side__left'>
                <h4>العروض</h4>
        </div> */}
					<SubTitle title={'العروض'} />
					<div className='container offers'>
						<div className='Content__imgs'>
							{/* <OfferDetails index={0} />
							<OfferDetails index={1} /> */}

							{isLoading && (
								<div className='text-center my-5'>
									<div class='spinner-border' role='status'>
										<span class='visually-hidden'>Loading...</span>
									</div>
								</div>
							)}

							{error?.response?.data?.message && (
								<div className='parent_noOffers'>
									{' '}
									<div className='d-flex justify-content-center align-items-center alert alert-danger noOffers'>
										{' '}
										<h3>{error?.response?.data?.message}</h3>{' '}
									</div>{' '}
								</div>
							)}

							{offersData &&
								offersData.length &&
								offersData.map((item, index) => (
									<Bounce right>
										<div className=' offer_container  mt-3'>
											<OfferDetails
												index={index}
												image={
													process.env.REACT_APP_STORAGE_URL + item.offer_image
												}
												desc={item.offer_text}
											/>

											{/* <OfferDetails /> */}
											{/* <Roll left>
                <div className='d-flex justify-conten-center align-items-center IMG'>
                  <img src={Chulk} className='ms-3'/>
                   <p >{item.offer_text}</p>
               </div>
           
          </Roll>
          <Roll right>
                   <img className='offer_image' src={process.env.REACT_APP_STORAGE_URL+item.offer_image}/>
            </Roll> */}
										</div>
									</Bounce>
								))}
						</div>
					</div>
				</div>
			</div>
			{/* {data?.data?.total <= 10  ? <> </> : <Pagination data={data} handlePageClick={handlePageClick} page={page} />  }   */}
		</Layout>
	);
};

export default Offers;
