import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetData } from '../../../../customhook/getDatawithAuth';

export default function LessonQuizesLogic({ api_url }) {
	const { getDatafrom, isSuccess, progress, IsLoading, IsError, data } =
		GetData(`${api_url}`);

	useEffect(() => {
		getDatafrom();
	}, []);
	return {
		isSuccess,
		progress,
		IsLoading,
		IsError,
		data,
	};
}
