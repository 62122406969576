//this slice for LessonComments

import {
	createSlice,
	createAsyncThunk,
	createDraftSafeSelector,
} from '@reduxjs/toolkit';
export const getAllComments = createAsyncThunk(
	'categories/getAllCategories',

	//lesson
	async (id, thunkApi) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}/get_all_comments_of_lesson_by_lesson_id/${id}?limit=10`
			);
			const data = await res.json();
			if (res.ok) {
				console.log('YES');
				console.log('data', data);
				return data;
			} else {
				console.log('NO', data);
				return thunkApi.rejectWithValue(data);
			}
		} catch (error) {
			console.log('errorLesson', error);
			return thunkApi.rejectWithValue(error.message);
		}
	}
);

const initialState = {
	post: [],
	loading: false,
	error: null,
};

export const AllCommentsSlice = createSlice({
	name: 'comments',
	initialState,
	reducers: {},
	extraReducers: {
		[getAllComments.pending]: (state, action) => {
			state.loading = true;
			state.error = null;
		},
		[getAllComments.fulfilled]: (state, action) => {
			state.loading = false;
			console.log('fulfilled', action.payload);
			state.post = action.payload;
		},
		[getAllComments.rejected]: (state, action) => {
			console.log('rejected_categories', action);
			state.loading = false;
			state.error = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = counterSlice.actions

export default AllCommentsSlice.reducer;
