

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const postSubscribtionStudent  = createAsyncThunk(
    "postOpinion/postOpinionwrite",
    async ({
        subject_id,
        }, thunkAPI) => {
      try {
        
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/post_user_opinions`,
          {
            method: "POST",
            headers: {
            // "Access-Control-Allow-Origin": "*",
        // 'X-CSRF-TOKEN':token.content,
            // 'X-Requested-With': 'XMLHttpRequest',
              "Authorization":"Bearer 1|QPFWFFU3USajdEpY3LN2tGtxGjpyQA44x4K93a2z",
              "Accept": "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "subject_id":subject_id,
            }),
          }
        )
        let data = await response.json()
        console.log("dataOpinionSlice", data)
        if (response.ok) {
            console.log('response from postOpinionSlice',response);
        //   localStorage.setItem("token", data.token)
          return { ...data, subject_id:subject_id,  }
        } else {
            console.log(data);
          return thunkAPI.rejectWithValue(data)
        }
      } catch (e) {
        console.log("Error", e.response.data)
        return thunkAPI.rejectWithValue(e.response.data)
      }
    }
  )


  const initialState={
    post:[],
    loading:false,
    error:null,
}

export const PostStudentSubscribtionSlice = createSlice({
  name: 'opinion',
  initialState,
  reducers: {},
  extraReducers:{
    [postSubscribtionStudent.pending]: (state,action)=>{
        state.loading =true;
        state.error=null;
    },
    [postSubscribtionStudent.fulfilled]: (state,action)=>{
        state.loading = false;
        console.log("fulfilled_postOpinion",action.payload);
        state.post = action.payload;
    },
    [postSubscribtionStudent.rejected]:  (state,action)=>{
        console.log("rejected_aboutUs",action);
        state.loading=false;
        state.error=action.payload;
    },
  }
})



export default  PostStudentSubscribtionSlice.reducer






