import React, { useState } from 'react';
import './index.css';
import rightAnswer from '../../assets/icons/rightcheckbox.png';
import wrongAnswer from '../../assets/icons/wrongcheckbox.png';
import icon from '../../assets/automatedquiz/c5f0fa5ac14327b8330fde1c621ffa8a.jpg';
function Questino({
	question,
	options,
	marks,
	questionId,
	showAnswersToUser = false,
	hanldeStudentAnswer,
	notes,
}) {
	const [state, setState] = useState({ checked: false });
	const [answers, setAnswers] = useState({});
	const [positions, setPositions] = useState([]);
	const [showNote, setshowNote] = useState();
	const handlechange = (event, option_id) => {
		// // disable activated checkbox
		// if(event.target.checked){
		//     event.target.checked = false;
		// }else {
		//     event.target.checked = true;
		// }

		hanldeStudentAnswer(questionId, option_id);

		let inputs = document.querySelectorAll(`#question_${questionId}`);
		for (let i = 0; i < inputs.length; i++) {
			if (inputs[i] != event.target) {
				inputs[i].checked = false;
			}
		}

		setAnswers({ question_id: questionId, answer_id: option_id });
		console.log({ question_id: questionId, answer_id: option_id });
	};

	return (
		<div className={`question`}>
			<div className='question__container'>
				<div className='topsection'>
					<h4 dangerouslySetInnerHTML={{ __html: question }}>{}</h4>
					<span>{`( ${marks}${marks == 1 ? 'mark' : 'marks'} )`}</span>
				</div>
				{/* <div>
					<img
						className={`mt-1 `}
						onMouseEnter={() => setshowNote(!showNote)}
						onMouseLeave={() => setshowNote(!showNote)}
						style={{ width: '1.8rem' }}
						src={icon}
					/>
					{showNote && <sapn>{notes}</sapn>}
				</div> */}

				<div className='answers'>
					{showAnswersToUser
						? options.map((answer, id) => {
								return (
									<div
										className={`answerItself ${
											answer.is_true ? 'rightAnswer' : null
										} ${!answer.is_true ? 'wrongAnswer' : null}`}>
										<label
											className='fs-4'
											dangerouslySetInnerHTML={{ __html: answer.answear }}>
											{}
										</label>

										{/* <input 
                            type='checkbox' 
                            className={`${ answer.is_true ? 'hidecheckbox' : ''}`}  
                            disabled 
                            onChange={(e) => handlechange(e)} 
                            value={answer.text}
                             name={id} 
                             /> */}

										<div className='statusImage'>
											{answer.is_true == 1 ? (
												<img
													style={{ width: '30px', height: '30px' }}
													src={rightAnswer}
													alt=''
												/>
											) : null}
											{answer.is_true != 1 && answers.answer_id == answer.id ? (
												<img
													style={{ width: '20px' }}
													src={wrongAnswer}
													alt=''
												/>
											) : null}
										</div>
									</div>
								);
						  })
						: options.map((answer, id) => {
								return (
									<div className={`answerItself`}>
										<input
											className='mx-2 mt-2 '
											style={{
												width: '18px',
												height: '18px',
												borderRadius: '30%',
											}}
											disabled={
												localStorage.getItem('role') == 2 ||
												localStorage.getItem('role') == 3
													? true
													: false
											}
											type='radio'
											name={`answer${questionId}`}
											id={`question_${questionId}`}
											onChange={(e) => handlechange(e, answer.id)}
										/>
										<label
											className='mt-2 fs-4'
											dangerouslySetInnerHTML={{ __html: answer.answear }}
											style={{ width: '10rem' }}>
											{}
										</label>
									</div>
								);
						  })}
				</div>
			</div>
		</div>
	);
}
// <img src={wrongAnswer} alt=""/>

// className={`${answer.checked &&  ? 'hidecheckbox' : ''}`}
export default Questino;
