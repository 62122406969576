import React, { useEffect, useState } from 'react';
import './index.css';
import comment from './../../assets/icons/comment.png';
import { useDispatch, useSelector } from 'react-redux';
import {
	postOpinionfun,
	getSuccess,
	getError,
} from '../../redux-toolkit/reducers/PostOpinion/postOpinionSlice';
import Toast from '../ToastSuccessError/ToastSucessError';
import { getLoading } from '../../redux-toolkit/reducers/SubjectComments/SubjectCommentsSlice';
import ButtonM from '../ButtonM/ButtonM';

function LeaveComment(props) {
	//****************************************************************** */
	const [opinion, setOpinion] = useState('');

	const { postDataComment, loading } = props;

	const dispatch = useDispatch();
	const onSubmit = () => {
		const data = {
			token: localStorage.getItem('token'),
			opinion: opinion,
			comment: opinion,
			...props,
		};
		if (opinion.length > 0) {
			dispatch(postDataComment(data));
		}
	};

	//********************************************************************** */

	//********************************************************************** */
	return (
		<>
			<div className='LeaveComment'>
				<h4 style={{ color: '#044364' }} className='fw-bold my-3'>
					<img src={comment} alt='' className='m-2 comment_image ' />
					أكتب تعليق
				</h4>

				{/* <div className='LeaveComment__header'>
					<img src={numbersicon} alt='' />
					<h4>أترك رايك هنا</h4>
				</div> */}

				<div className='LeaveComment__textField'>
					<textarea
						rows='4'
						cols='50'
						value={opinion}
						placeholder=''
						onChange={(e) => setOpinion(e.target.value)}
					/>
				</div>

				<div className='LeaveComment__sendBtn' onClick={() => onSubmit()}>
					{loading ? (
						<div class='spinner-border' role='status'>
							<span class='sr-only'>Loading...</span>
						</div>
					) : (
						<ButtonM text={'إرسال'} className='mx-auto' />
						// <button>إرسال</button>
					)}
				</div>
			</div>
		</>
	);
}

export default LeaveComment;
