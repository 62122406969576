import axios from 'axios';

export default async function fetchStudents({ queryKey }) {
	// {       console.log('sss'.repeat(30), queryKey)
	//           console.log("process",process.env.REACT_APP_API_URL)
	//           console.log('queryKey[2]' , queryKey[2]);

	const { data } = await axios.get(
		`${process.env.REACT_APP_API_URL}/get_all_students_that_have_quizzes_by_teacher/${queryKey[2]}?page=${queryKey[1]}&q=${queryKey[3]}`,
		{
			params: { limit: 30 },
			headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
		}
	);

	//   console.log('data'.repeat(20), data);

	return data;
}
