import React, { useState, useEffect } from 'react';

import './index.css';
import Slider from '../../components/slider';
import { FileUploader } from 'react-drag-drop-files';

import Attachment from '../../components/attachment';
import clock from '../../assets/icons/clock.png';
import point from '../../assets/icons/point.png';
import Layout from '../Layout/Layout';
import ExamLogic from './examLogic';
import ToastSuccessError from '../../components/ToastSuccessError/ToastSucessError';
import { useSelector } from 'react-redux';
import axios from 'axios';
import CountDown from '../../components/CountDoun';
import pdfImg from '../../assets/icons/pdfImg.png';
import camera from '../../assets/icons/camera.png';
import timerimg from '../../assets/icons/egg-timer.png';
import {
	clearMsg,
	getError,
	getSuccess,
} from '../../redux-toolkit/reducers/notification';
import { useParams } from 'react-router-dom';
import MainHeader from '../../components/MainHeader/MainHeader';
function ExamScreen() {
	const { id } = useParams();
	const [timer, settimer] = useState();
	const [visibleTmer, setvisibleTmer] = useState(false);
	// Sroll to top
	useEffect(() => {
		const intervalId = setInterval(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		}, 1);

		setTimeout(() => {
			clearInterval(intervalId);
		}, 50);
	}, []);
	const {
		fileTypes,
		examTime,
		startExam,
		examIsFinished,
		file,
		allFiles,
		handleChange,
		AnswersFile,
		setStartExam,
		setExamIsFinished,
		QuizQuestions,
		QuizDetails,
		SavedAnswersFiles,
		setloading,
		loading,
		loadingDetailes,
		loadingPostAnswer,
	} = ExamLogic();
	console.log(QuizQuestions);
	const startQuiz = () => {
		setStartExam(true);
		!QuizDetails.has_started_quiz_before &&
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/start_traditional_quiz_by_traditional_quiz_id`,
					{
						traditional_quiz_id: id,
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					}
				)
				.then((res) => {
					settimer(res?.data?.data?.remaining_time);

					console.log('end  Time', res?.data?.data);
				})
				.catch((err) => {});
	};
	useEffect(() => {
		if (timer) {
			setvisibleTmer(true);
		} else if (QuizDetails?.quiz_remaining_time) {
			setvisibleTmer(true);
		} else {
			setvisibleTmer(false);
		}
	}, [timer, QuizDetails?.quiz_remaining_time]);
	const successMessage = useSelector(getSuccess);
	const errorMsg = useSelector(getError);

	return (
		<Layout>
			{/* <MainHeader /> */}
			<div className='examScreen'>
				<div className='row justify-content-center text-center my-5'>
					<h1 className='fw-bold mb-4'>
						{loadingDetailes ? (
							<div class='spinner-border' role='status'>
								<span class='sr-only'>Loading...</span>
							</div>
						) : (
							<span style={{ color: '#044364' }}>{QuizDetails?.quiz_name}</span>
						)}
					</h1>
					<div className='row justify-content-center'>
						<div className='col-lg-4 col-md-5 col-sm-10 col-10 my-2'>
							<img src={clock} alt='book' width={'30px'}></img>
							{loadingDetailes ? (
								<div class='spinner-border' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							) : (
								<span className='mx-2 fs-3'>
									{' '}
									مدة الاختبار :
									{'د' + QuizDetails?.quiz_duration &&
									QuizDetails?.quiz_duration > 60
										? Math.floor(QuizDetails?.quiz_duration / 60)
										: QuizDetails?.quiz_duration}
									دقيقة
								</span>
							)}
						</div>
						<div className='col-lg-4 col-md-5 col-sm-10 col-10'>
							<img
								className='mx-2'
								src={point}
								alt='point'
								width={'30px'}></img>
							{loadingDetailes ? (
								<div class='spinner-border' role='status'>
									<span class='sr-only'>Loading...</span>
								</div>
							) : (
								<span className='mx-2 fs-3'>
									عدد النقاط:{' '}
									{QuizDetails?.quiz_points && QuizDetails?.quiz_points} نقطة{' '}
								</span>
							)}
						</div>
					</div>

					{/* <img
							src={
								unitDetails &&
								`${process.env.REACT_APP_STORAGE_URL}${
									unitDetails && unitDetails.unit_cover
								}`
							}
							alt='cover'
						/> */}
				</div>
				<div className='examScreen__explanatino'>
					{/* <h2>شرح عن الاختبار</h2> */}
					<ul style={{ listStyle: 'none', fontSize: '1.2rem' }}>
						<li>{QuizDetails && QuizDetails.quiz_description}</li>
						<li>
							تحتوي معظم الدورات التدريبية على تقييمات تفاعلية تختبر معلوماتك ،
							مثل التقييم أدناه. يمكن أن يكونوا جزءًا من تسلسل تعليمي أو حتى
							اختبار ، لكننا سننتقل إلى ذلك بعد ذلك.
						</li>
						<li>
							تتضمن بعض المشكلات زر إظهار الإجابة الذي قد يتم عرضه قبل أو بعد
							محاولتك الإجابة على مشكلة. يمكنك أيضًا إعادة تعيين المشكلة إذا كنت
							ترغب في البدء من جديد.
						</li>
						<li>
							يمكنك إرسال الإجابات عدة مرات حسب حاجتك. ، حدد إجابتك ثم انقر أو
							انقر فوق إرسال لمعرفة ما إذا كانت إجابتك صحيحة.
						</li>
					</ul>
				</div>
				<>
					{' '}
					{localStorage.getItem('role') == 1 ? (
						<>
							{QuizDetails?.has_answerd_before ? (
								<>
									<div class='alert alert-secondary text-center' role='alert'>
										لقد انجزت الإختبار سابقا , يمكنك رؤية نتيجتك من قسم
										الإختبارات
									</div>
								</>
							) : (
								<>
									{startExam ? (
										<div className='examItself'>
											<div className='examItself__content'>
												{visibleTmer && startExam && (
													<div className='row '>
														<div className='col-lg-2 col-4 text-center'>
															{' '}
															<img
																src={timerimg}
																alt='timer'
																height={'70px'}></img>
														</div>
														<div className='col-lg-3 col-8 py-2'>
															<CountDown
																start={startExam}
																endTime={
																	QuizDetails &&
																	QuizDetails?.quiz_remaining_time
																		? QuizDetails?.quiz_remaining_time
																		: timer
																}
															/>
														</div>
													</div>
												)}
												{!visibleTmer && QuizDetails?.has_started_quiz_before && (
													<div class='alert alert-dark' role='alert'>
														يمكنك إرسال الإجابات لكن لن تحصل على النقاط
													</div>
												)}

												<div className='questions'>
													<span className='fs-4 mx-5 fw-bold'>الأسئلة :</span>
													<iframe 
													src={process.env.REACT_APP_STORAGE_URL + QuizDetails.questions_file } 
													height="500px" 
													width="100%" 
													/>
													{/* <Slider>
														{QuizQuestions &&
															QuizQuestions.map((img, index) => {
																return (
																	<img
																		src={`${process.env.REACT_APP_STORAGE_URL}${img.image_link}`}
																		className='imageAnswer'
																		alt=''
																		key={index}
																		onClick={() =>
																			window.open(
																				`${process.env.REACT_APP_STORAGE_URL}${img.image_link}`
																			)
																		}
																	/>
																);
															})}
													</Slider> */}

													<span className='fs-4 mx-5 fw-bold my-4'>
														{' '}
														الأجوبة :
													</span>

													<div className='questions__answersContainer my-5'>
														<div className='uploadFile'>
															<FileUploader
																className='holako'
																handleChange={handleChange}
																name='file'
																types={fileTypes}
															/>
															<div className='addimage'>
																{' '}
																<img
																	className='mx-2'
																	src={camera}
																	alt=''
																	height={'40px'}
																	width={'40px'}
																/>
																<span style={{ color: 'rgba(0,0,0,0.5)' }}>
																	ضع صورة الأجوبة هنا
																</span>
															</div>
														</div>
														{
															// dist ... static width of the images
															file ? (
																<Slider dist={200} gap={30}>
																	{allFiles.map((img, index) => {
																		return (
																			<img
																				src={img}
																				onClick={() => window.open(img)}
																				className='imageAnswer'
																				alt=''
																			/>
																		);
																	})}
																</Slider>
															) : null
														}
													</div>
												</div>
											</div>

											<div className='finishExamBtn'>
												{examIsFinished ? (
													<div className='examFinished'>تم إنهاء الاختبار</div>
												) : loadingPostAnswer ? (
													<div class='spinner-border' role='status'>
														<span class='sr-only'>Loading...</span>
													</div>
												) : (
													<button onClick={() => SavedAnswersFiles()}>
														إنهاء الاختبار
													</button>
												)}
											</div>
											{examIsFinished && AnswersFile ? (
												<>
													<h5 className='fw-bold  mx-5 my-5'>
														<span style={{ color: '#044364' }}>
															إجابات {QuizDetails?.quiz_name}
														</span>
													</h5>
													<div className='lessonAnswers'>
														<Attachment
															title='  '
															ladder_file={`${process.env.REACT_APP_STORAGE_URL}/${AnswersFile}`}
															btn1Text={
																<span>
																	تحميل PDF{' '}
																	<img
																		className=''
																		width={'22px'}
																		height='22px'
																		src={pdfImg}></img>
																</span>
															}
															btn2Text='تصفح'
														/>
													</div>
												</>
											) : null}
										</div>
									) : (
										<div className='BtnContainer'>
											{loading ? (
												<div className='text-center w-100'>
													<button onClick={startQuiz} className='startExamBtn'>
														{QuizDetails?.quiz_remaining_time &&
														QuizDetails?.quiz_remaining_time
															? '	الاستمرار بالاختبار'
															: '	البدء بالاختبار'}
													</button>
												</div>
											) : (
												<div class='spinner-border' role='status'>
													<span class='sr-only'>Loading...</span>
												</div>
											)}
										</div>
									)}
								</>
							)}
						</>
					) : (
						<>
							<div className='examItself'>
								<div className='examItself__content'>
									<div className='questions'>
										<ul>
											<li>الأسئلة</li>
										</ul>
										<iframe 
										src={process.env.REACT_APP_STORAGE_URL + QuizDetails.questions_file } 
										height="500px" 
										width="100%" 
										/>
										{/* <Slider>
											{QuizQuestions &&
												QuizQuestions.map((img, index) => {
													return (
														<img
															src={`${process.env.REACT_APP_STORAGE_URL}${img.image_link}`}
															className='imageAnswer'
															alt=''
															key={index}
															onClick={() =>
																window.open(
																	`${process.env.REACT_APP_STORAGE_URL}${img.image_link}`
																)
															}
														/>
													);
												})}
										</Slider> */}
									</div>
								</div>
							</div>
						</>
					)}
				</>
			</div>
			<ToastSuccessError
				ErrorMessage={errorMsg}
				clearMsg={clearMsg}
				successMessage={successMessage}
			/>
		</Layout>
	);
}

export default ExamScreen;
