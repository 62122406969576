import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { clearMsg } from '../../../../redux-toolkit/reducers/notification';
import ToastSuccessError from '../../../ToastSuccessError/ToastSucessError';
import Add_Lesson_To_Unit_Logic from './Add_Lesson_To_Unit_Logic';
import LessonRequiermentInput from '../../../lessonRequirementInput/lessonRequiermentInput';
export default function Add_Lesson_To_Unit() {
	const [loading, setLoading] = React.useState(false);
	const successMessage = useSelector((state) => state.notification.isSuccess);
	const errorMessage = useSelector((state) => state.notification.isError);
	const [req, setReq] = useState([]);
	useEffect(() => console.log('helllp', arrayToString(req)), []);
	console.log('outside');
	const arrayToString = (array) => array.join(',');
	const _new = arrayToString(req);

	const {
		handlecover,
		register,
		errors,
		isDirty,
		isValid,
		cover_Ref,
		cover_error,
		add_lesson_progress,
		add_lesson_error,
		add_lesson_success,
		add_lesson_loading,
		onSubmit,
		handleSubmit,
		watch
	} = Add_Lesson_To_Unit_Logic({ setLoading }, req);

	return (
		<>
			<ToastSuccessError
				successMessage={successMessage}
				ErrorMessage={errorMessage}
				clearMsg={clearMsg}
			/>
			<div className='container Add_traditional_quiz card shadow p-5 my-5'>
				<h4 className='fw-bold text-center mb-5' style={{ color: '#044364 ' }}>
					إنشاء درس جديد
				</h4>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className='row g-3 needs-validation'
					noValidate>
					<div className='col-md-6'>
						<label
							htmlFor='name'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اسم الدرس
						</label>
						<input
							type='text'
							className='form-control'
							id='name'
							{...register('name', { required: true })}
						/>
						{errors.name && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='points'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							نقاط الدرس
						</label>
						<input
							type='number'
							{...register('points', { required: true })}
							className='form-control'
							id='descriptionQuize'
						/>
						{errors.points && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='first_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الأول
						</label>
						<input
							type='url'
							{...register('first_video', { required: true })}
							className='form-control'
							id='first_video'
						/>
						{errors.first_video && (
							<p className='text-danger'>الفيديو الأول مطلوب</p>
						)}
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='duration'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							المدة (بالدقائق)
						</label>
						<input
							type='time'
							className='form-control'
							id='duration'
							{...register('duration', { required: true })}
						/>
						{errors.duration && (
							<div className='text-danger'>Invalid input</div>
						)}
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='second_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الثاني
						</label>
						<input
							type='url'
							{...register('second_video')}
							className='form-control'
							id='second_video'
						/>
						{errors.video && <p className='text-danger'>Invalid input</p>}
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='isFree'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							هل هو مجاني
						</label>
						<br />
						<select id='isFree' {...register('isFree', { required: true })}>
							<option value='1'>نعم</option>
							<option value='0'>لا</option>
						</select>
					</div>
					<div className='col-md-6'>
						<label
							htmlFor='third_video'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							رابط الفيديو الثالث
						</label>
						<input
							type='url'
							{...register('third_video')}
							className='form-control'
							id='third_video'
						/>
						{errors.video && <p className='text-danger'>Invalid input</p>}
					</div>

					<div className='col-md-6'>
						<label
							htmlFor='cover'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							اختر صورة غلاف
						</label>
						<input
							ref={cover_Ref}
							title='يرجى اختيار صورة'
							onChange={handlecover}
							accept='image/jpeg, image/jpg, image/png,image/bmp'
							type='file'
							class='form-control'
							id='cover'
							aria-label='file example'
							required
						/>
						{cover_error ? (
							<div className='text-danger'>{cover_error}</div>
						) : (
							<></>
						)}
					</div>

					{/* <div className="col-md-4">
            <label htmlFor="start_date" className="form-label">
              {" "}
              تاريخ البداية
            </label>
            <input
              type="date"
              {...register("start_date", { required: true })}
              className="form-control"
              id="start_date"
            />
            {errors.start_date && <p className="text-danger">Invalid input</p>}
          </div> */}

					{/* <div className="col-md-4">
            <label htmlFor="end_date" className="form-label">
              تريخ الانتهاء{" "}
            </label>
            <input
              type="date"
              {...register("end_date", { required: true })}
              className="form-control"
              id="end_date"
            />
            {errors.end_date && <p className="text-danger">Invalid input</p>}
          </div> */}

					<div>
						<label
							htmlFor='requirements'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							{' '}
							المتطلبات (يجب وضع فاصلة بين المتطلبات )
						</label>
						<textarea
							type='text'
							placeholder='رياضيات,فيزياء,'
							{...register('requirements', { required: true })}
							className='form-control'
							id='requirements'
						/>
						{errors.start_date && <p className='text-danger'>Invalid input</p>}
					</div>

					<div>
						<label
							htmlFor='what_we_will_learn'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							ماذا سوف نتعلم؟
						</label>
						<textarea
							onChange={handlecover}
							type='text'
							class='form-control'
							id='what_we_will_learn'
							{...register('what_we_will_learn', { required: true })}
						/>
						{errors.what_we_will_learn && (
							<div className='text-danger'>Invalid input</div>
						)}
					</div>
					<div>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							وصف الدرس
						</label>
						<textarea
							{...register('description', { required: true })}
							className='form-control '
							id='description'
						/>
						{errors.description && <p className='text-danger'>Invalid input</p>}
					</div>

					<div>
						<label
							htmlFor='description'
							className='form-label fs-5'
							style={{ color: '#044364 ' }}>
							ترتيب الدرس
						</label>
						<div>
						<input
							type="checkbox"
							{...register('auto_ordering')}
							className='d-inline'
							id='description'
							defaultChecked={true}
						/>
							<p className='d-inline mx-2'>ترتيب الدرس تلقائيا  </p>

						</div>
						{/* {errors.description && <p className='text-danger'>Invalid input</p>} */}
					</div>


					{
						( watch('auto_ordering') != 1 || !watch('auto_ordering') ) 
						&&

						<div>
						<label
								htmlFor='description'
								className='form-label fs-5'
								style={{ color: '#044364 ' }}>
								أدخل ترتيب الدرس
							</label>
	
	
							<input
								{...register('lesson_order')}
								className='form-control '
								id='description'
								type={'number'}
								min='1'
								placeholder='الرجاء إدخال ترتيب الدرس هنا ... '
							/>
													<div className='alert alert-danger shadow border rounded'>
								<stronge>تحذير هام!!!</stronge> في حال كان ترتيب الدرس المدخل تابع لدرس آخر عندها يجب تغيير ترتيب الدرس الآخر بشكل يدوي
							</div>
							{/* {errors.lesson_order && <p className='text-danger'>الرجاء ادخال ترتيب الدرس</p>} */}
						</div>
					}


					{add_lesson_progress ? (
						<div class='progress'>
							<div
								class='progress-bar progress-bar-striped'
								role='progressbar'
								style={{ width: `${add_lesson_progress}%` }}
								aria-valuenow='10'
								aria-valuemin='0'
								aria-valuemax='100'></div>
							<label className='text-center'>{add_lesson_progress}% </label>
						</div>
					) : (
						<></>
					)}

					<div className='col-12 text-center'>
						{loading ? (
							<span class='spinner-grow'></span>
						) : (
							<button
								// disabled={!isDirty || !isValid || cover_error}
								className='btn btn1  w-50 mx-auto'
								type='submit'>
								إضافة الدرس
							</button>
						)}

						{add_lesson_error && (
							<label className='text-center w-100 text-danger'>
								{add_lesson_error?.response?.data
									? add_lesson_error.response.data.message
									: add_lesson_error.message}
							</label>
						)}
					</div>
					{add_lesson_success && (
						<div class='alert alert-success text-center' role='alert'>
							تمت إضافة الدرس بنجاح
						</div>
					)}
				</form>
			</div>
		</>
	);
}
