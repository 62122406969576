import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../../pages/Layout/Layout';
import LayoutSideStudent from '../../../pages/LayoutSide/LayoutSideStudent';
import MainHeader from '../../MainHeader/MainHeader';
import UnitQuizCoreectedLogic from './UnitQuizCorresctedLogic';
import axios from 'axios';
export default function UnitQuizCoreected() {
	const token = localStorage.getItem('token');
	const [isSuccess, setIsSuccess] = useState(false);
	const [progress, setProgress] = useState(0);
	const [IsLoading, setIsLoading] = useState(false);
	const [IsError, setIsError] = useState('');
	const [data, setData] = useState(null);
	const [corrected, setcorrected] = useState(true);
	// const { isSuccess, progress, IsLoading, IsError, data } =
	// 	UnitQuizCoreectedLogic({ corrected });
	// console.log(data);

	useEffect(() => {
		console.log('yes '.repeat(100));
	}, []);
	useEffect(() => {
		
			getDatafrom('/get_student_unit_traditional_quizzes');
		// else getDatafrom('/get_student_uncorrected_unit_traditional_quizzes');
	}, []);

	const getDatafrom = async (api) => {
		setIsLoading(true);

		axios.withCredentials = true;

		await axios
			.get(`${process.env.REACT_APP_API_URL}` + api, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'multipart/form-data',

					Accept: 'application/json',
					'Content-Encoding': 'gzip , deflate, br',
				},

				onUploadProgress: (progressEvent) => {
					const progress = Math.floor(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					setProgress(progress);
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const { data } = res;
					console.log('the fucking url is => ', api);
					console.log('sucess data da da da da', data);
					setIsError('');
					setIsSuccess(true);
					setProgress(0);
					setTimeout(() => setIsSuccess(false), 4000);

					return setData(data);
				}
			})
			.catch((error) => {
				setProgress(0);
				console.log('error data', error);
				return setIsError(error);
			})
			.finally(() => setIsLoading(false));
	};

	return (
		<Layout>
			{/* <MainHeader /> */}
			<LayoutSideStudent desc={'الاختبارات'}>
				<div className='container-fluid text-center '>
					<h3 className='fw-bold  my-5 container ' style={{ color: '#044364' }}>
						الاختبارات
					</h3>
					<div
						className=' quiz_card_sub text-center   mx-auto my-5 '
						style={{ width: '25%' }}>
						<h5 className='text-center text-white  fs-4 fw-bold'>تقليدي </h5>
					</div>
					<div className='row'>
						{/* <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div
								onClick={() => {
									setData(null);
									setcorrected(true);
								}}
								className=' quiz_card text-center    mx-auto my-5 '
								style={{
									width: '50%',
									backgroundColor: 'white',
									border: '3px solid rgba(0,0,0,0.05)',
								}}>
								<h5
									className='text-center   fs-4 fw-bold'
									style={{ color: !corrected ? '#044364' : '#EF0000' }}>
									مصحح{' '}
								</h5>
							</div>
						</div> */}
						{/* <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
							<div
								onClick={() => {
									setData(null);
									setcorrected(true);
								}}
								className=' quiz_card text-center   mx-auto my-5 '
								style={{
									width: '50%',
									backgroundColor: 'white',
									border: '3px solid rgba(0,0,0,0.05)',
								}}>
								<h5
									className='text-center   fs-4 fw-bold'
									style={{ color: !corrected ? '#EF0000' : '#044364' }}>
									غير مصحح{' '}
								</h5>
							</div>
						</div> */}
					</div>

					{IsLoading && (
						<div class='d-flex justify-content-center my-5'>
							<div class='spinner-grow text-dark' role='status'>
								<span class='visually-hidden'>Loading...</span>
							</div>
						</div>
					)}

					{
						<div style={{ width: '70%', textAlign: 'center', margin: 'auto' }}>
							{/* <table className='table border  mt-3 teacherTable pl-5 pr-5'> */}
							{/* <thead>
									<tr>
										<th>#</th>
										<th> االوحدة </th>
										<th> اسم الاختبار </th>
										<th> الوصف</th>
										<th> المدة</th>
										<th>النقاط</th>
										<th>نوع الاختبار</th>
										{corrected && <th>النتيحة </th>}
									</tr>
								</thead> */}
							{data?.data && (
								<table className='table table-striped table-bordered'>
									{/* <div
										className='row justify-content-space-between point_card py-5 my-5'
										style={{ fontSize: '1.2rem' }}> */}

											<thead>
												<th>الوحدة</th>
												<th>الاختبار</th>
											</thead>
										{/* <span className='col-lg-3 col-md-6 col-sm-6 col-12 text-black text-center my-3 '>
											الوحدة
										</span> */}

										{/* <span
											className=' col-lg-3 col-md-6 col-sm-6 col-12 text-center my-3'
											style={{ color: '#044364' }}>
											الاختبار
										</span> */}

										{/* <span
											className=' col-lg-2 col-md-4 col-sm-4 col-12 text-center my-3'
											style={{ color: '#3B3B3B', opacity: '0.5' }}>
											حالة الاختبار
										</span> */}

										{/* <span
											className=' col-lg-2 col-md-4 col-sm-4 col-7 text-center my-3'
											style={{ color: '#044364' }}>
											نوع الاختبار
										</span> */}
										{/* {!corrected && ( */}
										{/* <span
											className=' col-lg-2 col-md-4 col-sm-4 col-5 text-center my-3'
											style={{ color: '#3B3B3B' }}>
											النتيجة
										</span> */}
										{/* )} */}
										<tbody>
										{data?.data?.map((quiz, index) => (
											<tr>
												<td>
												<Link
													className='col-lg-3 col-md-6 col-sm-6 col-12 text-black text-center my-3 '
													key={quiz.id}
													state={{ quizDetailes: quiz }}
													to={`show/${quiz.quiz_id}`}>
													<span> {quiz.quiz_unit_name} </span>
												</Link>
												</td>
												<td>

												{quiz.quiz_name}{' '}
												</td>
												{/* <span
													className=' col-lg-3 col-md-6 col-sm-6 col-12 text-center my-3'
													style={{ color: '#044364' }}>
													{' '}
													{quiz.quiz_name}{' '}
												</span> */}

												{/* <span
													className=' col-lg-2 col-md-4 col-sm-4 col-12 text-center my-3'
													style={{ color: '#3B3B3B', opacity: '0.5' }}>
													{' '}
													{corrected ? (
														<span>مصحح</span>
													) : (
														<span style={{ color: '#EF0000' }}>غيرمصحح</span>
													)}
												</span> */}
												{/* 
												<span
													className='col-lg-2 col-md-4 col-sm-4 col-7 text-center my-3'
													style={{ color: '#044364' }}>
													{' '}
													{quiz.isAboveLevel == 1
														? 'فوق المستوى'
														: 'نهائي'}{' '}
												</span> */}
												{/* {corrected ? (
													<span
														className=' col-lg-2 col-md-4 col-sm-4 col-5 text-center my-3'
														style={{ color: '#3B3B3B' }}>
														{quiz.deserved_mark}%
													</span>
												) : (
													<span
														className=' col-lg-2 col-md-4 col-sm-4 col-5 text-center my-3'
														style={{ color: '#3B3B3B' }}>
														__
													</span>
												)} */}
											</tr>
										))}
										</tbody>
									{/* </div> */}
								</table>
							)}
							{IsError && !IsLoading && (
								<div
									className='row justify-content-space-between point_card py-5 my-5'
									style={{ fontSize: '1.2rem' }}>
									<label className='text-danger'>
										{IsError?.response?.data?.message
											? IsError?.response?.data?.message
											: IsError.message}
									</label>
								</div>
							)}
							{/* </table> */}
						</div>
					}
				</div>
			</LayoutSideStudent>
		</Layout>
	);
}
