import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import './MainContent.css';
import MainContentLogic from './MainContentLogic';
import learning from '../../assets/learning.png';
import SubTitle from '../SubTitle/SubTitle';
import logo from '../../assets/logo/logo.png';
import Zoom from 'react-reveal/Zoom';
export default function MainContent({ data }) {
	const { socialInfo } = MainContentLogic();
	const navigate = useNavigate();
	return (
		<>
			{/* start main content  */}

			<div className='main-content p-5 text-center'>
				<div className='d-flex flex-column justify-content-center     fw-bold  dataContent'>
					<div className='  mx-auto text-center cardData mb-3'>
						<img
							className='mx-auto   bacgroundWhaite'
							width={'280px'}
							height={'120px'}
							src={logo}
						/>
						<h1
							className='my-2'
							// style={{ color: '#C50507' }}
						>
							أكاديمية الشرق الاوسط
						</h1>
						<p className='mt-3 mx-auto mb-5 mt-3 w-sm-100 w-md-50 text-center fontsize '>
							احصل على المساعده الدراسيه التي تحتاجها واستمتع بتجربه تعلميه
							ممتعة
						</p>
						{!localStorage.getItem('token') && (
							<div className='d-flex justify-content-center align-items-center gap-2'>
								<button
									className=' btnBackground   '
									onClick={() => navigate('/signin')}>
									تسجيل الدخول
								</button>
								<button
									className=' btnBackground '
									onClick={() => navigate('/signup')}>
									انشاء حساب
								</button>
							</div>
						)}
						<img
							src={learning}
							alt=''
							className='background_main_content_image'
						/>
					</div>
				</div>
			</div>

			<div className='     '>
				{/* <div className=' my-4  container  text-center '>
					{
					// data?.main_video && (
					// 	<div className='mt-4'>
					// 		<SubTitle
					// 			title={'فيديو توضيحي عن الأكاديمية'}
					// 			className='mb-4'
					// 		/>
					// 		<Zoom>
					// 			<div className='main_video_section mx-auto'>
					// 				<iframe
					// 					className='vidio_ifram'
					// 					src={process.env.REACT_APP_STORAGE_URL + data?.main_video}
					// 					title='YouTube video'
					// 					allowFullScreen></iframe>
					// 			</div>
					// 		</Zoom>
					// 	</div>
					// )
					}
				</div> */}
			</div>
		</>
	);
}
